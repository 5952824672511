import Vue from 'vue'
import messages from './messages'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

let locale = navigator.language

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en-US',
  messages
})

export default i18n
