//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable vue/no-v-html */
import dataRequester from '../../mixins/utilities/DataRequester'
import inputHelper from '../../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "Register",
  mixins: [dataRequester, inputHelper],
  data: function () {
    return {
      fullName: '',
      username: '',
      password: '',
      confirmPassword: '',
      phoneNo: '',
      email: '',
      facebook: '',
      metaData: {
        logoPath: '',
        siteName: '',
        footNote: '',
        shortFootNote: '',
        availableLang: ''
      }
    }
  },
  mounted: function () {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    this.setReferralToLocalStorage()

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }

    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.shortFootNote = metaData.domainObjectList['shortfootnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
    }, function () {
      self.$toasted.error(self.$t('domain.toast.errMetaData'))
    })
  },
  methods: {
    FixBugTheme,
    register: function () {
      let self = this
      if (this.password !== this.confirmPassword) {
        this.$toasted.error(this.$t('register.toast.differentPassword'))
      } else if (!this.validateEmail(this.email)) {
        this.$toasted.error(this.$t('register.toast.invalidEmail'))
      } else {
        let userInfo = {
          full_name: this.fullName,
          password: this.password,
          email: this.email,
          phone_no: this.phoneNo,
          facebook: this.facebook,
          ref: this.$cookies.get('ref')
        }

        this.submitObjectList(this.$config.apiPath.register, userInfo, 'user', undefined, function (result) {
          self.$cookies.set('username', result.data.username, '90D')
          self.$cookies.set('sessionId', result.data.sessionId, '90D')
          if (self.$cookies.get('redirectAfterLogin') !== null) {
            self.$router.push(self.$cookies.get('redirectAfterLogin'))
            self.$cookies.remove('redirectAfterLogin')
          } else {
            self.$router.push('/#/home/vps')
          }
          self.$toasted.success(result.msg)
          self.$emit('loginEvent', result.data)
        })
      }
    },
    login: function () {
      this.$router.push('/login')
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    },
    setReferralToLocalStorage: function () {
      if (this.$route.query.ref !== undefined) {
        this.$cookies.set('ref', this.$route.query.ref, '30D')
      }
      if (this.$route.query.r !== undefined) {
        this.$cookies.set('ref', this.$route.query.r, '30D')
      }
    }
  }
}
