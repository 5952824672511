//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable vue/no-v-html */
/* eslint-disable vue/require-default-prop */
import Vuetable from 'vuetable-2'
import _ from 'lodash'
import moment from 'moment'
import md5 from 'md5'

import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue"
import VuetablePaginationMixin from "vuetable-2/src/components/VuetablePaginationMixin"

import LevPagination from "@/components/children/LevTablePagination"

import { FixBugTheme } from '@/mixins/MaterialTheme';
export default {
  name: "LevTable",
  components: {
    Vuetable,
    LevPagination
  },
  mixins: [VuetablePaginationMixin],
  props: {
    fields: Array,
    searchalbeFields: Array,
    extendedFields: Array, //Format: Array of object {name: '', title: '', order: '', titleClass: '', dataClass: '' , default: '', visible: true|false, formatter: function}
    visibleFields: Array,
    tableData: Array,
    perPageSetting: Array,
    onCellClickProcessing: Function,
    searchTextProps: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      levTable: {
        data: [],
        pagination: {},
        sortOrder: {},
        perPageSetting: [10, 20, 30, 50, 100, 'All']
      },
      levTableCss: {
        table: {
          tableClass: 'table table-striped table-bordered dataTable-table levtable',
          sortableIcon: 'fas fa-lg fa-sort smaller',
          ascendingIcon: 'fas fa-lg fa-sort-alpha-up smaller',
          descendingIcon: 'fas fa-lg fa-sort-alpha-down-alt smaller'
        },
        pagination: {
          perPage: 20
        }
      },
      selectedPerPage: 20,
      searchText: ''
    }
  },
  computed: {

  },
  watch: {
    selectedPerPage: function () {
      let self = this
      if (this.selectedPerPage === 'All') {
        this.levTableCss.pagination.perPage = 1000000
      } else {
        this.levTableCss.pagination.perPage = parseInt(this.selectedPerPage)
      }
      this.$nextTick(() => {
        self.$refs.levtable.refresh()
      })
    },
    tableData: {
      handler: function () {
        // console.log('changing')
        this.subFuncInitLevTableData()
        this.$nextTick(this.showHideFields)
      },
      deep: true
    },
    searchTextProps: function () {
      this.searchText = this.searchTextProps
    },
    searchText: function () {
      let self = this
      if (this.searchText === '' || this.searchText === undefined) {
        this.subFuncTextSearchLevTableData()
      } else {
        _.debounce(function () {
          let searchTextTrimmed = self.searchText.trim().replace(new RegExp('\\$', 'g'), '')
          self.subFuncTextSearchLevTableData()
          self.levTable.data = self.levTable.data.filter(function (obj) {
            return self.searchalbeFields.some(function (k) {
              if (obj[k] === null || obj[k] === undefined) {
                return false
              } else if (self.isNumber(obj[k])) {
                if (typeof (obj[k]) === 'number') {
                  return obj[k].toFixed(2).toString().includes(searchTextTrimmed)
                } else {
                  return obj[k].toString().includes(searchTextTrimmed)
                }
                // @todo fix compare date
              } else if (obj[k].length === 24 && obj[k].endsWith('Z') && moment(obj[k].substring(0, 10), '"YYYY-MM-DD').isValid()) {
                return moment(obj[k]).format("YYYY-MM-DD").includes(searchTextTrimmed)
              } else {
                return obj[k].toString().toLowerCase().includes(searchTextTrimmed.toLowerCase())
              }
            })
          })
          self.showHideFields()
        }, 800)()
      }
      // this.$nextTick(this.showHideFields)
      this.$nextTick(this.showHideFieldsForSearch)
    },
    visibleFields: function () {
      this.showHideFields()
    }
  },
  mounted: function () {
    this.FixBugTheme()
    if (this.perPageSetting !== undefined) {
      this.levTable.perPageSetting = this.perPageSetting
    }
  },
  methods: {
    FixBugTheme,
    getFields: function () {
      this.allFields = [{
        name: VuetableFieldCheckbox,
        title: 'checkbox',
        titleClass: "center",
        dataClass: "center",
        width: "2%",
        togglable: true
      }
    ].concat(this.fields)
      if (this.extendedFields !== undefined && this.extendedFields.length > 0) {
        let self = this
        let extendedFieldsWithOrder = this.extendedFields.filter(f => f['order'] !== undefined)
        extendedFieldsWithOrder.forEach(function (value, key) {
          self.allFields.splice(value['order'], 0, value)
          self.allFields.join()
        })
        self.allFields = self.allFields.concat(this.extendedFields.filter(f => f['order'] === undefined))
      }
      this.allFields.forEach(function (field) {
        if (field['title'] !== 'checkbox') {
          field['titleClass'] = 'text-bolder text-uppercase'
        }
      })
      return this.allFields
    },
    dataManager(sortOrder, pagination) {
      let local = this.levTable.data

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        )
      }

      pagination = this.$refs.levtable.makePagination(
          local.length,
          this.levTableCss.pagination.perPage
      );
      // console.log('pagination:', pagination)
      let from = pagination.from - 1
      let to = from + this.levTableCss.pagination.perPage
      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.levtable.changePage(page);
    },
    onCheckbox() {
      this.$emit('selected-changed', this.$refs.levtable.selectedTo)
    },
    onCellClick(event) {
      let self = this
      this.onCellClickProcessing(event, event.data, event.field, function (rowValueUpdated, rowClassUpdated) {
        if (rowClassUpdated !== undefined) {
          self.$refs.levtable.refresh()
        }
      })
    },
    onExtendedCellClick(event, rowData, field) {
      let self = this
      this.onCellClickProcessing(event, rowData, field, function (rowValueUpdated, rowClassUpdated) {
        if (rowClassUpdated !== undefined) {
          self.$refs.levtable.refresh()
        }
      })
    },

    // sub functions
    subFuncInitLevTableData: function () {
      let self = this
      this.levTable.data = this.tableData

      // @todo fix init table
      if (this.searchText !== undefined && this.searchText !== '') {
        let searchTextTrimmed = self.searchText.trim().replace(new RegExp('\\$', 'g'), '')
        self.levTable.data = self.levTable.data.filter(function (obj) {
          return self.searchalbeFields.some(function (k) {
            if (obj[k] === null || obj[k] === undefined) {
              return false
            } else if (self.isNumber(obj[k])) {
              if (typeof (obj[k]) === 'number') {
                return obj[k].toFixed(2).toString().includes(searchTextTrimmed)
              } else {
                return obj[k].toString().includes(searchTextTrimmed)
              }
              // @todo fix compare date
            } else if (obj[k].length === 24 && obj[k].endsWith('Z') && moment(obj[k].substring(0, 10), '"YYYY-MM-DD').isValid()) {
              return moment(obj[k]).format("YYYY-MM-DD").includes(searchTextTrimmed)
            } else {
              return obj[k].toString().toLowerCase().includes(searchTextTrimmed.toLowerCase())
            }
          })
        })
      }

      this.levTable.data = this.levTable.data.map(function (row) {
        let extendedProperties = {}
        self.extendedFields.forEach(function (ef, key) {
          if (row[ef['name']] === undefined || row[ef['name']] === '') {
            extendedProperties[ef['name']] = typeof(ef['extended_data']) === 'function' ? ef['extended_data'](row) : ef['extended_data']
          }
        })
        // Merge properties 2 objects
        return {... row, ...extendedProperties}
      })
    },
    // sub functions
    subFuncTextSearchLevTableData: function () {
      let self = this
      if (this.extendedFields === undefined || this.extendedFields.length === 0) {
        this.levTable.data = this.tableData
      } else {
        this.levTable.data = this.tableData.map(function (row) {
          let extendedProperties = {}
          self.extendedFields.forEach(function (ef, key) {
            if (row[ef['name']] === undefined || row[ef['name']] === '') {
              extendedProperties[ef['name']] = typeof(ef['extended_data']) === 'function' ? ef['extended_data'](row) : ef['extended_data']
            }
          })
          // Merge properties 2 objects
          return {... row, ...extendedProperties}
        })
      }
    },
    showHideFields: function () {
      let self = this
      if (this.visibleFields !== undefined && this.visibleFields.length > 0) {
        let allFields = self.$refs.levtable.tableFields
        for (let i = 0; i < allFields.length; i++) {
          if (self.visibleFields.includes(allFields[i].name) ||
              allFields[i].name === VuetableFieldCheckbox) { // Always show checkbox column
            self.$refs.levtable.showField(i)
          } else {
            self.$refs.levtable.hideField(i)
          }
        }
      }
      this.$refs.levtable.reload()
    },
    showHideFieldsForSearch: function () {
      let self = this
      if (this.visibleFields !== undefined && this.visibleFields.length > 0) {
        let allFields = self.$refs.levtable.tableFields
        for (let i = 0; i < allFields.length; i++) {
          if (self.visibleFields.includes(allFields[i].name) ||
              allFields[i].name === VuetableFieldCheckbox) { // Always show checkbox column
            self.$refs.levtable.showField(i)
          } else {
            self.$refs.levtable.hideField(i)
          }
        }
      }
      this.$refs.levtable.refresh()
    },
    // Keep the utility function here to make the component more portable
    isNumber: function (value) {
      return !isNaN(parseFloat(value)) && !isNaN(value - 0)
    }
  }
}
