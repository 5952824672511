export default {
  en: {
    breadcrumb: 'VPS Detail',
    cardBody: {
      titleGeneral: 'General information',
      titleAddon: 'Addon',
      titleNetwork: 'Network information',
      titlePayment: 'Payment information',
      titleControl: 'Control',
      titlePortMap: 'Port mapping table'
    },
    table: {
      tdTitleStatus: 'Status',
      tdTitleMainIP: 'VPS IPv4 & Port',
      tdTitleRGCName: 'RGC Server Name',
      tdTitleOutgoing: 'Outgoing IP',
      tdTitleDedicatedIP: 'Dedicated IP',
      tdTitleCPU: 'CPU',
      tdTitleRAM: 'RAM',
      tdTitleDisk: 'Hard disk',
      tdTitleSpec: 'Specification',
      tdTitleOS: 'OS',
      tdTitleUser: 'Default username',
      tdTitlePass: 'Default password',
      tdClickToCopy: 'Click to copy',
      tdTitleLocation: 'Location',
      tdTitleCpuOptimization: 'CPU Optimization',
      tdTransferLimit: 'Transfer limit',

      tdTitleLocalIP: 'Local IP',
      tdTitleLocalMask: 'Local netmask',
      tdTitleLocalGw: 'Local gateway',

      tdTitlePrice: 'Price',
      tdTitleStart: 'Start date',
      tdTitleEnd: 'Due date',
      tdTitlePay: 'Payment status',
      tdTitlePlan: 'Base plan',
      tdTitleAutoRenew: 'Automatically renewal',
      tdTitleTotalAddonPrice: 'Total addon cost',
      tdTitleTotalPrice: 'Total price',
      tdTitleBackup: 'Backup',
      tdTitleAddon: 'Addon',
      tdTitleNote: 'Your note',

      tdTitleAddonName: 'Addon',
      tdTitleAddonPrice: 'Price',
      tdTitleAddonAmount: 'Amount',

      tdDataOK: 'OK',
      tdDataOverdue: 'Over due',
      tdDataSuspend: 'Suspended',
      tdDataCancel: 'Cancelled',
      tdDataUnknown: 'Unknown',
      tdDataChecking: 'Checking',
      tdDataRunning: 'Running',
      tdDataStopped: 'Stopped',
      tdDataSuccess: 'Success',
      tdDataError: 'Error',
      tdDataManage: 'Manage',
      tdDataStarting: 'Starting...',
      tdDataShuttingDown: 'Shutting down...',
      tdDataStopping: 'Stopping...',
      tdDataRestarting: 'Restarting...',
      tdDataReinstalling: 'Reinstalling OS...',
      tdDataTakingSnap: 'Taking snapshot...',
      tdDataRestoring: 'Restoring...',
      tdAutoRenewEnabled: 'Enabled',
      tdAutoRenewDisabled: 'Disabled',
      tdDataEachMonth: 'each month',

      tdServiceName: 'Service name',
      tdNormalPort: 'Normal port',
      tdMappedPort: 'Map port',
      tdCustomPort: 'Your custom ports',
      tdOptimizeNormal: 'Optimize for performance',
      tdOptimizeForEmulator: 'Optimize for emulator',

      tdOwner: 'Owner',
      tdCo_Owner: 'Co-Owner'
    },
    tooltip: {
      copyRemark: 'Copy your note',
      editRemark: 'Edit your note',
      tdClickToCopy: 'Click to copy',
      owner_vps: '{owner_full_name}\'s VPS',
      co_owner_vps: 'Shared for {co_owner_full_name}'
    },
    modal: {
      titleEditRemark: 'Edit your note for {0}',
      titleAddonDetail: 'Addon list',
      bodyEditRemark: 'You can take a note for each VPS so it will be easier to identify in case you have a lot of VPS.',
      buttonEditRemark: 'Edit',

      bodyRGCCreateWarning: 'If you use custom remote port, fill this to here',
      bodyDatalossWarning: '<strong>Warning:</strong>This action is the same as press/hold the power button on your PC. It will cut off the power of the VPS' +
        ' and make the VPS {0} immediately. Therefore, some recent data that is currently being saved on disk may be lost. ' +
        ' Use this function only when the VPS is not responding. ' + '<br/>We strongly recommend you to shutdown/restart the VPS INSIDE it.',

      titleConfirmRebuild: 'Confirm reinstall this VPS',
      bodyRebuildWarning: '<strong>Warning:</strong> This action will erase your disk and cannot be recovered!',
      bodySelectOS: 'Select OS image to rebuild',

      titleConfirmSnap: 'Confirm take snapshot VPS',
      bodyConfirmSnapshot: 'Do you really want to take snapshot for this VPS?',
      bodySnapshotWarning: '<strong>Warning - Please read entirely</strong><ul>' +
        '<li>There are some steps you have to do before taking snapshot:<ol>' +
        '<li>Delete all the unnecessary files from the snapshot to keep it light.</li>' +
        '<li>Keep our "Fast VPS Config tool" starts with Windows or you won\'t have network access.</li>' +
        '<li>Shutdown VPS INSIDE the VPS to prevent data loss.</li></ol></li>' +
        '<li>Snapshot is provided free, so please don\'t abuse it. <strong>It is not a back up function</strong>,' +
        ' it is intended for "VPS CLONING". Therefore, there are some restriction:' +
        '<ol><li>You have only 1 snapshot quota. You can delete it and re-create a new one.</li>' +
        '<li>You can create a new snapshot once a week.</li></ol></li>',

      titleConfirmRestore: 'Confirm restore snapshot to this VPS',
      titleConfirmChangeCpuOPtimization: 'Confirm change CPU optimization',
      titleConfirmTransfer: 'Confirm transfer VPS',
      titleConfirmShare: 'Confirm share VPS',
      bodySelectSnapshot: 'Select snapshot to restore',
      bodySelectCpuOptimization: 'Select CPU optimization',
      bodyRestoreWarning: '<strong>Warning:</strong> Restore snapshot will erase your disk and all current data will be lost!',
      bodyCpuOptimizationWarning: '<strong>Warning:</strong> This feature will change the CPU configuration to optimize usage. Please attention when changing default options.',
      titleConfirmCancel: 'Confirm cancel VPS',
      bodySelectCancelMode: 'Select cancel method',
      bodyCancelModeEndOfBilling: 'End of billing',
      bodyCancelModeImmediate: 'Immediate',
      bodyConfirmCancel: 'Do you really want to cancel this VPS?',
      bodyCancelWarning: '<h5 class="text-danger">Warning:</h5></br><ol>' +
        'There are 2 modes of cancelling: Immediate and End of billing.' +
        '<ul>' +
        '<li>End of billing: Your VPS will be cancelled at the end of billing period. No notification/email will be sent for it anymore.</li>' +
        '<li>Immediate: Your VPS will be deleted right after you cancelled. No prorate refund will be issued even if your VPS still have unused time.</li>' +
        '</ul>' +
        'Please contact us immediately if you cancel the VPS by mistake.',
      titleConfirmChange: 'Generate change invoice for this VPS',
      titleConfirmRenew: 'Generate renew invoice for this VPS',
      titleConfirmSetAutoRenew: 'Enable automatic renewal for this VPS',
      titleConfirmCancelAutoRenew: 'Disable automatic renewal for this VPS',
      titleConfirmBackup: 'Enable automatically backup for this VPS',
      titleConfirmCreateRGC: 'Confirm create RGC this VPS',
      titleConfirmDeleteRGC: 'Confirm delete RGC URL this VPS',
      titleConfirmDisableBackup: 'Confirm delete backup this VPS',
      titleConfirmRevokeCancellation: 'Confirm revoke cancellation this VPS',
      bodySelectBackupPeriod: 'Select backup period',
      selectBackupPeriod7: 'Weekly backup',
      selectBackupPeriod1: 'Daily backup',
      bodyConfirmChange: 'The system will generate an invoice to change this VPS.',
      bodyConfirmRenew: 'The system will generate an invoice to renew this VPS.',
      bodySelectRGC: 'Select RGC server to create',
      bodySelectTeamMember: 'Select team member, who you want to share',
      bodyConfirmDeleteRGC: 'Do you really want to delete RGC URL this VPS?',
      bodyConfirmDisableBackup: 'Do you really want to delete backup this VPS?',
      bodyConfirmSetAutoRenew: 'You are about to set automatic renewal for this VPS. The system will automatically generate an' +
        ' invoice to renew 3 days before the due date. If you have sufficient balance, your balance will be automatically deducted.<br/>',
      bodyConfirmCancelAutoRenew: 'You are cancelling automatic renewal for this VPS. You can turn it on back anytime. You can also' +
        ' renew it manually.<br/>',
      bodyConfirmTransfer: 'Please enter the email of the user that you want to transfer this VPS.<br/>' +
        'For security reason, if you enter wrong email address more than 5 times, this feature will be blocked.',
      bodyTransferWarning: '<h5 class="text-danger">Warning:</h5></br><ol>' +
        '<li>We WILL NOT touch your data, so if you don\'t want to transfer data, simply rebuild the VPS before transferring. ' +
        'If you don\'t rebuild the VPS, the received person will have access to your data.</li>' +
        '<li>Any due invoices belong to you, the received person will pay the next cycle invoice.</li>' +
        '</ol>>',
      bodyChangeWarning: 'You will be taken to the invoice page to review and pay the invoice.</br>',
      bodyRenewWarning: 'You will be taken to the invoice page to review and pay the invoice.</br>',
      bodyBackupWarning: '<dl><dt>Weekly backup</dt><dd>Weekly backup are provided free in the beta test. However, you should enable this feature if you really need it ' +
        'because it will slow down your VPS a little bit. The disk fail rate is just 0.11% per month, therefore software installing and setting might not be needed ' +
        'to backup. You should use snapshot for it.</dd><br/>' +
        '<dt>Daily backup</dt><dd>-For daily backup, please contact support and pay the invoice to enable. Daily backups are charged at $0.03/GB. Your VPS is using ' +
        '{0} GB of disk, so it will cost $ {1}/month.</dd></dl>',
      bodyTeamMember: '<strong>Attention:</strong> When you share vps, team member can control this vps like your permission. But, you still are payment for this VPS. So, you need check to end date and make a bill.',
      bodyRevokeCancellationWarning: 'Are you sure you want to revoke cancellation the VPS?',
      buttonCancel: 'Cancel',
      buttonRestore: 'Restore',
      buttonConfirm: 'Confirm',
      buttonSnapshot: 'Snapshot',
      buttonRebuild: 'Reinstall',
      buttonCancelVPS: 'Confirm cancellation',
      buttonChange: 'Generate invoice',
      buttonRenew: 'Generate invoice',
      buttonSetAutoRenew: 'Enable auto renew',
      buttonCancelAutoRenew: 'Disable auto renew',
      buttonTransfer: 'Transfer',
      buttonRDP: 'Generate RDP Profile',
      buttonEnableBackup: 'Enable backup',
      buttonDisableBackup: 'Disable backup',
      buttonCreate: 'Create',
      buttonDelete: 'Delete',
      buttonShare: 'Share',
      buttonDeleteShare: 'Stop Sharing',
      buttonRevokeCancellation: 'Revoke cancellation',
      period: 'Period renew',
      month: 'Months',
    },
    button: {
      start: 'Start',
      shutdown: 'Shutdown',
      stop: 'Stop',
      restart: 'Restart',
      console: 'Web remote',
      rdp: 'Export RDP',
      createRGC: 'Create RGC',
      deleteRGC: 'Delete RGC',
      reinstall: 'Reinstall OS',
      snapshot: 'Snapshot',
      restore: 'Restore',
      transfer: 'Transfer',
      change: 'Change',
      renew: 'Renew',
      setAutoRenew: 'Enable auto renew',
      cancelAutoRenew: 'Disable auto renew',
      cancel: 'Cancel',
      enableBackup: 'Enable backup',
      disableBackup: 'Disable backup',
      team: 'Share',
      cpuOptimization: 'CPU Optimization',
      revokeCancellation: 'Revoke cancellation',
    },
    toast: {
      errPendingTask: 'Another action is in progress! Please wait!',
      okRebuildRequestSent: 'Sent rebuild request to VPS. Please wait about 10 minutes!',
      okRestoreRequestSent: 'Sent restore request to VPS. Please wait about 10 minutes!',
      okExportRDP: 'Successfully exported Remote Desktop profile!',
      okCopy: 'Copied: {0}.',
      noBackup: 'You don\'t have any backup yet!',
      rgcCreating: 'Creating RGC forwarded port for {0}',
      rgcDelete: 'Deleting RGC URL of {0}',
      cpuOptimizationNoChange: 'CPU Optimization not changed. This action is not counted.',
    },
    label: {
      portMap: 'Port mapping is our technology to enable incoming connection to your NAT VPS. You will have 100 ports mapped ' +
        'to your VPS. Some regular ports are already mapped. If you are using other ports, you have to change your application\'s port ' +
        'to one of your enabled port.<br/>' +
        'For example: If you are using XAMPP or Web server on this VPS, you can connect to your web server at: {0}.',
      localNetwork: 'Your VPS is on a virtual LAN. If it cannot get a correct IP, please configure it as follow:',
      note: 'Note',
      cpuOptimizationForEmulator: 'Optimize for emulator',
      cpuOptimizationNormal: 'Optimize for performance',
    }
  },
  vi: {
    breadcrumb: 'Thông Tin VPS',
    cardBody: {
      titleGeneral: 'Thông tin chung',
      titleAddon: 'Dịch vụ gia tăng',
      titlePayment: 'Thông tin thanh toán',
      titleNetwork: 'Thông tin mạng',
      titleControl: 'Điều khiển',
      titlePortMap: 'Bảng map port'
    },
    table: {
      tdTitleStatus: 'Trạng thái',
      tdTitleMainIP: 'VPS IPv4 & Port',
      tdTitleRGCName: 'RGC Server Name',
      tdTitleOutgoing: 'IP bên ngoài thấy quý khách',
      tdTitleDedicatedIP: 'IP riêng',
      tdTitleCPU: 'CPU',
      tdTitleRAM: 'RAM',
      tdTitleDisk: 'Ổ đĩa',
      tdTitleSpec: 'Cấu hình',
      tdTitleOS: 'Hệ điều hành',
      tdTitleUser: 'ID mặc định',
      tdTitlePass: 'Mật khẩu mặc định',
      tdClickToCopy: 'Ấn để sao chép',
      tdTitleLocation: 'Khu vực',
      tdTransferLimit: 'Lưu lượng giới hạn',

      tdTitleLocalIP: 'IP',
      tdTitleLocalMask: 'Network mask',
      tdTitleLocalGw: 'Gateway',

      tdTitlePrice: 'Giá',
      tdTitleStart: 'Ngày bắt đầu',
      tdTitleEnd: 'Ngày hết hạn',
      tdTitlePay: 'Trạng thái thanh toán',
      tdTitlePlan: 'Gói cơ bản',
      tdTitleAutoRenew: 'Tự động gia hạn',
      tdTitleTotalAddonPrice: 'Dịch vụ gia tăng',
      tdTitleTotalPrice: 'Tổng cộng',
      tdTitleBackup: 'Tự động sao lưu',
      tdTitleAddon: 'Dịch vụ gia tăng',
      tdTitleNote: 'Ghi chú của quý khách',
      tdTitleCpuOptimization: 'CPU tối ưu hoá',

      tdTitleAddonName: 'Addon',
      tdTitleAddonPrice: 'Đơn giá',
      tdTitleAddonAmount: 'Số lượng',

      tdDataOK: 'OK',
      tdDataOverdue: 'Hết hạn',
      tdDataSuspend: 'Tạm khóa',
      tdDataCancel: 'Chờ hủy',
      tdDataUnknown: 'Không biết',
      tdDataChecking: 'Đang kiểm tra',
      tdDataRunning: 'Đang hoạt động',
      tdDataStopped: 'Đã tắt',
      tdDataSuccess: 'Thành công',
      tdDataError: 'Lỗi',
      tdDataManage: 'Chi tiết',
      tdDataStarting: 'Đang bật...',
      tdDataShuttingDown: 'Đang tắt...',
      tdDataStopping: 'Đang dừng...',
      tdDataRestarting: 'Đang khởi động lại...',
      tdDataReinstalling: 'Đang cài đặt hệ điều hành...',
      tdDataTakingSnap: 'Đang tạo snapshot...',
      tdDataRestoring: 'Đang khôi phục...',
      tdAutoRenewEnabled: 'Đã bật',
      tdAutoRenewDisabled: 'Chưa được bật',
      tdDataEachMonth: ' / tháng',
      tdOptimizeNormal: 'Tối ưu hoá hiệu năng',
      tdOptimizeForEmulator: 'Tối ưu hoá cho giả lập',

      tdServiceName: 'Phần mềm/dịch vụ',
      tdNormalPort: 'Port thông thường',
      tdMappedPort: 'Port đã map',
      tdCustomPort: 'Port của quý khách',

      tdOwner: 'Chủ sở hữu',
      tdCo_Owner: 'Đồng sở hữu'
    },
    tooltip: {
      copyRemark: 'Sao chép ghi chú',
      editRemark: 'Chỉnh sửa ghi chú',
      tdClickToCopy: 'Ấn để sao chép',
      owner_vps: '{owner_full_name} sở hữu VPS này',
      co_owner_vps: 'VPS này được chia sẻ cho {co_owner_full_name}'
    },
    modal: {
      titleEditRemark: 'Ghi chú cho VPS {0}',
      titleAddonDetail: 'Danh sách dịch vụ gia tăng',
      bodyEditRemark: 'Thay đổi ghi chú của VPS để giúp việc phân biệt dễ dàng hơn.',
      buttonEditRemark: 'Lưu lại',

      bodyRGCCreateWarning: 'Nếu quý khách dùng custom remote port khác port remote mặc định thì điền vào đây',
      bodyDatalossWarning: '<strong>Chú ý:</strong> Chỉ dùng chức năng này khi VPS bị treo hoặc không thể đăng nhập.' +
        ' Thao tác này y hệt như việc nhấn nút nguồn trên máy tính, ' +
        ' và sẽ khiến VPS {0} ngắt ngay lập tức. Do đó, một số dữ liệu chưa được lưu hoặc đang trong quá trình lưu sẽ bị mất.' + '<br/>Quý khách nên dùng chức năng shutdown/restart BÊN TRONG VPS để đảm bảo an toàn dữ liệu.',

      titleConfirmRebuild: 'Xác nhận cài đặt lại hệ điều hành',
      bodyRebuildWarning: '<strong>Chú ý:</strong> Chức năng này sẽ xóa toàn bộ dữ liệu hiện có và không thể phục hồi. Vui lòng backup dữ liệu!',
      bodySelectOS: 'Vui lòng lựa chọn hệ điều hành',

      titleConfirmSnap: 'Xác nhận tạo snapshot',
      bodyConfirmSnapshot: 'Quý khách có thực sự muốn tạo snapshot?',
      bodySnapshotWarning: '<strong>Chú ý - Vui lòng đọc toàn bộ những lưu ý sau</strong><ul>' +
        '<li>Quý khách cần làm 3 bước sau trước khi tạo snapshot để đảm bảo bản snapshot hoạt động đúng:<ol>' +
        '<li>Xóa tất cả những dữ liệu không cần thiết để đảm bảo bản snpshot nhẹ nhàng và ít nguy cơ xảy ra lỗi trong quá trình tạo.</li>' +
        '<li>Trước khi tạo snapshot, vui lòng tắt - Shutdown VPS ở trong win để tránh việc mất/lỗi dữ liệu.</li></ol></li>' +
        '<li>Chức năng Snapshot được chúng tôi cung cấp miễn phí, vui lòng không lạm dụng. <strong>Chức năng này không được thiết kế như một chức năng backup dữ liệu</strong>,' +
        ' Nó được thiết kế chuyên biệt cho việc sao chép VPS - "VPS CLONING". Chúng tôi cũng áp dụng một số giới hạn để đảm bảo hiệu năng của hệ thống:' +
        '<ol><li>Quý khách chỉ có tối đa 1 bản snapshot. Quý khách có thể xóa và tạo lại 1 bản mới.</li>' +
        '<li>Quý khách chỉ có thể tạo 1 bản snapshot mới một lần mỗi tuần.</li></ol></li>',

      titleConfirmRestore: 'Xác nhận khôi phục dữ liệu từ snapshot',
      titleConfirmChangeCpuOPtimization: 'Xác nhận thay đổi CPU tối ưu hoá',
      titleConfirmTransfer: 'Xác nhận chuyển giao VPS',
      titleConfirmShare: 'Xác nhận chia sẻ quyền sử dụng VPS',
      bodySelectTeamMember: 'Lựa chọn thành viên nhóm mà bạn muốn chia sẻ',
      bodySelectSnapshot: 'Lựa chọn bản snapshot để bung',
      bodySelectCpuOptimization: 'Lựa chọn kiểu tối ưu hoá cho CPU',
      bodyRestoreWarning: '<strong>Chú ý:</strong> Chức năng này sẽ xóa toàn bộ dữ liệu hiện có và không thể phục hồi. Vui lòng backup dữ liệu!',
      bodyCpuOptimizationWarning: '<strong>Chú ý:</strong> Chức năng này sẽ thay đổi config CPU để tối ưu hoá sử dụng. Hãy lưu ý khi thay đổi lựa chọn mặc định.',
      titleConfirmCancel: 'Xác nhận hủy VPS',
      bodyConfirmCancel: 'Quý khách thực sự muốn hủy VPS này?',
      bodySelectCancelMode: 'Chọn phương thức hủy',
      bodyCancelModeEndOfBilling: 'Khi hết hạn',
      bodyCancelModeImmediate: 'Ngay lập tức',
      bodyCancelWarning: '<h5 class="text-danger">Cảnh báo:</h5></br><ol>' +
        'Có 2 cách để quý khách hủy VPS : Hủy khi hết hạn (End of billing) và hủy ngay lập tức (Immediate).' +
        '<ul>' +
        '<li>Khi hết hạn: VPS của quý khách sẽ được hủy khi hết hạn, hệ thống sẽ không nhắn tin hay gửi email cho quý khách.</li>' +
        '<li>Ngay lập tức: VPS của quý khách sẽ được hủy ngay lập tức. Quý khách sẽ không được trả lại số ngày chưa sử dụng.</li>' +
        '</ul>' +
        'Nếu quý khách hủy nhầm VPS hoặc muốn sử dụng lại VPS đã hủy, xin hãy liện hệ hỗ trợ sớm nhất có thể.',
      titleConfirmChange: 'Xác nhận tạo hóa đơn đổi mới VPS',
      titleConfirmRenew: 'Xác nhận tạo hóa đơn gia hạn',
      titleConfirmSetAutoRenew: 'Xác nhận bật tự động gia hạn cho VPS',
      titleConfirmCancelAutoRenew: 'Xác nhận tắt tự động gia hạn cho VPS',
      titleConfirmBackup: 'Xác nhận bật chế độ tự động sao lưu cho VPS',
      titleConfirmCreateRGC: 'Xác nhận quý khách muốn tạo địa chỉ RGC cho VPS này',
      titleConfirmDeleteRGC: 'Xác nhận quý khách muốn xoá địa chỉ RGC cho VPS này',
      titleConfirmDisableBackup: 'Xác nhận quý khách muốn xoá tự động sao lưu cho VPS này',
      titleConfirmRevokeCancellation: 'Xác nhận quý khách muốn hủy yêu cầu hủy VPS',
      bodySelectBackupPeriod: 'Lựa chọn chu kỳ tự động sao lưu',
      selectBackupPeriod7: 'Sao lưu hàng tuần',
      selectBackupPeriod1: 'Sao lưu hàng ngày',
      bodyConfirmChange: 'Hệ thống sẽ tạo 1 hóa đơn đổi mới cho một/nhiều VPS dưới đây.',
      bodyConfirmRenew: 'Hệ thống sẽ tạo 1 hóa đơn gia hạn cho một/nhiều VPS dưới đây.',
      bodySelectRGC: 'Chọn server RGC trung gian',
      bodyConfirmDeleteRGC: 'Quý khách có chắc chắn muốn xoá địa chỉ RGC cho VPS này?',
      bodyConfirmDisableBackup: 'Quý khách có chắc chắn muốn xoá tự động sao lưu cho VPS này?',
      bodyConfirmSetAutoRenew: 'Quý khách đang bật tự động gia hạn cho VPS này. Hệ thống sẽ tự động tạo ra hoá đơn gia hạn cho VPS 3 ngày' +
        ' trước khi hết hạn. Nếu quý khách có đủ số dư tài khoản, hệ thống sẽ tự động trừ số dư tài khoản của quý khách.<br/>',
      bodyConfirmCancelAutoRenew: 'Quý khách đang tắt gia hạn tự động cho VPS này. Quý khách có thể bật lại bất kì lúc nào. Quý khách cũng có thể gia hạn' +
        ' VPS thủ công bằng nút "Gia hạn" tại bảng điều khiển.<br/>',
      bodyConfirmTransfer: 'Quý khách cần điền email của user mà quý khách muốn chuyển giao VPS này.<br/>' +
        'Vì lý do bảo mật, nếu quý khách nhập sai email quá 5 lần, chức năng này sẽ bị tạm khóa.',
      bodyTransferWarning: '<h5 class="text-danger">Lưu ý:</h5></br><ol>' +
        '<li>Chúng tôi sẽ không tác động bất bất cứ điều gì vào dữ liệu của quý khách. Nếu quý khách muốn chuyển giao VPS mà không muốn chuyển giao dữ liệu, hãy cài lại hệ điều hành trước khi chuyển giao. ' +
        'Nếu quý khách không cài lại hệ điều hành, người nhận có toàn quyền truy cập vào dữ liệu của quý khách.</li>' +
        '<li>Các hóa đơn quá hạn sẽ thuộc trách nhiệm thanh toán của quý khách. Người nhận sẽ thanh toán các hóa đơn kì sau.</li>' +
        '</ol>',
      bodyChangeWarning: 'Quý khách sẽ được chuyển đến trang hóa đơn để kiểm tra và tiến hành thanh toán.</br>',
      bodyRenewWarning: 'Quý khách sẽ được chuyển đến trang hóa đơn để kiểm tra và tiến hành thanh toán.</br>',
      bodyBackupWarning: '<dl><dt>Sao lưu hàng tuần</dt><dd>Quý khách được miễn phí sao lưu hàng tuần trong thời gian thử nghiệm tính năng này. Tuy nhiên, quý khách nên cân nhắc' +
        'khi bật chế độ tự động sao lưu vì nó sẽ làm VPS của quý khách xử lý chậm hơn một chút. Chỉ nên bật tính năng này khi dữ liệu của quý khách thực sự quan trọng, ' +
        'mất mát gây thiệt hại lớn. VPS có tỉ lệ gặp sự cố chỉ khoảng 0.11%/tháng, nên việc bật tính năng này để lưu trữ các cài đặt phần mềm là không cần thiết.</dd></br>' +
        '<dt>Sao lưu hàng ngày</dt><dd>Đối với sao lưu hàng ngày, vui lòng liên hệ hỗ trợ để thanh toán và bật sao lưu. Giá backup hàng ngày là $0.03/GB. VPS của quý khách hiện đang sử dụng {0} GB ổ ' +
        'cứng, chi phí sao lưu hàng ngày sẽ là $ {1}/tháng.</dd>',
      bodyTeamMember: '<strong>Chú ý:</strong> Khi chia sẻ VPS, thành viên sẽ có quyền điều khiển và cài đặt giống như bạn. Tuy nhiên, nghĩa vụ thanh toán khi VPS tới hạn thanh toán vẫn là của bạn. Vì vậy bạn cần chú ý thời hạn thanh toán với những VPS này.',
      bodyRevokeCancellationWarning: 'Quý khách xác nhận việc muốn thu hồi việc huỷ VPS?',
      buttonCancel: 'Hủy',
      buttonRestore: 'Khôi phục',
      buttonConfirm: 'Xác nhận',
      buttonSnapshot: 'Tạo snapshot',
      buttonRebuild: 'Cài đặt lại OS',
      buttonChange: 'Tạo hoá đơn đổi mới',
      buttonRenew: 'Tạo hóa đơn',
      buttonSetAutoRenew: 'Bật tự động gia hạn',
      buttonCancelAutoRenew: 'Tắt tự động gia hạn',
      buttonCancelVPS: 'Xác nhận hủy',
      buttonTransfer: 'Chuyển giao',
      buttonRDP: 'Xuất RDP file',
      buttonEnableBackup: 'Bật tự động sao lưu',
      buttonDisableBackup: 'Tắt tự động sao lưu',
      buttonCreate: 'Tạo mới',
      buttonDelete: 'Xoá',
      buttonShare: 'Chia sẻ',
      buttonDeleteShare: 'Ngừng chia sẻ',
      buttonRevokeCancellation: 'Thu hồi lệnh huỷ VPS',
      period: 'Chu kì gia hạn',
      month: 'tháng'
    },
    button: {
      start: 'Bật',
      shutdown: 'Tắt',
      stop: 'Dừng VPS',
      restart: 'Khởi động lại',
      console: 'Web remote',
      rdp: 'Xuất RDP',
      createRGC: 'Tạo RGC',
      deleteRGC: 'Xoá RGC',
      reinstall: 'Cài đặt lại OS',
      snapshot: 'Tạo Snapshot',
      restore: 'Khôi phục',
      transfer: 'Chuyển giao',
      change: 'Đổi mới',
      renew: 'Gia hạn',
      setAutoRenew: 'Bật tự động gia hạn',
      cancelAutoRenew: 'Tắt tự động gia hạn',
      cancel: 'Hủy',
      enableBackup: 'Bật backup',
      disableBackup: 'Tắt backup',
      buttonDelete: 'Xoá',
      team: 'Chia sẻ',
      cpuOptimization: 'Tối ưu hoá CPU',
      revokeCancellation: 'Thu hồi huỷ VPS'
    },
    toast: {
      errPendingTask: 'Một vài tác vụ khác đang được xử lý! Vui lòng đợi!',
      okRebuildRequestSent: 'Bắt đầu cài lại hệ điều hành. Vui lòng đợi khoảng 10 phút!',
      okRestoreRequestSent: 'Bắt đầu khôi phục từ snapshot. Vui lòng đợi khoảng 10 phút!',
      okExportRDP: 'Xuất RDP file thành công!',
      okCopy: 'Đã sao chép: {0}.',
      noBackup: 'Quý khách không có bất kì bản sao lưu nào!',
      rgcCreating: 'Đang tạo mới địa chỉ RGC tới VPS {0}',
      rgcDelete: 'Đang xoá địa chỉ RGC của VPS {0}'
    },
    label: {
      portMap: 'Port mapping là công nghệ cho phép kết nối từ bên ngoài đến NAT VPS của quý khách. VPS của quý khách được sử dụng 100 port. ' +
        'Các port thường sử dụng đã được map sẵn như dưới đây. Nếu ứng dụng của quý khách sử dụng các port khác, quý khách cần cài đặt nó ' +
        'để sử dụng một trong những port được map vào VPS của quý khách.<br/>' +
        'Ví dụ: Nếu quý khách chạy XAMPP hoặc Web server ở port 80, quý khách có thể kết nối đến Web server này tại địa chỉ: <a href="{0}">{0}</a>.',
      localNetwork: 'VPS của quý khách nằm trên một mạng LAN ảo. Thông thường, nó sẽ được tự động phân phát IP. Trong trường hợp VPS của quý khách không ' +
        'tự động nhận dạng IP, quý khách hãy sử dụng chức năng Web Remote và cấu hình mạng của VPS như sau:',
      note: 'Ghi chú',
      cpuOptimizationForEmulator: 'Tối ưu hoá CPU cho giả lập',
      cpuOptimizationNormal: 'Tối ưu hoá CPU cho hiệu năng',
    }
  },
  zh: {
    cardBody: {
      titleGeneral: '综合消息',
      titleAddon: '增值业务',
      titlePayment: '交易信息',
      titleNetwork: '网状系统',
      titleControl: '控制',
      titlePortMap: '端口映射'
    },
    table: {
      tdTitleStatus: '状态',
      tdTitleMainIP: 'VPS IPv4 & 端口',
      tdTitleOutgoing: '公开IP',
      tdTitleDedicatedIP: '独立IP',
      tdTitleCPU: 'CPU',
      tdTitleRAM: 'RAM',
      tdTitleDisk: '碟盘',
      tdTitleSpec: '配置',
      tdTitleOS: '操作系统',
      tdTitleUser: '默认ID',
      tdTitlePass: '默认密码',
      tdClickToCopy: '点击复制',
      tdTitleLocation: '位置',

      tdTitleLocalIP: 'IP',
      tdTitleLocalMask: '网络掩码',
      tdTitleLocalGw: '网关',

      tdTitlePrice: '价钱',
      tdTitleStart: '开始',
      tdTitleEnd: '结束',
      tdTitlePay: '交易状态',
      tdTitlePlan: '基本套餐',
      tdTitleAutoRenew: '自动续费',
      tdTitleTotalAddonPrice: '增值业务',
      tdTitleTotalPrice: '总数',
      tdTitleBackup: '自动备份',
      tdTitleAddon: '增值业务',
      tdTitleNote: '您的备注',

      tdTitleAddonName: '插件',
      tdTitleAddonPrice: '单价',
      tdTitleAddonAmount: '数量',

      tdDataOK: 'OK',
      tdDataOverdue: '已过期',
      tdDataSuspend: '暂停',
      tdDataCancel: '等待取消',
      tdDataUnknown: '未知',
      tdDataChecking: '正在检查',
      tdDataRunning: '运行中',
      tdDataStopped: '已暂停',
      tdDataSuccess: '成功',
      tdDataError: '故障',
      tdDataManage: '详情',
      tdDataStarting: '正在启动...',
      tdDataShuttingDown: '正在关机...',
      tdDataStopping: '暂停中...',
      tdDataRestarting: '正在重启...',
      tdDataReinstalling: '正在重装系统...',
      tdDataTakingSnap: '正在复制样板...',
      tdDataRestoring: '正在恢复...',
      tdAutoRenewEnabled: '已启动',
      tdAutoRenewDisabled: '未启动',
      tdDataEachMonth: ' / 月',

      tdServiceName: '软件/服务',
      tdNormalPort: '普通端口',
      tdMappedPort: '端口已映射',
      tdCustomPort: '您的端口'
    },
    tooltip: {
      editRemark: '修改备注',
      clickToCopy: '点击复制'
    },
    modal: {
      titleEditRemark: '给此VPS备注 {0}',
      titleAddonDetail: '增值业务清单',
      bodyEditRemark: '温馨提示：如果您有较多VPS你可以给VPS换上备注这样会更容易辨别.',
      buttonEditRemark: '保存',

      bodyDatalossWarning: '<strong>注意:</strong>建议此功能在VPS死机或不能的登录的情况下使用.' +
        ' 此操作就像您在您的计算机直接拔掉电源' +
        ' 电源这样会导致你的电脑一些你未来得及保存的文件以及正在进行的文件文档会丢失 因此为了保证你的文件不被丢失您要关机或重启请你在VPS里面进行谢谢.',

      titleConfirmRebuild: '确认重装系统',
      bodyRebuildWarning: '<strong>注意:</strong>重装系统会删除原有的所有资料会将无法恢复请您将重要资料进行备份!',
      bodySelectOS: '请选择你需要的操作系统',

      titleConfirmSnap: '确认制作样版',
      bodyConfirmSnapshot: '你是否确定要制作样版?',
      bodySnapshotWarning: '<strong>注意 - 请详细读完此注意事项</strong><ul>' +
        '<li>在制作样版之前您需要操作这3步骤来保证制作样版能顺利制作完成:<ol>' +
        '<li>删除掉所有多余的资料文件等等来确保样版在最佳状态这样能减少制作过程中会出现故障.</li>' +
        '<li>不要删除系统和Windows自带所有工具和文件不然会导致您在复制回来的版本导致故障没网络等等.</li>' +
        '<li>在制作样版之前请在VPS内正常关机这样确保你的重要文件不被丢失.</li></ol></li>' +
        '<li>制作样版此功能我们是免费提供但请您不要滥用资源此功能不允许拿来当作备份功能使用谢谢合作</strong>,' +
        ' 此制作样版功能只用于复制一个做成样版然后子复印出更多个相同VPS这样比较方便您同时使用多个VPS，:' +
        '<ol><li>为了节省资源您只能制作一个样版. 您可以删除旧的再造一个新的.</li>' +
        '<li>每一个星期您只能制作一次.</li></ol></li>',

      titleConfirmRestore: '确认从样版回复资料',
      titleConfirmTransfer: '确认转交VPS',
      bodySelectSnapshot: '请选择你所需要的样版',
      bodyRestoreWarning: '<strong>注意:</strong> 此功能会删除原有的所有资料会将无法恢复请您将重要资料进行备份!',
      titleConfirmCancel: '确认删除VPS',
      bodyConfirmCancel: '您是否确认删除此VPS?',
      bodySelectCancelMode: '请选择删除方式',
      bodyCancelModeEndOfBilling: '到期限再删除',
      bodyCancelModeImmediate: '立即删除',
      bodyCancelWarning: '<h5 class="text-danger">警报:</h5></br><ol>' +
        '有两种方式删除VPS  : 到期后删除和立即删除 (End of billing) 和立即删除 (Immediate).' +
        '<ul>' +
        '<li>到期后删除: 您的VPS到期后会自动删除系统会通过邮箱来发消息通知您，请您注意查收.</li>' +
        '<li>立即删除: 就是立即删除剩余的天数也能转让给别的VPS也不能退钱.</li>' +
        '</ul>' +
        '如有误删VPS或者想继续使用请及时联系客服请求帮助.',
      titleConfirmRenew: '确认创建续费订单',
      titleConfirmSetAutoRenew: '确认给此VPS开启自动续费功能',
      titleConfirmCancelAutoRenew: '确认给此VPS关掉自动续费功能',
      titleConfirmBackup: '确认给此VPS打开自动备份功能',
      bodySelectBackupPeriod: '请选择自动备份周期',
      selectBackupPeriod7: '备份每周',
      selectBackupPeriod1: '备份每天',
      bodyConfirmRenew: '系统会给以下一个或多个VPS造出续费订货单.',
      bodyConfirmSetAutoRenew: '您正在给此VPS开始自动续费功能。系统会在此VPS到期的前三天给此VPS自动续费' +
        ' 到期之前如果你的余额够给此VPS续费系统会自动扣除您的余额.<br/>',
      bodyConfirmCancelAutoRenew: '您正在关掉此VPS的自动续费功能您可以随时重新打开自动续费功能或者你也可以手动续费' +
        ' 您需要手动续费请在主页的菜单栏点击续费即可.<br/>',
      bodyConfirmTransfer: '您填入收VPS的人的邮箱或者名称.<br/>' +
        '为了您的账号安全如果你填错对方的邮箱或名称到达5次系统会冻结此功能需要解冻请联系客服.',
      bodyTransferWarning: '<h5 class="text-danger">留意:</h5></br><ol>' +
        '<li>我们是不会触碰到您们的资料文件假如您只想转交VPS不想转交资料你可以重装系统后再进行转交. ' +
        '如果您不重装系统就转交的话对方可以进行操作你的资料文件请谨慎操作.</li>' +
        '<li>此VPS以前的续费订单贵你所有收件人收到VPS 以后他的续费订单等您将看不见任何信息.</li>' +
        '</ol>',
      bodyRenewWarning: '系统会转跳到付款页面请您确认金额.</br>',
      bodyBackupWarning: '<dl><dt>每周备份</dt><dd>本备份功能正在属于试用阶段属于免费使用，开启此功能你能要注意以下几点' +
        '开启自动备份功能后会让您的VPS卡顿处理速度下降如果你的资料不是很重要的话我们不建议您使用此从能, ' +
        'VPS的故障概率只有0.11%/1个月所以资料不太重要的话我们是不建议开启此功能这样会给您们带来不好的体验.</dd></br>' +
        '<dt>每天备份</dt><dd>如果你有需求每天备份的话请联系客服咨询 每天备份我们将收取费用费用计算是$0.03/1GB 你现在拥有的备份容量为 硬盘 开启每天备份另收$1/月.</dd>',
      buttonCancel: '取消',
      buttonRestore: '恢复',
      buttonSnapshot: '制作样版',
      buttonRebuild: '重装系统',
      buttonRenew: '新建订单',
      buttonSetAutoRenew: '打开自动续费',
      buttonCancelAutoRenew: '关掉自动续费',
      buttonCancelVPS: '确认取消',
      buttonTransfer: '转交',
      buttonRDP: '导出RDP文件',
      buttonEnableBackup: '开启自动备份'
    },
    button: {
      start: '开',
      shutdown: '关',
      stop: '暂停VPS',
      restart: '重新启动',
      console: '网页远程链接',
      rdp: '导出RDP',
      reinstall: '重装系统',
      snapshot: '制作样版',
      restore: '恢复',
      transfer: '转交',
      renew: '续费',
      setAutoRenew: '打开自动续费',
      cancelAutoRenew: '关掉自动续费',
      cancel: '取消',
      backup: '开启备份'
    },
    toast: {
      errPendingTask: '正在处理别的进程，请稍后!',
      okRebuildRequestSent: '开始重装系统请等待10分钟!',
      okRestoreRequestSent: '开始从样版恢复请等待10分钟!',
      okExportRDP: '导出RDP文件成功!',
      okCopy: '已复制: {0}.'
    },
    label: {
      portMap: '端口映射是我们的技术，用于实现与NAT VPS的传入连接。您将有100个端口映射到VPS. ' +
        '一些常规端口已映射。如果您正在使用其他端口 ' +
        '则必须将应用程序的端口更改为启用的端口之一.<br/>' +
        '例如：如果您在此VPS上使用XAMPP或Web服务器用端口是80，则可以通过以下地址连接到Web服务器: <a href="{0}">{0}</a>.',
      localNetwork: '您的VPS位于虚拟LAN上正常的情况下踏会自动分配IP. 如果无法获得正确的IP, 请按如下方式进行配置：端口'
    }
  }
}
