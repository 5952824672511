//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'

export default {
  name: "Dashboard",
  mixins: [dataRequester],
  props: {
    languageSelected: String
  },
  data() {
    return {
      overviewData: null,
      ticketOverview: null,
      guideData: null
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created() {
    let self = this
    this.submitObjectList(this.$config.apiPath.dashboardOverview, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.overviewData = result.data['serviceSummaryObject'],
        self.ticketOverview = result.data['ticketSummaryObject']
      } else {
        this.$toasted.error(result.msg)
      }
    })
    this.submitObjectList(this.$config.apiPath.dashboardGuide, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.guideData = result.data['dashboardLinkObjectList']
      } else {
        this.$toasted.error(result.msg)
      }
    })
  },
  methods: {
    FixBugTheme
  }
}



