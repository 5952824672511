//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: 'ConfirmModal',
  props: {
    action: String,
    confirmModalDisplayObject: {},
    active: Boolean
  },
  watch: {
    active: function () {
      if (this.active) {
        let confirmModal = new bootstrap.Modal(document.getElementById('confirmModal'), {  keyboard: false })
        confirmModal.show()
      } else {
        let confirmModal = bootstrap.Modal.getInstance(document.querySelector('#confirmModal'))
        confirmModal.hide()
      }
    },
  },
  mounted: function () {
    this.FixBugTheme()
    let self = this
    $(this.$refs.confirmModal).on('hidden.bs.modal', function () {
      self.$emit('modalCancel')
    })
  },
  methods: {
    FixBugTheme,
    additionalAction: function () {
      this.$emit('modalAdditionalButton')
    },
    onModalConfirm: function () {
      let inputObjectList = []
      for (let input of document.getElementById('confirmModal').getElementsByTagName('input')) {
        let value
        if (input.options !== undefined && input.selectedIndex !== undefined) {
          value = input.options[input.selectedIndex]
        }

        if(input.type && input.type.toLowerCase() === 'radio') {
          value = input.filter(":checked").val()
        } else {
          value = input.value
        }
        let inputObject = {}
        inputObject[input.id] = value
        inputObjectList.push(inputObject)
      }
      this.$emit('modalConfirm', inputObjectList)
    },
    onModalCancel: function () {
      this.$emit('modalCancel')
    }
  }
}
