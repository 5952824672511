import async from 'async'

export default {
  data: function () {
    return {
      dataObjectList: [],
      selectedObjectList: [],
      notifyModelChange: false,
    }
  },
  methods: {
    initDataObjectList: function (apiPath, callback) {
      this.getDataFromAPI(apiPath, function (result) {
        callback(result.data)
      })
    },
    syncObjectToList: function (objectList, dataObject, objectKey, callback) {
      let self = this
      async.forEachOf(objectList, function (v, k, cb) {
        async.forEachOf(v, function (v1, k1) {
          if (k1 !== objectKey && !self.strIsEmpty(dataObject[k1])) {
            objectList[k][k1] = dataObject[k1]
          }
        })
        cb()
      }, function () {
        callback()
      })
    },
    syncListToList: function (sourceList, destinationList, objectKey, callback) {
      async.forEachOf(sourceList, function (v, k, cb) {
        async.forEachOf(destinationList, function (v1, k1) {
          if (sourceList[k][objectKey] === destinationList[k1][objectKey]) {
            destinationList[k1] = JSON.parse(JSON.stringify(sourceList[k]))
          }
        })
        cb()
      }, function () {
        callback()
      })
    },
    syncListToDatatable: function (sourceList, objectKey, callback) {
      let dataTable = $('#dataTable').DataTable()
      let selectedRowsIndexes = dataTable.rows('.selected').indexes()
      async.forEachOf(selectedRowsIndexes, function (v, k, cb) {
        async.forEachOf(sourceList, function (v2) {
          if (dataTable.row(v).data()[objectKey] === v2[objectKey]) {
            dataTable.row(v).data(v2).draw(false)
          }
        })
        cb()
      }, function () {
        callback()
      })
    },
    setItemProperty: function (dataObjectID, property, value) {
      let dataObjectIndex = this.dataObjectList.findIndex(o => o['id'] === dataObjectID)
      if (dataObjectIndex !== -1) {
        let dataObject = this.dataObjectList[dataObjectIndex]
        dataObject[property] = value
        this.$set(this.dataObjectList, dataObjectIndex, dataObject)
      }
    }
  }
}
