//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import LevTable from "@/components/children/LevTable";
import confirmModal from '@/components/children/ConfirmModal'

import dataObjectListMixin from '@/mixins/DataObjectList'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import dataRequester from '../../../mixins/utilities/DataRequester'
import inputHelper from '../../../mixins/utilities/InputHelper'
import async from "async";
import RDPGenerator from '@/mixins/utilities/RDPGenerator'
import FileExport from '@/mixins/utilities/FileExport'
import confirm2Fa from "@/components/children/Confirm2Fa";
import {FixBugTheme} from "@/mixins/MaterialTheme";

export default {
  name: "DedicatedListVue",
  components: {
    LevTable,
    confirmModal,
    confirm2Fa
  },
  mixins: [dataObjectListMixin, dataRequester, confirmModalBuilder, inputHelper, RDPGenerator, FileExport],
  props: {
    notifications: {},
    metaData: {},
    themeSetting: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
  },
  data: function () {
    let self = this
    return {
      inputObjectList: [],
      otp_code: '',
      otpConfirmAction: 'none',
      action: 'none',
      filter: {
        dateFrom: {
          currentSelection: null
        },
        dateTo: {
          currentSelection: null
        }
      },
      rawDataFromDB: [],
      rgcObject: {
        serverList: null,
        vmObject: null
      },
      selectedObjectIndex: null,
      tableDedicatedColumns: [
        {
          name: 'start_date',
          title: '<i class="far fa-calendar-plus"></i><span class="text-bolder text-uppercase"> ' + this.$t('dedicated.table.thStart') + '</span>',
          width: '8%',
          formatter: function (cellData) {
            return '<div class="small">' + self.convertISODate(cellData) + '</div>'
          },
          sortField: 'start_date'
        },
        {
          name: 'end_date',
          title: '<i class="far fa-calendar-times"></i><span class="text-bolder text-uppercase"> ' + this.$t('dedicated.table.thEnd') + '</span>',
          width: '8%',
          sortField: 'end_date',
          formatter: function (cellData) {
            return '<div class="small">' + self.convertISODate(cellData) + '</div>'
          }
        }
      ],
      searchableFields: ['ip_port', 'login_user', 'price', 'auto_renew', 'dedicated_plan_name', 'start_date', 'end_date', 'user_remark', 'payment_status'],
      DedicatedExtendtedFields: [
        {
          name: 'ip_port',
          title: '<i class="fas fa-ethernet"></i> IP',
          formatter: function (rowData) {
            let iconDisplay
            if (rowData['rgc_server_name'] === null && rowData['rgc_hidden']) {
              iconDisplay = '<i class="cursor-pointer fas fa-plus text-success btn-tooltip" data-bs-toggle="tooltip"' +
                  'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.rgc_create') + '"' +
                  'name="createRGC"></i>'
            } else {
              if (!rowData['rgc_hidden']) {
                iconDisplay = '<i class="cursor-pointer fas fa-eye-slash text-info btn-tooltip me-1" data-bs-toggle="tooltip"' +
                    'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.rgc_hidden') + '"' +
                    'name="hiddenRGC"></i>' +
                    '<i class="cursor-pointer fas fa-trash text-danger btn-tooltip" data-bs-toggle="tooltip"' +
                    'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.rgc_delete') + '"' +
                    'name="deleteRGC"></i>'
              } else {
                iconDisplay = '<i class="cursor-pointer fas fa-eye text-info btn-tooltip" data-bs-toggle="tooltip"' +
                    'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.rgc_show') + '"' +
                    'name="showRGC"></i>'
              }
            }

            let iconTeamMember = ''
            if (self.currentUser['team_private_key'] === null) {
              if (rowData['co_owner_id'] !== null) {
                iconTeamMember = `<i class="fas fa-users text-info me-1 cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('dedicated.tooltip.owner_dedicated', rowData)}"></i>`
              }
            } else {
              if (rowData['co_owner_id'] !== null) {
                iconTeamMember = `<i class="fas fa-user-friends text-info me-1 cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('dedicated.tooltip.co_owner_dedicated', rowData)}"></i>`
              }
            }

            let iconCopy = '<i class="cursor-pointer fas fa-copy text-primary btn-tooltip me-1" data-bs-toggle="tooltip"' +
                'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.ip_copy') + '"' +
                'name="ipCopy"></i>'

            if(rowData['rgc_hidden']) {
              return  '<div class="d-flex justify-content-between align-items-center small">' +
                  '<a href="/#/home/dedicated-server/detail/' + rowData.id + '" class="font-weight-bolder cursor-pointer">' +
                  self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['osType']) + '</a>' +
                  '<div class="d-flex justify-content-between align-items-center">' +
                  iconTeamMember +
                  iconCopy +
                  iconDisplay +
                  '</div>' +
                  '</div>'
            } else {
              return  '<div class="d-flex justify-content-between align-items-center small">' +
                  '<a href="/#/home/dedicated-server/detail/' + rowData.id + '" class="text-success font-weight-bolder cursor-pointer ip-hover">' +
                  rowData['rgc_server_name'] + ':' + rowData['rgc_forwarded_port'] + '</a>' +
                  '<div class="d-flex justify-content-between align-items-center">' +
                  iconTeamMember +
                  iconCopy +
                  iconDisplay +
                  '</div>' +
                  '</div>'
            }

          },
          extended_data: function (rowData) {
            // To allow search on the table
            return self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['osType'])
          },
          order: 1,
          // width: '15%',
          sortField: 'ip_port'
        },
        {
          name: 'login_user',
          title: '<span class="text-uppercase"><i class="fas fa-user"></i> ' + this.$t('dedicated.table.thUsername') + '</span>',
          formatter: function (rowData) {

            let iconCopy = '<i class="cursor-pointer fas fa-copy text-primary btn-tooltip me-1" data-bs-toggle="tooltip"' +
                'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.user_copy') + '"' +
                'name="userCopy"></i>'

            return '<div class="small d-flex justify-content-between">' +
                rowData['login_user'] + ' ' + iconCopy +
                '</div>'
          },
          extended_data: function (rowData) {
          },
          order: 2,
          sortField: 'login_user'
          // width: '15%'
        },
        {
          name: 'login_password',
          title: '<span class="text-uppercase"><i class="fas fa-key"></i> ' + this.$t('dedicated.table.thPassword') + '</span>',
          formatter: function (rowData) {

            let iconCopy = '<i class="cursor-pointer fas fa-copy text-primary btn-tooltip me-1" data-bs-toggle="tooltip"' +
                'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.pw_copy') + '"' +
                'name="pwCopy"></i>'

            if (rowData['pw_hidden']) {
              return '<div class="small">' +
                  '****** ' + iconCopy +
                  '<span class="fas fa-eye text-info font-weight-bolder cursor-pointer" data-bs-toggle="tooltip"' +
                  'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.pw_show') + '"' +
                  'name="showPassword"></span></div>'
            } else {
              return  '<div class="small d-flex justify-content-between">' +
                  '<span class="me-1">' +
                  rowData['login_password'] +
                  '</span>' +
                  '<div>' +
                  iconCopy +
                  '<span class="fas fa-eye-slash cursor-pointer text-info text-end" data-bs-toggle="tooltip"' +
                  'data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.pw_hide') + '"' +
                  'name="hidePassword">' +
                  '</span>' +
                  '</div>' +
                  '</div>'
            }
          },
          extended_data: function (rowData) {
            return rowData['login_password']
          },
          order: 3,
          sortField: 'login_password'
          // width: '15%'
        },
        {
          name: 'price',
          title: '<i class="fas fa-dollar-sign"></i><span class="text-bolder text-uppercase"> ' + this.$t('dedicated.table.thPrice') + '</span>',
          width: '5%',
          sortField: 'price',
          formatter: function (rowData) {
            let totalPrice = parseFloat(rowData['price'])
            if (rowData['addon_revenue'] !== null) {
              return '<div class="small d-flex d-inline">' +
                  '<span class="text-info text-bold">$' + totalPrice.toFixed(2) + '&nbsp;</span>' +
                  '<span class="badge badge-success text-success"><span class="fa fa-puzzle-piece"></span></span>'  +
                  '</div>'
            } else {
              return '<span class="text-info text-bold small">$' + totalPrice.toFixed(2) + ' </span>'
            }
          },
          order: 4
        },
        {
          name: 'dedicated_plan_name',
          title: '<i class="fab fa-hive"></i><span class="text-bolder text-uppercase"> ' + this.$t('dedicated.table.thPlan') + '</span>',
          width: '5%',
          sortField: 'dedicated_plan_name',
          formatter: function (rowData) {
            return '<span class="text-bold small">' + rowData['dedicated_plan_name'] + '</span>'
          },
          order: 5
        },
        {
          name: 'location',
          title: '<i class="fas fa-map-marker"></i><span class="text-bolder text-uppercase"> ' + this.$t('dedicated.table.thLocation') + '</span>',
          width: '8%',
          sortField: 'location',
          formatter: function (rowData) {
            return '<span class="text-info btn-tooltip cursor-pointer small" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + rowData.country + ' (' + rowData.state + ')">' + rowData.country_code + ' (' + rowData.state_code +')</span>'
          },
          order: 6
        },
        {
          name: 'auto_renew',
          width: '10%',
          sortField: 'auto_renew',
          title: '<span class="text-uppercase"><i class="fas fa-retweet"></i> ' + this.$t('dedicated.table.thAutoRenew') + '</span>',
          formatter: function(rowData){
            if (rowData['auto_renew'].toString() === '1' || rowData['auto_renew'].toString() === 'true') {
              return '<span class="text-success text-bold small">✓</span>'
            } else {
              return '<span class="text-danger text-bold small">✗</span>'
            }
          }
        },
        {
          name: 'user_remark',
          title: '<span class="text-uppercase"><i class="fas fa-edit"></i> ' + this.$t('dedicated.table.thNote') + '</span>',
          formatter: function (rowData) {
            if (rowData['is_editing_note']) {
              return '<div class="input-group input-group-dynamic align-items-center small">' +
                  '<input type="text" class="form-control text-primary small" value="' + rowData['user_remark'] + '">' +
                  '<span class="cursor-pointer fas fa-save text-success mx-1 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.save_note') + '" name="saveNote"></span>' +
                  '<span class="cursor-pointer fas fa-window-close text-secondary mx-1 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.cancel_note') + '" name="cancelNote"></span>' +
                  '</div>'
            } else {
              return '<div class="small d-flex justify-content-between">' + rowData['user_remark'] +
                      '<div>' +
                      ' <i class="cursor-pointer fas fa-edit text-info btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.edit_note') + '" name="editNote"></i>' +
                      ' <i class="cursor-pointer fas fa-copy text-primary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="' + self.$t('dedicated.tooltip.copy_note') + '" name="copyNote"></i>' +
                      '</div>' +
                      '</div>'
            }
          },
          extended_data: function (rowData) {
            // To allow search on the table
            return rowData['user_remark']
          },
          sortField: 'user_remark',
        },
        {
          name: 'payment_status',
          title: '<span class="text-uppercase"><i class="fas fa-file-invoice-dollar"></i> ' + this.$t('dedicated.table.thPay') + '</span>',
          width: '10%',
          formatter: function(rowData) {
            if (rowData['payment_status'] === 'ok') {
              if (rowData['end_date'] <= self.getCurrentTime()) {
                return '<div class="small"><span class="badge badge-warning"><span>' + self.$t('dedicated.table.tdOverdue') + '</span></span></div>'
              } else {
                return '<div class="small"><span class="badge badge-success"><span>' + self.$t('dedicated.table.tdOK') + '</span></span></div>'
              }
            } else if (rowData['payment_status'] === 'suspend') {
              return '<div class="small"><span class="badge badge-danger"><span>' + self.$t('dedicated.table.tdSuspend') + '</span></span></div>'
            } else if (rowData['payment_status'] === 'cancel') {
              return '<div class="small"><span class="badge badge-dark"><span>' + self.$t('dedicated.table.tdCancel') + '</span></span></div>'
            } else {
              return '<div class="small"><span class="badge badge-info"><span>' + self.$t('dedicated.table.tdUnknown') + '</span></span></div>'
            }
          },
          sortField: 'payment_status',
        },
        {
          name: 'pw_hidden',
          extended_data: true,
          visible: false
        },
        {
          name: 'is_editing_note',
          extended_data: false,
          visible: false
        },
        {
          name: 'rgc_hidden',
          extended_data: true,
          visible: false
        }
      ],
      visibleFields: [],
      teamMembers: [],
    }
  },
  watch: {
    themeSetting: {
      handler: function () {
        this.updateVisiableFields()
      },
      deep: true
    }
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    this.updateVisiableFields()

    self.submitObjectList(self.$config.apiPath.dedicatedList, undefined, 'vps',
        {
          filter: {
            dateFrom: self.convertISODate(self.filter.dateFrom.currentSelection),
            dateTo: self.convertISODate(self.filter.dateTo.currentSelection)
          }
        }, function (result) {
          self.$set(self, 'dataObjectList', _.sortBy(result.data, 'end_date'))
          self.rawDataFromDB = result.data
        })

    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users'].filter(x => x['id'] !== self.currentUser['userID'])
    }, null, false)
  },
  methods: {
    FixBugTheme,
    onSelectedChanged(selectedRowID) {
      this.selectedObjectList = this.dataObjectList.filter(d => selectedRowID.includes(d['id']))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      let self = this
      switch (cellField.name) {
        case 'user_remark':
          if (event.target.getAttribute('name') === 'editNote') {
            rowData['is_editing_note'] = true
          } else if (event.target.getAttribute('name') === 'copyNote') {
            this.copyContent(rowData['user_remark'])
          }
          else if (event.target.getAttribute('name') === 'cancelNote') {
            rowData['is_editing_note'] = false
          }
          else if (event.target.getAttribute('name') === 'saveNote') {
            let currentObjectList = [rowData]
            let newNote = event.target.parentElement.getElementsByTagName('input')[0].value
            this.submitObjectList(this.$config.apiPath.dedicatedNote, currentObjectList.map(function (a) { return {id: a.id} }), 'dedicated', {note: newNote}, function (result) {
              self.$toasted.success(result.msg)
              self.setItemProperty(rowData.id, 'user_remark', newNote)
            }, function () {
            })
            rowData['is_editing_note'] = false
          }
          break
        case 'ip_port':
          switch (event.target.getAttribute('name')) {
            case 'showRGC':
              rowData['rgc_hidden'] = false
              break
            case 'hiddenRGC':
              rowData['rgc_hidden'] = true
              break
            case 'createRGC':
              self.createRGC(rowData)
              break
            case 'deleteRGC':
              self.deleteRGC(rowData)
            case 'ipCopy':
              if (rowData['rgc_hidden']) {
                self.copyContent(rowData['ip_port'])
              } else {
                self.copyContent(rowData['rgc_server_name'] + ':' + rowData['rgc_forwarded_port'])
              }
              break
            default:
              break
          }
          break
        case 'login_password':
          switch (event.target.getAttribute('name')) {
            case 'showPassword':
              rowData['pw_hidden'] = false
              break
            case 'hidePassword':
              rowData['pw_hidden'] = true
              break
            case 'pwCopy':
              self.copyContent(rowData['login_password'])
              break
            default:
              break
          }
          break
        case 'login_user':
          switch (event.target.getAttribute('name')) {
            case 'userCopy':
              self.copyContent(rowData['login_user'])
              break
            default:
              break
          }
          break
        default:
          break
      }
      updatePostTable()
    },
    confirmDedicated: function (actionToConfirm) {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }

      if (this.action === 'none') {
        this.action = actionToConfirm
        this.initConfirmDedicatedAction(self.selectedObjectList, actionToConfirm, function () {
          if (self.action === 'stop' || self.action === 'restart') { // means stop or restart
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('dedicated.modal.bodyDatalossWarning', [self.$t('dedicated.action.' + self.action)]) + '</span>'
          } else if (self.action === 'renew') {
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('dedicated.modal.bodyRenewWarning', [self.$t('dedicated.action.' + self.action)]) + '</span>'
          }
        })
        self.showModal(true)
      } else {
        self.$toasted.error(this.$t('dedicated.toast.errPendingTask'))
      }
    },
    editNote: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      let currentNote = self.selectedObjectList[0].user_remark
      if (self.selectedObjectList.length > 1) {
        currentNote = ''
      }
      self.confirmModalDisplayObject.title = '<h4>' + self.$t('dedicated.modal.titleEditRemark', ['<b style="color: red;">' + this.selectedObjectList.length + '</b>']) + '</h4>'
      self.confirmModalDisplayObject.bodyMsg = self.$t('dedicated.modal.bodyEditRemark')
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      for (let i = 0; i < self.selectedObjectList.length; i ++) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>' + self.getIPPortFormat(self.selectedObjectList[i]['ip'], self.selectedObjectList[i]['port']) + '</strong></li>'
      }
      self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      self.confirmModalDisplayObject.bodyMsg += self.$t('dedicated.label.yourNote')
      self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="editNote" aria-describedby="detail" value="' + currentNote + '">'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonEdit')

      self.action = 'editRemark'
      self.showModal(true)
    },
    consoleDedicated: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      if (this.selectedObjectList.length === 1) {
        window.open('./#/vm/console/' + this.selectedObjectList[0].id, '_blank')
      } else {
        self.$toasted.error(self.$t('dedicated.toast.errSingleConsole'))
      }
    },
    generateRDP: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleRDP', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicated.modal.bodyConfirmRDP')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicated.modal.bodyRDPWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonRDP')

        self.action = 'confirmRDP'
        self.showModal(true)
      })
    },
    confirmExportTextFile: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleExportTextFile', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicated.modal.bodyConfirmGenerateTextFile')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonGenerateTextFile')

        self.action = 'confirmExportTextFile'
        self.showModal(true)
      })
    },
    confirmExportExcelFile: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleExportExcelFile', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicated.modal.bodyConfirmGenerateExcelFile')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonGenerateExcelFile')

        self.action = 'confirmExportExcelFile'
        self.showModal(true)
      })
    },
    transfer: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleConfirmTransfer', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicated.modal.bodyConfirmTransfer')
      self.confirmModalDisplayObject.bodyMsg += '<div class="form-row mt-3 mb-3">'
      self.confirmModalDisplayObject.bodyMsg += '<label class="col-form-label col-3">Email</label>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      self.confirmModalDisplayObject.bodyMsg += '<input class="form-control col-9" id="transferEmailTarget">'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicated.modal.bodyTransferWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonTransfer')

        self.action = 'confirmTransfer'
        self.showModal(true)
      })
    },
    teamMemberShare: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('dedicated.toast.errPendingTask'))
      } else {
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleConfirmShare', ['<b style="color: red;">' + this.selectedObjectList.length + '</b>']) + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectTeamMembers">' + self.$t('dedicated.modal.bodySelectTeamMember') + '</label><select class="form-select ps-3 mb-3" id="selectTeamMembers">'


        async.forEachOf(self.teamMembers, function (member, key, cb) {
          self.confirmModalDisplayObject.bodyMsg += '<option value="' + member.id + '">' + member.full_name + '</option>'
          cb()
        }, function () {
          self.confirmModalDisplayObject.bodyMsg += '</select></div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
          async.forEachOf(self.selectedObjectList, function (value, key, callback) {
            self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
            callback()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
            self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicated.modal.bodyTeamMember') + '</div>'
          })
          self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonShare')
          self.confirmModalDisplayObject.additionalButton = {
            isShow: true,
            title: self.$t('dedicated.modal.buttonDeleteShare'),
            class: 'btn btn-danger'
          }

          self.action = 'teamMemberShare'
          self.showModal(true)
        })

      }
    },
    renew: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleConfirmRenew', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="form-label">' + `${this.$t('dedicated.modal.period')} (${this.$t('dedicated.modal.month')})` + '</label>'
      this.confirmModalDisplayObject.bodyMsg += '<input id="periodRenew" class="form-control" type="number" min="1" max="24" value="1" onKeyDown="return false">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += this.$t('dedicated.modal.bodyConfirmRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicated.modal.bodyRenewWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonRenew')

        self.action = 'confirmRenew'
        self.showModal(true)
      })
    },
    setAutoRenew: function () {
      let self = this
      if(this.selectedObjectList === null || this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('dedicated.toast.errNoSelect'))
        return
      }
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleConfirmAutoRenew', [this.selectedObjectList.length]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicated.modal.bodyConfirmAutoRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(self.selectedObjectList, function (value, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>IP ' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicated.modal.bodyAutoRenewWarning') + '</div>'
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonSetAutoRenew')
        self.confirmModalDisplayObject.additionalButton = {
          isShow: true,
          title: self.$t('dedicated.modal.buttonCancelAutoRenew'),
          class: 'btn btn-danger'
        }

        self.action = 'confirmAutoRenew'
        self.showModal(true)
      })
    },
    createRGC: function (rowData) {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('dedicated.toast.errPendingTask'))
      } else {
        let self = this
        self.rgcObject.vmObject = rowData
        let buildImageSelection = function () {

          self.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>IP. ' + rowData['ip_port'] + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<strong>Remote Port</strong>'
          self.confirmModalDisplayObject.bodyMsg += '<span class="smaller d-block">Nếu quý khách dùng custom remote port khác port remote mặc định thì điền vào đây</span>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline mb-3">'
          self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="rgcPortCreating" aria-describedby="detail" value="' + rowData['port'] + '">'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<div class="form-group"><label for="selectImageRebuild">' + self.$t('dedicated.modal.bodySelectRGC') + '</label><select class="form-select ps-3 mb-3" id="selectRGCServer">'

          async.forEachOf(self.rgcObject.serverList, function (item, key, cb) {
            self.confirmModalDisplayObject.bodyMsg += '<option value="' + item['id'] + '">' + item['rgc_server_name'] + ' (' + item['location'] + ')</option>'
            cb()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</select></div>'
            self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicated.modal.buttonCreate')

            self.action = 'confirmCreateRGC'
            self.showModal(true)
          })
        }
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleConfirmCreateRGC') + '</h4>'

        // Get the image list
        if (self.rgcObject.serverList === null) {
          this.getDataFromAPI(this.$config.apiPath.rgcServerAvailable, function (result) {
            self.rgcObject.serverList = result.data['RGCServerObjectList']
            buildImageSelection()
          })
        } else {
          buildImageSelection()
        }
      }
    },
    deleteRGC: function (rowData) {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('dedicated.toast.errPendingTask'))
        return
      }
      self.rgcObject.vmObject = rowData
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleConfirmDeleteRGC') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicated.modal.bodyConfirmDeleteRGC')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + rowData['ip_port'] + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicated.modal.buttonDelete')

      this.action = 'confirmDeleteRGC'
      this.showModal(true)
    },
    onConfirmButton: function (inputObjectList) {
      let self = this
      if (this.action === 'start') {
        // $('#vm-start i').removeClass('fa-play').addClass('fa-spin fa-circle-o-notch')
        // Update status display text by jQuery for better performance than the dataTable
        self.updateStatusSelectedRow(self.$t('dedicated.table.tdStarting'))
        this.submitPVEAction(this.$config.apiPath.dedicatedStart, 'dedicated', self.selectedObjectList, function (rowDataObject, status) {
          self.updateStatusRowById(rowDataObject.id, status)
        }, function () {
          // $('#vm-start i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-play')
          // self.getSelectedRows().invalidate().draw(false)
          self.action = 'none'
        })
      } else if (this.action === 'stop') {
        // $('#vm-stop i').removeClass('fa-stop').addClass('fa-spin fa-circle-o-notch')
        self.updateStatusSelectedRow(self.$t('dedicated.table.tdStopping'))
        this.submitPVEAction(this.$config.apiPath.dedicatedStop,'dedicated', self.selectedObjectList, function (rowDataObject, status) {
          self.updateStatusRowById(rowDataObject.id, status)
        }, function () {
          // $('#vm-stop i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-stop')
          // self.getSelectedRows().invalidate().draw(false)
          self.action = 'none'
        })
      } else if (this.action === 'restart') {
        // $('#vm-restart i').removeClass('fa-refresh').addClass('fa-spin fa-circle-o-notch')
        self.updateStatusSelectedRow(self.$t('dedicated.table.tdRestarting'))
        this.submitPVEAction(this.$config.apiPath.dedicatedRestart,'dedicated', self.selectedObjectList, function (rowDataObject, status) {
          self.updateStatusRowById(rowDataObject.id, status)
        }, function () {
          // $('#vm-restart i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-refresh')
          // self.getSelectedRows().invalidate().draw(false)
          self.action = 'none'
        })
      } else if (this.action === 'confirmRenew') {
        // $('#vm-renew i').removeClass('fa-calendar').addClass('fa-spin fa-circle-o-notch')
        this.submitObjectList(this.$config.apiPath.dedicatedRenew,
            this.selectedObjectList.map(function (a) {
              return {
                id: a.id
              }
            }),
            'dedicated',
            {
              period: inputObjectList[0]['periodRenew']
            },
            function (renewResult) {
          self.$toasted.success(renewResult.msg)
          // $('#vm-renew i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-calendar')
          self.action = 'none'
          window.open('/#/home/order/detail/' + renewResult.data.id, '_blank')
        })
      } else if (this.action === 'confirmAutoRenew') {
        this.submitObjectList(this.$config.apiPath.dedicatedAutoRenew, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'dedicated', {autoRenew: 1}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          for (let i = 0; i < self.selectedObjectList.length; i++) {
            let index = self.dataObjectList.findIndex(x => x.id === self.selectedObjectList[i].id)
            self.dataObjectList[index]['auto_renew'] = 1
          }
          self.action = 'none'
          self.confirmModalDisplayObject.additionalButton.isShow = false
        })
      } else if (this.action === 'confirmTransfer') {
        // $('#vm-transfer i').removeClass('fa-exchange').addClass('fa-spin fa-circle-o-notch')
        this.submitObjectList(this.$config.apiPath.dedicatedTransfer,
            this.selectedObjectList.map(function (a) { return {id: a.id} }), 'dedicated',
            {
              targetEmail: inputObjectList[0]['transferEmailTarget'],
              otp_code: self.otp_code
            }, function (transferResult) {
              if (transferResult.data.is_enable_2fa) {
                self.otpConfirmAction = 'confirmTransfer'
                self.inputObjectList = inputObjectList
                $('#otp_modal').modal('show')
              } else {
                self.$toasted.success(transferResult.msg)
                // $('#vm-transfer i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-exchange')
                self.action = 'none'
                self.dataObjectList = self.dataObjectList.filter(ar => !self.selectedObjectList.find(rm => ar.id === rm.id))
                // $('#dataTable').DataTable().rows('.selected').remove().draw(false)
              }
        },null, true, function () {
          self.action = 'none'
          self.otp_code = ''
          $('#otp_modal').modal('hide')
        })
      } else if (this.action === 'teamMemberShare') {
        let memberId = $('#selectTeamMembers').children('option:selected').val()
        let memberName = $('#selectTeamMembers').children('option:selected').text()
        this.submitObjectList(this.$config.apiPath.shareTeamDedicated,
            this.selectedObjectList.map(function (a) { return {id: a.id} }),
            'dedicated',
            {
              member_id: memberId
            }, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_id', memberId)
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_full_name', memberName)
              }
            }, null, true, function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmRDP') {
        // $('#vm-rdp i').removeClass('fa-windows').addClass('fa-spin fa-circle-o-notch')
        self.generateRDGFile(self.selectedObjectList, function (xml) {
          // $('#vm-rdp i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-windows')
          self.action = 'none'
        })
      } else if (this.action === 'confirmCreateRGC') {
        let rgcServerId = $('#selectRGCServer').children('option:selected').val()
        let portRGC = $('#rgcPortCreating')[0].value
        self.$toasted.info(self.$t('dedicated.toast.rgcCreating', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['osType'])]))
        this.submitObjectList(this.$config.apiPath.rgcCreate, undefined, undefined, {
              serviceTypeID: self.rgcObject.vmObject['service_type_id'],
              serviceID: self.rgcObject.vmObject['id'],
              RGCServerID: rgcServerId,
              remotePort: portRGC
            },function (rowDataObject, status) {
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_server_name', rowDataObject.data['rgc_server_name'])
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_forwarded_port',rowDataObject.data['rgc_forwarded_port'])
          self.$toasted.success(rowDataObject.msg)
            }, function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmDeleteRGC') {
        self.$toasted.info(self.$t('dedicated.toast.rgcDelete', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['osType'])]))
        this.submitObjectList(this.$config.apiPath.rgcDelete, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id']
        },function (rowDataObject, status) {
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_server_name',null)
          self.updateFieldRowById(self.rgcObject.vmObject['id'], 'rgc_forwarded_port',null)
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'confirmExportTextFile') {
        self.exportTextFile(self.selectedObjectList.map(function(element) {
          return element.id + '|' + element.ip + '|' + element.port + '|'
              + element.login_user + '|' + element.login_password + '|'
              + element.vm_plan_name + '|'
              + element.country + ' (' + element.state + ')' + '|'
              + self.convertISODate(element.end_date) + '|'
              + element.user_remark
        }), 'LowEndViet_Dedicated_Text_File_Export', function () {
          self.action = 'none'
        })
      } else if (this.action === 'confirmExportExcelFile') {
        self.exportExcelFile(self.selectedObjectList.map(function(element) {
          let result = {
            id: element.id,
            host: self.getIPPortFormat(element.ip, element.port),
            user: element.login_user,
            password: element.login_password,
            plan: element.vm_plan_name,
            location: element.country + ' (' + element.state + ')',
            end_date: self.convertISODate(element.end_date),
            note: element.user_remark
          }
          return result
        }), 'LowEndViet_Dedicated_Excel_File_Export', function () {
          self.action = 'none'
        })
      } else if (self.action === 'notification') {
        self.action = 'none'
      } else if (this.action === 'editRemark') {
        let self = this
        //let note = $('#editNote').val()
        this.submitObjectList(this.$config.apiPath.dedicatedNote, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'dedicated', {note: inputObjectList[0]['editNote']}, function (result) {
          self.$toasted.success(result.msg)
          for (let i = 0; i < self.selectedObjectList.length; i++) {
            self.setItemProperty(self.selectedObjectList[i].id, 'user_remark', inputObjectList[0]['editNote'])
          }

          // self.getSelectedRows().invalidate().draw(false)
          // self.finishModal()
        }, function () {
        })
      }
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      if (this.action === 'confirmAutoRenew') {
        // $('#vm-autorenew i').removeClass('fa-thumbs-o-up').addClass('fa-spin fa-circle-o-notch')
        this.submitObjectList(this.$config.apiPath.dedicatedAutoRenew, this.selectedObjectList.map(function (a) { return {id: a.id} }), 'dedicated', {autoRenew: 0}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          // $('#vm-autorenew i').removeClass('fa-spin fa-circle-o-notch').addClass('fa-thumbs-o-up')
          for (let i = 0; i < self.selectedObjectList.length; i++) {
            let index = self.dataObjectList.findIndex(x => x.id == self.selectedObjectList[i].id)
            self.dataObjectList[index]['auto_renew'] = 0
          }
          self.action = 'none'
          self.confirmModalDisplayObject.additionalButton.isShow = false
        })
      } else if (this.action === 'teamMemberShare') {
        this.submitObjectList(this.$config.apiPath.cancelShareDedicated,
            this.selectedObjectList.map(function (a) { return {id: a.id} }),
            'dedicated',
            null, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_id', null)
                self.setItemProperty(self.selectedObjectList[i].id, 'co_owner_full_name', null)
              }
            }, null, true, function () {
              self.action = 'none'
            })
      }
      self.finishModal()
    },
    finishAction: function () {
      this.action = 'none'
      this.confirmModalDisplayObject.additionalButton.isShow = false
    },
    onDedicatedFilterChange: function(event) {
      let self = this
      switch(event.target.id) {
        case 'btnradioAllDedicated':
          self.dataObjectList = self.rawDataFromDB
          break
        case 'btnradioOverDueDedicated':
          self.dataObjectList = self.getListDedicatedOverDue()
          break
        case 'btnradioSuspendDedicated':
          self.dataObjectList = self.getListDedicatedSuspend()
          break
        case 'btnradioCancelDedicated':
          self.dataObjectList = self.getListDedicatedCancel()
          break
        case 'btnradioUnknownDedicated':
          self.dataObjectList = self.getListDedicatedUnknown()
          break
        case 'btnradioVPSShare':
          self.dataObjectList = self.getListDedicatedTeam()
          break
        default:
          break
      }
    },
    updateStatusSelectedRow: function(status) {
      let self = this
      for (let i = 0; i < self.selectedObjectList.length; i ++) {
        let objectIndex = self.dataObjectList.findIndex(x => x.id === self.selectedObjectList[i].id)
        self.setItemProperty(self.dataObjectList[objectIndex].id, 'operation_status', status)
      }
    },
    updateStatusRowById: function(vmId, status) {
      this.setItemProperty(vmId, 'operation_status', status)
    },
    updateFieldRowById: function(vmId, field, value) {
      this.setItemProperty(vmId, field, value)
    },
    getListDedicatedOverDue: function () {
      let currentDate = new Date(this.getCurrentTime())
      return this.rawDataFromDB.filter(item => item.end_date <= this.convertISODatePlusDays(currentDate, 3))
    },
    getListDedicatedCancel: function () {
      return this.rawDataFromDB.filter(item => item.payment_status === 'cancel')
    },
    getListDedicatedSuspend: function () {
      return this.rawDataFromDB.filter(item => item.payment_status === 'suspend')
    },
    getListDedicatedUnknown: function () {
      return this.rawDataFromDB.filter(
          item => item.payment_status !== 'cancel'
              && item.payment_status !== 'ok'
              && item.payment_status !== 'suspend'
      )
    },
    getListDedicatedTeam: function () {
      return this.rawDataFromDB.filter(
          item => item['co_owner_id'] !== null
      )
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('dedicatedDetail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
    updateVisiableFields: function () {
      this.visibleFields = []
      if(this.themeSetting.dedicatedTableSetting.ip_port.isVisible) {
        this.visibleFields.push('ip_port')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'ip_port')
      }

      if(this.themeSetting.dedicatedTableSetting.login_user.isVisible) {
        this.visibleFields.push('login_user')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'login_user')
      }

      if(this.themeSetting.dedicatedTableSetting.login_password.isVisible) {
        this.visibleFields.push('login_password')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'login_password')
      }

      if(this.themeSetting.dedicatedTableSetting.price.isVisible) {
        this.visibleFields.push('price')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'price')
      }

      if(this.themeSetting.dedicatedTableSetting.dedicated_plan_name.isVisible) {
        this.visibleFields.push('dedicated_plan_name')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'dedicated_plan_name')
      }

      if(this.themeSetting.dedicatedTableSetting.location.isVisible) {
        this.visibleFields.push('location')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'location')
      }

      if(this.themeSetting.dedicatedTableSetting.start_date.isVisible) {
        this.visibleFields.push('start_date')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'start_date')
      }

      if(this.themeSetting.dedicatedTableSetting.end_date.isVisible) {
        this.visibleFields.push('end_date')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'end_date')
      }

      if(this.themeSetting.dedicatedTableSetting.auto_renew.isVisible) {
        this.visibleFields.push('auto_renew')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'auto_renew')
      }

      if(this.themeSetting.dedicatedTableSetting.user_remark.isVisible) {
        this.visibleFields.push('user_remark')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'user_remark')
      }

      if(this.themeSetting.dedicatedTableSetting.payment_status.isVisible) {
        this.visibleFields.push('payment_status')
      } else {
        this.visibleFields = this.visibleFields.filter(f => f !== 'payment_status')
      }
    },
    confirmOTP: function (otp_code) {
      this.action = this.otpConfirmAction
      this.otp_code = otp_code
      this.onConfirmButton(this.inputObjectList)
      this.otpConfirmAction = 'none'
      $('#otp_modal').modal('hide')
    }
  }
}
