export default {
  en: {
    label: {
      top: 'Recover password',
      username: 'Username/Email/Phone',
      email: 'Email',
      password: 'Enter new password',
      password2: 'Reenter the password'
    },
    placeholder: {
      username: 'Username/Email/Phone',
      password: 'More than 8 chars, both text and number'
    },
    button: {
      submit: 'Submit',
      login: 'Login',
      resetPW: 'Reset password'
    },
    status: {
      errPWNotSame: 'The 2 passwords are not the same!',
      errPWTooShort: 'The password must have more than 8 characters!',
      errPWNumber: 'The password must have at least a number!'
    }
  },
  vi: {
    label: {
      top: 'Lấy lại mật khẩu',
      email: 'Email',
      username: 'Tên đăng nhập/Email/Số điện thoại',
      password: 'Điền mật khẩu mới',
      password2: 'Điền lại mật khẩu mới một lần nữa'
    },
    placeholder: {
      username: 'Username/Email/Phone',
      password: 'Dài hơn 8 kí tự, đủ chữ và số'
    },
    button: {
      submit: 'Xác nhận',
      login: 'Trở lại trang đăng nhập',
      resetPW: 'Đổi mật khẩu'
    },
    status: {
      errPWNotSame: 'Mật khẩu không giống nhau!',
      errPWTooShort: 'Mật khẩu cần dài hơn 8 ký tự!',
      errPWNumber: 'Mật khẩu phải chứa ít nhất một ký tự chữ hoặc số!'
    }
  },
  zh: {
    label: {
      top: '忘记密码',
      email: '邮箱',
      username: '登录名称/邮箱/电话号码',
      password: '请填入新密码',
      password2: '请再次填入新密码'
    },
    placeholder: {
      username: '登录名称/邮箱/电话号',
      password: '请填入8位数以上包含数字及字母'
    },
    button: {
      submit: '确认',
      login: '返回登陆页面',
      resetPW: '更改密码'
    },
    status: {
      errPWNotSame: '密码不一致!',
      errPWTooShort: '密码要求至少8位数!',
      errPWNumber: '密码最少要有一位数字或字母!'
    }
  }
}
