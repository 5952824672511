//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BasicList from "@/components/services/proxy/BasicList";
import DetailList from "@/components/services/proxy/DetailList";
import {FixBugTheme} from "@/mixins/MaterialTheme";
import dataRequester from "@/mixins/utilities/DataRequester";

export default {
  name: "List",
  mixins: [dataRequester],
  components: {
    BasicList,
    DetailList
  },
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    }
  },
  data: function () {
    return {
      searchText: '',
      proxyDetailDeleteList: [],
      proxyBasicList: [],
      proxyDetailList: []
    }
  },
  mounted() {
    FixBugTheme()
  },
  updated() {
    FixBugTheme()
  },
  created () {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.proxyList, function (result) {
      self.proxyBasicList = result.data['proxyObjectList']
      self.getDataFromAPI(self.$config.apiPath.proxyDetailList, function (result) {
        self.proxyDetailList = result.data['proxyDetailObjectList']
        self.proxyDetailList = self.proxyDetailList.map(x => {
          let obj = self.proxyBasicList.find(y => x['vm_id'] === y['id'])
          x['user_remark'] = obj['user_remark']
          return x
        })
      })
    })
  },
  methods: {
    FixBugTheme,
    filterDetailByIp(ip) {
      this.searchText = ip

      let basicTab = document.getElementById('tab-basic')
      let detailTab = document.getElementById('tab-detail')
      basicTab.classList.remove('active')
      detailTab.classList.add('active')

      let basicContent = document.getElementById('pills-basic')
      let detailContent = document.getElementById('pills-detail')
      basicContent.classList.remove('show')
      basicContent.classList.remove('active')
      detailContent.classList.add('show')
      detailContent.classList.add('active')
    },
    deleteProxy(proxyDetailList) {
      this.proxyDetailDeleteList = proxyDetailList
    }
  }
}
