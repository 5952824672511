//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LevInput from "@/components/children/lev_components/LevInput.vue";

export default {
  name: "HostingInfo",
  components: {LevInput},
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    username () {
      this.updateValue()
    },
    domain () {
      this.updateValue()
    },
    email () {
      this.updateValue()
    },
    password () {
      this.updateValue()
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', {
        username: value['username'],
        domain: value['domain'],
        email: value['email'],
        password: value['password']
      })
    }
  }
}
