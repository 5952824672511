//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LevTable from "@/components/children/LevTable";

import dataObjectList from '@/mixins/DataObjectList'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import dataRequester from '../mixins/utilities/DataRequester'
import inputHelper from '../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';


export default {
  name: "LogList",
  components: {LevTable},
  mixins: [dataObjectList, dataRequester, confirmModalBuilder, inputHelper],
  data: function () {
    return {
      action: 'none',
      filter: {
        dateFrom: {
          currentSelection: null
        },
        dateTo: {
          currentSelection: null
        }
      },
      selectedObjectIndex: null,
      tableLogColumns: [
        {
          name: 'action',
          title: '<i class="fas fa-hand-point-up"></i> ' + this.$t('log.table.thAction'),
          width: "10%"
        },
        {
          name: 'status',
          title: '<i class="fas fa-info"></i> ' + this.$t('log.table.thStatus'),
          width: "10%"
        },
        {
          name: 'content',
          title: '<i class="fas fa-indent"></i> ' + this.$t('log.table.thDetail'),
          width: "50%"
        },
        {
          name: 'ip',
          title: '<i class="fas fa-ethernet"></i> ' + this.$t('log.table.thAccessIP'),
          width: "15%"
        },
        {
          name: 'created_at',
          sortField: 'created_at',
          title: '<i class="fas fa-clock"></i> ' + this.$t('log.table.thUpdated'),
          width: "10%"
        }
      ],
      extendtedFields: [],
      visibleFields: [],
      searchableFields:['action', 'status', 'content', 'ip', 'created_at']
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    let cookieStorage = this.$cookies.get('storage')
    let logFilter = {}
    if (cookieStorage != null && cookieStorage['logFilter'] !== undefined) {
      logFilter = cookieStorage['logFilter']
      this.filter.dateFrom.currentSelection = logFilter.dateFrom
      this.filter.dateTo.currentSelection = logFilter.dateTo
    } else {
      this.filter.dateFrom.currentSelection = this.getDateFromCurrent()
      this.filter.dateTo.currentSelection = this.getDateFromCurrent(1)
    }
    self.submitObjectList(self.$config.apiPath.logList, undefined, 'log',
        {
          filter: {
            dateFrom: self.convertISODate(self.filter.dateFrom.currentSelection),
            dateTo: self.convertISODate(self.filter.dateTo.currentSelection)
          }
        }, function (result) {
          self.dataObjectList = result.data['logObjectList']
          self.dataObjectList.forEach(function (v, k) {
            self.dataObjectList[k]['created_at'] = self.convertISODate(v['created_at'])
          })
        })
  },
  methods: {
    FixBugTheme,
    filterLog: function () {
      let cookieStorage = this.$cookies.get('storage')
      if (cookieStorage === null) {
        cookieStorage = {}
      }

      cookieStorage['logFilter'] = {
        dateFrom: this.filter.dateFrom.currentSelection,
        dateTo: this.filter.dateTo.currentSelection
      }
      this.$cookies.set('storage', JSON.stringify(cookieStorage))
      // Force re-render
      setTimeout(() => {
        window.location.reload()
      }, 800)
    },
    onSelectedChanged(selectedRowID) {
      // console.log(selectedRowID)
      // console.log(this.dataObjectList.filter(d => selectedRowID.includes(d['id'])))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      // if (cellField.name === 'status') {
      //   if (rowData['status'] === 'success') {
      //     rowData['status'] = 'Totally success'
      //   }
      //   updatePostTable()
      // }
    }
  }
}
