import {saveAs} from 'file-saver'
import xlsExport from "xlsexport";

export default {
    methods: {
        exportTextFile: function (rows, filename, callback) {
            let self = this
            let blob = new Blob([rows.join('\n')], {
                type: "text/plain;charset=utf-8"
            });
            // saveAs(blob, '-' + self.getCurrentTime() + '.txt');
            saveAs(blob, `${filename}-${self.getCurrentTime()}.txt`)
            self.$toasted.success(self.$t('vps.toast.okExportTextFile', [rows.length]))
            if (typeof callback === 'function') {
                callback()
            }
        },
        exportExcelFile: function (selectedListVM, filename, callback) {
            let self = this
            const xls = new xlsExport(selectedListVM, 'LEV');
            // xls.exportToXLS('LowEndViet_VPS_Excel_File_Export-' + self.getCurrentTime() + '.xls')
            xls.exportToXLS(`${filename}-${self.getCurrentTime()}.xls`)
            self.$toasted.success(self.$t('vps.toast.okExportExcelFile', [rows.length]))
            if (typeof callback === 'function') {
                callback()
            }
        }
    }
}
