export default {
  en: {
    breadcrumb: 'Your Snapshot List',
    cardHeader: {
      title: 'Your snapshot list'
    },
    table: {
      thName: 'Name',
      thImageName: 'OS Version',
      thSize: 'Disk size',
      thStatus: 'Status',
      thManage: 'Manage',
      thNote: 'Note',

      tdDelete: 'Delete'
    },
    modal: {
      title: 'Confirm delete snapshot?',
      bodyWarningTooSoon: 'Notice: You have taken a snapshot in the last 7 days. Our policy allow to take new snapshot' +
        ' each 7 days. The soonest time that you can take snapshot is {0}.',
      bodyWarningDelete: 'Do you really want to delete this snapshot?',
      buttonDelete: 'Delete'
    },
    tooltip: {
      editNote: 'Edit note',
      saveNote: 'Save note',
      cancelEditNote: 'Cancel edit note'
    }
  },
  vi: {
    breadcrumb: 'Danh Sách Snapshot',
    cardHeader: {
      title: 'Danh sách snapshot'
    },
    table: {
      thName: 'Tên',
      thImageName: 'Hệ điều hành',
      thSize: 'Size ổ cứng',
      thStatus: 'Trạng thái',
      thManage: 'Quản lý',
      thNote: 'Ghi chú',

      tdDelete: 'Xóa'
    },
    modal: {
      title: 'Xác nhận xóa snapshot?',
      bodyWarningTooSoon: 'Chú ý: Bạn đã tạo 1 snapshot trong vòng 7 ngày gần nhất. Chính sách của chúng tôi chỉ cho phép' +
        ' tạo mới snapshot mỗi tuần. Thời điểm sớm nhất bạn có thể tạo snapshot là {0}.',
      bodyWarningDelete: 'Bạn thực sự muốn xóa snapshot này?',
      buttonDelete: 'Xóa'
    },
    tooltip: {
      editNote: 'Thay đổi ghi chú',
      saveNote: 'Lưu ghi chú',
      cancelEditNote: 'Huỷ bỏ thay đổi ghi chú'
    }
  },
  zh: {
    cardHeader: {
      title: '样版清单'
    },
    table: {
      thName: '名字',
      thImageName: '操作系统',
      thSize: '硬盘容量',
      thStatus: '状态',
      thManage: '管理',

      tdDelete: '删除'
    },
    modal: {
      title: '确认删除样版?',
      bodyWarningTooSoon: '注意: 在这个星期您已经制造一个样版. 我们的政策只允许' +
        ' 一个星期制作一个样版. 距离您能再次制作样版是 {0}.',
      bodyWarningDelete: '您是否确认删除此样版?',
      buttonDelete: '删除'
    }
  }
}
