//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import dataRequester from '../mixins/utilities/DataRequester'
import {activedRoutes} from "@/main"
import { FixBugTheme } from '@/mixins/MaterialTheme';
import async from "async";
import confirmModal from '@/components/children/ConfirmModal'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'
import inputHelper from "@/mixins/utilities/InputHelper";
import moment from 'moment'
import LevModal from "@/components/children/lev_components/LevModal.vue";

export default {
  name: "Home",
  components: {
    LevModal,
    confirmModal
  },
  mixins: [dataRequester, confirmModalBuilder, inputHelper],
  props: {
    currentUser: {
      default: function () { return {} },
      type: Object
    }
  },
  data: function () {
    return {
      metaData: {
        logoPath: '',
        siteName: '',
        siteTicker: '',
        contactWebsite: '',
        contactEmail: '',
        contactPhone: '',
        footNote: '',
        support: [],
        tosURL: '',
        slaURL: '',
        privacyURL: '',
        isMenuTopupEnable: false,
        isMenuNotiEnable: false,
        isMenuSnapshotEnable: false,
        isMenuBackupEnable: false,
        isMenuAPIEnable: false
      },
      notificationObjectList: [],
      notificationHighLightObjectSelected: {},
      routeMeta: {},
      updateUserInfoInterval: {},
      themeSetting: {},
      languageSelected: 'en',
      startTimeToday: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      notificationPopupHide: false
    }
  },
  computed: {
    notificationHighlightObjectList() {
      return this.notificationObjectList.filter(x => x['is_highlight'] === 1)
    },
    notificationPopupList() {
      let self = this
      let localStorageKey = `noti_${this.currentUser['userID']}`
      let notiIdString = localStorage.getItem(localStorageKey)
      if ([null, undefined].includes(notiIdString)) {
        return this.notificationObjectList.filter(x => x['is_popup'] === 1)
      } else {
        let notiIds = JSON.parse(notiIdString)
        return this.notificationObjectList.filter(x => x['is_popup'] === 1 && !notiIds.includes(x['id']))
      }
    },
    isOrderScreen() {
      return this.$route.meta['name'] === 'New Order' && this.$route.query['filter'] !== 'promotion'
    },
    isOrderPromotionScreen() {
      return this.$route.meta['name'] === 'New Order' && this.$route.query['filter'] === 'promotion'
    },
  },
  mounted: function () {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    window.addEventListener('scroll', this.handleScroll);

    this.themeSetting = JSON.parse(this.getLocalStorageData())

    if (window.document.cookie.indexOf('username') > -1 && window.document.cookie.indexOf('sessionId') > -1) {
      self.updateCurrentUserInfo()
      self.updateUserInfoInterval = setInterval(function () {self.updateCurrentUserInfo()}, 1000 * 60)
    }

    window.addEventListener('resize', function () {
      let sidebar = document.querySelectorAll('#sidenav-main')[0]
      let mainLogo = document.getElementById('main_logo')
      if(window.innerWidth < 1500) {
        if(!sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
          sidebar.parentElement.classList.remove('g-sidenav-pinned')
          sidebar.parentElement.classList.add('g-sidenav-hidden')
          mainLogo.src = "../../assets/img/logo/LowendViet-small.png"
        }
      } else {
        if(sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
          sidebar.parentElement.classList.add('g-sidenav-pinned')
          sidebar.parentElement.classList.remove('g-sidenav-hidden')
          mainLogo.src = self.metaData.logoPath
        }
      }
    })

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
      this.languageSelected = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
        this.languageSelected = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }
    this.getDataFromAPI(self.$config.apiPath.notiList, function (result) {
      self.notificationObjectList = result.data
      setTimeout(function () {
        self.popupNoification()
      }, 3000)
    })
    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.siteTicker = metaData.domainObjectList['site_ticker']
      self.metaData.contactWebsite = metaData.domainObjectList['contact_website']
      self.metaData.contactEmail = metaData.domainObjectList['contact_email']
      self.metaData.contactPhone = metaData.domainObjectList['contact_phone']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
      self.metaData.support = JSON.parse(metaData.domainObjectList['support'])
      self.metaData.tosURL = metaData.domainObjectList['tos_url']
      self.metaData.slaURL = metaData.domainObjectList['sla_url']
      self.metaData.privacyURL = metaData.domainObjectList['privacy_url']
      self.metaData.isMenuTopupEnable = metaData.domainObjectList['is_menu_topup_enable']
      self.metaData.isMenuNotiEnable = metaData.domainObjectList['is_menu_noti_enable']
      self.metaData.isMenuSnapshotEnable = metaData.domainObjectList['is_menu_snapshot_enable']
      self.metaData.isMenuBackupEnable = metaData.domainObjectList['is_menu_backup_enable']
      self.metaData.isMenuAPIEnable = metaData.domainObjectList['is_menu_api_enable']
      self.metaData.isMenuOrderPromotionEnable = metaData.domainObjectList['is_menu_order_promotion_enable']
      self.metaData.defaultCurrency = metaData.domainObjectList['default_currency']
      self.metaData.exchangeRate = metaData.domainObjectList['exchange_rate']
      self.metaData['exchangeRateBlackMarket'] = metaData.domainObjectList['exchange_rate_black_market']

      const popoverElement = document.getElementById('exchange-rate-popover'); // Thay đổi bằng selector đúng của bạn
      const popoverInstance = bootstrap.Popover.getInstance(popoverElement);
      // Cập nhật nội dung mới
      popoverElement.setAttribute('data-bs-content', self.$t('home.label.exchange_rate_popover', [self.startTimeToday, self.currencyFormat(self.metaData['exchangeRateBlackMarket'], self.metaData['defaultCurrency'])]));
      // Hủy Popover hiện tại
      if (popoverInstance) {
        popoverInstance.dispose();
      }
      // Khởi tạo lại Popover với nội dung mới
      const newPopoverInstance = new bootstrap.Popover(popoverElement);
      let popover = new bootstrap.Popover(popoverElement, {
        trigger: 'manual'
      });
      popoverElement.addEventListener('mouseenter', () => {
        popover.show();
      });
      popoverElement.addEventListener('mouseleave', () => {
        popover.hide();
      });

      // $('.dropdown').hover(function () {
      //   $('.dropdown-toggle', this).trigger('click')
      }, function () {
        self.$toasted.error(self.$t('domain.toast.errMetaData'))
      })
  },
  beforeUpdate: function() {
    this.routeMeta = activedRoutes.map(e => e.meta).filter(e => e)

    document.getElementById("pluginSettings").addEventListener('click', function () {
      this.parentElement.classList.add('show')
    })
    document.getElementById("displaySettings").addEventListener('click', function () {
      document.getElementById("pluginSettings").parentElement.classList.add('show')
    })
    document.getElementById("closePluginSettings").addEventListener('click', function () {
      document.getElementById("pluginSettings").parentElement.classList.remove('show')
    })

    this.themeSetting = JSON.parse(this.getLocalStorageData())
    this.sidebarTypeOnload()
    this.updateNavbarFixed(this.themeSetting.isNavbarFixed)
    this.updateDarkMode(this.themeSetting.isDarkTheme)
  },
  updated() {
    document.getElementById('breadcrumb').textContent = this.$t(this.$route.meta['i18n'])
    document.title = this.$t(this.$route.meta['i18n']) + ' - Cloud Service Manager';
    this.updateDarkMode(this.themeSetting.isDarkTheme)

    let sidebar = document.querySelectorAll('#sidenav-main')[0]
    let mainLogo = document.getElementById('main_logo')
    if(window.innerWidth < 1500) {
      if(!sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
        sidebar.parentElement.classList.remove('g-sidenav-pinned')
        sidebar.parentElement.classList.add('g-sidenav-hidden')
        mainLogo.src = "../../assets/img/logo/LowendViet-small.png"
      }
    }
  },
  methods: {
    FixBugTheme,
    handleScroll: function() {
      //set top bar with style top = 0 when scroll
      let topBar = document.getElementById('navbarBlur')
      if(window.scrollY > 0) {
        topBar.setAttribute('style', 'transform: translateY(-15px);')
      } else {
        topBar.setAttribute('style', 'transform: translateY(0);')
      }
    },
    logout: function () {
      clearInterval(this.updateUserInfoInterval)
      this.$cookies.remove('username')
      this.$cookies.remove('sessionId')
      this.$cookies.remove('email')
      this.$router.push('/login')
    },
    changeLocale: function (locale) {
      this.languageSelected = locale
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    },
    forwardLoginEvent: function (userObject) {
      this.$emit('loginEvent', userObject)
    },
    sideBarAction: function () {
      let sidebar = document.querySelectorAll('#sidenav-main')[0]
      // let navbar = document.getElementById('navbarMinimize')
      let mainLogo = document.getElementById('main_logo')
      if(sidebar.parentElement.classList.contains('g-sidenav-hidden')) {
        sidebar.parentElement.classList.remove('g-sidenav-hidden')
        sidebar.parentElement.classList.add('g-sidenav-pinned')
        mainLogo.src = this.metaData.logoPath
        // navbar.removeAttribute("checked");
      }
      else {
        sidebar.parentElement.classList.remove('g-sidenav-pinned')
        sidebar.parentElement.classList.add('g-sidenav-hidden')
        mainLogo.src = "../../assets/img/logo/LowendViet-small.png"
        // navbar.setAttribute("checked", "true");
      }
    },
    getLocalStorageData: function () {
      if(localStorage.getItem(this.currentUser.userID) === null) {
        let objectData = {
          sidebarType: 0,
          isDarkTheme: false,
          isNavbarFixed: false,
          vpsTableSetting: {
            start_date: {
              isVisible: false,
              isSortable: true
            },
            end_date: {
              isVisible: true,
              isSortable: true
            },
            ip_port: {
              isVisible: true,
              isSortable: true
            },
            login_user: {
              isVisible: true,
              isSortable: true
            },
            login_password: {
              isVisible: true,
              isSortable: true
            },
            price: {
              isVisible: true,
              isSortable: true
            },
            vm_plan_name: {
              isVisible: true,
              isSortable: true
            },
            location: {
              isVisible: true,
              isSortable: true
            },
            backup_period: {
              isVisible: false,
              isSortable: true
            },
            auto_renew: {
              isVisible: false,
              isSortable: true
            },
            user_remark: {
              isVisible: true,
              isSortable: true
            },
            payment_status: {
              isVisible: true,
              isSortable: true
            },
            operation_status: {
              isVisible: true,
              isSortable: true
            }
          },
          dedicatedTableSetting: {
            start_date: {
              isVisible: false,
              isSortable: true
            },
            end_date: {
              isVisible: true,
              isSortable: true
            },
            ip_port: {
              isVisible: true,
              isSortable: true
            },
            login_user: {
              isVisible: true,
              isSortable: true
            },
            login_password: {
              isVisible: true,
              isSortable: true
            },
            price: {
              isVisible: true,
              isSortable: true
            },
            dedicated_plan_name: {
              isVisible: true,
              isSortable: true
            },
            location: {
              isVisible: false,
              isSortable: true
            },
            auto_renew: {
              isVisible: false,
              isSortable: true
            },
            user_remark: {
              isVisible: true,
              isSortable: true
            },
            payment_status: {
              isVisible: true,
              isSortable: true
            }
          }
        }
        localStorage.setItem(this.currentUser.userID, JSON.stringify(objectData))
      }
      return localStorage.getItem(this.currentUser.userID)
    },
    updateVPSTableSetting: function (event) {
      this.themeSetting.vpsTableSetting[event.target.name].isVisible = event.target.checked
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateDedicatedTableSetting: function (event) {
      this.themeSetting.dedicatedTableSetting[event.target.name].isVisible = event.target.checked
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateDarkMode: function (isDarkMode) {

      const body = document.getElementsByTagName('body')[0];
      const modal_content = document.getElementById('modal-content');
      const hr = document.querySelectorAll('div:not(.sidenav) > hr');
      const hr_card = document.querySelectorAll('div:not(.bg-gradient-dark) hr');
      const text_btn = document.querySelectorAll('button:not(.btn) > .text-dark');
      const text_span = document.querySelectorAll('span.text-dark, .breadcrumb .text-dark');
      const text_span_white = document.querySelectorAll('span.text-white, .breadcrumb .text-white');
      const text_strong = document.querySelectorAll('strong.text-dark');
      const text_strong_white = document.querySelectorAll('strong.text-white');
      const text_nav_link = document.querySelectorAll('a.nav-link.text-dark');
      const text_nav_link_white = document.querySelectorAll('a.nav-link.text-white');
      const secondary = document.querySelectorAll('.text-secondary');
      const bg_gray_100 = document.querySelectorAll('.bg-gray-100');
      const bg_gray_600 = document.querySelectorAll('.bg-gray-600');
      const btn_text_dark = document.querySelectorAll('.btn.btn-link.text-dark, .material-icons.text-dark');
      const btn_text_white = document.querySelectorAll('.btn.btn-link.text-white, .material-icons.text-white');
      const card_border = document.querySelectorAll('.card.border');
      const card_border_dark = document.querySelectorAll('.card.border.border-dark');
      const mainContent_blur = document.querySelectorAll('.main-content .container-fluid .card');
      const text_dark = document.querySelectorAll('.text-dark');
      const text_light = document.querySelectorAll('.text-light');
      const bg_black = document.querySelectorAll('.bg-black');
      const bg_white = document.querySelectorAll('.bg-white');
      const svg = document.querySelectorAll('g');

      if (isDarkMode) {
        body.classList.add('dark-version');
        if (modal_content !== undefined && modal_content !== null) {
          modal_content.classList.add('dark-version');
        }
        for (var i = 0; i < text_dark.length; i++) {
          if (text_dark[i].classList.contains('text-dark')) {
            text_dark[i].classList.remove('text-dark');
            text_dark[i].classList.add('text-light');
          }
        }
        for (var i = 0; i < bg_black.length; i++) {
          if (bg_black[i].classList.contains('bg-black')) {
            bg_black[i].classList.remove('bg-black');
            bg_black[i].classList.add('bg-white');
          }
        }
        for (var i = 0; i < hr.length; i++) {
          if (hr[i].classList.contains('dark')) {
            hr[i].classList.remove('dark');
            hr[i].classList.add('light');
          }
        }
        for (var i = 0; i < mainContent_blur.length; i++) {
          if (mainContent_blur[i].classList.contains('blur')) {
            mainContent_blur[i].classList.remove('blur', 'shadow-blur');
          }
        }
        for (var i = 0; i < hr_card.length; i++) {
          if (hr_card[i].classList.contains('dark')) {
            hr_card[i].classList.remove('dark');
            hr_card[i].classList.add('light');
          }
        }
        for (var i = 0; i < text_btn.length; i++) {
          if (text_btn[i].classList.contains('text-dark')) {
            text_btn[i].classList.remove('text-dark');
            text_btn[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < text_span.length; i++) {
          if (text_span[i].classList.contains('text-dark')) {
            text_span[i].classList.remove('text-dark');
            text_span[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < text_strong.length; i++) {
          if (text_strong[i].classList.contains('text-dark')) {
            text_strong[i].classList.remove('text-dark');
            text_strong[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < text_nav_link.length; i++) {
          if (text_nav_link[i].classList.contains('text-dark')) {
            text_nav_link[i].classList.remove('text-dark');
            text_nav_link[i].classList.add('text-white');
          }
        }
        for (var i = 0; i < secondary.length; i++) {
          if (secondary[i].classList.contains('text-secondary')) {
            secondary[i].classList.remove('text-secondary');
            secondary[i].classList.add('text-white');
            secondary[i].classList.add('opacity-8');
          }
        }
        for (var i = 0; i < bg_gray_100.length; i++) {
          if (bg_gray_100[i].classList.contains('bg-gray-100')) {
            bg_gray_100[i].classList.remove('bg-gray-100');
            bg_gray_100[i].classList.add('bg-gray-600');
          }
        }
        for (var i = 0; i < btn_text_dark.length; i++) {
          btn_text_dark[i].classList.remove('text-dark');
          btn_text_dark[i].classList.add('text-white');
        }
        for (var i = 0; i < svg.length; i++) {
          if (svg[i].hasAttribute('fill')) {
            svg[i].setAttribute('fill', '#fff');
          }
        }
        for (var i = 0; i < card_border.length; i++) {
          card_border[i].classList.add('border-dark');
        }
      } else {
        body.classList.remove('dark-version');
        if (modal_content !== undefined && modal_content !== null) {
          modal_content.classList.remove('dark-version');
        }
        for (var i = 0; i < text_light.length; i++) {
          if (text_light[i].classList.contains('text-light')) {
            text_light[i].classList.remove('text-light');
            text_light[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < bg_white.length; i++) {
          if (bg_white[i].classList.contains('bg-white')) {
            bg_white[i].classList.remove('bg-white');
            bg_white[i].classList.add('bg-black');
          }
        }
        for (var i = 0; i < hr.length; i++) {
          // if (hr[i].classList.contains('light')) {
          //   hr[i].classList.add('dark');
          //   hr[i].classList.remove('light');
          // }
        }
        for (var i = 0; i < hr_card.length; i++) {
          // if (hr_card[i].classList.contains('light')) {
          //   hr_card[i].classList.add('dark');
          //   hr_card[i].classList.remove('light');
          // }
        }
        // for (var i = 0; i < mainContent_blur.length; i++) {
        //   mainContent_blur[i].classList.add('blur', 'shadow-blur');
        // }
        for (var i = 0; i < text_btn.length; i++) {
          if (text_btn[i].classList.contains('text-white')) {
            text_btn[i].classList.remove('text-white');
            text_btn[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < text_span_white.length; i++) {
          if (text_span_white[i].classList.contains('text-white') && !text_span_white[i].closest('.sidenav') && !text_span_white[i].closest('.card.bg-gradient-dark')) {
            text_span_white[i].classList.remove('text-white');
            text_span_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < text_strong_white.length; i++) {
          if (text_strong_white[i].classList.contains('text-white')) {
            text_strong_white[i].classList.remove('text-white');
            text_strong_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < text_nav_link_white.length; i++) {
          if (text_nav_link_white[i].classList.contains('text-white') && !text_nav_link_white[i].closest('.sidenav')) {
            text_nav_link_white[i].classList.remove('text-white');
            text_nav_link_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < secondary.length; i++) {
          if (secondary[i].classList.contains('text-white')) {
            secondary[i].classList.remove('text-white');
            secondary[i].classList.remove('opacity-8');
            secondary[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < bg_gray_600.length; i++) {
          if (bg_gray_600[i].classList.contains('bg-gray-600')) {
            bg_gray_600[i].classList.remove('bg-gray-600');
            bg_gray_600[i].classList.add('bg-gray-100');
          }
        }
        for (var i = 0; i < svg.length; i++) {
          if (svg[i].hasAttribute('fill')) {
            svg[i].setAttribute('fill', '#252f40');
          }
        }
        for (var i = 0; i < btn_text_white.length; i++) {
          if (!btn_text_white[i].closest('.card.bg-gradient-dark')) {
            btn_text_white[i].classList.remove('text-white');
            btn_text_white[i].classList.add('text-dark');
          }
        }
        for (var i = 0; i < card_border_dark.length; i++) {
          card_border_dark[i].classList.remove('border-dark');
        }
      }

      this.themeSetting.isDarkTheme = isDarkMode
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateNavbarFixed: function (isFixed) {
      let classes = ['position-sticky', 'blur', 'shadow-blur', 'mt-4', 'left-auto', 'top-1', 'z-index-sticky'];
      const navbar = document.getElementById('navbarBlur');

      if (isFixed) {
        navbar.classList.add(...classes);
        navbar.setAttribute('data-scroll', 'true');
        navbarBlurOnScroll('navbarBlur');
      } else {
        navbar.classList.remove(...classes);
        navbar.setAttribute('data-scroll', 'false');
        navbarBlurOnScroll('navbarBlur');
      }

      this.themeSetting.isNavbarFixed = isFixed
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    updateSidebarType: function (typeId) {
      let a = document.getElementById(typeId)
      var parent = a.parentElement.children;
      var color = a.getAttribute("data-class");
      var body = document.querySelector("body");
      var bodyWhite = document.querySelector("body:not(.dark-version)");
      var bodyDark = body.classList.contains('dark-version');

      var colors = [];

      for (var i = 0; i < parent.length; i++) {
        parent[i].classList.remove('active');
        colors.push(parent[i].getAttribute('data-class'));
      }

      if (!a.classList.contains('active')) {
        a.classList.add('active');
      } else {
        a.classList.remove('active');
      }

      var sidebar = document.querySelector('.sidenav');

      for (var i = 0; i < colors.length; i++) {
        sidebar.classList.remove(colors[i]);
      }

      sidebar.classList.add(color);


      // Remove text-white/text-dark classes
      if (color == 'bg-transparent' || color == 'bg-white') {
        var textWhites = document.querySelectorAll('.sidenav .text-white');
        for (let i = 0; i < textWhites.length; i++) {
          textWhites[i].classList.remove('text-white');
          textWhites[i].classList.add('text-dark');
        }
      } else {
        var textDarks = document.querySelectorAll('.sidenav .text-dark');
        for (let i = 0; i < textDarks.length; i++) {
          textDarks[i].classList.add('text-white');
          textDarks[i].classList.remove('text-dark');
        }
      }

      if (color == 'bg-transparent' && bodyDark) {
        var textDarks = document.querySelectorAll('.navbar-brand .text-dark');
        for (let i = 0; i < textDarks.length; i++) {
          textDarks[i].classList.add('text-light');
          textDarks[i].classList.remove('text-dark');
        }
      }

      // Remove logo-white/logo-dark

      if ((color == 'bg-transparent' || color == 'bg-white') && bodyWhite) {
        var navbarBrand = document.querySelector('.navbar-brand-img');
        var navbarBrandImg = navbarBrand.src;

        if (navbarBrandImg.includes('logo-ct.png')) {
          var navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
          navbarBrand.src = navbarBrandImgNew;
        }
      } else {
        var navbarBrand = document.querySelector('.navbar-brand-img');
        var navbarBrandImg = navbarBrand.src;
        if (navbarBrandImg.includes('logo-ct-dark.png')) {
          var navbarBrandImgNew = navbarBrandImg.replace("logo-ct-dark", "logo-ct");
          navbarBrand.src = navbarBrandImgNew;
        }
      }

      if (color == 'bg-white' && bodyDark) {
        var navbarBrand = document.querySelector('.navbar-brand-img');
        var navbarBrandImg = navbarBrand.src;

        if (navbarBrandImg.includes('logo-ct.png')) {
          var navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
          navbarBrand.src = navbarBrandImgNew;
        }
      }

      switch (typeId) {
        case 'sidebarTypeDark':
          this.themeSetting.sidebarType = 0
          break
        case 'sidebarTypeTransparent':
          this.themeSetting.sidebarType = 1
          break
        case 'sidebarTypeWhite':
          this.themeSetting.sidebarType = 2
          break
        default:
          break
      }
      localStorage.setItem(this.currentUser.userID, JSON.stringify(this.themeSetting))
    },
    sidebarTypeOnload: function () {
      switch (this.themeSetting.sidebarType) {
        case 0:
          document.getElementById('sidebarTypeDark').classList.add('active')
          this.updateSidebarType('sidebarTypeDark')
          break
        case 1:
          document.getElementById('sidebarTypeTransparent').classList.add('active')
          this.updateSidebarType('sidebarTypeTransparent')
          break
        case 2:
          document.getElementById('sidebarTypeWhite').classList.add('active')
          this.updateSidebarType('sidebarTypeWhite')
          break
        default:
          break
      }
    },
    popupNoification: function () {
      this.notificationPopupList.forEach((notification) => {
        $(`#notification_${notification['id']}`).modal('show')
      })
    },
    updateNotification: function (notification) {
      if (this.notificationPopupHide === true) {
        let localStorageKey = `noti_${this.currentUser['userID']}`
        let notiIdString = localStorage.getItem(localStorageKey)
        if ([null, undefined].includes(notiIdString)) {
          localStorage.setItem(localStorageKey, JSON.stringify([notification['id']]))
        } else {
          let notiIds = JSON.parse(notiIdString)
          notiIds.push(notification['id'])
          localStorage.setItem(localStorageKey, JSON.stringify(notiIds))
        }
      }
    },
    showNotificationModal: function (notification) {
      this.notificationHighLightObjectSelected = notification
      $('#notification-highlight-modal').modal('show')
    },
    onConfirmButton: function (inputObjectList) {
      let self = this
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      self.finishModal()
    },
    finishAction: function () {
      this.action = 'none'
      this.confirmModalDisplayObject.additionalButton.isShow = false
    },
  }
}
/* eslint-disable vue/no-v-html */
