export default {
    en: {
        breadcrumb: 'Notification',
        no_notification: 'Don\'t have any notification'
    },
    vi: {
        breadcrumb: 'Thông Báo',
        no_notification: 'Không có bất kỳ thông báo nào'
    },
    zh: {

    }
}
