let baseDomain = window.location.hostname
let bPath = 'https://' + baseDomain + '/api2/user/'
let pPath = 'https://' + baseDomain + '/api2/public/'
if (baseDomain.match(/127/)) { //127.0.0.1
    bPath = 'http://' + baseDomain + ':8080/api2/user/'
    pPath = 'http://' + baseDomain + ':8080/api2/public/'
} else if (baseDomain.match(/localhost/) || baseDomain.includes('192')) {
    bPath = 'http://localhost:8080/api2/user/'
    pPath = 'http://localhost:8080/api2/public/'
    // bPath = 'https://my.lowendviet.com/api2/user/'
    // pPath = 'https://my.lowendviet.com/api2/public/'
} else if (baseDomain.includes('betax')) {
    bPath = 'https://betaapi.levnode.com/api2/user/'
    pPath = 'https://betaapi.levnode.com/api2/public/'
}

export default {
    basePath: bPath,
    publicPath: pPath,
    install (Vue) {
        Vue.prototype.$config = {
            apiPath: {
                login: this.basePath + 'login',
                register: this.publicPath + '/levprotected/register',
                requestPWEmail: this.publicPath + '/levprotected/requestpw',
                resetPassword: this.publicPath + '/levprotected/resetpw',
                profile: this.basePath + 'profile',
                updateProfile: this.basePath + 'levprotected/profile/update',
                updatePassword: this.basePath + 'levprotected/profile/password',

                domainGetMeta: this.publicPath + 'domain/metadata',

                vpsList: this.basePath + 'service/vps/list',
                vpsStatus: this.basePath + 'service/vps/status',
                vpsStart: this.basePath + 'service/vps/start',
                vpsShutdown: this.basePath + 'service/vps/shutdown',
                vpsStop: this.basePath + 'service/vps/stop',
                vpsRestart: this.basePath + 'service/vps/restart',
                vpsRebuild: this.basePath + 'service/vps/rebuild',
                vpsSnapshot: this.basePath + 'service/levprotected/vps/snapshot',
                vpsRestore: this.basePath + 'service/vps/restore',
                vpsTransfer: this.basePath + 'service/vps/transfer',
                vpsRenew: this.basePath + 'service/vps/renew',
                vpsChange: this.basePath + 'service/vps/change',
                vpsAutoRenew: this.basePath + 'service/vps/autorenew',
                vpsCancel: this.basePath + 'service/levprotected/vps/cancel',
                vpsDetail: this.basePath + 'service/vps/detail',
                vpsAddon: this.basePath + 'service/vps/addon',
                vpsNote: this.basePath + 'service/vps/note',
                vpsConsole: this.basePath + 'service/vps/console',
                vpsChangeResourceLimit: this.basePath + 'service/vps/resource/change-optimization',
                vpsRevokeCancellation: this.basePath + 'service/levprotected/vps/revoke-cancellation',

                orderList: this.basePath + 'order/list',
                orderPlace: this.basePath + 'levprotected/order/place',
                orderCancel: this.basePath + 'order/cancel',
                orderFinish: this.basePath + 'order/finish',
                orderDetail: this.basePath + 'order/detail/',

                imageList: this.basePath + 'service/vps/os',
                snapList: this.basePath + 'service/vps/snapshot/list',
                snapDelete: this.basePath + 'service/vps/snapshot/delete',
                snapShotSaveNote: this.basePath + 'service/vps/snapshot/update-note',
                planList: this.publicPath + 'plan/list',
                notiList: this.basePath + 'notification/list',

                backupList: this.basePath + 'service/vps/backup/list',
                backupEnable: this.basePath + 'service/levprotected/vps/backup/add',
                backupDelete: this.basePath + 'service/vps/backup/delete',
                backupRecover: this.basePath + 'service/vps/backup/recover',

                paymentDetailList: this.basePath + 'payment_detail/list',
                logList: this.basePath + 'log/list',

                // Dedicated section
                dedicatedAddon: this.basePath + 'service/dedicated/addon',
                dedicatedList: this.basePath + 'service/dedicated/list',
                dedicatedStatus: this.basePath + 'service/dedicated/status',
                dedicatedStart: this.basePath + 'service/dedicated/start',
                dedicatedStop: this.basePath + 'service/dedicated/stop',
                dedicatedShutdown: this.basePath + 'service/dedicated/shutdown',
                dedicatedRestart: this.basePath + 'service/dedicated/restart',

                dedicatedTransfer: this.basePath + 'service/dedicated/transfer',

                dedicatedCancel: this.basePath + 'service/levprotected/dedicated/cancel',
                dedicatedRevokeCancel: this.basePath + 'service/levprotected/dedicated/revoke-cancellation',
                dedicatedRenew: this.basePath + 'service/dedicated/renew',
                dedicatedAutoRenew: this.basePath + 'service/dedicated/autorenew',

                dedicatedNote: this.basePath + 'service/dedicated/note',
                dedicatedDetail: this.basePath + 'service/dedicated/detail',

                proxyDetailList: this.basePath + 'service/vms/app/proxy/list-detail',
                proxyList: this.basePath + 'service/vms/app/proxy/list',
                proxyDetailAdd: this.basePath + 'service/levprotected/vms/app/proxy/add',
                updateProxyUsername: this.basePath + 'service/levprotected/vms/app/proxy/change/username',
                updateProxyPassword: this.basePath + 'service/levprotected/vms/app/proxy/change/password',
                updateProxyPort: this.basePath + 'service/levprotected/vms/app/proxy/change/port',
                checkProxyUsernameExisted: this.basePath + 'service/vms/app/proxy/check-username-existed',
                deleteProxy: this.basePath + 'service/vms/app/proxy/delete',
                updateRemark: this.basePath + 'service/vms/app/proxy/update-remark',

                rgcServerAvailable: this.basePath + 'rgc/server/list',
                rgcCreate: this.basePath + 'levprotected/rgc/session/create',
                rgcDelete: this.basePath + 'levprotected/rgc/session/delete',

                dashboardOverview: this.basePath + 'dashboard/overview',
                dashboardGuide: this.basePath + 'dashboard/links',

                vmPlanList: this.publicPath + 'service/plan/list/vm',
                dedicatedPlanList: this.publicPath + 'service/plan/list/dedicated',

                getServiceTypes: this.basePath + 'service/type/list',

                generate2FaToken: this.basePath + '2fa/otp-token',
                enable2FaToken: this.basePath + '2fa/enable',
                disable2FaToken: this.basePath + '2fa/disable',
                check2FaEnable: this.basePath + '2fa/is-enable',

                showApiKey: this.basePath + 'profile/api-key',
                updateApiKey: this.basePath + 'levprotected/profile/api-key/update',

                listMemberOfTeam: this.basePath + 'team/list',
                joinTeam: this.basePath + 'team/join',
                createNewTeam: this.basePath + 'team/create-new',
                removeTeamMember: this.basePath + 'team/remove',
                exitTeam: this.basePath + 'team/exit',
                deleteTeam: this.basePath + 'team/delete',
                openTeam: this.basePath + 'team/open',
                closeTeam: this.basePath + 'team/close',
                teamUpdatePrivateKey: this.basePath + 'levprotected/team/update-private-key',
                teamNotification: this.basePath + 'team/team-notification',

                shareTeam: this.basePath + 'service/vps/share-team',
                cancelShare: this.basePath + 'service/vps/share-cancel',
                shareTeamDedicated: this.basePath + 'service/dedicated/share-team',
                cancelShareDedicated: this.basePath + 'service/dedicated/share-cancel',

                ticketList: this.basePath + 'ticket/list',
                ticketAddNew: this.basePath + 'levprotected/ticket/add-new',
                ticketDetail: this.basePath + 'ticket/detail',
                ticketReply: this.basePath + 'levprotected/ticket/reply',
                ticketClose: this.basePath + 'ticket/close',
                ticketReopen: this.basePath + 'ticket/reopen',

                hostingList: this.basePath + 'hosting/list',
                hostingUpdateNote: this.basePath + 'hosting/update-remark',
                hostingRenew: this.basePath + 'hosting/renew',

                referralList: this.basePath + 'referral/list',
                referralWithdraw: this.basePath + 'referral/withdraw',
                promotionCheck: this.basePath + 'promotion/levprotected/check',
                hostingPlanList: this.publicPath + 'service/plan/list/hosting',
                hostingDisableAutoRenew: this.basePath + 'hosting/disable-auto-renew',
                hostingEnableAutoRenew: this.basePath + 'hosting/enable-auto-renew',

                exchangeRateHistory: this.basePath + 'exchange-rate-history'
            },
            basePath: 'https://' + baseDomain,
            rate: 23000,
            upgradeMode: false,
            domain: 'levnode.com'
        }
    }
}
