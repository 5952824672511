//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '@/mixins/MaterialTheme';
import md5 from 'md5'
import confirm2Fa from "@/components/children/Confirm2Fa";

export default {
  name: "Profile",
  components: {
    confirm2Fa
  },
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {},
    metaData: {}
  },
  data: function () {
    return {
      apiKey: '**************************',
      actionType: 'none'
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    otpConfirm: function (otp_code) {
      switch (this.actionType) {
        case 'updateProfile':
          this.updateProfile(otp_code)
          break
        case 'showApiKey':
          this.showApiKey(otp_code)
          break
        case 'updateApiKey':
          this.updateApiKey(otp_code)
        default:
          break
      }
      $('#otp_modal').modal('hide')
    },
    generateNewAPI: function () {
      this.apiKey = md5(Math.random())
    },
    updateProfile: function (otp_code) {
      let self = this

      this.submitObjectList(this.$config.apiPath.updateProfile,
          {
            full_name: this.currentUser.fullName,
            email: this.currentUser.email,
            phone_no: this.currentUser.phoneNo,
            facebook: this.currentUser.facebook,
            // api_key: this.currentUser.APIKey
          },
          'user', {
            otp_code: otp_code
          }, function (result) {
            if (result.data['is_enable_2fa']) {
              self.actionType = 'updateProfile'
              $('#otp_modal').modal('show')
            } else {
              self.$toasted.success(result.msg)
              self.$cookies.set('email', self.currentUser.email, '90D')
            }
          }, undefined, true, function () {
            $('#otp_modal').modal('hide')
          })
    },
    updateApiKey: function (otp_code) {
      let self = this
      this.submitObjectList(this.$config.apiPath.updateApiKey,
          {
            api_key: self.apiKey
          },
          'user', {
            otp_code: otp_code
          }, function (result) {
            if (result.data['is_enable_2fa']) {
              self.actionType = 'updateApiKey'
              $('#otp_modal').modal('show')
            } else {
              self.$toasted.success(result.msg)
            }
          }, undefined, true, function () {
            $('#otp_modal').modal('hide')
          })
    },
    showApiKey: function (otp_code) {
      let self = this
      this.submitObjectList(this.$config.apiPath.showApiKey, null,
          null, {
            otp_code: otp_code
          }, function (result) {
            if (result.data['is_enable_2fa']) {
              self.actionType = 'showApiKey'
              $('#otp_modal').modal('show')
            } else {
              self.apiKey = result.data.APIKey
            }
          }, undefined, true, function () {
            $('#otp_modal').modal('hide')
          })
    },
  }
}
