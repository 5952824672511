//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import dataObjectListMixin from "@/mixins/DataObjectList";
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import {FixBugTheme} from "@/mixins/MaterialTheme"

import LevButton from "@/components/children/lev_components/LevButton.vue";
import LevEditor from "@/components/children/lev_components/LevEditor.vue";
import LevModal from "@/components/children/lev_components/LevModal.vue";
import LevBadge from "@/components/children/lev_components/LevBadge.vue";

export default {
  name: "TicketDetail",
  components: {
    LevModal,
    LevButton,
    LevEditor,
    LevBadge
  },
  mixins: [dataObjectListMixin, dataRequester, inputHelper],
  data() {
    return {
      ticketDetail: {
        conversationList: []
      },

      editorContent: '',
      buttonSendLoading: false
    }
  },
  mounted() {
    FixBugTheme()
  },
  updated() {
    FixBugTheme()
  },
  created() {
    let self = this
    let ticketId = this.$route.params['ticketID']
    this.submitObjectList(this.$config.apiPath.ticketDetail,
        {id: ticketId},
        'ticketObj', undefined,(result) => {
          self.ticketDetail = result.data
          document.getElementById('breadcrumb').textContent = result.data.title
          document.title = result.data.title + ' - Cloud Service Manager';
    }, function () {
      self.$router.push({name: 'ticket'})
    })
  },
  methods: {
    sendConversation () {
      let self = this
      self.buttonSendLoading = true
      this.submitObjectList(this.$config.apiPath.ticketReply, {
        ticket_id: self.ticketDetail['id'],
        content: self.editorContent
      }, 'conversationObj', undefined, (result) => {
        self.ticketDetail.conversationList.unshift(result.data['conversationObj'])
        self.editorContent = ''
        self.$toasted.success(result.msg)
      }, undefined, true, () => {
        self.buttonSendLoading = false
      })
    },
    closeTicket () {
      $('#modal-closed').modal('show')
    },
    reopenTicket () {
      $('#modal-reopen').modal('show')
    },
    confirmCloseTicket () {
      this.submitObjectList(this.$config.apiPath.ticketClose, {
        id: this.ticketDetail['id']
      }, 'ticketObj', undefined, (result) => {
        $('#collapse-editor').collapse('hide')
        this.ticketDetail.status = result.data['ticketObj'].status
        this.$toasted.success(result.msg)
      })
    },
    confirmReopenTicket () {
      this.submitObjectList(this.$config.apiPath.ticketReopen, {
        id: this.ticketDetail['id']
      }, 'ticketObj', undefined, (result) => {
        $('#collapse-editor').collapse('show')
        this.ticketDetail.status = result.data['ticketObj'].status
        this.$toasted.success(result.msg)
      })
    }
  }
}
