
import inputHelper from './utilities/InputHelper'

export default {
  mixins: [inputHelper],
  data: function () {
    return {
      confirmModalDisplayObject: {
        title: '',
        bodyMsg: '',
        confirmButtonTitle: '',
        additionalButton: {
          isShow: false,
          title: '',
          class: ''
        }
      },
      action: 'none',
      active: false
    }
  },
  methods: {
    initConfirmModal: function (title, bodyMsg, confirmButtonTitle, additionalButton = undefined) {
      this.confirmModalDisplayObject.title = title
      this.confirmModalDisplayObject.bodyMsg = bodyMsg
      this.confirmModalDisplayObject.confirmButtonTitle = confirmButtonTitle
      this.confirmModalDisplayObject.additionalButton = additionalButton
    },
    initConfirmVMAction: function (selectedVM, action, cb = undefined) {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('vps.modal.titleConfirm', [this.$t('vps.action.' + action), '<b style="color: red;">' + selectedVM.length + '</b>']) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('vps.modal.bodyConfirm', [this.$t('vps.action.' + action)])
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'


      selectedVM.forEach(function (value) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
      })
      self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      cb()
      this.confirmModalDisplayObject.confirmButtonTitle = this.capitalizeFirstLetter(this.$t('vps.action.' + action))
    },
    initConfirmDedicatedAction: function (selectedVM, action, cb = undefined) {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicated.modal.titleConfirm', [this.$t('dedicated.action.' + action), '<b style="color: red;">' + selectedVM.length + '</b>']) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicated.modal.bodyConfirm', [this.$t('dedicated.action.' + action)])
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'


      selectedVM.forEach(function (value) {
        self.confirmModalDisplayObject.bodyMsg += '<li><strong>' + self.getIPPortFormat(value['ip'], value['port']) + '</strong></li>'
      })
      self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      cb()
      this.confirmModalDisplayObject.confirmButtonTitle = this.capitalizeFirstLetter(this.$t('dedicated.action.' + action))
    },
    showModal: function (isShown) {
      this.active = isShown
    },
    finishModal: function () {
      this.showModal(false)
      this.confirmModalDisplayObject.additionalButton.isShow = false
      this.action = 'none'
    },
  }
}
