//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VuetablePaginationMixin from "vuetable-2/src/components/VuetablePaginationMixin";
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  mixins: [VuetablePaginationMixin],
  mounted() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme
  }
};
