export default {
    vi: {
        breadcrumb: 'Danh Sách Proxy',
        basic: 'Cơ Bản',
        detail: 'Chi Tiết',
        table: {
            header: {
                ip: 'IP',
                total_proxy: 'Tổng số Proxy',
                price: 'Giá tiền',
                plan_name: 'Gói',
                region: 'Khu vực',
                start_date: 'Bắt đầu',
                end_date: 'Hết hạn',
                auto_renew: 'Tự động gia hạn',
                remark: 'Ghi chú',
                payment_status: 'Thanh toán',
                username: 'Tài khoản',
                password: 'Mật khẩu',
                type: 'Giao thức',
                transfer_limit: 'Lưu lượng giới hạn'
            },
            tooltip: {
                copyIp: 'Sao chép IP',
                copyPort: 'Sao chép Port',
                copyUsername: 'Sao chép tài khoản',
                copyPassword: 'Sao chép mật khẩu'
            },
            tdOK: 'OK',
            tdOverdue: 'Quá hạn',
            tdSuspend: 'Tạm khóa',
            tdCancel: 'Chờ hủy',
            tdUnknown: 'Không rõ',
        },
        button: {
            all: 'Tất cả ({0})',
            overdue: 'Sắp hết hạn ({0})',
            suspended: 'Đã tạm dừng ({0})',

            actionMenu: 'Thao tác nhiều Proxy',
            exportFile: 'Xuất tệp',
            addProxy: 'Thêm Proxy',
            editNote: 'Sửa ghi chú',
            transfer: 'Chuyển giao',
            change: 'Đổi mới',
            renew: 'Gia hạn',
            autorenew: 'Tự động gia hạn',
            restart: 'Khởi động lại',

            changeUsername: 'Thay đổi tài khoản',
            changePassword: 'Thay đổi mật khẩu',
            changePort: 'Thay đổi port',
            exportExcel: 'Xuất tệp Excel',
            exportText: 'Xuất tệp văn bản',
            deleteProxy: 'Xóa Proxy',
            cancel: 'Huỷ gia hạn',
            revokeCancellation: 'Thu hồi lệnh huỷ'
        },
        modal: {
            title: {
                editNote: '<strong>Sửa ghi chú cho <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                transferProxy: '<strong>Xác nhận chuyển giao <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                renewProxy: '<strong>Xác nhận gia hạn cho <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                changeProxy: '<strong>Xác nhận đổi mới cho <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                autoRenewProxy: '<strong>Xác nhận cài đặt tự động gia hạn cho <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                addProxy: '<strong>Xác nhận thêm mới proxy cho <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                restart: '<strong>Xác nhận khởi động lại <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                usernameExisted: '<strong>Tên đăng nhập đã tồn tại</strong>',
                changeUsername: '<strong>Thay đổi tên đăng nhập cho <span class="text-danger">{0}</span> proxy.</strong>',
                changePassword: '<strong>Thay đổi tên mật khẩu cho <span class="text-danger">{0}</span> proxy.</strong>',
                changePort: '<strong>Thay đổi port cho <span class="text-danger">{0}</span> proxy.</strong>',
                deleteProxy: '<strong>Xác nhận xóa <span class="text-danger">{0}</span> proxy.</strong>',
                exportTextFile: '<strong>Xuất tệp văn bản</strong>',
                exportExcelFile: '<strong>Xuất tệp Excel</strong>',
                warningChangeUsername: 'Cảnh báo thay đổi tên đăng nhập',
                warningChangePassword: 'Cảnh báo thay đổi mật khẩu',
                cancel: '<strong>Huỷ gia hạn cho <span class="text-danger">{0}</span> địa chỉ IP</strong>',
                revokeCancellation: '<strong>Thu hồi lệnh huỷ cho <span class="text-danger">{0}</span> địa chỉ IP</strong>',
            },
            body: {
                label: {
                  selectProxyType: 'Chọn loại Proxy',
                  proxyPort: 'Nhập vào Proxy Port',
                  proxyUsername: 'Nhập vào Proxy Username',
                  proxyPassword: 'Nhập vào Proxy Password'
                },
                description: {
                    editNote: 'Bạn có thể ghi chú cho một vài địa chỉ IP chung mục đích để dễ dàng phân biệt.',
                    inputNoteField: 'Ghi chú của bạn cho địa chỉ IP',
                    transferProxy: 'Bạn cần điền email của user mà bạn muốn chuyển giao những địa chỉ IP dưới đây.<br/>' +
                        'Vì lý do bảo mật, nếu bạn nhập sai email quá 5 lần, chức năng này sẽ bị tạm khóa.',
                    periodRenew: 'Chu kì gia hạn',
                    confirmChange: 'Hệ thống sẽ tạo Hóa đơn đổi mới cho những địa chỉ IP dưới đây.',
                    confirmRenew: 'Hệ thống sẽ tạo Hóa đơn gia hạn cho những địa chỉ IP dưới đây.',
                    confirmAutoRenew: 'Khi cài đặt tự động gia hạn, hệ thống sẽ tự động tạo ra hoá đơn gia hạn 3 ngày trước khi VPS đến hạn. ' +
                        'Nếu số dư tài khoản của bạn đủ để gia hạn, hệ thống sẽ tự đông trừ tài khoản của bạn.',
                    confirmRestart: 'Bạn có thực sự muốn khởi động lại những địa chỉ IP dưới đây?',
                    usernameDuplicated: 'Tên đăng nhập <strong class="text-danger">{0}</strong> đang được sử dụng bởi {1} proxy.<br/>',
                    usernameDuplicatedWarning: 'Khi thay đổi, tất cả các proxy sử dụng chung tên đăng nhập cũng sẽ được thay đổi. Hãy xác nhận nếu bạn muốn tiếp tục.',
                    changeUsername: 'Việc thay đổi tên đăng nhập sẽ được áp dụng cho danh sách proxy phía dưới.',
                    changePassword: 'Việc thay đổi mật khẩu sẽ được áp dụng cho danh sách proxy phía dưới.',
                    changePort: 'Việc thay đổi port sẽ được áp dụng cho danh sách proxy phía dưới.',
                    deleteProxy: 'Bạn có chắc chắn muốn xóa tất cả proxy dưới đây?',
                    exportTextFile: 'Bạn có thể xuất tệp văn bản để lưu trữ hoặc chuyển giao cho người khác.',
                    exportExcelFile: 'Bạn có thể xuất tệp Excel để lưu trữ hoặc chuyển giao cho người khác.',
                    confirmCancel: 'Bạn có chắc chắn muốn huỷ gia hạn cho những địa chỉ IP dưới đây?',
                    confirmRevokeCancellation: 'Bạn có chắc chắn muốn thu hồi lệnh huỷ gia hạn cho những địa chỉ IP dưới đây?',

                },
                warning: {
                    transferProxy: '<h5 class="text-danger">Lưu ý:</h5></br><ol>' +
                        '<li>Sau khi chuyển giao proxy, bạn sẽ không còn quyền quản lý các proxy này nữa.</li>' +
                        '<li>Các hóa đơn quá hạn sẽ thuộc trách nhiệm thanh toán của bạn. Người nhận sẽ thanh toán các hóa đơn kì sau.</li>' +
                        '</ol>',
                    renewProxy: 'Bạn sẽ được chuyển đến trang hóa đơn để kiểm tra và thanh toán hóa đơn.</br>',
                    changeProxy: 'Bạn sẽ được chuyển đến trang hóa đơn để kiểm tra và thanh toán hóa đơn.</br>',
                    autoRenew: 'Bạn có thể tắt cài đặt tự động gia hạn bất kì lúc nào bằng nút dưới đây.',
                    usernameExistedWarning: 'Tài khoản <strong class="text-danger">{0}</strong> đã tồn tại. Các địa chỉ IP tương ứng với tài khoản này sẽ được cập nhật mật khẩu như bên dưới.<br/>',
                    usernameExisted: 'IP: <strong class="text-primary">{ip}</strong>, Tài khoản: <strong class="text-danger">{proxy_username}</strong> - ' +
                        'mật khẩu: <strong class="text-danger">{proxy_password}</strong><br/>',
                    bodyCancelWarning: '<h5 class="text-danger">Cảnh báo:</h5></br><ol>' +
                        'Có 2 cách để quý khách hủy Proxy : Hủy khi hết hạn (End of billing) và hủy ngay lập tức (Immediate).' +
                        '<ul>' +
                        '<li>Khi hết hạn: Proxy của quý khách sẽ được hủy khi hết hạn, hệ thống sẽ không nhắn tin hay gửi email cho quý khách.</li>' +
                        '<li>Ngay lập tức: Proxy của quý khách sẽ được hủy ngay lập tức. Quý khách sẽ không được trả lại số ngày chưa sử dụng.</li>' +
                        '</ul>' +
                        'Nếu quý khách hủy nhầm Proxy hoặc muốn sử dụng lại Proxy đã hủy, xin hãy liện hệ hỗ trợ sớm nhất có thể.',
                }
            },
            button: {
                addProxy: 'Thêm mới Proxy',
                cancelAutoRenew: 'Tắt tự động gia hạn',
                editNote: 'Sửa ghi chú',
                transfer: 'Chuyển giao',
                renew: 'Gia hạn',
                change: 'Đổi mới',
                autoRenew: 'Bật tư động gia hạn',
                restart: 'Khởi động lại',
                cancel: 'Hủy',
                revokeCancellation: 'Thu hồi lệnh hủy'
            }
        },
        tooltip: {
            copy_note: 'Sao chép ghi chú',
            edit_note: 'Sửa ghi chú',
            save_note: 'Lưu lại thay đổi ghi chú',
            cancel_note: 'Huỷ bỏ thay đổi ghi chú',
        },
        toast: {
            errNoSelect: 'Vui lòng lựa chọn ít nhất 1 địa chỉ IP!',
            requestRestartingSuccess: 'Gửi yêu cầu khởi động lại thành công cho {0} IP!',
            duplicatedVms: 'IP {0} được chọn {1} lần. Một địa chỉ IP không thể có nhiều Port giống nhau. Vui lòng kiểm tra lại!',
            invalidPort: 'Port {0} không hợp lệ. Port nên ở trong khoảng 1000 đến 65535!'
        }
    },
    en: {
        breadcrumb: 'Proxy list',
        basic: 'Basic',
        detail: 'Detail',
        table: {
            header: {
                ip: 'IP',
                total_proxy: 'Total proxies',
                price: 'Price',
                plan_name: 'Plan',
                region: 'Region',
                start_date: 'Start',
                end_date: 'End',
                auto_renew: 'Auto renew',
                remark: 'Note',
                payment_status: 'Payment',
                username: 'Username',
                password: 'Password',
                type: 'Protocol',
                transfer_limit: 'Transfer limit'
            },
            tooltip: {
                copyIp: 'Copy IP',
                copyPort: 'Copy port',
                copyUsername: 'Copy username',
                copyPassword: 'Copy password'
            },
            tdOK: 'OK',
            tdOverdue: 'Overdue',
            tdSuspend: 'Suspended',
            tdCancel: 'Cancelled',
            tdUnknown: 'Unknown',
        },
        button: {
            all: 'Total ({0})',
            overdue: 'Overdue ({0})',
            suspended: 'Suspended ({0})',

            actionMenu: 'Batch action',
            exportFile: 'Export file',
            addProxy: 'Add new proxy',
            editNote: 'Edit note',
            transfer: 'Transfer',
            change: 'Đổi mới',
            renew: 'Renew',
            autorenew: 'Turn auto renew on/off',
            restart: 'Restart',

            changeUsername: 'Change username',
            changePassword: 'Change password',
            changePort: 'Change port',
            exportExcel: 'Export Excel file',
            exportText: 'Export text file',
            deleteProxy: 'Delete proxy',
            cancel: 'Cancel renew',
            revokeCancellation: 'Revoke cancellation'
        },
        modal: {
            title: {
                editNote: '<strong>Edit note for <span class="text-danger">{0}</span> IP</strong>',
                transferProxy: '<strong>Confirm transferring <span class="text-danger">{0}</span> IP</strong>',
                changeProxy: '<strong>Confirm changing <span class="text-danger">{0}</span> IP</strong>',
                renewProxy: '<strong>Confirm renewing for <span class="text-danger">{0}</span> IP</strong>',
                autoRenewProxy: '<strong>Turn auto-renew on/off for <span class="text-danger">{0}</span> IP</strong>',
                addProxy: '<strong>Confirm adding new proxy for <span class="text-danger">{0}</span> IP</strong>',
                restart: '<strong>Confirm restarting for <span class="text-danger">{0}</span> IP</strong>',
                usernameExisted: '<strong>Username is already existed</strong>',
                changeUsername: '<strong>Changing username for <span class="text-danger">{0}</span> proxy.</strong>',
                changePassword: '<strong>Changing password for <span class="text-danger">{0}</span> proxy.</strong>',
                changePort: '<strong>Changing port for <span class="text-danger">{0}</span> proxy.</strong>',
                deleteProxy: '<strong>Confirm deleting <span class="text-danger">{0}</span> proxy.</strong>',
                exportTextFile: '<strong>Export text file</strong>',
                exportExcelFile: '<strong>Export Excel file</strong>',
                warningChangeUsername: 'Changing username warning',
                warningChangePassword: 'Changing password warning',
                cancel: '<strong>Cancel renew for <span class="text-danger">{0}</span> IP</strong>',
                revokeCancellation: '<strong>Revoke cancellation for <span class="text-danger">{0}</span> IP</strong>',
            },
            body: {
                label: {
                    selectProxyType: 'Select proxy type',
                    proxyPort: 'Enter proxy port',
                    proxyUsername: 'Enter proxy username',
                    proxyPassword: 'Enter proxy password'
                },
                description: {
                    editNote: 'You can make a note for each IP for easy tracking',
                    inputNoteField: 'Enter note',
                    transferProxy: 'Enter the email of the receiver.<br/>' +
                        'For security reason, this feature will be locked if you enter the wrong email address 5 times.',
                    periodRenew: 'Renew period',
                    confirmChange: 'A new invoice will be created for the following IPs',
                    confirmRenew: 'A new invoice will be created for the following IPs',
                    confirmAutoRenew: 'When auto-renew feature is turned on, a new invoice will be created automatically 3 days before the due date.' +
                        'If you have enough credit balance, it will be automatically applied to the invoice.',
                    confirmRestart: 'Do you really want to restart the proxies with the following IPs?',
                    usernameDuplicated: 'The username <strong class="text-danger">{0}</strong> is currently being used by {1} proxy.<br/>',
                    usernameDuplicatedWarning: 'When an username is changed, it will apply for all the proxy with the same username. Are you sure you want to change it?',
                    changeUsername: 'The username of the following proxies will be changed.',
                    changePassword: 'The password of the following proxies will be changed.',
                    changePort: 'The port of the following proxies will be changed',
                    deleteProxy: 'Are you sure to delete the following proxies?',
                    exportTextFile: 'Exporting txt file (.txt) ',
                    exportExcelFile: 'Exporting Excel file (.xlsx)',
                    confirmCancel: 'Are you sure to cancel the renew for the following IPs?',
                    confirmRevokeCancellation: 'Are you sure to revoke the cancellation for the following IPs?',

                },
                warning: {
                    transferProxy: '<h5 class="text-danger">Warning:</h5></br><ol>' +
                        '<li>You will lose permission to manage the transferred proxies.</li>' +
                        '<li>The overdue invoices (if any) are your responsibility. The receiver will pay for the next billing cycle.</li>' +
                        '</ol>',
                    changeProxy: 'You will be directed to the invoice detail page to review and pay.</br>',
                    renewProxy: 'You will be directed to the invoice detail page to review and pay.</br>',
                    autoRenew: 'You can turn off auto-renew feature any time by click on the below button',
                    usernameExistedWarning: 'The username <strong class="text-danger">{0}</strong> is already exist. The following proxy with the same username will be also updated.<br/>',
                    usernameExisted: 'IP: <strong class="text-primary">{ip}</strong>, Username: <strong class="text-danger">{proxy_username}</strong> - ' +
                        'password: <strong class="text-danger">{proxy_password}</strong><br/>',
                    bodyCancelWarning: '<h5 class="text-danger">Warning:</h5></br><ol>' +
                        'There are 2 modes of cancelling: Immediate and End of billing.' +
                        '<ul>' +
                        '<li>End of billing: Your Proxy will be cancelled at the end of billing period. No notification/email will be sent for it anymore.</li>' +
                        '<li>Immediate: Your Proxy will be deleted right after you cancelled. No prorate refund will be issued even if your Proxy still have unused time.</li>' +
                        '</ul>' +
                        'Please contact us immediately if you cancel the Proxy by mistake.',
                }
            },
            button: {
                addProxy: 'Add new proxy',
                cancelAutoRenew: 'Turn off auto-renew',
                editNote: 'Edit note',
                transfer: 'Transfer',
                change: 'Change',
                renew: 'Renew',
                autoRenew: 'Turn auto-renew on/off',
                restart: 'Restart',
                cancel: 'Cancel renew',
                revokeCancellation: 'Revoke cancellation'
            }
        },
        tooltip: {
            copy_note: 'Copy note',
            edit_note: 'Edit note',
            save_note: 'Save changes',
            cancel_note: 'Abort changes',
        },
        toast: {
            errNoSelect: 'You have to select at least 1 IP',
            requestRestartingSuccess: 'Successfully requested to restart {0} IP!',
            duplicatedVms: 'IP {0} is selected {1} times. A single port cannot be set for multiple proxies, check again.',
            invalidPort: 'Invalid port {0}. Port should be in the range 1000-65535.',
        }
    }
}
