//
//
//
//

export default {
  name: "LevBadge",
  props: {
    text: {
      type: String,
      default: "Badge",
    },
    variant: {
      type: String,
      default: "primary",
    },
  }
}
