export default {
    en: {
        breadcrumb: 'Two-Factor Authentication',
        label: {
            two_fa_title: 'Two-Factor Authentication',
            two_fa_enable: 'Two-Factor Authentication is enabled',
            setup_2fa: 'Set up Authenticator',
            otp_code: 'OTP Code',
            password: 'Password'
        },
        button: {
            enable: 'Enable Two-Factor Authentication',
            disable: 'Disable Two-Factor Authentication'
        },
        message: {
            your_account_safe: 'Your account is protected!',
            setup_2fa: 'Use the authentication app (such as <strong>Authy</strong> or <strong>Google Authenticator</strong>) to scan this QR code, ' +
                'then enter <code>OTP Code</code> below.'
        }
    },
    vi: {
        breadcrumb: 'Bảo Mật 2 Lớp',
        label: {
            two_fa_title: 'Bảo Mật 2 Lớp',
            two_fa_enable: 'Bảo mật 2 lớp đã được bật',
            setup_2fa: 'Cài đặt phần mềm Authenticator',
            otp_code: 'Mã OTP',
            password: 'Mật Khẩu'
        },
        button: {
            enable: 'Bật bảo mật 2 lớp',
            disable: 'Tắt bảo mật 2 lớp'
        },
        message: {
            your_account_safe: 'Tài khoản của bạn đã được bảo vệ!',
            setup_2fa: 'Quý khách hãy sử dụng phần mềm hỗ trợ xác thực tài khoản (ví dụ <strong>Authy</strong> hoặc <strong>Google Authentication</strong>)' +
                'để quét hình ảnh mã QR bên dưới, sau đó nhập mã OTP hiện trên phần mềm và nhập vào mục <code>Mã OTP</code> phía dưới.'
        }
    },
    zh: {

    }
}
