export default {
    vi: {
        breadcrumb: 'Danh sách Hosting',
        order: {
            domain: 'Tên miền',
            username: 'Tên đăng nhập',
            password: 'Mật khẩu',
            email: 'Email',
            hostingInfo: 'Thông tin hosting',
            hostingPlanName: 'Gói hosting',
            ram: 'Dung lượng RAM',
            disk: 'Dung lượng lưu trữ',
            numberOfWebsites: 'Số lượng website',
            numberOfemails: 'Số lượng email',
            prices: 'Giá tiền',
            description: 'Mô tả',
        },
        table: {
            header: {
                domain: 'Tên miền',
                username: 'Tên đăng nhập',
                password: 'Mật khẩu',
                email: 'Email',
                planName: 'Gói hosting',
                startDate: 'Bắt đầu',
                endDate: 'Hết hạn',
                autoRenew: 'Tự động gia hạn',
                paymentStatus: 'Thanh toán',
                note: 'Ghi chú',
                price: 'Giá tiền',
                location: 'Vị trí'
            },
            label: {
                paymentOverdue: 'Quá hạn',
                paymentOk: 'OK',
                paymentSuspended: 'Tạm ngưng',
                paymentCancelled: 'Hủy',
                paymentUnknown: 'Không xác định',
            }
        },
        tooltip: {
            saveNote: 'Lưu ghi chú',
            editNote: 'Sửa ghi chú',
            copyNote: 'Sao chép ghi chú',
            cancelNote: 'Hủy ghi chú',
            copyUsername: 'Sao chép tên đăng nhập',
            copyPassword: 'Sao chép mật khẩu',
            showPassword: 'Hiện mật khẩu',
            hidePassword: 'Ẩn mật khẩu'
        },
        modal: {
            title: {
                editNote: '<h5>Sửa ghi chú cho <span class="text-danger">{0}</span> hosting</h5>',
                invoiceRenew: '<h5>Tạo hoá đơn gia hạn <span class="text-danger">{0}</span> hosting</h5>',
                enableAutoRenew: '<h5>Bật tự động gia hạn cho <span class="text-danger">{0}</span> hosting</h5>',
                disableAutoRenew: '<h5>Tắt tự động gia hạn cho <span class="text-danger">{0}</span> hosting</h5>'
            },
            button: {
                confirmEditNote: 'Lưu ghi chú',
                confirmInvoiceRenew: 'Tạo hoá đơn gia hạn',
                confirmEnableAutoRenew: 'Bật tự động gia hạn',
                confirmDisableAutoRenew: 'Tắt tự động gia hạn'
            },
            label: {
                editRemark: 'Sửa ghi chú cho các domain dưới đây:',
                yourNote: 'Ghi chú của bạn',
                createInvoiceRenew: 'Tạo hoá đơn gia hạn cho các domain dưới đây:',
                enableAutoRenew: 'Bật tự động gia hạn cho các domain dưới đây:',
                disableAutoRenew: 'Tắt tự động gia hạn cho các domain dưới đây:'
            }
        },
        button: {
            multipleHostingAction: 'Thao tác nhiều hosting',
            editNote: 'Sửa ghi chú',
            invoiceRenew: 'Tạo hoá đơn gia hạn',
            enableAutoRenew: 'Bật tự động gia hạn',
            disableAutoRenew: 'Tắt tự động gia hạn',
        }
    },
    en: {
        breadcrumb: 'Hosting list',
        order: {
            domain: 'Domain',
            username: 'Username',
            password: 'Password',
            email: 'Email',
            hostingInfo: 'Hosting info',
            hostingPlanName: 'Hosting plan',
            ram: 'RAM',
            disk: 'Disk space',
            numberOfWebsites: 'Number of websites',
            numberOfemails: 'Number of emails',
            prices: 'Prices',
            description: 'Description',
        },
        table: {
            header: {
                domain: 'Domain',
                username: 'Username',
                password: 'Password',
                email: 'Email',
                planName: 'Hosting plan',
                startDate: 'Start date',
                endDate: 'End date',
                autoRenew: 'Auto renew',
                paymentStatus: 'Payment status',
                note: 'Note',
                price: 'Price',
                location: 'Location'
            },
            label: {
                paymentOverdue: 'Overdue',
                paymentOk: 'OK',
                paymentSuspended: 'Suspended',
                paymentCancelled: 'Cancelled',
                paymentUnknown: 'Unknown',
            }
        },
        tooltip: {
            saveNote: 'Save note',
            editNote: 'Edit note',
            copyNote: 'Copy note',
            cancelNote: 'Cancel note',
            copyUsername: 'Copy username',
            copyPassword: 'Copy password',
            showPassword: 'Show password',
            hidePassword: 'Hide password'
        },
        modal: {
            title: {
                editNote: '<h5>Edit note for <span class="text-danger">{0}</span> hosting</h5>',
                invoiceRenew: '<h5>Create invoice renew for <span class="text-danger">{0}</span> hosting</h5>',
                enableAutoRenew: '<h5>Enable auto renew for <span class="text-danger">{0}</span> hosting</h5>',
                disableAutoRenew: '<h5>Disable auto renew for <span class="text-danger">{0}</span> hosting</h5>'
            },
            button: {
                confirmEditNote: 'Save note',
                confirmInvoiceRenew: 'Create invoice renew',
                confirmEnableAutoRenew: 'Enable auto renew',
                confirmDisableAutoRenew: 'Disable auto renew'
            },
            label: {
                editRemark: 'Edit note for these domains:',
                yourNote: 'Your note',
                createInvoiceRenew: 'Create invoice renew for these domains:',
                enableAutoRenew: 'Enable auto renew for these domains:',
                disableAutoRenew: 'Disable auto renew for these domains:'
            }
        },
        button: {
            multipleHostingAction: 'Multiple hosting action',
            editNote: 'Edit note',
            invoiceRenew: 'Create invoice renew',
            enableAutoRenew: 'Enable auto renew',
            disableAutoRenew: 'Disable auto renew',
        }
    }
}
