//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LevDropdownMenu from "@/components/children/lev_components/LevDropdownMenu.vue";
import moment from "moment/moment";
import inputHelper from "@/mixins/utilities/InputHelper";

export default {
  name: "DedicatedPlans",
  components: {LevDropdownMenu},
  mixins: [inputHelper],
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    isPromotionFilter: {
      type: Boolean,
      default: false
    },
  },
  data () {
    let self = this
    return {
      columns: [
        {
          key: 'name',
          label: self.$t('orderNew.table.h_name')
        },
        {
          label: self.$t('orderNew.table.h_cpu')
        },
        {
          label: self.$t('orderNew.table.h_ram')
        },
        {
          label: self.$t('orderNew.table.h_storage')
        },
        {
          label: self.$t('orderNew.table.h_network')
        },
        {
          label: self.$t('orderNew.table.h_price')
        },
        {
          key: 'setup_fee',
          label: self.$t('orderNew.table.h_setupFee')
        },
        {
          label: self.$t('orderNew.table.h_stock'),
          isHide: !self.isPromotionFilter
        },
        {
          key: 'setup_free',
          label: self.$t('orderNew.table.h_setupFree'),
          isHide: self.isPromotionFilter
        },
        {
          label: self.$t('orderNew.table.h_note')
        }
      ]
    }
  },
  computed: {
    freeSetupLabel: function () {
      let self = this
      return (freeSetup => {
        if (freeSetup === null) {
          return ``
        } else {
          let freeSetupString = ''
          for(let i = 0; i < freeSetup.length; i++) {
            let datePoint = 5 //5th of the month
            let item = freeSetup[i]
            let dateToday = moment().date()
            let dateList = item['dates']
                .map(x => self.convertISODate(`${moment().year()}-${item['month']}-${x}`))
                .sort()

            for (let j = 0; j < dateList.length; j++) {
              let date = dateList[j]
              if (!moment(date).isBefore(moment().subtract(1, 'day'))) {
                if (moment().date() < datePoint && moment(date).date() !== moment(date).endOf('month').date()) {
                  freeSetupString += `<strong class="text-success">${date}</strong><br/>`
                } else if (moment().date() >= datePoint) {
                  if (moment(date).isBefore(moment(dateList.pop())) && i === 1) {
                    freeSetupString += `<strong class="text-success">${date}</strong><br/>`
                  } else {
                    freeSetupString += `<strong class="text-success">${date}</strong><br/>`
                  }
                }
              }
            }

            if (dateToday < datePoint) {
              break
            }
          }

          if (freeSetupString === '') {
            return ``
          } else {
            return `${self.$t('orderNew.label.setupFree')}<br/> ${freeSetupString}`
          }
        }
      })
    }
  }
}
