//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'

export default {
  name: 'Topup',
  mixins: [dataRequester, inputHelper],
  components: {
  },
  props: {
    currentUser: {},
    metaData: {},
  },
  watch: {
    metaData: {
      handler: function () {
        this.defaultCurrency = this.metaData.defaultCurrency
        this.exchangeRate = this.metaData.exchangeRate
      },
      deep: true
    },
  },
  data: function () {
    return {
      topupAmount: 100,
      exchangeRate: 0,
      defaultCurrency: undefined,

      promotion: undefined,
      promotionMsg: '',
      promotionCode: ''
    }
  },
  computed: {
    promotionValue: function () {
      if (this.promotion !== undefined) {
        return this.topupAmount / 100 * this.promotion.promotion_rate
      } else {
        return 0
      }
    }
  },
  created() {
    this.FixBugTheme()
    this.defaultCurrency = this.metaData.defaultCurrency
    this.exchangeRate = this.metaData.exchangeRate
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    reset: function () {
      this.topupAmount = 100
      this.promotion = undefined
      this.promotionMsg = ''
      this.promotionCode = ''
    },
    finish: function () {
      let self = this
      self.submitObjectList(self.$config.apiPath.orderPlace, {
        type: 'topup',
        total_amount: self.topupAmount,
        promotion_code: self.promotion ? self.promotion.promotion_code : undefined
      }, 'order', undefined, function (result) {
        function openOrderTab (url) {
          window.open(url, '_blank')
        }
        self.$toasted.success(result.msg)
        setTimeout(openOrderTab('/#/home/order/detail/' + result.data.id), 1500)
      })
    },
    quickAmountClick: function (value) {
      this.topupAmount = value
    },

    promotionCheck: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.promotionCheck, {
        promotion_code: self.promotionCode
      }, 'promotion', undefined, function (result) {
        self.promotion = result.data.promotion
        if (result.status === 'success') {
          self.promotionMsg = self.$t('topup.label.promotionCodeValid')
        } else {
          self.promotionMsg = self.$t('topup.label.promotionCodeInvalid')
        }
      })
    }
  },
  mounted() {
    this.FixBugTheme()
  }
}
