export default {
  en: {
    cardHeader: {
      title: 'Register new account at'
    },
    label: {
      fullName: 'Name on your social account',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      email: 'Email address',
      phoneNo: 'Phone number',
      phoneNoDescription: 'You can also use phone number to login',
      facebook: 'Your Facebook/Twitter URL / QQ Number',
      emailDescription: 'You will use this email to login and receive notification email when your VPS is nearly expired.',
      facebookURLDescription: 'Go to your Facebook profile, copy&paste your URL here to receive renew VPS notifications.',
      fullnameDescription: 'Please enter exactly your name display on your Facebook account for faster support.'
    },
    placeholder: {
      fullName: 'Name on facebook, twitter...',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      phoneNo: '+18882721234',
      email: 'Email address',
      facebook: 'Your Social account URL(Twitter/Facebook) or QQ Number',
      facebookURLDescription: 'Go to your Social profile, copy&paste your URL here to receive renew VPS notifications.'
    },
    button: {
      register: 'Register',
      login: 'Login'
    },
    toast: {
      differentPassword: 'The password are not the same!',
      invalidEmail: 'Invalid email, please recheck it!'
    }
  },
  vi: {
    cardHeader: {
      title: 'Đăng ký một tài khoản mới tại '
    },
    label: {
      fullName: 'Tên trên tài khoản mạng xã hội',
      username: 'Tên đăng nhập',
      password: 'Mật khẩu',
      passwordConfirm: 'Xác nhận mật khẩu',
      phoneNo: 'Số điện thoại',
      phoneNoDescription: 'Bạn cũng có thể sử dụng số điện thoại để đăng nhập',
      email: 'Địa chỉ email',
      facebook: 'Facebook URL',
      emailDescription: 'Bạn sẽ sử dụng email này để đăng nhập và để nhận thông báo khi VPS gần hết hạn.',
      facebookURLDescription: 'Bật trang Facebook cá nhân của ban, copy và dán link URL tại đây để nhận thông báo gia hạn VPS khi đến hạn.',
      fullnameDescription: 'Xin hãy điền chính xác tên trên profile Facebook của bạn để được hỗ trợ nhanh nhất.'
    },
    placeholder: {
      fullName: 'Tên trên tài khoản mạng xã hội',
      username: 'Tên đăng nhập',
      password: 'Mật khẩu',
      passwordConfirm: 'Xác nhận mật khẩu',
      phoneNo: '0912345678',
      email: 'Địa chỉ email',
      facebook: 'Facebook URL',
      facebookURLDescription: 'Truy cập trang Facebook cá nhân của ban, copy và dán link URL tại đây để nhận thông báo gia hạn VPS khi đến hạn.'
    },
    button: {
      register: 'Đăng kí',
      login: 'Trở lại trang đăng nhập'
    },
    toast: {
      differentPassword: 'Mật khẩu không trùng khớp, vui lòng kiểm tra lại!',
      invalidEmail: 'Email không tồn tại, vui lòng kiểm tra lại!'
    }
  },
  zh: {
    cardHeader: {
      title: '请在这里注册新的账户 '
    },
    label: {
      fullName: '社交账号名字',
      username: '登录名称',
      password: '密码',
      passwordConfirm: '确认密码',
      phoneNo: '电话号码',
      phoneNoDescription: '您也可以使用电话号码来到登录',
      email: '邮箱地址',
      facebook: '联系QQ',
      emailDescription: '您可以用此邮箱来登录账号以及接受续费提醒通知，您的VPS快到期我们会通过发邮箱来提醒您，请您注意查收.',
      facebookURLDescription: '联系QQ:请正确填入您正常使用的QQ号来保证我们能联系的上您，谢谢合作。',
      fullnameDescription: '请您填准确您的资料信息如有紧急维护我们能第一时间能联系到您.'
    },
    placeholder: {
      fullName: '请填入您就社交账号名称',
      username: '登陆名字',
      password: '密码',
      passwordConfirm: '确认密码',
      phoneNo: '+86123456789',
      email: '邮箱地址',
      facebook: 'QQ号',
      facebookURLDescription: '联系QQ:请正确填入您正常使用的QQ号来保证我们能联系的上您，谢谢合作.'
    },
    button: {
      register: '注册',
      login: '返回登陆页面'
    },
    toast: {
      differentPassword: '密码错误请重新输入!',
      invalidEmail: '此邮箱不存在请重新输入!'
    }
  }
}
