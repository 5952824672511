export default {
    en: {
        header: {
            show: 'Show ',
            itemEachPage: ' items each page',
            search: 'Search',
            displaySetting: 'Display setting'
        },
        pagination: {
            show: 'Showing {0} to {1} of total {2} entries'
        }
    },
    vi: {
        header: {
            show: 'Hiện ',
            itemEachPage: ' mục mỗi trang',
            search: ' Tìm kiếm',
            displaySetting: 'Cài đặt hiển thị'
        },
        pagination: {
            show: 'Hiển thị từ {0} tới {1} trên tổng số {2} mục'
        }
    },
    zh: {

    }
}
