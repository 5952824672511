//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @todo: This screen and VPS screen share a lot of function/code. Consider to write children component
import async from 'async'

import confirmModal from '@/components/children/ConfirmModal'

import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'

import RDPGenerator from '@/mixins/utilities/RDPGenerator'
import confirm2Fa from "@/components/children/Confirm2Fa";
import {FixBugTheme} from "@/mixins/MaterialTheme";
import NewConfirmModal from "@/components/children/NewConfirmModal.vue";

export default {
  name: 'DedicatedDetail',
  components: {
    NewConfirmModal,
    confirmModal,
    confirm2Fa
  },
  mixins: [dataRequester, inputHelper, confirmModalBuilder, RDPGenerator],
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
  },
  data: function () {
    return {
      action: 'none',
      VMObject: {},
      VMAddonObject: {
        activeAddonObjectList: []
      },
      rgcObject: {
        serverList: null,
        vmObject: null
      },
      OSTypeIcon: '',
      imageValues: {
        imageListCache: [],
        selectedImageId: 0,
        snapshotListCache: [],
        selectedSnapshotId: 0
      },
      teamMembers: []
    }
  },
  computed: {
    isTeamMember: function () {
      return this.VMObject['co_owner_id'] === this.currentUser['userID']
    },
    VMPaymentStatus () {
      switch(this.VMObject['payment_status']) {
        case 'ok':
          if (this.VMObject['end_date'] <= this.getCurrentTime()) {
            return '<span class="badge badge-warning"><span>' + this.$t('dedicatedDetail.table.tdDataOverdue') + '</span></span>'
          } else {
            return '<span class="badge badge-success"><span>' + this.$t('dedicatedDetail.table.tdDataOK') + '</span></span>'
          }
        case 'cancel':
          return '<span class="badge badge-dark"><span>' + this.$t('dedicatedDetail.table.tdDataCancel') + '</span></span>'
        case 'suspend':
          return '<span class="badge badge-danger"><span>' + this.$t('dedicatedDetail.table.tdDataSuspend') + '</span></span>'
        default:
          return '<span class="badge badge-info"><span>' + this.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
      }
    }
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    this.submitObjectList(this.$config.apiPath.dedicatedDetail, {id: this.$route.params.VMID}, 'dedicated', undefined, function (result) {
      self.VMObject = result.data.VM
      self.submitObjectList(self.$config.apiPath.dedicatedAddon, {
        serviceTypeID: result.data.VM.service_type_id,
        serviceID: result.data.VM.id
      }, 'addon', undefined, function (result) {
        self.VMAddonObject = result.data
      })
      // Display OS icon
      if (self.VMObject['os_type'] === 'Windows') {
        self.OSTypeIcon = 'text-info fa fa-windows'
      } else if (self.VMObject['os_type'] === 'Linux') {
        self.OSTypeIcon = 'text-warning fa fa-linux'
      }
    })
    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users'].filter(x => x['id'] !== self.currentUser['userID'])
    }, null, false)
    // this.submitObjectList(this.$config.apiPath.dedicatedStatus, {id: this.$route.params.VMID}, 'dedicated', undefined, function (result) {
    //   if (result.data.VMStatus === 'Running') {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('dedicatedDetail.table.tdDataRunning') + '</span></span>'
    //   } else if (result.data.VMStatus === 'Stopped') {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('dedicatedDetail.table.tdDataStopped') + '</span></span>'
    //   } else if (result.data.VMStatus === 'Suspended') {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-warning"><span>' + self.$t('dedicatedDetail.table.tdDataSuspend') + '</span></span>'
    //   } else {
    //     self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-info"><span>' + result.data.VMStatus + '</span></span>'
    //   }
    // })
  },
  methods: {
    FixBugTheme,
    finishAction: function () {
      this.action = 'none'
    },
    confirmVM: function (actionToConfirm) {
      let self = this
      if (this.action === 'none') {
        this.action = actionToConfirm
        this.initConfirmVMAction([this.VMObject], actionToConfirm, function () {
          if (self.action === 'stop' || self.action === 'restart') { // means stop or restart
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('dedicatedDetail.modal.bodyDatalossWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          } else if (self.action === 'renew') {
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('dedicatedDetail.modal.bodyRenewWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          }
        })
        self.showModal(true)
      } else {
        self.$toasted.error(this.$t('dedicatedDetail.toast.errPendingTask'))
      }
    },
    onConfirmButton: function (otp_code) {
      let self = this
      if (this.action === 'start') {
        // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataStarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedStart, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('dedicatedDetail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.action = 'none'
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
        })
      } else if (this.action === 'shutdown') {
        // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataShuttingDown') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedShutdown, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('dedicatedDetail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'stop') {
        // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataStopping') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedStop, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('dedicatedDetail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          // self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'restart') {
        // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('dedicatedDetail.table.tdDataRestarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.dedicatedRestart, {id: this.VMObject['id']}, 'dedicated', undefined, function (result) {
          // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('dedicatedDetail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          // self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'editRemark') {
        let self = this
        let note = $('#editRemark').val()
        this.submitObjectList(this.$config.apiPath.dedicatedNote, {id: self.VMObject['id']}, 'dedicated', {note: note}, function (result) {
          self.$toasted.success(result.msg)
          self.action = 'none'
          self.VMObject['user_remark'] = note
        }, function () {
        })
      } else if (this.action === 'confirmTransfer') {
        this.submitObjectList(this.$config.apiPath.dedicatedTransfer,
            {id: self.VMObject['id']}, 'dedicated',
            {
              targetEmail: $('#transferEmailTarget').val(),
              otp_code: otp_code
            },
            function (transferResult) {
              if (transferResult.data.is_enable_2fa) {
                $('#otp_modal').modal('show')
                self.otpConfirmAction = 'confirmTransfer'
              } else {
                self.$toasted.success(transferResult.msg)
                self.$router.push('/home/dedicated-server')
              }
            },
            null, true, function () {
              self.action = 'none'
              $('#otp_modal').modal('hide')
            })
      } else if (this.action === 'teamMemberShare') {
        let memberId = $('#selectTeamMembers').children('option:selected').val()
        this.submitObjectList(this.$config.apiPath.shareTeamDedicated,
            {
              id: self.VMObject['id']
            },
            'dedicated',
            {
              member_id: memberId
            }, function (transferResult) {
              window.location.reload()
              self.$toasted.success(transferResult.msg)
            }, null, true, function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmRenew') {
        let periodRenew = $('#periodRenew').val()
        this.submitObjectList(this.$config.apiPath.dedicatedRenew,
            {id: this.VMObject.id}, 'dedicated',
            {period: periodRenew}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          window.open('/#/home/order/detail/' + renewResult.data.id, '_blank')
        })
      } else if (this.action === 'confirmAutoRenew') {
        this.submitObjectList(this.$config.apiPath.dedicatedAutoRenew, {id: this.VMObject.id}, 'dedicated', {autoRenew: (self.VMObject['auto_renew'] * -1) + 1}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          self.VMObject['auto_renew'] = (self.VMObject['auto_renew'] * -1) + 1
        })
      } else if (this.action === 'confirmRevokeCancel') {
        this.submitObjectList(this.$config.apiPath.dedicatedRevokeCancel,
            {id: this.VMObject.id},
            'dedicated',
            null,
            function (revokeResult) {
              self.$toasted.success(revokeResult.msg)
              self.action = 'none'
              self.VMObject['payment_status'] = 'ok'
            },
            function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmCreateRGC') {
        let rgcServerId = $('#selectRGCServer').children('option:selected').val()
        let portRGC = $('#rgcPortCreating')[0].value
        self.$toasted.info(self.$t('dedicatedDetail.toast.rgcCreating', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['osType'])]))
        this.submitObjectList(this.$config.apiPath.rgcCreate, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id'],
          RGCServerID: rgcServerId,
          remotePort: portRGC
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = rowDataObject.data['rgc_server_name']
          self.VMObject['rgc_forwarded_port'] = rowDataObject.data['rgc_forwarded_port']
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'confirmDeleteRGC') {
        self.$toasted.info(self.$t('dedicatedDetail.toast.rgcDelete', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['osType'])]))
        this.submitObjectList(this.$config.apiPath.rgcDelete, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id']
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = null
          self.VMObject['rgc_forwarded_port'] = null
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'showAddonDetail') {
        self.action = 'none'
      }
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      if (this.action === 'teamMemberShare') {
        this.submitObjectList(this.$config.apiPath.cancelShareDedicated,
            {id: self.VMObject.id},
            'dedicated',
            null, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              self.VMObject['co_owner_id'] = null
              self.VMObject['co_owner_full_name'] = null
              self.VMObject['co_owner_facebook_uid'] = null
            }, null, true, function () {
              self.action = 'none'
            })
      }
      self.finishModal()
    },
    editRemark: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleAddonDetail', [this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'])]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyEditRemark')
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="editRemark" aria-describedby="detail" value="' + this.VMObject['user_remark'] + '">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonEditRemark')
      this.action = 'editRemark'
      this.showModal(true)
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('dedicatedDetail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
    showAddonDetail: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleAddonDetail') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(JSON.parse(this.VMObject['addon_detail']), function (addon, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<strong>' + (key + 1) + ': </strong>'
        self.confirmModalDisplayObject.bodyMsg += addon['amount'] + ' x ' + addon['addon_name'] + ' ($' + addon['addon_price'] + self.$t('dedicatedDetail.table.tdDataEachMonth') + ') <br/>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = 'OK'
        self.action = 'showAddonDetail'
        self.showModal(true)
      })
    },
    consoleVM: function () {
      window.open('./#/vm/console/' + this.VMObject.id, '_blank')
    },
    generateRDPFile: function () {
      let self = this
      this.generateSingleRDP(self.VMObject, function (xml) {
        self.action = 'none'
      })
    },
    transfer: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmTransfer') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmTransfer')
      this.confirmModalDisplayObject.bodyMsg += '<div class="form-row mt-3 mb-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="col-form-label col-3">Email</label>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input class="form-control col-9" id="transferEmailTarget">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      this.confirmModalDisplayObject.bodyMsg += '<strong>Transfer IP:  ' + this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + this.$t('dedicatedDetail.modal.bodyTransferWarning') + '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonTransfer')

      this.action = 'confirmTransfer'
      this.showModal(true)
    },
    teamMemberShare: function () {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmShare') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectTeamMembers">' + self.$t('dedicatedDetail.modal.bodySelectTeamMember') + '</label><select class="form-select ps-3 mb-3" id="selectTeamMembers">'


        async.forEachOf(self.teamMembers, function (member, key, cb) {
          self.confirmModalDisplayObject.bodyMsg += '<option value="' + member.id + '">' + member.full_name + '</option>'
          cb()
        }, function () {
          self.confirmModalDisplayObject.bodyMsg += '</select></div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>Share IP:  ' + self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicatedDetail.modal.bodyTeamMember') + '</div>'
          self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonShare')
          self.confirmModalDisplayObject.additionalButton = {
            isShow: true,
            title: self.$t('dedicatedDetail.modal.buttonDeleteShare'),
            class: 'btn btn-danger'
          }

          self.action = 'teamMemberShare'
          self.showModal(true)
        })

      }
    },
    renew: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmRenew') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="form-label">' + `${this.$t('dedicatedDetail.modal.period')} (${this.$t('dedicatedDetail.modal.month')})` + '</label>'
      this.confirmModalDisplayObject.bodyMsg += '<input id="periodRenew" class="form-control" type="number" min="1" max="24" value="1" onKeyDown="return false">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += this.$t('dedicatedDetail.modal.bodyConfirmRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('dedicatedDetail.modal.bodyRenewWarning') + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonRenew')

      self.action = 'confirmRenew'
      self.showModal(true)
    },
    setAutoRenew: function () {
      let self = this
      if (this.VMObject['auto_renew'] === 0) {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmSetAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmSetAutoRenew')
      } else {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmCancelAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmCancelAutoRenew')
      }

      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      if (this.VMObject['auto_renew'] === 0) {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonSetAutoRenew')
      } else {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonCancelAutoRenew')
      }
      self.action = 'confirmAutoRenew'
      self.showModal(true)
    },
    revokeCancellation: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmRevokeCancel') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmRevokeCancel')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonRevokeCancel')

      this.action = 'confirmRevokeCancel'
      this.showModal(true)
    },
    createRGC: function (rowData) {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        let self = this
        self.rgcObject.vmObject = rowData
        let buildImageSelection = function () {
          self.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>IP. ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['osType']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<strong>Remote Port</strong>'
          self.confirmModalDisplayObject.bodyMsg += '<span class="smaller d-block">Nếu quý khách dùng custom remote port khác port remote mặc định thì điền vào đây</span>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline mb-3">'
          self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="rgcPortCreating" aria-describedby="detail" value="' + rowData['port'] + '">'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<div class="form-group"><label for="selectImageRebuild">' + self.$t('dedicatedDetail.modal.bodySelectRGC') + '</label><select class="form-select ps-3 mb-3" id="selectRGCServer">'

          async.forEachOf(self.rgcObject.serverList, function (item, key, cb) {
            self.confirmModalDisplayObject.bodyMsg += '<option value="' + item['id'] + '">' + item['rgc_server_name'] + ' (' + item['location'] + ')</option>'
            cb()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</select></div>'
            self.confirmModalDisplayObject.confirmButtonTitle = self.$t('dedicatedDetail.modal.buttonCreate')

            self.action = 'confirmCreateRGC'
            self.showModal(true)
          })
        }
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmCreateRGC') + '</h4>'

        // Get the image list
        if (self.rgcObject.serverList === null) {
          this.getDataFromAPI(this.$config.apiPath.rgcServerAvailable, function (result) {
            self.rgcObject.serverList = result.data['RGCServerObjectList']
            buildImageSelection()
          })
        } else {
          buildImageSelection()
        }
      }
    },
    deleteRGC: function (rowData) {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('dedicatedDetail.toast.errPendingTask'))
        return
      }
      self.rgcObject.vmObject = rowData
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('dedicatedDetail.modal.titleConfirmDeleteRGC') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('dedicatedDetail.modal.bodyConfirmDeleteRGC')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['osType']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('dedicatedDetail.modal.buttonDelete')

      this.action = 'confirmDeleteRGC'
      this.showModal(true)
    },
    confirmOTP: function (otp_code) {
      this.action = this.otpConfirmAction
      this.onConfirmButton(otp_code)
      this.otpConfirmAction = 'none'
      $('#otp_modal').modal('hide')
    },
    openModalCancel() {
      $('#cancel-modal').modal('show')
    },
    confirmCancel: function () {
      $('#cancel-modal').modal('hide')
      let self = this
      let cancelMode = $('input[name=rbCancallation]:checked').val()
      this.submitObjectList(this.$config.apiPath.dedicatedCancel,
          {id: self.VMObject['id']}, 'dedicated',
          {cancelMode: cancelMode},
          function (revokeResult) {
            self.$toasted.success(revokeResult.msg)
            self.VMObject['payment_status'] = 'cancel'
            if (cancelMode === 'immediate') {
              self.$router.push('/home/dedicated-server')
            }
          })
    },
  }
}
