export default {
  en: {
    breadcrumb: 'Invoice List',
    cardHeader: {
      title: 'Your order list'
    },
    table: {
      thType: 'Type',
      thTotal: 'Total amount',
      thStatus: 'Status',
      thMethod: 'Payment method',
      thNote: 'Note',
      thCreated: 'Created',
      thUpdated: 'Updated',
      thManage: 'Manage',

      tdPay: 'Pay',
      tdDetail: 'View detail',
      tdStatusUnpaid: 'Unpaid',
      tdStatusCredited: 'Credited',
      tdStatusProvisioned: 'Provisioned',
      tdStatusRenewed: 'Renewed',
      tdStatusInstalling: 'Installing',
      tdStatusPending: 'Pending',
      tdStatusCancelled: 'Cancelled',
      tdStatusFraud: 'Fraud',
      tdStatusFinished: 'Finished',
      tdStatusPartially: 'Partially',

      tdTypeTopup: 'Topup',
      tdTypeNew: 'Create new',
      tdTypeRenew: 'Renew',
      tdTypeChange: 'Change',
      tdTypePromotion: 'Promotion',
      tdTypeOther: 'other',
      tdTypeRefwith: 'Referral withdraw',
      tdTypeUpgrade: 'Upgrade',
      tdTypeTesting: 'Trial'
    }
  },
  vi: {
    breadcrumb: 'Danh Sách Hoá Đơn',
    cardHeader: {
      title: 'Danh sách đơn hàng'
    },
    table: {
      thType: 'Loại',
      thTotal: 'Tổng cộng',
      thStatus: 'Trạng thái',
      thMethod: 'P.thức thanh toán',
      thNote: 'Note',
      thCreated: 'Ngày tạo',
      thUpdated: 'Ngày cập nhật',
      thManage: 'Thao tác',

      tdPay: 'Thanh toán ngay',
      tdDetail: 'Chi tiết',
      tdStatusUnpaid: 'Chưa thanh toán',
      tdStatusCredited: 'Đã nạp tiền',
      tdStatusProvisioned: 'Đã giao',
      tdStatusRenewed: 'Đã gia hạn',
      tdStatusInstalling: 'Đang cài đặt',
      tdStatusPending: 'Chờ xác nhận',
      tdStatusCancelled: 'Hủy',
      tdStatusFraud: 'Lỗi',
      tdStatusFinished: 'Hoàn tất',
      tdStatusPartially: 'Đang giao VPS',

      tdTypeTopup: 'Nạp tiền',
      tdTypeNew: 'Tạo mới',
      tdTypeRenew: 'Gia hạn',
      tdTypeChange: 'Thay đổi',
      tdTypePromotion: 'Khuyến mãi',
      tdTypeOther: 'Khác',
      tdTypeRefwith: 'Rút tiền hoa hồng',
      tdTypeUpgrade: 'Nâng cấp',
      tdTypeTesting: 'Dùng thử'
    }
  },
  zh: {
    cardHeader: {
      title: '您的订单'
    },
    table: {
      thType: '类型',
      thTotal: '总数',
      thStatus: '状态',
      thMethod: '付款方式',
      thNote: '备注',
      thCreated: '创建时间',
      thUpdated: '完成时间',
      thManage: '操作',

      tdPay: '立即交易',
      tdDetail: '详情',
      tdStatusUnpaid: '未付款',
      tdStatusCredited: '已充值',
      tdStatusProvisioned: '交易成功',
      tdStatusRenewed: '已续费',
      tdStatusInstalling: '准备发货',
      tdStatusPending: '等待确认',
      tdStatusCancelled: '取消',
      tdStatusFraud: '故障'
    }
  }
}
