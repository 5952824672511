//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import md5 from 'md5'
import qrcode from 'qrcode'
import { FixBugTheme } from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'

export default {
  name: "two_fa",
  data: function () {
    return {
      isEnable2Fa: false,
      qrCode2Fa: null,
      secretKeyOtpToken: null,
      accountService: null,
      digitToken: '',
      password: ''
    }
  },
  mixins: [dataRequester],
  created() {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.check2FaEnable, function (result) {
      self.isEnable2Fa = result.data
      if (!self.isEnable2Fa) {
        self.getDataFromAPI(self.$config.apiPath.generate2FaToken, function (result) {
          self.secretKeyOtpToken = result.data['secretKey']
          self.accountService = result.data['siteName']
          self.generateQRCode(result.data['otpToken']).then(qrCode => {
            self.qrCode2Fa = qrCode
          })
        })
      }
    }, function (error) {
    })
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    generateQRCode: async function (otpAuth) {
      try {
        const QRCodeImageUrl = await qrcode.toDataURL(otpAuth)
        return QRCodeImageUrl
      } catch (error) {
        return null
      }
    },
    enable2FaToken: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.enable2FaToken, {
        password: md5(self.password),
        token: self.digitToken,
        secret_key: self.secretKeyOtpToken
      }, 'otp2fa', null, function (result) {
        if (result.status = 'success') {
          self.$toasted.success(result.msg)
          self.isEnable2Fa = true
        }
      })
    },
    disable2FaToken: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.disable2FaToken, {
        password: md5(self.password),
        token: self.digitToken
      }, 'otp2fa', null, function (result) {
        if (result.status = 'success') {
          self.$toasted.success(result.msg)
          window.location.reload()
        }
      })
    }
  }
}
