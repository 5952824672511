//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LevButton",
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  }
}
