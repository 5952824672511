var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header pb-1"},[_c('div',{staticClass:"btn-group d-flex justify-content-end",attrs:{"role":"group","aria-label":"Basic radio toggle button group"}},[_c('div',{},[_c('div',{staticClass:"dropdown d-inline"},[_c('button',{staticClass:"btn bg-gradient-primary dropdown-toggle",attrs:{"id":"dropdownMenuButtonControl","type":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-cog"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('hosting.button.multipleHostingAction')))])]),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButtonControl"}},_vm._l((_vm.menuActionItems),function(item,index){return _c('li',{key:index},[_c('span',{staticClass:"dropdown-item",on:{"click":item['openModal']}},[_c('i',{class:((item['icon']) + " menu-icon text-" + (item['color']))}),_c('span',[_vm._v(" "+_vm._s(item['text']))])])])}),0)])])]),_c('hr',{staticClass:"pt-0 pb-0"})]),_c('div',{staticClass:"card-body"},[_c('lev-table',{staticClass:"lev-table",attrs:{"table-data":_vm.dataObjectList,"fields":_vm.fields,"searchalbe-fields":_vm.searchableFields,"extended-fields":_vm.extendtedFields,"visible-fields":_vm.visibleFields,"on-cell-click-processing":_vm.onCellClickProcessing},on:{"selected-changed":_vm.onSelectedChanged},scopedSlots:_vm._u([{key:"cpanel",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"fab fa-cpanel text-5xl text-primary cursor-pointer",on:{"click":function($event){return _vm.windowOpen(((item['host_name']) + ":2083"))}}})]}},{key:"user_remark",fn:function(ref){
var item = ref.item;
return [(item['is_editing_note'])?_c('div',[_c('div',{staticClass:"input-group input-group-dynamic align-items-center small"},[_c('input',{staticClass:"form-control text-primary text-sm",attrs:{"id":"user_remark_value","type":"text"},domProps:{"value":item['user_remark']}}),_c('div',{staticClass:"d-flex gap-1 align-self-center"},[_c('icon-button',{attrs:{"data":item,"icon":"fas fa-edit","color":"info","tooltip":_vm.$t('hosting.tooltip.editNote'),"type":"save"},on:{"click":_vm.updateUserRemark},model:{value:(item['is_editing_note']),callback:function ($$v) {_vm.$set(item, 'is_editing_note', $$v)},expression:"item['is_editing_note']"}}),_c('icon-button',{attrs:{"icon":"fas fa-edit","color":"info","tooltip":_vm.$t('hosting.tooltip.editNote')},model:{value:(item['is_editing_note']),callback:function ($$v) {_vm.$set(item, 'is_editing_note', $$v)},expression:"item['is_editing_note']"}})],1)])]):_c('div',[_c('div',{staticClass:"small d-flex justify-content-between"},[_vm._v(" "+_vm._s(item['user_remark'])+" "),_c('div',{staticClass:"d-flex gap-1 align-self-center"},[_c('icon-button',{attrs:{"icon":"fas fa-edit","color":"info","tooltip":_vm.$t('hosting.tooltip.editNote'),"type":"edit"},model:{value:(item['is_editing_note']),callback:function ($$v) {_vm.$set(item, 'is_editing_note', $$v)},expression:"item['is_editing_note']"}}),_c('icon-button',{attrs:{"copy-content":item['user_remark'],"tooltip":_vm.$t('hosting.tooltip.copyNote'),"type":"copy"}})],1)])])]}},{key:"username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('strong',[_vm._v(_vm._s(item['username']))]),_c('icon-button',{attrs:{"copy-content":item['username'],"tooltip":_vm.$t('hosting.tooltip.copyUsername'),"type":"copy"}})],1)]}},{key:"password",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(item['is_password_show'])?_c('div',[_vm._v(" "+_vm._s(item['password'])+" ")]):_c('div',[_vm._v(" ******* ")]),_c('div',{staticClass:"d-flex gap-1"},[(item['is_password_show'])?_c('icon-button',{attrs:{"icon":"fas fa-eye","color":"info","tooltip":_vm.$t('hosting.tooltip.showPassword')},model:{value:(item['is_password_show']),callback:function ($$v) {_vm.$set(item, 'is_password_show', $$v)},expression:"item['is_password_show']"}}):_c('icon-button',{attrs:{"icon":"fas fa-eye-slash","color":"info","tooltip":_vm.$t('hosting.tooltip.hidePassword')},model:{value:(item['is_password_show']),callback:function ($$v) {_vm.$set(item, 'is_password_show', $$v)},expression:"item['is_password_show']"}}),_c('icon-button',{attrs:{"copy-content":item['password'],"tooltip":_vm.$t('hosting.tooltip.copyPassword'),"type":"copy"}})],1)])]}},{key:"start_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertISODate(item['start_date'])))])]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertISODate(item['end_date'])))])]}},{key:"auto_renew",fn:function(ref){
var item = ref.item;
return [(item['auto_renew'] === 1)?_c('span',{staticClass:"text-success small text-bold"},[_vm._v("✓")]):_c('span',{staticClass:"text-danger small text-bold"},[_vm._v("✗")])]}},{key:"payment_status",fn:function(ref){
var item = ref.item;
return [(item['payment_status'] === 'ok' && item['end_date'] <= _vm.getCurrentTime())?_c('div',{staticClass:"small"},[_c('span',{staticClass:"badge badge-warning"},[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.table.label.paymentOverdue')))])])]):(item['payment_status'] === 'ok')?_c('div',{staticClass:"small"},[_c('span',{staticClass:"badge badge-success"},[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.table.label.paymentOk')))])])]):(item['payment_status'] === 'suspend')?_c('div',{staticClass:"small"},[_c('span',{staticClass:"badge badge-danger"},[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.table.label.paymentSuspended')))])])]):(item['payment_status'] === 'cancel')?_c('div',{staticClass:"small"},[_c('span',{staticClass:"badge badge-dark"},[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.table.label.paymentCancelled')))])])]):_c('div',{staticClass:"small"},[_c('span',{staticClass:"badge badge-info"},[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.table.label.paymentUnknown')))])])])]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"text-info"},[_vm._v(_vm._s(_vm.USDCurrencyFormat(item['price'])))])]}},{key:"region",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-info cursor-pointer",attrs:{"data-bs-toggle":"tooltip","data-bs-placement":"top","data-container":"body","data-animation":"true","title":((item['country']) + " (" + (item['state']) + ")")}},[_vm._v(" "+_vm._s(item['country_code'])+" ("+_vm._s(item['state_code'])+") ")])]}},{key:"plan_name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item['plan_name']))])]}}])})],1)]),_c('lev-modal',{ref:"main-modal",attrs:{"id":"main-modal","title":_vm.modalContenObj['title'],"confirm-button":_vm.modalContenObj['buttonConfirm'],"is-manual-close":true},on:{"modalConfirm":_vm.modalContenObj['confirmAction']}},[(_vm.modalName === 'edit-note')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.modal.label.editRemark')))]),_c('lev-item-list',{attrs:{"item-list":_vm.selectedObjectList,"color":"info"},scopedSlots:_vm._u([{key:"item-content",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item['domain']))])]}}],null,false,692913801)}),_c('label',[_vm._v(_vm._s(_vm.$t('hosting.modal.label.yourNote')))]),_c('lev-input',{model:{value:(_vm.noteContentForUpdate),callback:function ($$v) {_vm.noteContentForUpdate=$$v},expression:"noteContentForUpdate"}})],1):(_vm.modalName === 'invoice-renew')?_c('div',[_c('span',[_vm._v("Tạo hoá đơn gia hạn cho các domain dưới đây")]),_c('lev-item-list',{attrs:{"item-list":_vm.selectedObjectList,"color":"info"},scopedSlots:_vm._u([{key:"item-content",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item['domain']))])]}}])}),_c('lev-input',{attrs:{"label":"Chu kì gia hạn (tháng)","type":"number"},model:{value:(_vm.periodRenew),callback:function ($$v) {_vm.periodRenew=$$v},expression:"periodRenew"}})],1):(_vm.modalName === 'enable-auto-renew')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.modal.label.enableAutoRenew')))]),_c('lev-item-list',{attrs:{"item-list":_vm.selectedObjectList,"color":"info"},scopedSlots:_vm._u([{key:"item-content",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item['domain']))])]}}])})],1):(_vm.modalName === 'disable-auto-renew')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('hosting.modal.label.disableAutoRenew')))]),_c('lev-item-list',{attrs:{"item-list":_vm.selectedObjectList,"color":"info"},scopedSlots:_vm._u([{key:"item-content",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item['domain']))])]}}])})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }