//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import md5 from 'md5'
import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '../../mixins/MaterialTheme';
import confirm2Fa from "@/components/children/Confirm2Fa";
export default {
  name: "Login",
  components: {confirm2Fa},
  mixins: [ inputHelper, dataRequester ],
  props: {
    currentUser: {}
  },
  data: function () {
    return {
      username: '',
      password: '',
      remember: true,
      is_enable_2fa: false,
      fbLoginOptions: {
        scope: 'id,name,email'
      },
      metaData: {
        logoPath: '',
        siteName: '',
        footNote: '',
        shortFootNote: '',
        availableLang: ''
      }
    }
  },
  mounted: function () {
   this.FixBugTheme()
  },
  created: function () {
    let self = this

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }

    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.shortFootNote = metaData.domainObjectList['shortfootnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
    }, function () {
      this.$router.push('https://levcs.com')
    })

    self.loginByApiKey()
  },
  methods: {
    FixBugTheme,
    login: function (otp_code) {
      let self = this
      let loginData = {
        username: this.username.toString().trim(),
        encryptedPassword: md5(this.password),
        otp_code: otp_code
      }
      if (this.checkValidEmail(this.username)) {
        loginData['email'] = this.username
      }
      axios.post(this.$config.apiPath.login, loginData)
          .then(function (response) {
                if (response.data.status === 'success') {
                  if (otp_code === '') {
                    self.is_enable_2fa = response.data.data['is_enable_2fa']
                  }
                  if (!response.data.data['is_enable_2fa']) {
                    let expiredTime = '0'
                    if (self.remember === true) {
                      expiredTime = '90D'
                    }
                    self.$cookies.set('username', response.data.data.username, expiredTime)
                    self.$cookies.set('email', response.data.data.email, expiredTime)
                    self.$cookies.set('sessionId', response.data.data.sessionId, expiredTime)
                    self.$toasted.success(response.data.msg)
                    self.$emit('loginEvent', response.data.data)
                    if (self.$cookies.get('redirectAfterLogin') !== null) {
                      self.$router.push(self.$cookies.get('redirectAfterLogin'))
                      self.$cookies.remove('redirectAfterLogin')
                    } else {
                      self.$router.push('/home/dashboard')
                    }
                  }
                } else {
                  self.$emit('loginEvent', null)
                  self.$toasted.error(response.data.msg)
                }
              }
          )
    },
    loginByApiKey: function () {
      let username = this.$router.currentRoute.query['username']
      let apiKey = this.$router.currentRoute.query['apiKey']
      if (username !== undefined && apiKey !== undefined) {
        let self = this
        let loginData = {
          username: username,
          APIKey: apiKey
        }
        axios.post(this.$config.apiPath.login, loginData)
            .then(function (response) {
                  if (response.data.status === 'success') {
                    let expiredTime = '0'
                    if (self.remember === true) {
                      expiredTime = '90D'
                    }
                    self.$cookies.set('username', response.data.data.username, expiredTime)
                    self.$cookies.set('email', response.data.data.email, expiredTime)
                    self.$cookies.set('sessionId', response.data.data.sessionId, expiredTime)
                    self.$toasted.success(response.data.msg)
                    self.$emit('loginEvent', response.data.data)
                    if (self.$cookies.get('redirectAfterLogin') !== null) {
                      self.$router.push(self.$cookies.get('redirectAfterLogin'))
                      self.$cookies.remove('redirectAfterLogin')
                    } else {
                      self.$router.push('/home/dashboard')
                    }
                  } else {
                    self.$emit('loginEvent', null)
                    self.$toasted.error(response.data.msg)
                  }
                }
            )
      }
    },
    register: function () {
      this.$router.push('/register')
    },
    recoverPassword: function () {
      this.$router.push('/forgotpw')
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    }
  }
}
