export default {
    en: {
        breadcrumb: 'Team Management',
        label: {
            title: 'Team Management',
            create_new_team: 'You are currently not a member of any teams.<br/>Join a team or create your own team.',
            no_member: 'There are currently no members in this team.',
            team_is_closed: 'Your team is <strong class="text-danger">LOCKED</strong>. New members cannot join your team.',
            team_is_opened: 'Your team is <strong class="text-success">UNLOCKED</strong>. Others can join your team by entering invite code <code class="text-2xl">{team_private_key}</code> or using the link below.',
            link_join_team: 'Link to join your team: ',
            total_services: 'Total services',
            leader: 'Leader',
            member: 'Member',
            click: 'Click',
            close_team_msg: 'to disable joining team for new members.',
            open_team_msg: 'to allow others joining your team.',
            change_invite_code: 'for getting new invite code.',
            allow_notification: 'Allow get service\'s notification.'
        },
        button: {
            create_new_team: 'Click to create new team',
            join_a_team: 'Click to join a team',
            delete_team: 'Delete Team',
            remove_team: 'Remove member',
            exit_team: 'Exit Team',
            closed_team: 'LOCK',
            opened_team: 'UNLOCK',
            change_invite_code: 'CHANGE INVITE CODE'
        },
        tooltip: {
            copy: 'Copy URL'
        },
        modal: {
            title: {
                create_team: "Confirm creating a new team",
                open_team: "Confirm unlocking team",
                close_team: "Confirm locking team",
                exit_team: "Confirm exiting team",
                delete_team: "Confirm deleting team",
                remove_member: "Confirm removing member",
                join_team: 'Join a team',
                update_private_key: 'Update \'Invite Code\''
            },
            label: {
                invite_code: 'Invite code'
            },
            content: {
                create_team: "You are about to create a new team. You will be the team leader. Any other members joining team after you will be team members. Are you sure?",
                open_team: "Unlocking team will make any new members who has the URL/Invite code can join your team. Are you sure?",
                close_team: "Locking your team will stop any new members from joining. The existing members will stay the same. Are you sure?",
                exit_team: "After exiting from this team, you will not have permission to manage all the shared services. Are you sure?",
                delete_team: "You are about to delete this team. All the member will be removed. Their management permission with shared services are also revoked. Are you sure?",
                remove_member: 'You are about to remove <strong class="text-info">{full_name}</strong> from your team. The members\' management permission with shared services are also revoked. Are you sure?',
                join_team: 'Type <code>Invite Code</code> to join a team.',
                update_private_key: 'The old invite code will be invalid and cannot be used to join this team. Are you sure?'
            },
            button: {
                confirm: 'Confirm',
                close: 'Close',
                join: 'Join Team'
            }
        }
    },
    vi: {
        breadcrumb: 'Quản Lý Nhóm',
        label: {
            title: 'Quản Lý Nhóm',
            create_new_team: 'Bạn chưa tham gia nhóm nào.<br/>Hãy tham gia một nhóm hoặc tạo một nhóm mới của riêng bạn.',
            no_member: 'Hiện đang không có thành viên nào trong nhóm này.',
            team_is_closed: 'Nhóm của bạn đang ở chế độ <strong class="text-danger">ĐÓNG</strong>. Thành viên mới không thể tham gia vào nhóm này.',
            team_is_opened: 'Nhóm của bạn đang ở chế độ <strong class="text-success">MỞ</strong>. Thành viên mới có thể tham gia nhóm bằng mã tham gia <code class="text-2xl">{team_private_key}</code> hoặc link bên dưới.',
            link_join_team: 'Link tham gia nhóm: ',
            total_services: 'Số lượng dịch vụ',
            leader: 'Trưởng nhóm',
            member: 'Thành viên',
            click: 'Ân',
            close_team_msg: 'để ngăn không cho thành viên mới tham gia vào nhóm này.',
            open_team_msg: 'để cho phép thành viên khác tham gia vào nhóm này.',
            change_invite_code: ' để thay đổi mã tham gia nhóm này.',
            allow_notification: 'Cho phép nhận thông báo dịch vụ.'
        },
        button: {
            create_new_team: 'Ấn để tạo nhóm',
            join_a_team: 'Ấn để tham gia nhóm',
            delete_team: 'Giải tán nhóm',
            remove_team: 'Xoá thành viên',
            exit_team: 'Thoát Nhóm',
            closed_team: 'Đóng Nhóm',
            opened_team: 'Mở Nhóm',
            change_invite_code: 'THAY ĐỔI MÃ THAM GIA'
        },
        tooltip: {
            copy: 'Sao chép đường dẫn'
        },
        modal: {
            title: {
                join_team: 'Tham gia vào nhóm',
                create_team: "Tạo nhóm mới",
                open_team: "Mở nhóm",
                close_team: "Khóa nhóm",
                exit_team: "Thoát nhóm",
                delete_team: "Giải tán nhóm",
                remove_member: "Xoá thành viên",
                update_private_key: 'Cập nhật \'Mã Tham Gia Nhóm\''
            },
            label: {
              invite_code: 'Mã tham gia'
            },
            content: {
                create_team: "Bạn đang tạo một nhóm mới. Bạn sẽ trở thành trưởng nhóm này. Các thành viên tham gia nhóm sau bạn sẽ trở thành thành viên của nhóm.",
                open_team: "Bạn đang mở khóa nhóm này. Các thành viên khác nếu có mã tham gia hoặc URL tham gia đều có thể tham gia vào nhóm.",
                close_team: "Bạn đang khóa nhóm này lại. Các thành viên mới sẽ không thê tham gia nhóm. Các thành viên cũ trong nhóm vẫn hoạt động bình thường.",
                exit_team: "Bạn sẽ không còn quyền quản lý các dịch vụ đã được chia sẻ từ nhóm trưởng. Bạn có chắc chắn muốn thoát khỏi nhóm này? ",
                delete_team: "Các thành viên trong nhóm sẽ bị xóa khỏi nhóm. Họ cũng sẽ không còn quyền quản lý các dịch vụ bạn đã chia sẻ. Bạn có chắc chắn muốn giải tán nhóm này?",
                remove_member: 'Bạn có chắc chắn muốn xoá thành viên <strong class="text-info">{full_name}</strong> ra khỏi nhóm? Thành viên này sẽ không còn quản lý được các dịch vụ bạn đã chia sẻ.',
                join_team: 'Nhập vào <code>Mã Tham Gia</code> để tham gia vào nhóm.',
                update_private_key: 'Cập nhật lại mã tham gia nhóm sẽ khiến mã tham gia cũ bị vô hiệu hóa. Bạn có chắc muốn cập nhật lại mã tham gia nhóm?'
            },
            button: {
                confirm: 'Xác Nhận',
                close: 'Đóng',
                join: 'Tham Gia Nhóm'
            }
        }
    },
}
