//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LevItemList",
  props: {
    itemList: {
      type: Array,
      default: function () {
        return []
      }
    },
    color: {
      type: String,
      default: "info"
    }
  }
}
