//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "TeamManager",
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {},
    metaData: {}
  },
  data: function () {
    return {
      actionType: 'none',
      teamMembers: [],
      modal_title: '',
      modal_content: '',
      currentTeamMember: {},
      invite_code: ''
    }
  },
  computed: {
    basePath: function() {
      return this.$config.basePath + '/#/home/team/join?invite_code=' + this.currentUser['team_private_key']
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this
    // this.basePath = this.$config.basePath + '/#/home/team/join?private_key=' + this.currentUser['team_private_key']
    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users']
    })
  },
  methods: {
    FixBugTheme,
    showModalConfirm (actionType, teamMember) {
      this.actionType = actionType
      this.currentTeamMember = teamMember
      switch(this.actionType) {
        case 'create':
          this.modal_title = this.$t('team.modal.title.create_team')
          this.modal_content = this.$t('team.modal.content.create_team')
          break
        case 'open':
          this.modal_title = this.$t('team.modal.title.open_team')
          this.modal_content = this.$t('team.modal.content.open_team')
          break
        case 'close':
          this.modal_title = this.$t('team.modal.title.close_team')
          this.modal_content = this.$t('team.modal.content.close_team')
          break
        case 'remove':
          this.modal_title = this.$t('team.modal.title.remove_member')
          this.modal_content = this.$t('team.modal.content.remove_member', teamMember)
          break
        case 'exit':
          this.modal_title = this.$t('team.modal.title.exit_team')
          this.modal_content = this.$t('team.modal.content.exit_team')
          break
        case 'delete':
          this.modal_title = this.$t('team.modal.title.delete_team')
          this.modal_content = this.$t('team.modal.content.delete_team')
          break
        case 'update-private-key':
          this.modal_title = this.$t('team.modal.title.update_private_key')
          this.modal_content = this.$t('team.modal.content.update_private_key')
          break
        default:
          break
      }
      $('#modal-confirm').modal('show')
    },
    modalConfirm () {
      switch(this.actionType) {
        case 'create':
          this.createNewTeam()
          break
        case 'open':
          this.openTeam()
          break
        case 'close':
          this.closeTeam()
          break
        case 'remove':
          this.removeTeamMember(this.currentTeamMember['id'])
          break
        case 'exit':
          this.exitTeam()
          break
        case 'delete':
          this.deleteTeam()
          break
        case 'update-private-key':
          this.updatePrivateKey()
          break
        default:
          break
      }
      $('#modal-confirm').modal('hide')
    },
    createNewTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.createNewTeam, function (result) {
        self.$toasted.success(result.msg)
        window.location.reload()
      })
    },
    removeTeamMember (memberId) {
      let self = this
      this.submitObjectList(this.$config.apiPath.removeTeamMember, {
        member_id: memberId
      }, 'team', null, function (result) {
        self.$toasted.success(result.msg)
        self.teamMembers = self.teamMembers.filter(x => x['id'] !== memberId)
      })
    },
    exitTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.exitTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['team_id'] = null
      })
    },
    deleteTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.deleteTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['team_id'] = null
        self.currentUser['team_private_key'] = null
      })
    },
    openTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.openTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['is_group_opened'] = true
      })
    },
    closeTeam () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.closeTeam, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['is_group_opened'] = false
      })
    },
    joinTeam () {
      let self = this
      this.submitObjectList(this.$config.apiPath.joinTeam, {
        private_key: self.invite_code
      }, 'team', null, function (result) {
        $('#modal-confirm-join-team').modal('show')
        self.$toasted.success(result.msg)
        window.location.reload()
      })
    },
    updatePrivateKey () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.teamUpdatePrivateKey, function (result) {
        self.$toasted.success(result.msg)
        self.currentUser['team_private_key'] = result.data.invite_code
      })
    },
    updateTeamNotification (member) {
      let self = this
      this.submitObjectList(this.$config.apiPath.teamNotification, {
        member_id: member.id,
        received_team_notification: !member.received_team_notification
      }, 'team', null, function (result) {
        self.$toasted.success(result.msg)
      })
    },

    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('dedicatedDetail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    }
  }
}
