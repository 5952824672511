 export default {
  en: {
    breadcrumb: 'Dashboard',
    overview: {
      services: 'Services',
      servicesExpired: ' services expired in next {0} days',

      orders: 'Total orders',
      ordersPending: 'orders pending',
      ordersInstalling: 'orders installing',
      vpsAvailable: 'VPS is available',
      dedicatedServerAvailable: 'dedicated server is available',
      ticketOpen: 'open tickets',
      ticketClosed: 'closed tickets',

      guide: 'Guide',
      security: 'Security'
    }
  },
  vi: {
    breadcrumb: 'Tổng Quan',
    overview: {
      services: 'Dịch vụ',
      servicesExpired: ' dịch vụ sẽ hết hạn trong {0} ngày kế tiếp',

      orders: 'Tổng đơn hàng',
      ordersPending: 'đơn đặt hàng đang chờ',
      ordersInstalling: 'đơn đặt hàng đang được cài đặt',
      vpsAvailable: 'dịch vụ VPS',
      dedicatedServerAvailable: 'dịch vụ máy chủ vật lý',
      ticketOpen: 'tickets chưa giải quyết',
      ticketClosed: 'tickets đã giải quyết',

      guide: 'Hướng dẫn',
      security: 'Bảo mật'
    }
  },
  zh: {

  }
}
