//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import levButton from "@/components/children/lev_components/LevButton.vue";
import moment from "moment";
import orderDetail from "../../lang/components/orderDetail";
export default {
  name: 'OrderDetail',
  components: {
    levButton
  },
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {},
    metaData: {}
  },
  data: function () {
    return {
      orderObject: {},
      paymentDetailList: [],
      selectedPaymentDetail: '',
      showPaymentOption: '',
      vietQRIsShow: false,
      isButtonLoading: false,
      exchangeRateHistoryList: []
    }
  },
  computed: {
    orderDetail() {
      return orderDetail
    },
    getWebsiteContactDisplay: function () {
      let websiteArr = this.metaData.contactWebsite.split('|')
      return websiteArr.reduce(function (a, s) {
        return a + ' <a href="' + s + '">' + s + '</a> |'
      }, '')
    },
    due: function () {
      let result = {
        amount: this.orderObject['total_amount'],
        textClass: 'text-danger'
      }
      if (this.orderObject['status'] !== 'Unpaid' && this.orderObject['status'] !== 'Pending') {
        result.amount = 0
        result.textClass = 'text-success'
      }
      return result
    },
    isSetupFee: function () {
      let self = this
      return function (serviceOtherInfo) {
        if (![null, undefined].includes(serviceOtherInfo) && self.isValidJSON(serviceOtherInfo)) {
          return JSON.parse(serviceOtherInfo)['description'] === 'Setup fee'
        } else {
          return false
        }
      }
    },
    moneyTransferContent: function () {
      if (this.orderObject['confirm_code'] === null) {
        return `${ this.metaData.siteTicker }${ this.orderObject['id'] }`
      } else {
        return this.orderObject['confirm_code']
      }
    }
  },
  created: function () {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.orderDetail + this.$route.params.orderID, function (orderDetail) {
      self.orderObject = orderDetail.data
      if (self.orderObject['items'] !== undefined) { //
        self.orderObject['items'].sort((a,b) => {
          if ([null, undefined].includes(a['service_other_info']) || [null, undefined].includes(b['service_other_info'])) {
            return 0
          } else {
            return b['service_other_info'].localeCompare(a['service_other_info'])
          }
        })
      }

      self.getDataFromAPI(self.$config.apiPath.paymentDetailList, function (paymentDetail) {
        self.paymentDetailList = paymentDetail.data
        if (orderDetail.data['payment_name'] !== 'Not selected') {
          self.selectedPaymentDetail = self.paymentDetailList.find(p => p['payment_name'] === orderDetail.data['payment_name'])
        }
      })
    })
    this.submitObjectList(this.$config.apiPath.exchangeRateHistory, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.exchangeRateHistoryList = result.data['exchangeRateHistoryList']
      } else {
        self.$toasted.error(result.msg)
      }
    })
  },
  methods: {
    selectPaymentDetail: function (paymentName, accountNo) {
      if (paymentName === 'Balance' && this.currentUser['balance'] < this.orderObject['total_amount']) {
        this.selectedPaymentDetail = ''
        this.$toasted.error(this.$t('orderDetail.toast.notEnoughCredit'))
      } else if (paymentName === 'LPOINT' && this.currentUser['lpoint'] < this.orderObject['total_amount']) {
        this.selectedPaymentDetail = ''
        this.$toasted.error(this.$t('orderDetail.toast.notEnoughPoint'))
      } else if (accountNo === undefined) {
        this.selectedPaymentDetail = this.paymentDetailList.find(p => p['payment_name'] === paymentName)
      } else {
        this.selectedPaymentDetail = this.paymentDetailList.find(p => p['payment_name'] === paymentName && p['account_no'] === accountNo)
      }

      this.vietQRIsShow = false
    },
    cancel: function () {
      let self = this
      self.submitObjectList(self.$config.apiPath.orderCancel, {id: self.orderObject['id']}, 'order', undefined, function (result) {
        self.$toasted.success(result.msg)
        self.orderObject['status'] = 'Cancelled'
      })
    },
    nextToPaymentDetail: function () {
      let self = this

      if (this.$route.query['step'] === undefined || this.$route.query['step'] === null || parseInt(this.$route.query['step']) <= 1) {
        this.$router.push('/home/order/detail/' + this.orderObject['id'] + '?step=2')
      } else if (this.$route.query['step'] === '2') {
        if (this.selectedPaymentDetail['id'] === undefined) {
          this.$toasted.error(this.$t('orderDetail.toast.methodNotSelected'))
          return
        }
        if (this.orderObject['total_amount'] === 0) {
          this.$toasted.error(this.$t('orderDetail.toast.emptyCart'))
          return
        }
        if (this.selectedPaymentDetail['payment_name'] === 'Balance' && this.currentUser['balance'] < this.orderObject['total_amount']) {
          // This is necessary because user may insist on using balance when not enough balance
          this.$toasted.error(this.$t('orderDetail.toast.notEnoughCredit'))
          return
        }
        if (this.selectedPaymentDetail['payment_name'] === 'LPOINT' && this.currentUser['lpoint'] < this.orderObject['total_amount']) {
          this.$toasted.error(this.$t('orderDetail.toast.notEnoughPoint'))
          return
        }

        this.orderObject['payment_method_id'] = self.selectedPaymentDetail['id']
        self.isButtonLoading = true

        self.submitObjectList(self.$config.apiPath.orderFinish,
            {id: self.orderObject['id'],
              payment_detail_id: self.selectedPaymentDetail['id']},
            'order', undefined,
            function (result) {
              self.$toasted.success(result.msg)
              self.orderObject['status'] = result.data.status

              // Update current balance to
              self.updateCurrentUserInfo()
            }, undefined, undefined, function () {
              self.isButtonLoading = false
              this.$router.push('/home/order/detail/' + this.orderObject['id'] + '?step=3')
            })
      }
    },
    backToPreviousStep: function () {
      if (this.$route.query['step'] === undefined || this.$route.query['step'] === null || parseInt(this.$route.query['step']) <= 1) {
        this.$router.push('/home/order/history')
      } else {
        this.$router.push('/home/order/detail/' + this.orderObject['id'] + '?step=1')
      }
    },
    exchangeRateValue (orderCreatedAt) {
      let exchangeRateValue
      if (orderCreatedAt === undefined) {
        exchangeRateValue = null
      } else {
        let exchangeRate = this.exchangeRateHistoryList.find(e => moment(e['applied_at']).isSameOrBefore(orderCreatedAt))
        if (exchangeRate === undefined) {
          exchangeRateValue = null
        } else {
          exchangeRateValue = exchangeRate['exchange_rate']
        }
      }
      return exchangeRateValue
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.orderObject['status'] === 'Unpaid') {
      const answer = window.confirm(this.$t('orderDetail.alert.unpaid'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
