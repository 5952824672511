//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import inputHelper from '../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "Notification",
  mixins: [ inputHelper ],
  props: {
    notifications: null
  },
  mounted() {
    this.FixBugTheme()
  },
  created() {

  },
  methods: {
    FixBugTheme
  }
}
