//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import levButton from "@/components/children/lev_components/LevButton.vue";

export default {
  name: "LevModal",
  components: {
    levButton
  },
  props: {
    title: {
      type: String,
      default: "Modal title",
    },
    confirmButton: {
      type: String,
      default: function () {
        return this.$t('general.button.confirm')
      }
    },
    confirmButtonVariant: {
      type: String,
      default: "primary"
    },
    cancelButton: {
      type: String,
      default: function () {
        return this.$t('general.button.cancel')
      },
    },
    isManualClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isButtonLoading: false
    }
  },
  methods: {
    onModalConfirm: function () {
      this.$emit('modalConfirm')
      if (this.isManualClose) {
        this.isButtonLoading = true
      } else {
        $(this.$refs.confirmModal).modal('hide')
      }
    },
    onModalCancel: function () {
      this.$emit('modalCancel')
      $(this.$refs.confirmModal).modal('hide')
    },
    hide: function () {
      if (this.isManualClose) {
        this.isButtonLoading = false
      }
      $(this.$refs.confirmModal).modal('hide')
    }
  },
}
