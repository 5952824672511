import domain from './components/domain'
import login from './components/login'
import home from './components/home'
import register from './components/register'
import vps from './components/vps'
import detail from './components/detail'
import general from './components/general'
import topup from './components/topup'
import order from './components/order'
import orderDetail from './components/orderDetail'
import orderNew from './components/orderNew'
import profile from './components/profile'
import snapshot from './components/snapshot'
import backup from './components/backup'
import log from './components/log'
import recoverPassword from './components/recoverPassword'

import levtable  from './components/levtable'
import dedicated from './components/dedicated'
import dedicatedDetail from "@/lang/components/dedicatedDetail";
import dashboard from "@/lang/components/dashboard";
import notification from '@/lang/components/notification'
import two_fa from "@/lang/components/two_fa";
import team from "@/lang/components/team"
import proxy from "@/lang/components/proxy"
import ticket from "@/lang/components/ticket";
import referrals from "@/lang/components/referrals";
import hosting from "@/lang/components/hosting";

const messages = {
  'en-US': {
    domain: domain.en,
    login: login.en,
    register: register.en,
    recoverPassword: recoverPassword.en,
    home: home.en,
    vps: vps.en,
    general: general.en,
    detail: detail.en,
    topup: topup.en,
    order: order.en,
    orderDetail: orderDetail.en,
    orderNew: orderNew.en,
    profile: profile.en,
    snapshot: snapshot.en,
    backup: backup.en,
    log: log.en,
    levtable: levtable.en,
    dedicated: dedicated.en,
    dedicatedDetail: dedicatedDetail.en,
    dashboard: dashboard.en,
    notification: notification.en,
    two_fa: two_fa.en,
    team: team.en,
    proxy: proxy.en,
    ticket: ticket.en,
    referrals: referrals.en,
    hosting: hosting.en
  },
  'vi-VN': {
    domain: domain.vi,
    login: login.vi,
    register: register.vi,
    recoverPassword: recoverPassword.vi,
    home: home.vi,
    vps: vps.vi,
    general: general.vi,
    detail: detail.vi,
    topup: topup.vi,
    order: order.vi,
    orderDetail: orderDetail.vi,
    orderNew: orderNew.vi,
    profile: profile.vi,
    snapshot: snapshot.vi,
    backup: backup.vi,
    log: log.vi,
    levtable: levtable.vi,
    dedicated: dedicated.vi,
    dedicatedDetail: dedicatedDetail.vi,
    dashboard: dashboard.vi,
    notification: notification.vi,
    two_fa: two_fa.vi,
    team: team.vi,
    proxy: proxy.vi,
    ticket: ticket.vi,
    referrals: referrals.vi,
    hosting: hosting.vi
  },
  'zh-CN': {
    domain: domain.zh,
    login: login.zh,
    register: register.zh,
    recoverPassword: recoverPassword.zh,
    home: home.zh,
    vps: vps.zh,
    general: general.zh,
    detail: detail.zh,
    topup: topup.zh,
    order: order.zh,
    orderDetail: orderDetail.zh,
    orderNew: orderNew.zh,
    profile: profile.zh,
    snapshot: snapshot.zh,
    backup: backup.zh,
    log: log.zh,
    levtable: levtable.zh,
    dedicated: dedicated.zh,
    dedicatedDetail: dedicatedDetail.zh,
    dashboard: dashboard.zh,
    two_fa: two_fa.zh,
    team: team.zh,
    proxy: proxy.zh,
    ticket: ticket.zh
  }
}

export default messages
