//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Confirm2Fa",
  data: function () {
    return {
      otp: {
        number1: '',
        number2: '',
        number3: '',
        number4: '',
        number5: '',
        number6: ''
      }
    }
  },
  methods: {
    digitValidate: function(ele){
     this.otp[ele.target.id] = ele.target.value.replace(/[^0-9]/g,'');
    },
    tabChange: function(val){
      try{
        let ele = document.querySelectorAll('.otp_code');
        if(ele[val-1].value != ''){
          ele[val].focus()
        }else if(ele[val-1].value == ''){
          ele[val-2].focus()
        }
      } catch (e) {}
    },
    confirmButtonClick: function () {
      let otp_code = `${this.otp['number1']}${this.otp['number2']}${this.otp['number3']}${this.otp['number4']}${this.otp['number5']}${this.otp['number6']}`
      if (otp_code === '') {
        otp_code = '000000'
      }
      this.otp = {
        number1: '',
        number2: '',
        number3: '',
        number4: '',
        number5: '',
        number6: ''
      }
      this.$emit('clicked-confirm-button', otp_code)
    }
  }
}
