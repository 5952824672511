import Vue from 'vue'
import App from './App.vue'


import config from './plugins/config'
import router from './routers'
import i18n from './lang/i18n'
import FlagIcon from 'vue-flag-icon'
import axios from 'axios'
import cookies from 'vue-cookies'
import toasted from 'vue-toasted'
import CountryFlag from 'vue-country-flag'
import constants from '../constants'

Vue.use(config)
Vue.use(FlagIcon)
Vue.use(cookies)
Vue.use(constants)
Vue.use(toasted, {
  position: 'top-right',
  duration: 5000,
  theme: 'bubble'
})
Vue.config.productionTip = false
Vue.component('country-flag', CountryFlag)

export let activedRoutes

let isUpgrading = false

router.beforeEach(function (to, from, next) {
  // // For upgrading
  if (isUpgrading) {
    if (to.path === '/upgrading') {
      next()
    } else {
      next('/upgrading')
    }
    return
  }

  activedRoutes = []
  to.matched.forEach((record) => { activedRoutes.push(record) })

  let baseDomain = window.location.hostname
  // let bPath = 'https://levnode.com/api2/user/'
  let bPath = 'https://' + baseDomain + '/api2/user/'
  if (baseDomain.match(/localhost/) || baseDomain.includes('192')) {
    bPath = 'http://localhost:8080/api2/user/'
    // bPath = 'https://my.lowendviet.com/api2/user/'
  } else if (baseDomain.match(/127/)) { //127.0.0.1
    bPath = 'http://' + baseDomain + ':8080/api2/user/'
  } else if (baseDomain.includes('betax')) {
    bPath = 'https://betaapi.levnode.com/api2/user/'
  }
  if (window.document.cookie.indexOf('username') > -1 && window.document.cookie.indexOf('sessionId') > -1) {
    let loginData = {
      'username': getCookieValue('username'),
      'sessionId': getCookieValue('sessionId')
    }
    if (window.document.cookie.indexOf('email') > -1) {
      loginData['email'] = getCookieValue('email')
    }
    axios.post(bPath + 'login', loginData)
        .then(function (response) {
          if (response.data.status === 'error') {
            window.document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;' /* Delete cookie */
            window.document.cookie = 'email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;' /* Delete cookie */
            window.document.cookie = 'sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;' /* Delete cookie */
            next('/login')
          } else {
            // Do not allow to login or register when already logged in. If requested path is login/register, go to home.
            if (to.path !== '/login' && to.path !== '/register') {
              next()
            } else {
              next('/home/notification')
            }
          }
        })
        .catch(function () {
          next('/login')
        })
  } else {
    // Do not need to authenticate
    let publicPathList = ['/login', '/fblogin', '/register', '/order/new/', '/forgotpw', '/resetpw']
    if (publicPathList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }

  if (window.document.cookie.indexOf('locale') !== -1) {
    i18n.locale = getCookieValue('locale')
  }
})

function getCookieValue (a) {
  let b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)')
  return decodeURIComponent(b ? b.pop() : '')
}

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
