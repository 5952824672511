//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "404",
  methods: {
    gotoHome: function () {
      this.$router.push({name: 'dashboard'})
    }
  }
}
