export default {
  en: {
    toast: {
      errMetaData: 'Error when loading metadata for domain. Domain signature is not visible!'
    }
  },
  vi: {
    toast: {
      errMetaData: 'Không thể tải metadata cho thương hiệu!!'
    }
  },
  zh: {
    toast: {
      errMetaData: '加载域的元数据时出错。域签名不可见！!!'
    }
  }
}
