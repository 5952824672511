//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HostingPlans",
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      columns: [
        {
          key: 'name',
          label: this.$t('hosting.order.hostingPlanName'),
          formatter: function (item) {
            return `<span class="text-md text-uppercase-none">${item['name']}</span>`
          }
        },
        {
          key: 'disk',
          label: this.$t('hosting.order.disk'),
          formatter: function (item) {
            return `<span class="text-md">${item['disk']}</span><span class="text-sm">GB</span> <span class="text-md">${item['disk_type']}</span>`
          }
        },
        {
          key: 'number_addon_domain',
          label: this.$t('hosting.order.numberOfWebsites'),
        },
        {
          key: 'number_email',
          label: this.$t('hosting.order.numberOfemails'),
        },
        {
          key: 'price',
          label: this.$t('hosting.order.prices'),
          formatter: function (item) {
            return `<span class="text-md text-success">$</span><span class="text-2xl text-success">${item['price']}</span>`
          }
        },
        {
          key: 'description',
          label: this.$t('hosting.order.description'),
          formatter: function (item) {
            return `<span class="text-xs text-justify font-weight-normal text-uppercase-none text-success">${item['description']}</span>`
          }
        }
      ]
    }
  }
}
