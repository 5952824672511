//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @todo: This screen and VPS screen share a lot of function/code. Consider to write children component
import async from 'async'

import confirmModal from '@/components/children/ConfirmModal'
import confirm2Fa from "@/components/children/Confirm2Fa";

import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import confirmModalBuilder from '@/mixins/ConfirmModalBuilder'

import RDPGenerator from '@/mixins/utilities/RDPGenerator'
import {FixBugTheme} from "@/mixins/MaterialTheme";

import levModal from '@/components/children/lev_components/LevModal'
import NewConfirmModal from "@/components/children/NewConfirmModal.vue";

export default {
  name: 'VPSDetail',
  components: {
    NewConfirmModal,
    confirmModal,
    confirm2Fa,
    levModal
  },
  mixins: [dataRequester, inputHelper, confirmModalBuilder, RDPGenerator],
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
    languageSelected: String
  },
  data: function () {
    return {
      otpConfirmAction: 'none',
      action: 'none',
      VMObject: {},
      VMAddonObject: {
        activeAddonObjectList: []
      },
      rgcObject: {
        serverList: null,
        vmObject: null
      },
      VMOperStatus: '<span id="btn-status" class="badge badge-pill bg-gradient-primary"><span>' + this.$t('detail.table.tdDataChecking') + '</span></span>',
      OSTypeIcon: '',
      imageValues: {
        imageListCache: [],
        selectedImageId: 0,
        snapshotListCache: [],
        selectedSnapshotId: 0,
        currentImageSelection: {},
        currentSnapshotSelection: {}
      },
      teamMembers: [],
      cpuOptions: [
        {
          key: 'normal',
          value: this.$t('detail.label.cpuOptimizationNormal')
        },
        {
          key: 'virtualization',
          value: this.$t('detail.label.cpuOptimizationForEmulator')
        }
      ],
      cpuOptimization: null
    }
  },
  computed: {
    isTeamMember: function () {
      return this.VMObject['co_owner_id'] === this.currentUser['userID']
    },
    VMPaymentStatus () {
      switch(this.VMObject['payment_status']) {
        case 'ok':
          if (this.VMObject['end_date'] <= this.getCurrentTime()) {
            return '<span class="badge badge-warning"><span>' + this.$t('dedicatedDetail.table.tdDataOverdue') + '</span></span>'
          } else {
            return '<span class="badge badge-success"><span>' + this.$t('dedicatedDetail.table.tdDataOK') + '</span></span>'
          }
        case 'cancel':
          return '<span class="badge badge-dark"><span>' + this.$t('dedicatedDetail.table.tdDataCancel') + '</span></span>'
        case 'suspend':
          return '<span class="badge badge-danger"><span>' + this.$t('dedicatedDetail.table.tdDataSuspend') + '</span></span>'
        default:
          return '<span class="badge badge-info"><span>' + this.$t('dedicatedDetail.table.tdDataUnknown') + '</span></span>'
      }
    }
  },
  updated() {
    this.FixBugTheme()
  },
  created: function () {
    let self = this

    this.submitObjectList(this.$config.apiPath.vpsDetail, {id: this.$route.params.VMID}, 'vm', undefined, function (result) {
      self.VMObject = result.data.VM
      self.cpuOptimization = self.VMObject['cpu_optimization']

      self.submitObjectList(self.$config.apiPath.vpsAddon, {
        serviceTypeID: result.data.VM.service_type_id,
        serviceID: result.data.VM.id
      }, 'addon', undefined, function (result) {
        self.VMAddonObject = result.data
      })

      // Display OS icon
      if (self.VMObject['os_type'] === 'Windows') {
        self.OSTypeIcon = 'text-info fa fa-windows'
      } else if (self.VMObject['os_type'] === 'Linux') {
        self.OSTypeIcon = 'text-warning fa fa-linux'
      }
    })

    this.submitObjectList(this.$config.apiPath.vpsStatus, {id: this.$route.params.VMID}, 'vm', undefined, function (result) {
      if (result.data.VMStatus === 'Running') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
      } else if (result.data.VMStatus === 'Stopped') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataStopped') + '</span></span>'
      } else if (result.data.VMStatus === 'Suspended') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-warning"><span>' + self.$t('detail.table.tdDataSuspend') + '</span></span>'
      } else {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-info"><span>' + result.data.VMStatus + '</span></span>'
      }
    })

    this.getDataFromAPI(this.$config.apiPath.listMemberOfTeam, function (result) {
      self.teamMembers = result.data['users'].filter(x => x['id'] !== self.currentUser['userID'])
    }, null, false)
  },
  methods: {
    FixBugTheme,
    finishAction: function () {
      this.action = 'none'
    },
    confirmVM: function (actionToConfirm) {
      let self = this
      if (this.action === 'none') {
        this.action = actionToConfirm
        this.initConfirmVMAction([this.VMObject], actionToConfirm, function () {
          if (self.action === 'stop' || self.action === 'restart') { // means stop or restart
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('detail.modal.bodyDatalossWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          } else if (self.action === 'renew') {
            self.confirmModalDisplayObject.bodyMsg += '<span class="text-danger text-left">' + self.$t('detail.modal.bodyRenewWarning', [self.$t('vps.action.' + self.action)]) + '</span>'
          }
        })
        self.showModal(true)
      } else {
        self.$toasted.error(this.$t('detail.toast.errPendingTask'))
      }
    },
    onConfirmButton: function (otp_code) {
      let self = this
      if (this.action === 'start') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataStarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsStart, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.action = 'none'
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
        })
      } else if (this.action === 'shutdown') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataShuttingDown') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsShutdown, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'stop') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataStopping') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsStop, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataStopped') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'restart') {
        self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataRestarting') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsRestart, {id: this.VMObject['id']}, 'vm', undefined, function (result) {
          self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
          self.action = 'none'
          self.$toasted.success(result.msg)
        }, function () {
          self.VMOperStatus = '<span type="button" id="btn-status" class="badge badge-pill bg-gradient-dark"><span>' + self.$t('detail.table.tdDataUnknown') + '</span></span>'
          self.action = 'none'
        })
      } else if (this.action === 'editRemark') {
        let self = this
        let note = $('#editRemark').val()
        this.submitObjectList(this.$config.apiPath.vpsNote, {id: self.VMObject['id']}, 'vm', {note: note}, function (result) {
          self.$toasted.success(result.msg)
          self.action = 'none'
          self.VMObject['user_remark'] = note
        }, function () {
        })
      } else if (this.action === 'confirmSnapshot') {
        self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataTakingSnap') + '</span></span>'
        this.submitObjectList(this.$config.apiPath.vpsSnapshot, {
          id: this.VMObject['id'],
          note: $('#snapshotNote').val()
            }, 'vm', undefined,
            function (snapshotResult) {
              self.$toasted.success(snapshotResult.msg)
              self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataSuccess') + '</span></span>'
              self.action = 'none'
            },
            function (snapshotResult) {
              self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataRunning') + '</span></span>'
              self.action = 'none'
            })
      } else if (this.action === 'confirmTransfer') {
        this.submitObjectList(this.$config.apiPath.vpsTransfer, {id: self.VMObject['id']}, 'vm', {
          targetEmail: $('#transferEmailTarget').val(),
          otp_code: otp_code
            }, function (transferResult) {
              if (transferResult.data.is_enable_2fa) {
                $('#otp_modal').modal('show')
                self.otpConfirmAction = 'confirmTransfer'
              } else {
                self.$toasted.success(transferResult.msg)
                self.$router.push('/home/cloud-vps')
              }
            },
            null, true, function () {
              self.action = 'none'
              $('#otp_modal').modal('hide')
            })
      } else if (this.action === 'teamMemberShare') {
        let memberId = $('#selectTeamMembers').children('option:selected').val()
        this.submitObjectList(this.$config.apiPath.shareTeam,
            {
              id: self.VMObject['id']
            },
            'vm',
            {
              member_id: memberId
            }, function (transferResult) {
              window.location.reload()
              self.$toasted.success(transferResult.msg)
            }, null, true, function () {
              self.action = 'none'
            })
      } else if (this.action === 'confirmRenew') {
        let periodRenew = $('#periodRenew').val()
        this.submitObjectList(this.$config.apiPath.vpsRenew,
            {id: this.VMObject.id}, 'vm',
            {period: periodRenew}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          window.open('/#/home/order/detail/' + renewResult.data.id, '_blank')
        })
      } else if (this.action === 'confirmChangeVps') {
        this.submitObjectList(this.$config.apiPath.vpsChange,
            {id: this.VMObject.id}, 'vm',
            undefined, function (result) {
              self.$toasted.success(result.msg)
              self.action = 'none'
              window.open('/#/home/order/detail/' + result.data.id, '_blank')
            })
      } else if (this.action === 'confirmAutoRenew') {
        this.submitObjectList(this.$config.apiPath.vpsAutoRenew, {id: this.VMObject.id}, 'vm', {autoRenew: (self.VMObject['auto_renew'] * -1) + 1}, function (renewResult) {
          self.$toasted.success(renewResult.msg)
          self.action = 'none'
          self.VMObject['auto_renew'] = (self.VMObject['auto_renew'] * -1) + 1
        })
      } else if (this.action === 'confirmCancel') {
        let cancelMode = $('#selectCancelMode').children('option:selected').val()
        this.submitObjectList(this.$config.apiPath.vpsCancel, {id: this.VMObject.id}, 'vm', { cancelMode: cancelMode }, function (cancelResult) {
          self.$toasted.success(cancelResult.msg)
          self.action = 'none'
          self.VMObject['payment_status'] = 'cancel'
          if (cancelMode === 'immediate') {
            self.$router.push('/home/cloud-vps')
          }
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'showAddonDetail') {
        self.action = 'none'
      } else if (this.action === 'confirmEnableBackup') {
        let backupPeriod = {backupPeriod: $('#selectBackupPeriod').children('option:selected').val()}
        if (backupPeriod.backupPeriod >= 7) {
          this.submitObjectList(this.$config.apiPath.backupEnable, {id: this.VMObject.id}, 'vm', backupPeriod, function (enableResult) {
            self.$toasted.success(enableResult.msg)
            self.VMObject['backup_id'] = -1
          })
        } else {
          window.open('https://www.messenger.com/t/LowendVietServer', '_blank')
        }
        self.action = 'none'
      } else if (this.action === 'confirmDisableBackup') {
        this.submitObjectList(this.$config.apiPath.backupDelete, {id: this.VMObject['backup_id']},
            'backup', undefined, function (deleteResult) {
              self.$toasted.success(deleteResult.msg)
              self.VMObject['backup_id'] = null
            })
        self.action = 'none'
      } else if (this.action === 'confirmCreateRGC') {
        let rgcServerId = $('#selectRGCServer').children('option:selected').val()
        let portRGC = $('#rgcPortCreating')[0].value
        self.$toasted.info(self.$t('detail.toast.rgcCreating', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['os_type'])]))
        this.submitObjectList(this.$config.apiPath.rgcCreate, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id'],
          RGCServerID: rgcServerId,
          remotePort: portRGC
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = rowDataObject.data['rgc_server_name']
          self.VMObject['rgc_forwarded_port'] = rowDataObject.data['rgc_forwarded_port']
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      } else if (this.action === 'confirmDeleteRGC') {
        self.$toasted.info(self.$t('detail.toast.rgcDelete', [self.getIPPortFormat(self.rgcObject.vmObject['ip'], self.rgcObject.vmObject['port'], self.rgcObject.vmObject['os_type'])]))
        this.submitObjectList(this.$config.apiPath.rgcDelete, undefined, undefined, {
          serviceTypeID: self.rgcObject.vmObject['service_type_id'],
          serviceID: self.rgcObject.vmObject['id']
        },function (rowDataObject, status) {
          self.VMObject['rgc_server_name'] = null
          self.VMObject['rgc_forwarded_port'] = null
          self.$toasted.success(rowDataObject.msg)
        }, function () {
          self.action = 'none'
        })
      }
      self.finishModal()
    },
    onModalAdditionalButton: function () {
      let self = this
      if (this.action === 'teamMemberShare') {
        this.submitObjectList(this.$config.apiPath.cancelShare,
            {id: self.VMObject.id},
            'vm',
            null, function (transferResult) {
              self.$toasted.success(transferResult.msg)
              self.VMObject['co_owner_id'] = null
              self.VMObject['co_owner_full_name'] = null
              self.VMObject['co_owner_facebook_uid'] = null
            }, null, true, function () {
              self.action = 'none'
            })
      }
      self.finishModal()
    },
    editRemark: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleEditRemark', [this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'], this.VMObject['os_type'])]) + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyEditRemark')
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="editRemark" aria-describedby="detail" value="' + this.VMObject['user_remark'] + '">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonEditRemark')
      this.action = 'editRemark'
      this.showModal(true)
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('detail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
    showAddonDetail: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleAddonDetail') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left"><ol>'
      async.forEachOf(JSON.parse(this.VMObject['addon_detail']), function (addon, key, callback) {
        self.confirmModalDisplayObject.bodyMsg += '<strong>' + (key + 1) + ': </strong>'
        self.confirmModalDisplayObject.bodyMsg += addon['amount'] + ' x ' + addon['addon_name'] + ' ($' + addon['addon_price'] + self.$t('detail.table.tdDataEachMonth') + ') <br/>'
        callback()
      }, function () {
        self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
        self.confirmModalDisplayObject.confirmButtonTitle = 'OK'
        self.action = 'showAddonDetail'
        self.showModal(true)
      })
    },
    consoleVM: function () {
      window.open('./#/vm/console/' + this.VMObject.id, '_blank')
    },
    generateRDPFile: function () {
      let self = this
      this.generateSingleRDP(self.VMObject, function (xml) {
        self.action = 'none'
      })
    },
    rebuildVM: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this

        // Get the image list
        if (self.imageValues.imageListCache.length === 0) {
          this.getDataFromAPI(this.$config.apiPath.imageList, function (images) {
            self.imageValues.imageListCache = images.data.map(x => {
              return {id: x['id'], imageName: x['name'], description: x['description']}
            })
            if (images.data.length > 0) {
              self.imageValues.currentImageSelection = self.imageValues.imageListCache[0]
            }
            $('#modalRebuild').modal('show')
          })
        } else {
          $('#modalRebuild').modal('show')
        }
      }
    },
    takeSnap: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmSnap') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmSnapshot')
        this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><strong>'
        this.confirmModalDisplayObject.bodyMsg += this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'], this.VMObject['os_type']) + '</strong>'
        this.confirmModalDisplayObject.bodyMsg += '</div>'
        this.confirmModalDisplayObject.bodyMsg += `
            <div class="input-group input-group-outline my-3">
              <label class="form-label">${this.$t('vps.modal.labelTypeNote')}</label>
              <input id="snapshotNote" type="text" class="form-control">
            </div>
          `
        this.confirmModalDisplayObject.bodyMsg += '<div class="text-danger">' + this.$t('detail.modal.bodySnapshotWarning') + '</div>'
        this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonSnapshot')

        this.action = 'confirmSnapshot'
        this.showModal(true)
      }
    },
    restoreVM: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this

        // Get the image list
        if (self.imageValues.snapshotListCache.length === 0) {
          this.getDataFromAPI(this.$config.apiPath.snapList, function (snapshots) {
            self.imageValues.snapshotListCache = snapshots.data.map(x => {
              return {
                id: x['id'],
                snapshotName: x['snapshot_name'],
                imageName: x['image_name'],
                note: x['note']
              }
            })
            $('#restore-snapshot-modal').modal('show')
          })
        } else {
          $('#restore-snapshot-modal').modal('show')
        }
      }
    },
    changeOptimization: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this
        self.cpuOptimization = self.cpuOptions[0].key
        $('#change-optimization-modal').modal('show')
      }
    },
    revokeCancellation: function () {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
      } else {
        let self = this
        $('#revoke-cancellation-modal').modal('show')
      }
    },
    transfer: function () {
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmTransfer') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmTransfer')
      this.confirmModalDisplayObject.bodyMsg += '<div class="form-row mt-3 mb-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="col-form-label col-3">Email</label>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<input class="form-control col-9" id="transferEmailTarget">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      this.confirmModalDisplayObject.bodyMsg += '<strong>Transfer IP:  ' + this.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + this.$t('detail.modal.bodyTransferWarning') + '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonTransfer')

      this.action = 'confirmTransfer'
      this.showModal(true)
    },
    teamMemberShare: function () {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmShare') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectTeamMembers">' + self.$t('detail.modal.bodySelectTeamMember') + '</label><select class="form-select ps-3 mb-3" id="selectTeamMembers">'


        async.forEachOf(self.teamMembers, function (member, key, cb) {
          self.confirmModalDisplayObject.bodyMsg += '<option value="' + member.id + '">' + member.full_name + '</option>'
          cb()
        }, function () {
          self.confirmModalDisplayObject.bodyMsg += '</select></div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>Share IP:  ' + self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('detail.modal.bodyTeamMember') + '</div>'
          self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonShare')
          self.confirmModalDisplayObject.additionalButton = {
            isShow: true,
            title: self.$t('detail.modal.buttonDeleteShare'),
            class: 'btn btn-danger'
          }

          self.action = 'teamMemberShare'
          self.showModal(true)
        })

      }
    },
    change: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmChange') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmChange')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('detail.modal.bodyChangeWarning') + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonChange')

      self.action = 'confirmChangeVps'
      self.showModal(true)
    },
    renew: function () {
      let self = this
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmRenew') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="input-group input-group-outline my-3">'
      this.confirmModalDisplayObject.bodyMsg += '<label class="form-label">' + `${this.$t('detail.modal.period')} (${this.$t('detail.modal.month')})` + '</label>'
      this.confirmModalDisplayObject.bodyMsg += '<input id="periodRenew" class="form-control" type="number" min="1" max="24" value="1" onKeyDown="return false">'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.bodyMsg += this.$t('detail.modal.bodyConfirmRenew')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-secondary">' + self.$t('detail.modal.bodyRenewWarning') + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonRenew')

      self.action = 'confirmRenew'
      self.showModal(true)
    },
    setAutoRenew: function () {
      let self = this
      if (this.VMObject['auto_renew'] === 0) {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmSetAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmSetAutoRenew')
      } else {
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmCancelAutoRenew') + '</h4>'
        this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmCancelAutoRenew')
      }

      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</div>'
      if (this.VMObject['auto_renew'] === 0) {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonSetAutoRenew')
      } else {
        self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonCancelAutoRenew')
      }
      self.action = 'confirmAutoRenew'
      self.showModal(true)
    },
    cancel: function () {
      $('#cancel-modal').modal('show')
    },
    enableBackup: function () {
      let self = this

      // Build the modal
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmBackup') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = '<div class="form-group"><label for="selectBackupPeriod">' +
          this.$t('detail.modal.bodySelectBackupPeriod') + '</label><select class="form-select ps-3 mb-3" id="selectBackupPeriod">'
      this.confirmModalDisplayObject.bodyMsg += '<option value="7"> ' + this.$t('detail.modal.selectBackupPeriod7') + '</option>'
      this.confirmModalDisplayObject.bodyMsg += '<option value="1"> ' + this.$t('detail.modal.selectBackupPeriod1') + '</option>'
      this.confirmModalDisplayObject.bodyMsg += '</select></div>'
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left"><ol>'
      self.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port']) + '</strong>'
      self.confirmModalDisplayObject.bodyMsg += '</ol></div>'
      self.confirmModalDisplayObject.bodyMsg += '<div class="text-primary">' + self.$t('detail.modal.bodyBackupWarning',
          [this.VMObject['disk'], this.VMObject['disk'] * 0.03]) + '</div>'
      self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonEnableBackup')
      self.action = 'confirmEnableBackup'
      self.showModal(true)
    },
    disableBackup: function () {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
        return
      }

      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmDisableBackup') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmDisableBackup')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(this.VMObject['ip'], this.VMObject['port'], this.VMObject['os_type']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonDisableBackup')

      this.action = 'confirmDisableBackup'
      this.showModal(true)
    },
    createRGC: function (rowData) {
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('vps.toast.errPendingTask'))
      } else {
        let self = this
        self.rgcObject.vmObject = rowData
        let buildImageSelection = function () {

          self.confirmModalDisplayObject.bodyMsg = '<div class="alert modal-alert-info text-left">'
          self.confirmModalDisplayObject.bodyMsg += '<strong>IP. ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['os_type']) + '</strong>'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<strong>Remote Port</strong>'
          self.confirmModalDisplayObject.bodyMsg += '<span class="smaller d-block">' + self.$t('vps.modal.bodyRGCCreateWarning') + '</span>'
          self.confirmModalDisplayObject.bodyMsg += '<div class="input-group input-group-outline mb-3">'
          self.confirmModalDisplayObject.bodyMsg += '<input type="text" class="form-control" id="rgcPortCreating" aria-describedby="detail" value="' + rowData['port'] + '">'
          self.confirmModalDisplayObject.bodyMsg += '</div>'

          self.confirmModalDisplayObject.bodyMsg += '<div class="form-group"><label for="selectImageRebuild">' + self.$t('detail.modal.bodySelectRGC') + '</label><select class="form-select ps-3 mb-3" id="selectRGCServer">'

          async.forEachOf(self.rgcObject.serverList, function (item, key, cb) {
            self.confirmModalDisplayObject.bodyMsg += '<option value="' + item['id'] + '">' + item['rgc_server_name'] + ' (' + item['location'] + ')</option>'
            cb()
          }, function () {
            self.confirmModalDisplayObject.bodyMsg += '</select></div>'
            self.confirmModalDisplayObject.confirmButtonTitle = self.$t('detail.modal.buttonCreate')

            self.action = 'confirmCreateRGC'
            self.showModal(true)
          })
        }
        // Build the modal
        this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmCreateRGC') + '</h4>'

        // Get the image list
        if (self.rgcObject.serverList === null) {
          this.getDataFromAPI(this.$config.apiPath.rgcServerAvailable, function (result) {
            self.rgcObject.serverList = result.data['RGCServerObjectList']
            buildImageSelection()
          })
        } else {
          buildImageSelection()
        }
      }
    },
    deleteRGC: function (rowData) {
      let self = this
      if (this.action !== 'none') {
        this.$toasted.error(this.$t('detail.toast.errPendingTask'))
        return
      }
      self.rgcObject.vmObject = rowData
      this.confirmModalDisplayObject.title = '<h4>' + this.$t('detail.modal.titleConfirmDeleteRGC') + '</h4>'
      this.confirmModalDisplayObject.bodyMsg = this.$t('detail.modal.bodyConfirmDeleteRGC')
      this.confirmModalDisplayObject.bodyMsg += '<div class="alert modal-alert-info text-left">'
      this.confirmModalDisplayObject.bodyMsg += '<strong>IP: ' + self.getIPPortFormat(rowData['ip'], rowData['port'], rowData['os_type']) + '</strong>'
      this.confirmModalDisplayObject.bodyMsg += '</div>'
      this.confirmModalDisplayObject.confirmButtonTitle = this.$t('detail.modal.buttonDelete')

      this.action = 'confirmDeleteRGC'
      this.showModal(true)
    },
    confirmOTP: function (otp_code) {
      this.action = this.otpConfirmAction
      this.onConfirmButton(otp_code)
      this.otpConfirmAction = 'none'
      $('#otp_modal').modal('hide')
    },
    imageSelectChange() {
      let imageId = document.getElementById("selectImageRebuild").selectedOptions[0].value
      this.imageValues.currentImageSelection = this.imageValues.imageListCache.find(x => x.id == imageId)
    },
    snapshotSelectChange() {
      let snapshotId = document.getElementById("selectSnapshotRestore").selectedOptions[0].value
      this.imageValues.currentSnapshotSelection = this.imageValues.snapshotListCache.find(x => x.id == snapshotId)
    },
    cpuOptimizationChange() {
      this.cpuOptimization = document.getElementById("selectCpuOptimization").selectedOptions[0].value
    },
    confirmRebuild() {
      let self = this

      self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataReinstalling') + '</span></span>'
      let reinstallImageID = {reinstallImageID: document.getElementById("selectImageRebuild").selectedOptions[0].value}
      this.submitObjectList(this.$config.apiPath.vpsRebuild, {id: self.VMObject['id']}, 'vm', reinstallImageID, function (rebuildResult) {
            self.$toasted.success(self.$t('detail.toast.okRebuildRequestSent'))
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataSuccess') + '</span></span>'
            self.action = 'none'
          },
          function (rebuildResult) {
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataError') + '</span></span>'
            self.$toasted.error(self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + ' : ' + rebuildResult.data[0]['detail'])
            self.action = 'none'
          })
    },
    confirmRestore() {
      let self = this
      self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-secondary"><span>' + self.$t('detail.table.tdDataRestoring') + '</span></span>'
      let snapshotID = {snapshotID: document.getElementById("selectSnapshotRestore").selectedOptions[0].value}
      self.$toasted.success(self.$t('detail.toast.okRestoreRequestSent'))
      this.submitObjectList(this.$config.apiPath.vpsRestore, {id: self.VMObject['id']}, 'vm', snapshotID, function (restoreResult) {
            self.$toasted.success(restoreResult.msg)
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-success"><span>' + self.$t('detail.table.tdDataSuccess') + '</span></span>'
            self.action = 'none'
          },
          function (restoreResult) {
            self.VMOperStatus = '<span id="btn-status" class="badge badge-pill bg-gradient-danger"><span>' + self.$t('detail.table.tdDataError') + '</span></span>'
            self.$toasted.error(self.getIPPortFormat(self.VMObject['ip'], self.VMObject['port']) + ' : ' + restoreResult.data[0]['detail'])
            self.action = 'none'
          })
    },
    confirmChangeCpuOptimization() {
      let self = this
      if (self.cpuOptimization === self.VMObject['cpu_optimization']) {
        self.$toasted.error(self.$t('detail.toast.cpuOptimizationNoChange'))
      } else {
        this.submitObjectList(this.$config.apiPath.vpsChangeResourceLimit,
            {
              id: self.VMObject['id'],
              cpu_optimization: self.cpuOptimization
            },
            'vm',
            undefined,
            function (restoreResult) {
              self.$toasted.success(restoreResult.msg)
              self.VMObject['cpu_optimization'] = self.cpuOptimization
            }, undefined, true)
      }
    },
    confirmRevokeCancellation() {
      let self = this
      this.submitObjectList(this.$config.apiPath.vpsRevokeCancellation, {id: self.VMObject['id']}, 'vm', undefined, function (revokeResult) {
        self.$toasted.success(revokeResult.msg)
        self.VMObject['payment_status'] = 'ok'
      })
    },
    confirmCancel: function () {
      $('#cancel-modal').modal('hide')
      let self = this
      let cancelMode = $('input[name=rbCancallation]:checked').val()
      this.submitObjectList(this.$config.apiPath.vpsCancel,
          {id: self.VMObject['id']}, 'vm',
          {cancelMode: cancelMode},
          function (revokeResult) {
        self.$toasted.success(revokeResult.msg)
        self.VMObject['payment_status'] = 'cancel'
      })
      if (cancelMode === 'immediate') {
        self.$router.push('/home/cloud-vps')
      }
    },
  }
}
