import Referral from "@/components/Referral.vue";

export default {
    components: {Referral},
    en: {
        breadcrumb: 'Referrals',
        table: {
            header: {
                email: 'Email',
                fullName: 'Full name',
                reward: 'Reward',
                status: 'Status',
                createdAt: 'Joined at',
                updatedAt: 'Latest update'
            },
            content: {
                label: {
                    status_none: 'No purchases',
                    status_pendingReward: 'Pending mature',
                    status_rejectedReward: 'Rejected reward',
                    status_receivedReward: 'Available reward',
                    status_pendingWithdraw: 'Pending payment',
                    status_withdrawn: 'Paid',
                    status_rejectedWithdraw: 'Rejected withdrawal',
                }
            }
        },
        label: {
            availableReward: 'Available reward',
            rewardProcessing: 'Pending mature',
            rewardTotal: 'Total reward expected',
            rewardWithdraw: 'Pending payment',
            guideLabel: 'Get paid easily with <strong>{0}</strong> in just 3 steps:',
            refInvite: 'Share your <b>{0}</b> referral link',
            refRegister: 'Guide them to register and order at <b>{0}</b>',
            refOrder: 'Get money from <b>{0}</b> after 30 days of successful referring.',
            refUrl: 'Your referral link',
            refGuide: 'Copy this referral link and send to your friends'
        },
        button: {
            copy: 'Copy',
            withdraw: 'Withdraw'
        }
    },
    vi: {
        breadcrumb: 'Người giới thiệu',
        table: {
            header: {
                email: 'Email',
                fullName: 'Họ tên',
                reward: 'Hoa hồng',
                status: 'Trạng thái',
                createdAt: 'Ngày tham gia',
                updatedAt: 'Cập nhật lần cuối'
            },
            content: {
                label: {
                    status_none: 'Chưa đặt hàng',
                    status_pendingReward: 'Chưa đủ 30 ngày',
                    status_rejectedReward: 'Hoa hồng bị từ chối',
                    status_receivedReward: 'Hoa hồng có thể rút',
                    status_pendingWithdraw: 'Chờ nhận tiền',
                    status_withdrawn: 'Đã nhận tiền',
                    status_rejectedWithdraw: 'Từ chối rút tiền',
                }
            }
        },
        label: {
            availableReward: 'Hoa hồng chờ rút',
            rewardProcessing: 'Hoa hồng chưa đủ 30 ngày',
            rewardTotal: 'Tổng thu nhập dự kiến',
            rewardWithdraw: 'Lệnh rút tiền đang xử lí',
            guideLabel: 'Dễ dàng kiếm tiền cùng <strong>{0}</strong> với 3 bước:',
            refInvite: 'Chia sẻ đường dẫn đăng ký <b>{0}</b> cho bạn bè',
            refRegister: 'Bạn bè của quý khách đặt hàng sản phẩm tại <b>{0}</b>',
            refOrder: 'Nhận tiền từ <b>{0}</b> về số dư tài khoản sau 30 ngày',
            refUrl: 'Đường dẫn giới thiệu của bạn',
            refGuide: 'Sao chép đường dẫn giới thiệu và gửi cho bạn bè của bạn'
        },
        button: {
            copy: 'Sao chép',
            withdraw: 'Rút tiền'
        }
    },
    zh: {}
}
