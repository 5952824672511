export default {
    en: {
        breadcrumb: 'Ticket list',
        label: {
            showAll: 'Show all',
        },
        button: {
            addNew: 'Create new ticket',
            ticketReply: 'Reply ticket',
            closeTicket: 'Close ticket',
            reopenTicket: 'Reopen ticket',
            send: 'Send'
        },
        table: {
            header: {
                title: 'Title',
                status: 'Status',
                conversationCount: 'Number of conversations',
                createdAt: 'Created on',
                lastMessage: 'Last message',
                action: 'Action'
            },
            button: {
                view: 'View',
            }
        },
        tooltip: {

        },
        modal: {
            title: {
                addNewTicket: '<strong class="text-uppercase">Add new ticket</strong>',
                closeTicket: '<strong class="text-uppercase">Close ticket</strong>',
                reopenTicket: '<strong class="text-uppercase">Reopen ticket</strong>',
            },
            label: {
                title: 'Ticket title',
            },
            content: {
                closeTicket: `<i class="fas fa-window-close me-1 text-danger"></i><span class="btn-inner--text">Do you really want to close this ticket?</span>`,
                reopenTicket: `<i class="fas fa-lock-open me-1 text-success"></i><span class="btn-inner--text">Do you really want to re-open this ticket?</span>`,
            },
            button: {
                confirm: 'Confirm',
            }
        }
    },
    vi: {
        breadcrumb: 'Danh sách ticket',
        label: {
            showAll: 'Hiển thị tất cả',
        },
        button: {
            addNew: 'Tạo ticket mới',
            ticketReply: 'Trả lời',
            closeTicket: 'Đóng ticket',
            reopenTicket: 'Mở lại ticket',
            send: 'Gửi trả lời '
        },
        table: {
            header: {
                title: 'Tiêu đề',
                status: 'Trạng thái',
                conversationCount: 'Số lượng cuộc trò chuyện',
                createdAt: 'Ngày tạo',
                lastMessage: 'Tin nhắn cuối',
                action: 'Hành động'
            },
            button: {
                view: 'Xem',
            }
        },
        tooltip: {

        },
        modal: {
            title: {
                addNewTicket: '<strong class="text-uppercase">Tạo ticket mới</strong>',
                closeTicket: '<strong class="text-uppercase">Đóng ticket</strong>',
                reopenTicket: '<strong class="text-uppercase">Mở lại ticket</strong>',
            },
            label: {
                title: 'Tiêu đề ticket',
            },
            content: {
                closeTicket: `<i class="fas fa-window-close me-1 text-danger"></i><span class="btn-inner--text">Bạn có chắc chắn muốn đóng ticket này?</span>`,
                reopenTicket: `<i class="fas fa-lock-open me-1 text-success"></i><span class="btn-inner--text">Bạn có chắc chắn muốn mở lại ticket này?</span>`,
            },
            button: {
                confirm: 'Xác nhận',
            }
        }
    }
}
