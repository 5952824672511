export default {
  en: {
    label: {
      username: 'Username/Email/Phone',
      password: 'Password',
      remember: 'Remember password',
      otp_title: 'Authenticator Code'
    },
    placeholder: {
      username: 'Username or Email',
      password: 'Password'
    },
    button: {
      login: 'Login',
      register: 'Register',
      forgotpw: 'Forgot password?',
      verify: 'Confirm'
    }
  },
  vi: {
    label: {
      username: 'Tên đăng nhập/Email/SĐT',
      password: 'Mật khẩu',
      remember: 'Lưu đăng nhập',
      otp_title: 'Mã xác thực OTP'
    },
    placeholder: {
      username: 'Tên đăng nhập/Email',
      password: 'Mật khẩu'
    },
    button: {
      login: 'Đăng nhập',
      register: 'Đăng ký',
      forgotpw: 'Lấy lại mật khẩu',
      verify: 'Xác Nhận'
    }
  },
  zh: {
    label: {
      username: '名称/邮箱/电话号码',
      password: '密码',
      remember: '记住密码'
    },
    placeholder: {
      username: '名称/邮箱',
      password: '密码'
    },
    button: {
      login: '登录',
      register: '注册',
      forgotpw: '忘记密码'
    }
  }
}
