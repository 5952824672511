//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import dataRequester from '../../mixins/utilities/DataRequester'
import inputHelper from '../../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "Register",
  mixins: [ inputHelper, dataRequester ],
  props: {
    currentUser: {}
  },
  data: function () {
    return {
      user: '',
      requestPWEmailResult: {
        status: '',
        cssTextStatus: '',
        msg: ''
      },
      metaData: {
        logoPath: '',
        siteName: '',
        footNote: '',
        shortFootNote: '',
        availableLang: ''
      }
    }
  },
  mounted: function () {
    this.FixBugTheme()
  },
  created: function () {
    let self = this

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }

    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.shortFootNote = metaData.domainObjectList['shortfootnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
    }, function () {
      this.$router.push('https://levcs.com')
    })
  },
  methods: {
    FixBugTheme,
    login: function () {
      this.$router.push('/login')
    },
    submit: function () {
      let self = this
      this.submitObjectList(this.$config.apiPath.requestPWEmail, this.user, 'user', undefined, function (result) {
        self.requestPWEmailResult.cssTextStatus = 'text-success'
        self.requestPWEmailResult.status = result.status
        self.requestPWEmailResult.msg = result.msg
        self.$toasted.success(result.msg)
      }, function (result) {
        self.requestPWEmailResult.cssTextStatus = 'text-danger'
        self.requestPWEmailResult.status = result.status
        self.requestPWEmailResult.msg = result.msg
      })
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    }
  }
}
