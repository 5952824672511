//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import inputHelper from "@/mixins/utilities/InputHelper";
import dataRequester from '../mixins/utilities/DataRequester'
import dataObjectListMixin from '@/mixins/DataObjectList'

import LevTable from "@/components/children/LevTable";

export default {
  name: 'Referral',
  components: {
    LevTable
  },
  mixins: [inputHelper, dataRequester, dataObjectListMixin],
  props: {
    currentUser: {
      default: function () { return {} },
      type: Object
    },
    metaData: {
      default: function () { return {} },
      type: Object
    }
  },
  data() {
    let self = this
    return {
      availableReward: 0,
      pendingReward: 0,
      totalReward: 0,
      pendingWithdrawReward: 0,

      tableColumns: [
        {
          name: 'created_at',
          title: self.$t('referrals.table.header.createdAt'),
          formatter(cellData) {
            return `<span>${self.convertISODate(cellData)}</span>`
          }
        },
        {
          name: 'amount_reward',
          title: self.$t('referrals.table.header.reward'),
          formatter(cellData) {
            if (cellData > 0) {
              return `<strong><span class="text-success">$${cellData}</span></strong>`
            } else {
              return cellData
            }
          }
        },
        {
          name: 'updated_at',
          title: self.$t('referrals.table.header.updatedAt'),
          formatter(cellData) {
            return `<span>${self.convertISODate(cellData)}</span>`
          }
        },
        // {
        //   name: 'updated_at',
        //   formatter(cellData) {
        //     return `<span>${self.convertISODate(cellData)}</span>`
        //   }
        // }
      ],
      extendedFields: [
        {
          name: 'email',
          title: self.$t('referrals.table.header.email'),
          order: 1,
          formatter(rowData) {
            return rowData['email']
          }
        },
        {
          name: 'status',
          title: self.$t('referrals.table.header.status'),
          order: 4,
          formatter(rowData) {
            if (rowData['status'] === 'immature') {
              return `<i class="fas fa-info-circle text-info"></i> ${self.$t('referrals.table.content.label.status_none')}`
            } else if (rowData['status'] === 'pending reward') {
              return `<i class="far fa-hourglass text-warning"></i> ${self.$t('referrals.table.content.label.status_pendingReward')}`
            } else if (rowData['status'] === 'rejected reward') {
              if ([null, undefined, ''].includes(rowData['remark'])) {
                return `<i class="fas fa-ban text-danger"></i> ${self.$t('referrals.table.content.label.status_rejectedReward')}`
              } else {
                return `<i class="fas fa-ban text-danger"></i><i class="fas fa-comment-dots ms-1 text-info cursor-pointer" data-bs-toggle="tooltip" title="${rowData['remark']}"></i> ${self.$t('referrals.table.content.label.status_rejectedReward')}`
              }
            } else if (rowData['status'] === 'received reward') {
              return `<i class="fas fa-check text-success"></i> ${self.$t('referrals.table.content.label.status_receivedReward')}`
            } else if (rowData['status'] === 'pending withdraw') {
              return `<i class="far fa-hourglass text-warning"></i> ${self.$t('referrals.table.content.label.status_pendingWithdraw')}`
            } else if (rowData['status'] === 'withdrawn') {
              return `<i class="fas fa-check text-success"></i> ${self.$t('referrals.table.content.label.status_withdrawn')}`
            } else if (rowData['status'] === 'rejected withdraw') {
              if ([null, undefined, ''].includes(rowData['remark'])) {
                return `<i class="fas fa-ban text-danger"></i> ${self.$t('referrals.table.content.label.status_rejectedWithdraw')}`
              } else {
                return `<i class="fas fa-ban text-danger"></i><i class="fas fa-comment-dots ms-1 text-info cursor-pointer" data-bs-toggle="tooltip" title="${rowData['remark']}"></i> ${self.$t('referrals.table.content.label.status_rejectedWithdraw')}`
              }
            }
          }
        },
      ],
      searchableFields: ['full_name', 'status'],
    }
  },
  computed: {
    refUrl() {
      return `${window.location.origin}/#/register?r=${this.currentUser.userID}`
    }
  },
  created() {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.referralList, function (result) {
      self.dataObjectList = result.data['referralObjectList']
      self.dataObjectList.forEach((item, index) => {

        if(item['status'] !== 'rejected reward' && item['status'] !== 'rejected withdraw') {
          self.totalReward += item['amount_reward']
        }

        if (item['status'] === 'received reward') {
          self.availableReward += item['amount_reward']
        } else if (item['status'] === 'pending reward') {
          self.pendingReward += item['amount_reward']
        } else if (item['status'] === 'pending withdraw') {
          self.pendingWithdrawReward += item['amount_reward']
        }
      })
    })
  },
  methods: {
    onSelectedChanged(selectedRowID) {
      this.selectedObjectList = this.dataObjectList.filter(d => selectedRowID.includes(d['id']))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      let self = this
      switch (cellField.name) {
        default:
          break
      }
      updatePostTable()
    },
    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('detail.toast.okCopy', [s.trim()]))
      this.copyValueToClipboard(s)
    },
    withdraw: function () {
      let self = this
      this.getDataFromAPI(this.$config.apiPath.referralWithdraw, function (result) {
        self.$toasted.success(result.msg)
        self.action = 'none'
        window.open('/#/home/order/detail/' + result.data.id, '_parent')
      })
    }
  },
}
