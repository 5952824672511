import async from 'async'
import {json2xml} from 'xml-js'
import {saveAs} from 'file-saver'

export default {
  methods: {
    generateRDGFile: function (selectedVMList, callback) {
      let self = this
      let jsonResult = {
        declaration: {
          attributes: {
            version: '1.0',
            encoding: 'utf-8'
          }
        },
        elements: [
          {
            type: 'element',
            name: 'RDCMan',
            attributes: {
              schemaVersion: '1'
            },
            elements: [
              {
                type: 'element',
                name: 'version',
                elements: [
                  {
                    type: 'text',
                    text: '2.7'
                  }
                ]
              },
              {
                type: 'element',
                name: 'file',
                elements: [
                  {
                    type: 'element',
                    name: 'properties',
                    elements: [
                      {
                        type: 'element',
                        name: 'name',
                        elements: [
                          {
                            type: 'text',
                            text: 'LowEndViet VPS ' + self.getCurrentTime()
                          }
                        ]
                      },
                      {
                        type: 'element',
                        name: 'expanded',
                        elements: [
                          {
                            type: 'text',
                            text: 'True'
                          }
                        ]
                      },
                      {
                        type: 'element',
                        name: 'logonCredentials',
                        attributes:
                          {
                            inherit: 'FromParent'
                          }
                      },
                      {
                        type: 'element',
                        name: 'connectionSettings',
                        attributes:
                          {
                            inherit: 'FromParent'
                          }
                      },
                      {
                        type: 'element',
                        name: 'gatewaySettings',
                        attributes:
                          {
                            inherit: 'FromParent'
                          }
                      },
                      {
                        type: 'element',
                        name: 'remoteDesktop',
                        attributes:
                          {
                            inherit: 'FromParent'
                          }
                      },
                      {
                        type: 'element',
                        name: 'localResources',
                        attributes:
                          {
                            inherit: 'FromParent'
                          }
                      },
                      {
                        type: 'element',
                        name: 'securitySettings',
                        attributes:
                          {
                            inherit: 'FromParent'
                          }
                      },
                      {
                        type: 'element',
                        name: 'displaySettings',
                        attributes:
                          {
                            inherit: 'FromParent'
                          }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }

      async.forEachOf(selectedVMList, function (vm, key, cb) {
        let serverElement = {
          type: 'element',
          name: 'server',
          elements: [
            {
              type: 'element',
              name: 'name',
              elements: [
                {
                  type: 'text',
                  text: self.getIPPortFormat(vm['ip'], vm['port'])
                }
              ]
            },
            {
              type: 'element',
              name: 'displayName',
              elements: [
                {
                  type: 'text',
                  text: self.getIPPortFormat(vm['ip'], vm['port'])
                }
              ]
            },
            {
              type: 'element',
              name: 'comment',
              elements: [
                {
                  type: 'text',
                  text: 'Start: ' + self.convertISODate(vm['start_date']) + ', End: ' + self.convertISODate(vm['end_date'])
                }
              ]
            },
            {
              type: 'element',
              name: 'logonCredentials',
              attributes: {
                inherit: 'None'
              },
              elements: [
                {
                  type: 'element',
                  name: 'userName',
                  elements: [
                    {
                      type: 'text',
                      text: 'Administrator'
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'domain',
                  elements: [
                    {
                      type: 'text',
                      text: 'LowEndViet.com'
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'password',
                  attributes: {
                    storeAsClearText: 'True'
                  },
                  elements: [
                    {
                      type: 'text',
                      text: vm['login_password']
                    }
                  ]
                }
              ]
            },
            {
              type: 'element',
              name: 'connectionSettings',
              attributes:
                {
                  inherit: 'None'
                },
              elements: [
                {
                  type: 'element',
                  name: 'connectToConsole',
                  elements: [
                    {
                      type: 'text',
                      text: 'False'
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'startProgram'
                },
                {
                  type: 'element',
                  name: 'workingDir'
                },
                {
                  type: 'element',
                  name: 'port',
                  elements: [
                    {
                      type: 'text',
                      text: vm['port']
                    }
                  ]
                }
              ]
            },
            {
              type: 'element',
              name: 'gatewaySettings',
              attributes:
                {
                  inherit: 'FromParent'
                }
            },
            {
              type: 'element',
              name: 'remoteDesktop',
              attributes:
                {
                  inherit: 'FromParent'
                }
            },
            {
              type: 'element',
              name: 'localResources',
              attributes:
                {
                  inherit: 'FromParent'
                }
            },
            {
              type: 'element',
              name: 'securitySettings',
              attributes:
                {
                  inherit: 'FromParent'
                }
            },
            {
              type: 'element',
              name: 'displaySettings',
              attributes:
                {
                  inherit: 'FromParent'
                }
            }
          ]
        }
        jsonResult.elements[0].elements[1].elements.push(serverElement)
        cb()
      }, function () {
        let xml = json2xml(JSON.stringify(jsonResult), {compact: false, ignoreComment: true, spaces: 4})
        let blob = new Blob([xml], {type: 'text/plain;charset=utf-8'})
        saveAs(blob, 'LowEndViet_VPS_RDG_Profile-' + self.getCurrentTime() + '.rdg')
        self.$toasted.success(self.$t('vps.toast.okExportRDG', [selectedVMList.length]))
        callback()
      })
    },
    generateSingleRDP: function (VMObject, callback) {
      let stringResult = 'screen mode id:i:2\n' +
        'use multimon:i:0\n' +
        'desktopwidth:i:1600\n' +
        'desktopheight:i:900\n' +
        'session bpp:i:32\n' +
        'winposstr:s:0,3,0,0,800,600\n' +
        'compression:i:1\n' +
        'keyboardhook:i:2\n' +
        'audiocapturemode:i:0\n' +
        'videoplaybackmode:i:1\n' +
        'connection type:i:2\n' +
        'networkautodetect:i:1\n' +
        'bandwidthautodetect:i:1\n' +
        'displayconnectionbar:i:1\n' +
        'enableworkspacereconnect:i:0\n' +
        'disable wallpaper:i:1\n' +
        'allow font smoothing:i:0\n' +
        'allow desktop composition:i:0\n' +
        'disable full window drag:i:1\n' +
        'disable menu anims:i:1\n' +
        'disable themes:i:0\n' +
        'disable cursor setting:i:0\n' +
        'bitmapcachepersistenable:i:1\n' +
        'bitmapCacheSize:1:32000\n' +
        'audiomode:i:1\n' +
        'redirectprinters:i:1\n' +
        'redirectcomports:i:0\n' +
        'redirectsmartcards:i:1\n' +
        'redirectclipboard:i:1\n' +
        'redirectposdevices:i:0\n' +
        'autoreconnection enabled:i:1\n' +
        'authentication level:i:2\n' +
        'prompt for credentials:i:0\n' +
        'negotiate security layer:i:1\n' +
        'remoteapplicationmode:i:0\n' +
        'alternate shell:s:\n' +
        'shell working directory:s:\n' +
        'gatewayhostname:s:\n' +
        'gatewayusagemethod:i:4\n' +
        'gatewaycredentialssource:i:4\n' +
        'gatewayprofileusagemethod:i:0\n' +
        'promptcredentialonce:i:0\n' +
        'use redirection server name:i:0\n' +
        'rdgiskdcproxy:i:0\n' +
        'kdcproxyname:s:\n' +
        'drivestoredirect:s:\n' +
        'redirectdirectx:i:1\n' +
        'full address:s:' + this.getIPPortFormat(VMObject['ip'], VMObject['port']) + '\n' +
        'username:s:' + VMObject['login_user'] + '\n' +
        'password 51:b:01000000D08C9DDF0115D1118C7A00C04FC297EB01000000D93A1F303D2438469E8950C3FD128540040000000200000000001066000000010000200000004DF6FE738046D499456D9F7C516843B76A82D90FD8A8DCDBEBFF37734228F97A000000000E8000000002000020000000BF8842691E0486DF5B4B3ECAFDBB4082987EC3C349D01B6D282DAB610A4CA26E20000000D0B50889E3ABA19BDC70DB8EDA66A869792A52295886999EF6B2BF101BFF456040000000AC9F9DB4E91D53186AC5129970BD60794A49864C7A7C7BCFD28180E429740584A42F4CEC462D9FB2123C5DD5D3DCA3E1EA709F5062489075782770BE09F4447E'
      let blob = new Blob([stringResult], {type: 'text/plain;charset=utf-8'})
      saveAs(blob, this.getIPPortFormat(VMObject['ip'], VMObject['port']) + '.rdp')
      this.$toasted.success(this.$t('detail.toast.okExportRDP'))
      callback()
    }
  }
}
