//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "NewConfirmModal",
  props: {
    title: {
      type: String,
      default: "Modal Title",
    },
    confirmButton: {
      type: String,
      default: function () {
        return this.$t("vps.modal.buttonConfirm");
      },
    },
    closeButton: {
      type: String,
      default: function () {
        return this.$t("vps.modal.buttonCancel");
      },
    },
    additionalButton: {
      type: Object,
      is_visible: false,
      default: function () {
        return {
          is_visible: false,
          text: "Additional Button",
        }
      },
    },
  }
}
