//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ProxyPlans",
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    let self = this
    return {
      columns: [
        {
          key: 'name',
          label: self.$t('orderNew.table.h_name')
        },
        {
          key: 'cpu',
          label: self.$t('orderNew.table.h_traffic')
        },
        {
          key: 'ram',
          label: self.$t('orderNew.table.h_price')
        },
        {
          key: 'disk',
          label: self.$t('orderNew.table.h_note')
        },
      ]
    }
  }
}
