export default {
    install (Vue, options) {
        Vue.prototype.$constants = {
            DEDICATED_SERVICE_TYPE_ID : 1,
            LIVE_PLAN_SERVICE_TYPE_ID: 4,
            PROXY_SERVICE_TYPE_ID: 5,
            HOSTING_SERVICE_TYPE_ID: 6,
        }
    }
}
