import Vue from 'vue'
import Router from 'vue-router'

import login from '@/components/login/Login'
import register from '@/components/login/Register'
import forgotpw from "@/components/login/ForgotPassword"
import home from '@/components/Home'
import password from "@/components/login/Password"
import notification from "@/components/Notification"
import logList from "@/components/LogList"
import dashboard from "@/components/Dashboard"

import cloudVPS from "@/components/services/cloudvps/VPSList"
import snapshotVPS from "@/components/services/cloudvps/SnapshotVPS"
import backupVPS from "@/components/services/cloudvps/BackupVPS"
import VPSConsole from "@/components/Console"

import dedicatedList from "@/components/services/dedicated/DedicatedList"
import VPSDetail from '@/components/services/cloudvps/VPSDetail'
import DedicatedDetail from "@/components/services/dedicated/DedicatedDetail";
import profileDetail from "@/components/login/Profile"

import newOrder from '@/components/orders/NewOrder'
import history from '@/components/orders/History'
import topup from "@/components/orders/Topup"
import orderDetail from "@/components/orders/OrderDetail"
import notFound from '@/components/errors/404'
import upgrading from "@/components/errors/upgrading"
import resetPassword from "@/components/login/ResetPassword";
import two_fa from "@/components/security/TwoFactorAuthentication"
import teamManager from "@/components/teams/TeamManager";
import joinTeam from "@/components/teams/JoinTeam";
import proxyList from "@/components/services/proxy/List"
import ticket from "@/components/tickets/Ticket";
import ticketDetail from "@/components/tickets/TicketDetail";
import referral from "@/components/Referral";
import hosting from "@/components/services/hosting/List.vue";

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/', redirect: { name: 'dashboard' } },
        {
            path: '/login',
            name: 'login',
            component: login
        },
        {
            path: '/error/not-found',
            name: '404',
            component: notFound
        },
        {
            path: '/upgrading',
            name: 'upgrading',
            component: upgrading
        },
        {
            path: '/register',
            name: 'register',
            component: register
        },
        {
          path: '/forgotpw',
          name: 'forgotpw',
          component: forgotpw
        },
        {
            path: '/resetpw',
            name: 'resetpw',
            component: resetPassword
        },
        {
            path: '/vm/console/:VMID',
            name: 'vmConsole',
            component: VPSConsole
        },
        {
            path: '/home',
            name: 'Home',
            meta: {
                path: '/home',
                breadcrumb: 'Home'
            },
            component: home,
            redirect: '/home/dashboard',
            children: [
                {
                    path: 'cloud-vps',
                    meta: {
                        path: '/home/cloud-vps',
                        title: '<span class="fa fa-cloud"></span> <span id="breadcrumb">Your vps list</span>',
                        breadcrumb: 'Cloud VPS',
                        category: 'List',
                        name: 'VPS List',
                        i18n: 'vps.breadcrumb'
                    },
                    component: cloudVPS
                },
                {
                    path: 'cloud-vps/snapshot',
                    meta: {
                        path: '/home/cloud-vps',
                        title: '<span class="fas fa-camera"></span> <span id="breadcrumb">Your snapshot list</span>',
                        breadcrumb: 'Cloud VPS',
                        category: 'Snapshot',
                        name: 'VPS Snapshot',
                        i18n: 'snapshot.breadcrumb'
                    },
                    component: snapshotVPS
                },
                {
                    path: 'cloud-vps/backup',
                    meta: {
                        path: '/home/cloud-vps',
                        title: '<span class="fas fa-hdd"></span> <span id="breadcrumb">Your backup list</span>',
                        breadcrumb: 'Cloud VPS',
                        category: 'Backup',
                        name: 'VPS Backup',
                        i18n: 'backup.breadcrumb'
                    },
                    component: backupVPS
                },
                {
                    path: 'cloud-vps/detail/:VMID',
                    meta: {
                        path: '/home/cloud-vps',
                        title: '<span class="fas fa-desktop"></span> <span id="breadcrumb">Your VPS detail</span>',
                        breadcrumb: 'Cloud VPS',
                        category: 'VPS Detail',
                        name: 'VPS Detail',
                        i18n: 'detail.breadcrumb'
                    },
                    component: VPSDetail
                },
                {
                    path: 'dedicated-server',
                    meta: {
                        path: '/home/dedicated-server',
                        title: '<span class="fas fa-server"></span> <span id="breadcrumb">Your dedicated server list</span>',
                        breadcrumb: 'Dedicated Server',
                        category: 'List',
                        name: 'Dedicated Server List',
                        i18n: 'dedicated.breadcrumb'
                    },
                    component: dedicatedList
                },
                {
                    path: 'dedicated-server/detail/:VMID',
                    meta: {
                        path: '/home/dedicated-server',
                        title: '<span class="fas fa-desktop"></span> <span id="breadcrumb">Your dedicated server detail</span>',
                        breadcrumb: 'Dedicated Server',
                        category: 'Dedicated Server Detail',
                        name: 'Dedicated Server Detail',
                        i18n: 'dedicatedDetail.breadcrumb'
                    },
                    component: DedicatedDetail
                },
                {
                    path: 'hosting',
                    meta: {
                        path: '/home/hosting',
                        title: '<span class="fas fa-rocket"></span> <span id="breadcrumb">Your hosting list</span>',
                        breadcrumb: 'Hosting',
                        category: 'List',
                        name: 'Hosting List',
                        i18n: 'hosting.breadcrumb'
                    },
                    component: hosting
                },
                {
                    path: 'order',
                    meta: {
                        path: '/home/order',
                        title: '<span class="fas fa-cart-plus"></span> <span id="breadcrumb">New Order</span>',
                        breadcrumb: 'Order',
                        name: 'New Order',
                        i18n: 'orderNew.breadcrumb'
                    },
                    component: newOrder
                },
                {
                    path: 'order/history',
                    meta: {
                        path: '/home/order',
                        title: '<span class="fas fa-file-invoice-dollar"></span> <span id="breadcrumb">Order History</span>',
                        breadcrumb: 'Order',
                        category: 'History',
                        name: 'Order History',
                        i18n: 'order.breadcrumb'
                    },
                    component: history
                },
                {
                    path: 'order/topup',
                    meta: {
                        path: '/home/order',
                        title: '<span class="fas fa-wallet"></span> <span id="breadcrumb">Topup your account</span>',
                        breadcrumb: 'Order',
                        category: 'Topup',
                        name: 'Topup',
                        i18n: 'topup.breadcrumb'
                    },
                    component: topup
                },
                {
                    path: 'order/detail/:orderID',
                    meta: {
                        path: '/home/order',
                        title: '<span class="fas fa-file-invoice"></span> <span id="breadcrumb">Your order detail</span>',
                        breadcrumb: 'Order',
                        category: 'Order Detail',
                        name: 'Order Detail',
                        i18n: 'orderDetail.breadcrumb'
                    },
                    component: orderDetail
                },
                {
                    path: 'password',
                    name: 'password',
                    meta: {
                        title: '<span class="fa fa-key"></span> <span id="breadcrumb">Change Password</span>',
                        breadcrumb: 'Password',
                        path: '/home/password',
                        i18n: 'profile.pw_breadcrumb'
                    },
                    component: password
                },
                {
                    path: 'profile',
                    name: 'profile',
                    meta: {
                        title: '<span class="fas fa-id-card"></span> <span id="breadcrumb">Profile Detail</span>',
                        breadcrumb: 'Profile',
                        path: '/home/profile',
                        i18n: 'profile.breadcrumb'
                    },
                    component: profileDetail
                },
                {
                    path: 'notification',
                    name: 'notification',
                    meta: {
                        title: '<span class="fa fa-bell-o"></span> <span id="breadcrumb">Notification</span>',
                        breadcrumb: 'Notification',
                        path: '/home/notification',
                        i18n: 'notification.breadcrumb'
                    },
                    component: notification
                },
                {
                    path: 'log',
                    name: 'log',
                    meta: {
                        title: '<span class="fa fa-table"></span> <span id="breadcrumb">Activity Log</span>',
                        breadcrumb: 'Log',
                        path: '/home/log',
                        i18n: 'log.breadcrumb'
                    },
                    component: logList
                },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    meta: {
                        title: '<span class="fa fa-bar-chart-o"></span> <span id="breadcrumb">Dashboard</span>',
                        breadcrumb: 'Dashboard',
                        path: '/home/dashboard',
                        i18n: 'dashboard.breadcrumb'
                    },
                    component: dashboard
                },
                {
                    path: 'security/2fa',
                    name: 'two_fa',
                    meta: {
                        title: '<i class="fas fa-shield-alt"></i> <span id="breadcrumb">Security</span>',
                        breadcrumb: 'Security',
                        path: '/home/dashboard',
                        i18n: 'two_fa.breadcrumb'
                    },
                    component: two_fa
                },
                {
                    path: 'team-manager',
                    name: 'team-manager',
                    meta: {
                        title: '<i class="fas fa-users"></i> <span id="breadcrumb">Team Manager</span>',
                        breadcrumb: 'Team Manager',
                        path: '/home/dashboard',
                        i18n: 'team.breadcrumb'
                    },
                    component: teamManager
                },
                {
                    path: 'team/join',
                    name: 'team-join',
                    meta: {
                        title: '<i class="fas fa-users"></i> <span id="breadcrumb">Team Manager</span>',
                        breadcrumb: 'Team Manager',
                        path: '/home/dashboard',
                        i18n: 'team.breadcrumb'
                    },
                    component: joinTeam
                },
                {
                    name: 'proxy-list',
                    path: 'proxy',
                    meta: {
                        title: '<i class="fas fa-network-wired"></i> <span id="breadcrumb">Proxy List</span>',
                        breadcrumb: 'Proxy',
                        path: '/home/dashboard',
                        i18n: 'proxy.breadcrumb'
                    },
                    component: proxyList
                },
                {
                    name: 'ticket',
                    path: 'ticket',
                    meta: {
                        title: '<i class="fas fa-ticket-alt"></i> <span id="breadcrumb">Ticket List</span>',
                        breadcrumb: 'Tickets',
                        path: '/home/dashboard',
                        i18n: 'ticket.breadcrumb'
                    },
                    component: ticket
                },
                {
                    name: 'ticket-detail',
                    path: 'ticket/detail/:ticketID',
                    meta: {
                        title: '<i class="fas fa-ticket-alt"></i> <span id="breadcrumb">Ticket List</span>',
                        breadcrumb: 'Tickets',
                        path: '/home/ticket',
                        i18n: 'ticket.breadcrumb',
                        category: 'Ticket Detail',
                        name: 'Tickets'
                    },
                    component: ticketDetail,
                },
                {
                    name: 'referral',
                    path: 'referral',
                    meta: {
                        title: '<i class="fas fa-share-alt-square"></i> <span id="breadcrumb">Referral</span>',
                        breadcrumb: 'Referral',
                        path: '/home/dashboard',
                        i18n: 'referrals.breadcrumb',
                        name: 'Referral'
                    },
                    component: referral,
                }
            ]
        },
        { path: '*', redirect: { name: '404' } }
    ]
})
