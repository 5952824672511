//
//
//
//
//
//
//
//
//
//


import inputHelper from "@/mixins/utilities/InputHelper";

export default {
  name: "IconButton",
  mixins: [inputHelper],
  props: {
    tooltip: {
      type: String,
      default: "Copy",
      require: true
    },
    type: {
      type: String,
      default: undefined
    },
    copyContent: {
      type: String,
      default: ""
    },
    value: {
      type: [Boolean, Number, String],
      default: false
    },
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    icon: {
      type: String,
      default: "copy"
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  computed: {
    setting () {
      switch (this.type) {
        case 'copy':
          return {
            icon: 'fas fa-copy',
            color: 'primary'
          }
        case 'save':
          return {
            icon: 'fas fa-save',
            color: 'success'
          }
        case 'edit':
          return {
            icon: 'fas fa-edit',
            color: 'info'
          }
        case 'cancel':
          return {
            icon: 'fas fa-window-close',
            color: 'secondary'
          }
        default:
          return {
            icon: this.icon,
            color: this.color
          }
      }
    }
  },
  methods: {
    click () {
      this.$emit('click')

      switch (this.type) {
        case 'copy':
          this.copyText(this.copyContent)
          break
        case 'save':
          this.$emit('click', this.data)
          break
        default:
          this.$emit('input', !this.value)
          this.$emit('click', this.data)
          break
      }
    },

    copyText (content) {
      let self = this
      this.$toasted.info(self.$t('detail.toast.okCopy', [content.trim()]))
      this.copyValueToClipboard(content)
    }
  }
}
