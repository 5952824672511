//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import inputHelper from "@/mixins/utilities/InputHelper";

export default {
  name: "OsList",
  mixins: [inputHelper],
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    imageIdDefault: {
      type: Number,
      default: null
    },
    languageSelected: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {

    }
  },
  methods: {
    getImageDescription: function (jsonString) {
      if (this.isValidJSON(jsonString)) {
        try {
          return JSON.parse(jsonString)['description'][this.languageSelected.substring(0,2)]
        } catch (ex) {
          return null
        }
      } else {
        return null
      }
    }
  }
}
