//
//
//
//
//
//
//
//
//


import inputHelper from '@/mixins/utilities/InputHelper'

export default {
  name: 'App',
  mixins: [inputHelper],
  data: function () {
    return {
      currentUser: {
        promotionalBalance: 0,
        mainBalance: 0,
        balance: 0
      },

      notificationPushed: false
    }
  },
  created: function () {

  },
  methods: {
    login: function (user) {
      let self = this
      this.currentUser = user
      setTimeout(function () {
        if (self.currentUser.email === undefined || self.currentUser.phoneNo === undefined ||
            !self.checkValidEmail(self.currentUser.email) || !self.checkValidPhoneNo(self.currentUser.phoneNo)) {
          if(!self.notificationPushed) {
            self.$toasted.show('<i class="fa fa-exclamation-triangle"></i>' + self.$t('home.callToAction.updateEmailPhone'), {
              duration: 600000,
              action: [
                {
                  text: self.$t('home.callToAction.detail'),
                  onClick: (e, toastObject) => {
                    self.$router.push('/home/notification')
                  }
                },
                {
                  text: self.$t('home.callToAction.update'),
                  onClick: (e, toastObject) => {
                    self.$router.push('/home/profile')
                    toastObject.goAway(0)
                  }
                }
              ]
            })
            self.notificationPushed = true
          }
        }
      }, 2000)
    }
  }
}
