export default {
  en: {
    breadcrumb: 'Your order detail',
    cardHeader: {
      titleInvoice: 'Invoice ID ',
      titleStatus: 'Status',
      titleStatusUnpaid: 'Unpaid',
      titleStatusCredited: 'Credited',
      titleStatusProvisioned: 'Provisioned',
      titleStatusRenewed: 'Renewed',
      titleStatusInstalling: 'Installing',
      titleStatusPending: 'Pending',
      titleStatusCancelled: 'Cancelled',
      titleStatusFraud: 'Fraud',
      titlePaymentInfo: 'Payment information',
      titleStatusFinished: 'Finished',
      titleStatusPartially: 'Partially',
    },
    label: {
      selectPayment: 'Select payment method',
      topupAmount: 'Amount you want to topup (minimum is $1)',
      notiRenewed: 'Thank you for using our service.<br/>The services are already renewed.',
      notiProvisioned: 'Thank you for using our service.<br/>The services are already provisioned.',
      notiCredited: 'Thank you for using our service.<br/>Your credit balance is updated.',
      notiPending: 'Thank you for using our service.<br/>Your order is being checked! If you have paid it already,' +
        ' your order will be processed shortly.',
      notiInstalling: 'Thank you for using our service.<br/>We are installing your servers! It will be online shortly.',
      notiPartially: 'Thank you for using our service.<br/>We are installing your servers! It will be provision soon.',
      notiCancelled: 'Thank you for using our service.<br/>Your order is cancelled! Please order another service!',
      notiFinished: 'Thank you for using our service.<br/>Your order is finished! Please order another service!',
      due: 'Due today',
      invoiceID: 'Invoice ID',
      from: 'Pay to',
      to: 'Invoiced to',
      paymentDetail: 'Payment method:',
      paymentDetailAsBalance: 'Balance',
      paymentDetailAsLPoint: 'Loyalty point',
      paymentDetailStep3: 'Please send payment for this invoice as below',
      subTotal: 'Subtotal',
      total: 'Total',
      period: 'month',
      periods: 'months',
      showVietQR: 'Show VietQR',
      setupFee: 'One-time setup fee',
      trial_plan: 'Trial plan',
    },
    table: {
      thType: 'Type',
      thTotal: 'Total',
      thStatus: 'Status',
      thMethod: 'Payment method',
      thCreated: 'Created',
      thUpdated: 'Updated',
      thManage: 'Manage',

      thItem: 'Item',
      thDescription: 'Description',
      thUnitCost: 'Unit Cost',
      thQty: 'Qty',
      thSubtotal: 'Subtotal',
      thPeriod: 'Period',

      tdPay: 'Pay',
      tdDetail: 'View detail',
      tdStatusCredited: 'Credited',
      tdStatusProvisioned: 'Provisioned',
      tdStatusRenewed: 'Renewed',
      tdStatusInstalling: 'Installing',
      tdStatusPending: 'Pending',
      tdStatusCancelled: 'Cancelled',
      tdStatusFraud: 'Fraud',
      tdRefWith: 'Referral withdraw',

      tdOther: 'Custom Invoices',
      tdRenewVPS: 'Renew {0} {1}',
      tdRenewVPSDescription: 'Renew {0} <strong>{1}</strong> from {2} to {3}',
      tdChangeVPS: 'Change VPS {0}',
      tdUpgradeVPS: 'Upgrade VPS',
      tdChangeVPSDescription: 'Change VPS from old IP <strong class="text-primary">{0}</strong> to new IP <strong class="text-success">{1}</strong>',
      tdUpgradeVPSDescription: 'Upgrade VPS IP <strong class="text-primary">{0}</strong> from plan <strong>{1}</strong> to plan <strong>{2}</strong>',

      tdAccountBank: 'Payment method',
      tdAccountNo: 'Account number',
      tdAccountOwner: 'Beneficiary name',
      tdAmount: 'Amount',
      tdNote: 'Sending note',
      tdAccountBranch: 'Branch',

      tdCurrentBalance: 'Your current balance',
      tdCurrentLPoint: 'Your current loyalty point',
      tdAfterBalance: 'Your balance after process the order',
      tdAfterLpoint: 'Your loyalty point after process the order',
      tdCurrentPromoBalance: 'Your promotional current balance',
      tdAfterPromoBalance: 'Your promotional balance after process the order'
    },
    button: {
      payNow: 'Pay now',
      cancel: 'Cancel',
      finishOrder: 'Finish order',
      credit: 'Credit balance',
      lpoint: 'Loyalty point',
      back: 'Back',
      next: 'Next',
      initializing: 'Initializing',
    },
    toast: {
      notEnoughCredit: 'You do not have enough credit to cover this order!',
      notEnoughPoint: 'You do not have enough loyalty point to cover this order!',
      emptyCart: 'Your cart is empty! Please order at least 1 item!',
      methodNotSelected: 'You have to select the payment method!'
    },
    alert: {
      unpaid: 'You have not finished the order. Do you really want to leave?'
    },
    tooltip: {
      setupFee: 'This is a one-time fee for setting up the service. It will be charged only once when you order the service.'
    }
  },
  vi: {
    breadcrumb: 'Thông Tin Hoá Đơn',
    cardHeader: {
      titleInvoice: 'Mã hóa đơn ',
      titleStatus: 'Trạng thái',
      titleStatusUnpaid: 'Chưa thanh toán',
      titleStatusCredited: 'Đã nạp tiền',
      titleStatusProvisioned: 'Đã giao',
      titleStatusRenewed: 'Đã gia hạn',
      titleStatusInstalling: 'Đang cài đặt',
      titleStatusPending: 'Chờ xác nhận',
      titleStatusCancelled: 'Đã huỷ',
      titleStatusFraud: 'Lỗi',
      titlePaymentInfo: 'Thông tin thanh toán',
      titleStatusFinished: 'Hoàn tất',
      titleStatusPartially: 'Đang giao VPS'
    },
    label: {
      selectPayment: 'Lựa chọn phương thức thanh toán',
      topupAmount: 'Số tiền bạn muốn nạp (tối thiểu là $1)',
      notiRenewed: 'Cảm ơn bạn đã sử dụng dịch vụ của {0}.<br/>VPS của bạn đã được gia hạn.',
      notiProvisioned: 'Cảm ơn bạn đã sử dụng dịch vụ của {0}.<br/>VPS của bạn đã được giao đến tài khoản của bạn.',
      notiCredited: 'Cảm ơn bạn đã sử dụng dịch vụ của {0}.<br/>Số dư tài khoản {0} đã được cộng tiền.',
      notiPending: 'Cảm ơn bạn đã sử dụng dịch vụ của {0}.<br/>Chúng tôi đang xác nhận thanh toán! Nếu bạn đã thanh toán thành công, đơn hàng của bạn sẽ được xử lý trong giây lát.<br/>',
      notiInstalling: 'Cảm ơn bạn đã sử dụng dịch vụ của {0}.<br/>Chúng tôi đã nhận thanh toán của bạn. VPS của bạn sẽ được giao trong ít phút.',
      notiCancelled: 'Cảm ơn bạn đã sử dụng dịch vụ của {0}.<br/>Đơn hàng của bạn đã bị hủy! Vui lòng đặt lại đơn hàng khác.',
      notiFinished: 'Cảm ơn bạn đã sử dụng dịch vụ của {0}.<br/>Đơn hàng của bạn đã hoàn tất! Vui lòng đặt lại đơn hàng khác.',
      due: 'Cần thanh toán',
      invoiceID: 'Mã hoá đơn',
      from: 'Bên nhận',
      to: 'Bên thanh toán',
      paymentDetail: 'Phương thức thanh toán:',
      paymentDetailAsBalance: 'Số dư tài khoản',
      paymentDetailAsLPoint: 'Điểm thưởng',
      paymentDetailStep3: 'Bạn hãy chuyển khoản theo nội dung dưới đây',
      subTotal: 'Subtotal',
      total: 'Total',
      period: 'tháng',
      periods: 'tháng',
      showVietQR: 'Hiển thị mã thanh toán VietQR',
      setupFee: 'Phí cài đặt 1 lần',
      trial_plan: 'Gói dùng thử',
    },
    table: {
      thType: 'Loại',
      thTotal: 'Tổng cộng',
      thStatus: 'Trạng thái',
      thMethod: 'Phương thức thanh toán',
      thCreated: 'Ngày tạo',
      thUpdated: 'Ngày cập nhật',
      thManage: 'Thao tác',

      thItem: 'Gói',
      thDescription: 'Mô tả',
      thUnitCost: 'Giá',
      thQty: 'Số lượng',
      thSubtotal: 'Thành tiền',
      thPeriod: 'Chu kỳ',

      tdPay: 'Thanh toán ngay',
      tdDetail: 'Chi tiết',
      tdStatusCredited: 'Đã nạp tiền',
      tdStatusProvisioned: 'Đã giao',
      tdStatusRenewed: 'Đã gia hạn',
      tdStatusInstalling: 'Đang cài đặt',
      tdStatusPending: 'Chờ xử lý',
      tdStatusCancelled: 'Hủy',
      tdStatusFraud: 'Lỗi',
      tdRefWith: 'Rút tiền hoa hồng',

      tdRenewVPS: 'Gia hạn {0} {1}',
      tdRenewVPSDescription: 'Gia hạn {0} <strong>{1}</strong> từ {2} đến {3}',
      tdChangeVPS: 'Đổi mới VPS {0}',
      tdUpgradeVPS: 'Nâng cấp VPS',
      tdChangeVPSDescription: 'Đổi mới VPS từ IP cũ <strong class="text-primary">{0}</strong> đến IP mới <strong class="text-success">{1}</strong>',
      tdUpgradeVPSDescription: 'Nâng cấp VPS IP <strong class="text-primary">{0}</strong> từ gói <strong">{1}</strong> lên gói <strong>{2}</strong>',

      tdAccountBank: 'Phương thức thanh toán',
      tdAccountNo: 'Số tài khoản',
      tdAccountOwner: 'Người thụ hưởng',
      tdAmount: 'Số tiền',
      tdNote: 'Nội dung chuyển tiền',
      tdAccountBranch: 'Chi nhánh',

      tdCurrentBalance: 'Số dư hiện tại trong ví {0}',
      tdCurrentLPoint: 'Số dư điểm thưởng hiện tại {0}',
      tdAfterBalance: 'Số dư trong ví sau khi đặt hàng',
      tdAfterLpoint: 'Số dư điểm thưởng sau khi đặt hàng',
      tdCurrentPromoBalance: 'Số dư tài khoản khuyến mại hiện tại',
      tdAfterPromoBalance: 'Số dư tài khoản khuyến mại sau khi đặt hàng'
    },
    button: {
      payNow: 'Thanh toán ngay',
      cancel: 'Hủy hoá đơn',
      finishOrder: 'Hoàn thành đơn hàng',
      credit: 'Số dư ví',
      lpoint: 'Điểm thưởng',
      back: 'Quay lại',
      next: 'Tiếp theo',
      initializing: 'Đang khởi tạo',
    },
    toast: {
      notEnoughCredit: 'Bạn không có đủ số dư trong ví để thực hiện đơn hàng này!',
      notEnoughPoint: 'Bạn không có đủ điểm thưởng để thực hiện đơn hàng này!',
      emptyCart: 'Giỏ hàng không có sản phẩm nào! Bạn vui lòng đặt hàng ít nhất một VPS.',
      methodNotSelected: 'Vui lòng lựa chọn phương thức thanh toán!'
    },
    alert: {
      unpaid: 'Bạn chưa hoàn thành việc đặt hàng. Bạn có thực sự muốn rời đi?'
    },
    tooltip: {
      setupFee: 'Đây là phí cài đặt 1 lần cho dịch vụ. Phí này chỉ được tính 1 lần khi bạn đặt hàng.'
    }
  },
  zh: {
    cardHeader: {
      titleInvoice: '已解冻 ',
      titleStatus: '状态',
      titleStatusUnpaid: '未付款',
      titleStatusCredited: '已充值',
      titleStatusProvisioned: '交易成功',
      titleStatusRenewed: '已续费',
      titleStatusInstalling: '准备发货',
      titleStatusPending: '等待确认',
      titleStatusCancelled: '已取消',
      titleStatusFraud: '故障',
      titlePaymentInfo: '付款信息'
    },
    label: {
      selectPayment: '请选择付款方式',
      topupAmount: '请输入你所充值的金额 (充值至少为 $1)',
      notiRenewed: '谢谢您对 {0}的支持.<br/>你的VPS已续费成功.',
      notiProvisioned: '谢谢您对 {0}的支持.<br/>你的VPS 已经转到你的账号.',
      notiCredited: '谢谢您对 {0}的支持.<br/>您充值的余额已经到账.',
      notiPending: '谢谢您对{0}的支持.<br/>您的订单已提交成功!正在等待确认,如果你已付款成功请您稍等片刻.<br/>',
      notiInstalling: '谢谢您对{0}的支持.<br/>您的订单已经确认. 仓库正在为您的VPS设置请稍等片刻.',
      notiCancelled: '谢谢您对{0}的支持.<br/>您的订单已取消! 请您重新下单.',
      due: '需要付款',
      invoiceID: '订单号',
      from: '收方',
      to: '交易方',
      paymentDetail: '付款方式:',
      paymentDetailAsBalance: '账户余额',
      paymentDetailStep3: '请您付款给信息如下',
      subTotal: '单价',
      total: '总价'
    },
    table: {
      thType: '类型',
      thTotal: '总数',
      thStatus: '状态',
      thMethod: '付款方式',
      thCreated: '创建时间',
      thUpdated: '完成时间',
      thManage: '操作',

      thItem: '套餐',
      thDescription: '描述',
      thUnitCost: '价钱',
      thQty: '数量',
      thSubtotal: '总钱',

      tdPay: '立即付款',
      tdDetail: '详情',
      tdStatusCredited: '已充值',
      tdStatusProvisioned: '交易成功',
      tdStatusRenewed: '已续费',
      tdStatusInstalling: '准备发货',
      tdStatusPending: '等待处理',
      tdStatusCancelled: '取消',
      tdStatusFraud: '故障',

      tdRenewVPS: '续费 {0} {1}',
      tdRenewVPSDescription: '续费 {0} <strong>{1}</strong> 从 {2} 到 {3}',

      tdAccountBank: '付款方式',
      tdAccountNo: '账号',
      tdAccountOwner: '收款人姓名',
      tdAmount: '金额',
      tdNote: '转账内容',
      tdAccountBranch: '支行',

      tdCurrentBalance: '钱包余额 {0}',
      tdAfterBalance: '剩余余额'
    },
    button: {
      payNow: '立即付款',
      cancel: '取消订单',
      finishOrder: '完成订单',
      credit: '钱包余额',
      back: '返回',
      next: '继续'
    },
    toast: {
      notEnoughCredit: '您的余额不足来完成本次交易!',
      emptyCart: '购物车没有商品! 你最少需要一个VPS.',
      methodNotSelected: '请选择付款方式!'
    },
    alert: {
      unpaid: '您的订单未完成. 您是否确认离开此页面?'
    }
  }
}
