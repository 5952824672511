//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TitleNumber",
  props: {
    title: {
      type: String,
      default: "",
      required: true
    },
    number: {
      type: [Number, String],
      default: 0,
      required: true
    }
  }
}
