export default {
  en: {
    label: {
      new: 'NEW',
      // @todo: Bring this HTML code to the home component
      exchange_rate_popover: `<div>
        <div class="d-flex justify-content-between">
            <strong class="me-3">Last update: </strong>
            <strong class="text-success">{0}</strong>
        </div>
        <div class="d-flex justify-content-between">
            <strong class="me-3">Black market exchange rate: </strong>
            <strong class="text-success">{1}</strong>
        </div>
      </div>`,
    },
    link: {
      dashboard: 'Dashboard',
      dedicatedServer: 'Dedicated Server',
      listVps: 'List',
      vpn: 'VPN',
      ticket: 'Ticket',
      vps: 'Cloud VPS',
      order: 'Order',
      orderNew: 'Order new service',
      orderPromotion: 'Promotion order',
      topup: 'Top-up/Deposit',
      orderHistory: 'Invoices',
      snapshot: 'Snapshot',
      backup: 'Backup',
      notification: 'Notification',
      support: 'Support',
      wikiPage: 'LowEndViet Wiki',
      chatSupport: 'Facebook page',
      log: 'Activity logs',
      dev: 'For developers',
      api: 'API docs',
      security: '2-FA security',
      teams: 'Team Manager',
      tickets: 'Tickets',

      welcome: 'Welcome, ',
      balance: 'Balance',
      main_balance: 'Main',
      exchange_rate: 'Exchange rate',
      promotional_balance: 'Promo',
      profile: 'Profile',
      accountDetail: 'Account detail',
      changePassword: 'Change password',
      logout: 'Logout',
      services: 'SERVICES',
      others: 'OTHERS',
      extra: 'EXTRA',
      displaySettings: 'Display Settings',
      settingTitle: 'Display Settings',
      settingDescription: 'See our dashboard options.',
      sidenavType: 'Sidenav Type',
      sidenavTypeDescription: 'Choose between 2 different sidenav types.',
      sidenavDark: 'DARK',
      sidenavTransparent: 'TRANSPARENT',
      sidenavWhite: 'WHITE',
      navbarFixed: 'Navbar Fixed',
      lightdark: 'Light / Dark',
      vpsTableSettings: 'VPS Table Settings',
      dedicatedServerTableSettings: 'Dedicated Server Table Settings',
      sidenavNotice: 'You can change the sidenav type just on desktop view.'
    },
    callToAction: {
      updateEmailPhone: 'Please update your Email and Phone. You will now be able to login using Email/Phone',
      detail: 'Detail',
      update: 'Update now'
    }
  },
  vi: {
    label: {
      new: 'MỚI',
      exchange_rate_popover: `<div>
        <div class="d-flex justify-content-between">
            <strong class="me-3">Cập nhật lần cuối: </strong>
            <strong class="text-success">{0}</strong>
        </div>
        <div class="d-flex justify-content-between">
            <strong class="me-3">Tỷ giá chợ đen: </strong>
            <strong class="text-success">{1}</strong>
        </div>
        </div>`,
    },
    link: {
      dashboard: 'Tổng quan',
      vps: 'Cloud VPS',
      dedicatedServer: 'Máy chủ vật lý',
      listVps: 'Danh sách VPS',
      order: 'Đơn hàng',
      orderNew: 'Đặt hàng mới',
      orderPromotion: 'Đang khuyến mãi',
      topup: 'Nạp tiền',
      orderHistory: 'Danh sách hoá đơn',
      snapshot: 'Snapshot',
      backup: 'Tự động sao lưu',
      notification: 'Thông báo',
      support: 'Hỗ trợ',
      ticket: 'Ticket',
      wikiPage: 'Trang Wiki LowEndViet',
      chatSupport: 'Facebook page',
      log: 'Nhật kí hoạt động',
      dev: 'Nhà phát triển',
      api: 'API docs',
      security: 'Bảo mật 2 lớp',
      teams: 'Quản lý nhóm',
      tickets: 'Tickets',

      welcome: 'Xin chào, ',
      balance: 'Số dư',
      main_balance: 'Số dư',
      exchange_rate: 'Tỷ giá',
      promotional_balance: 'Khuyến mại',
      profile: 'Tài khoản',
      accountDetail: 'Thông tin chi tiết',
      changePassword: 'Đổi mật khẩu',
      logout: 'Logout',
      services: 'DỊCH VỤ',
      others: 'KHÁC',
      extra: 'TIỆN ÍCH',
      displaySettings: 'Cài đặt hiển thị',
      settingTitle: 'Cài đặt hiển thị',
      settingDescription: 'Cài đặt hiển thị theo nhu cầu của bạn.',
      sidenavType: 'Cài đặt menu',
      sidenavTypeDescription: 'Càu đặt Menu ngang và Menu dọc',
      sidenavDark: 'TỐI',
      sidenavTransparent: 'TRONG SUỐT',
      sidenavWhite: 'SÁNG',
      navbarFixed: 'Menu ngang cố định',
      lightdark: 'Sáng / Tối',
      vpsTableSettings: 'Cài đặt hiển thị bảng VPS',
      dedicatedServerTableSettings: 'Cài đặt hiển thị bảng máy chủ vật lý',
      sidenavNotice: 'Chỉ có thể cài đặt hiển thị của menu trên máy tính.'
    },
    callToAction: {
      updateEmailPhone: 'Mời bạn cập nhật email/số điện thoại. Sau đó, bạn có thể sử dụng email/số điện thoại này để đăng nhập.',
      detail: 'Chi tiết',
      update: 'Cập nhật ngay'
    }
  },
  zh: {
    link: {
      vps: '云 VPS',
      order: '订单',
      orderNew: '新订单',
      topup: '充值',
      orderHistory: '您的订单',
      snapshot: '样版',
      backup: '自动备份',
      notification: '通报',
      support: '帮助',
      wikiPage: '教程使用网页',
      chatSupport: 'Facebook 主页',
      log: '活动日记',
      dev: '开发者',
      api: 'API文档',

      welcome: '您好, ',
      balance: '余额剩余',
      exchange_rate: '汇率',
      profile: '账号',
      accountDetail: '信息详情',
      changePassword: '更改密码',
      logout: '注销'
    },
    callToAction: {
      updateEmailPhone: '请您填入邮箱/电话号码. 之后, 您可以用邮箱/电话号码来的登陆.',
      detail: '详情',
      update: '立即更新'
    }
  }
}
