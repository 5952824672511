import async from 'async'
import moment from 'moment'

/* eslint-disable */
export default {
  methods: {
    getInputBoxType: function (dataType) {
      if (dataType === 'bit' || dataType === 'smallint') {
        return 'checkbox'
      } else if (dataType.includes('int') || dataType.includes('varchar') || dataType.includes('double')) {
        return 'text'
      } else if (dataType === 'set') {
        return 'option'
      } else if (dataType === 'datetime') {
        return 'date'
      } else {
        return 'unknown'
      }
    },
    getAvailableValues: function (dataType, columnDefault, defaultValueSet, cb) {
      let result = []
      if (dataType === 'set') {
        async.forEachOf(defaultValueSet, function (value, key, callback) {
          /* columnName will be used to identify the vue-select in multiple selection generating */
          result.push({displayValue: value, realValue: value})
          callback(result)
        }, function (result) {
          return cb(result)
        })
      } else if (columnDefault === null || columnDefault === 'NULL') {
        let emptyStr = ''
        return cb(emptyStr)
      } else {
        return cb(columnDefault)
      }
    },
    getOptionObject: function (optionList, val, cb) {
      async.forEachOf(optionList, function (value, key, callback) {
        if (value.realValue === val) {
          cb(optionList[key])
        }
      })
    },
    convertISOTime: function (isoTimeString) {
      if (this.strIsEmpty(isoTimeString)) {
        return moment().format('YYYY-MM-DD hh:mm:ss')
      } else {
        return moment(isoTimeString).format('YYYY-MM-DD hh:mm:ss')
      }
    },
    convertISODate: function (isoTimeString) {
      if (this.strIsEmpty(isoTimeString)) {
        return null
      } else if (isoTimeString === 'Today') {
        return moment().format('YYYY-MM-DD')
      } else {
        return moment(isoTimeString).format('YYYY-MM-DD')
      }
    },
    convertISODatePlusDays: function (isoTimeString, days) {
      if (this.strIsEmpty(isoTimeString)) {
        return null
      } else if (isoTimeString === 'Today') {
        return moment().add(days, 'days').format('YYYY-MM-DD')
      } else {
        return moment(isoTimeString).add(days, 'days').format('YYYY-MM-DD')
      }
    },
    convertISODatePlusMonths: function (isoTimeString, months) {
      if (this.strIsEmpty(isoTimeString)) {
        return null
      } else if (isoTimeString === 'Today') {
        return moment().add(months, 'months').format('YYYY-MM-DD')
      } else {
        return moment(isoTimeString).add(months, 'months').format('YYYY-MM-DD')
      }
    },
    getCurrentTime: function () {
      return moment().format('YYYY-MM-DD hh:mm:ss')
    },
    getDateFromCurrent: function (offset = undefined) {
      let offsetFromCurrentDate = offset
      if (offsetFromCurrentDate === undefined) {
        offsetFromCurrentDate = 0
      }
      return moment().add(offsetFromCurrentDate, 'days').format('YYYY-MM-DD')
    },
    checkValidISOTime: function (isoTimeString) {
      return moment(isoTimeString, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()
    },
    strIsEmpty: function (str) {
      if (str === null || str === undefined || str === 'undefined' || str === '' || str.toString().toUpperCase() === 'NULL') {
        return true
      } else {
        return false
      }
    },
    capitalizeFirstLetter: function (s) {
      if (s !== undefined) {
        return s.charAt(0).toUpperCase() + s.slice(1)
      } else return s
    },
    getIPPortFormat: function (ip, port, osType = 'Windows') {
      let result = ip
      if (port !== '3389') {
        if (osType === 'Windows') {
          result += ':' + port
        } else if (osType === 'Linux') {
          result += ':' + (parseInt(port) + 1).toString()
        }
      }
      return result
    },
    getFirst3OctetIP: function (ip) {
      let n = ip.lastIndexOf('.')
      return ip.substr(0, n)
    },
    getLastOctetIP: function (ip) {
      let n = ip.lastIndexOf('.')
      return ip.substr(n + 1)
    },
    scrollTo: function (refName) {
      let element = this.$refs[refName]
      let top = element.offsetTop

      window.scrollTo(0, top)
    },
    validateEmail: function (email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    checkValidEmail: function (email) {
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(String(email).toLowerCase())
    },
    checkValidPhoneNo: function (phoneNoToCheck) {
      let pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      return pattern.test(phoneNoToCheck)
    },
    copyValueToClipboard: function (value) {
      let $temp = $('<input>')
      $('body').append($temp)
      $temp.val(value).select()
      document.execCommand('copy')
      $temp.remove()
    },
    USDCurrencyFormat: function(numberValue) {
      if (typeof(numberValue) !== 'number') {
        numberValue = 0
      }
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numberValue)
    },
    currencyFormat: function(numberValue, currency) {
      if (typeof(numberValue) !== 'number') {
        numberValue = 0
      }
      if (currency === undefined) {
        currency = 'VND'
      }
      return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: currency }).format(numberValue)
    },
    showLoadingModal () {
      document.getElementById("loading-modal").classList.remove("loading-hidden");
      document.getElementById("loading-modal").classList.add("loading-show");
    },
    hiddenLoadingModal () {
      document.getElementById("loading-modal").classList.remove("loading-show");
      document.getElementById("loading-modal").classList.add("loading-hidden");
    },
    isValidJSON(text) {
      if (text === undefined || text === null) {
        return false
      } else if (typeof text!=="string"){
        return false
      } else if (text.trim() === '') {
        return false
      }

      try{
        var json = JSON.parse(text)
        return (typeof json === 'object')
      }
      catch (error){
        return false
      }
    },
    decodeHtmlEntities(str) {
      let txt = document.createElement("textarea");
      txt.innerHTML = str;
      return txt.value;
    }
  }
}
