export default {
  en: {
    toast: {
      errRequestAPI: 'Request data error. Please contact administrator!',
      noItemSelected: 'No item selected. Please select at least one item to continue!',
    },
    button: {
      confirm: 'Confirm',
      cancel: 'Cancel',
      close: 'Close'
    },
    label: {
      not_show_again: 'Do not show this message again'
    }
  },
  vi: {
    toast: {
      errRequestAPI: 'Xuất hiện lỗi khi gọi dữ liệu. Vui lòng liên hệ support!',
      noItemSelected: 'Không có mục nào được chọn. Vui lòng chọn ít nhất một mục để tiếp tục!'
    },
    button: {
      confirm: 'Xác nhận',
      cancel: 'Huỷ bỏ',
      close: 'Đóng'
    },
    label: {
      not_show_again: 'Không hiển thị lại thông báo này'
    }
  },
  zh: {
    toast: {
      errRequestAPI: '请求数据错误。请联系客服请求帮助!'
    }
  }
}
