//
//
//
//
//
//
//

import {FixBugTheme} from "@/mixins/MaterialTheme";

export default {
  name: "LevInput",
  props: {
    label: {
      type: String,
      default: undefined,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    formType: {
      type: String,
      default: 'outline'
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    updateValue (value) {
      if (this.type === 'number') {
        this.$emit('input', parseInt(value.target.value))
      } else {
        this.$emit('input', value.target.value)
      }
    }
  }
}
