//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import LevTable from "@/components/children/LevTable";
import dataObjectListMixin from '@/mixins/DataObjectList'
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import FileExport from "@/mixins/utilities/FileExport";

import NewConfirmModal from "@/components/children/NewConfirmModal";

export default {
  name: "DetailList",
  components: {
    NewConfirmModal,
    LevTable
  },
  mixins: [dataObjectListMixin, dataRequester, inputHelper, FileExport],
  props: {
    metaData: {},
    currentUser: {
      default: function () { return {} },
      type: Object
    },
    searchText: {
      type: String,
      default: function () { return '' },
    },
    items: []
  },
  data: function () {
    let self = this
    return {
      rawDataFromDB:[],
      columns: [
        {
          name: 'start_date',
          title: `<span><i class="fas fa-calendar-plus"></i> ${self.$t('proxy.table.header.start_date')}</span>`,
          sortField: 'start_date',
          formatter(cellData) {
            return `<span>${self.convertISODate(cellData)}</span>`
          }
        },
        {
          name: 'end_date',
          title: `<span><i class="fas fa-calendar-times"></i> ${self.$t('proxy.table.header.end_date')}</span>`,
          sortField: 'end_date',
          formatter(cellData) {
            return `<span>${self.convertISODate(cellData)}</span>`
          }
        }
        ],
      extendedFields: [
        {
          name: 'ip',
          title: `<span><i class="fas fa-ethernet"></i> ${self.$t('proxy.table.header.ip')}</span>`,
          sortField: 'ip',
          formatter(rowData) {
            return `<div class="d-flex justify-content-between align-items-center">
                        <strong><a href="javascript:;">${rowData['ip']}</a></strong>
                        <i class="cursor-pointer fas fa-copy text-primary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('proxy.table.tooltip.copyIp')}" name="copy" @click="copyContent('s')"></i>
                    </div>`
          },
          order: 1
        },
        {
          name: 'port',
          title: `<span><i class="fas fa-route"></i> Port</span>`,
          sortField: 'port',
          formatter(rowData) {
            return `<div class="d-flex justify-content-between align-items-center">
                        <span>${rowData['port']}</span>
                        <i class="cursor-pointer fas fa-copy text-primary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('proxy.table.tooltip.copyPort')}" name="copy"></i>
                    </div>`
          },
          order: 2
        },
        {
          name: 'proxy_type',
          title: `<span><i class="fab fa-connectdevelop"></i> ${self.$t('proxy.table.header.type')}</span>`,
          sortField: 'proxy_type',
          formatter(rowData) {
            if (rowData['proxy_type'].toLowerCase() === 'https') {
              return `<span class="badge badge-success">${rowData['proxy_type']}</span>`
            } else if (rowData['proxy_type'].toLowerCase() === 'socks5') {
              return `<span class="badge badge-info">${rowData['proxy_type']}</span>`
            } else {
              return `${rowData['proxy_type']}`
            }
          },
          order: 3
        },
        {
          name: 'proxy_username',
          title: `<span><i class="fas fa-user"></i> ${self.$t('proxy.table.header.username')}</span>`,
          sortField: 'proxy_username',
          formatter(rowData) {
            return `<div class="d-flex justify-content-between align-items-center">
                        <span>${rowData['proxy_username']}</span>
                        <i class="cursor-pointer fas fa-copy text-primary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('proxy.table.tooltip.copyUsername')}" name="copy"></i>
                    </div>`
          },
          order: 4
        },
        {
          name: 'proxy_password',
          title: `<span><i class="fas fa-key"></i> ${self.$t('proxy.table.header.password')}</span>`,
          sortField: 'proxy_password',
          formatter(rowData) {
            return `<div class="d-flex justify-content-between align-items-center">
                        <span>${rowData['proxy_password']}</span>
                        <i class="cursor-pointer fas fa-copy text-primary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${self.$t('proxy.table.tooltip.copyPassword')}" name="copy"></i>
                    </div>`
          },
          order: 5
        },
        {
          name: 'region',
          title: `<span><i class="fas fa-map-marker-alt"></i> ${self.$t('proxy.table.header.region')}</span>`,
          sortField: 'region',
          formatter: function (rowData) {
            return `<span class="text-info btn-tooltip cursor-pointer small" data-bs-toggle="tooltip" data-bs-placement="top" data-container="body" data-animation="true" title="${rowData.country} (${rowData.state})">
                    ${rowData['country_code']} (${rowData['state_code']})</span>`
          },
          order: 6
        },
        {
          name: 'user_remark',
          title: `<span><i class="fas fa-sticky-note"></i> ${self.$t('proxy.table.header.remark')}</span>`,
          sortField: 'user_remark',
          formatter: function (rowData) {
            return rowData['user_remark']
          },
        },
        {
          name: 'is_editing_note',
          extended_data: false,
          visible: false
        }
        ],
      searchableFields: ['ip', 'port', 'proxy_type', 'proxy_username', 'proxy_password', 'start_date', 'end_date', 'user_remark'],
      usernameForChange: '',
      passwordForChange: '',
      portForChange: '',
      modalMessage: '',
      noteForEdit: '',
      isDuplicateUsername: false,
      isMultipleUsername: false,
      proxyListUpdate: []
    }
  },
  watch: {
    items: {
      handler: function () {
        this.dataObjectList = this.items
        this.rawDataFromDB = [...this.items]
      },
      deep: true
    }
  },
  methods: {
    onSelectedChanged(selectedRowID) {
      this.selectedObjectList = this.dataObjectList.filter(d => selectedRowID.includes(d['id']))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      let self = this
      switch (cellField.name) {
        case 'user_remark':
          if (event.target.getAttribute('name') === 'editNote') {
            rowData['is_editing_note'] = true
          } else if (event.target.getAttribute('name') === 'copyNote') {
            self.copyContent(rowData['user_remark'])
          }
          else if (event.target.getAttribute('name') === 'cancelNote') {
            rowData['is_editing_note'] = false
          }
          else if (event.target.getAttribute('name') === 'saveNote') {
            let currentObjectList = [rowData]
            let newNote = event.target.parentElement.getElementsByTagName('input')[0].value
            this.submitObjectList(this.$config.apiPath.updateRemark,
                currentObjectList.map(function (a) { return {
                  id: a.id,
                  vm_id: a.vm_id,
                } }),
                'proxyDetail', {remark: newNote},
                function (result) {
              self.$toasted.success(result.msg)
              self.setItemProperty(rowData.id, 'user_remark', newNote)
            }, function () {
            })
            rowData['is_editing_note'] = false
          }
          break
        case 'port':
          if (event.target.getAttribute('name') === 'copy') {
            self.copyContent(rowData['port'])
          }
          break
        case 'ip':
          if (event.target.getAttribute('name') === 'copy') {
            self.copyContent(rowData['ip'])
          }
          break
        case 'proxy_username':
          if (event.target.getAttribute('name') === 'copy') {
            self.copyContent(rowData['proxy_username'])
          }
          break
        case 'proxy_password':
          if (event.target.getAttribute('name') === 'copy') {
            self.copyContent(rowData['proxy_password'])
          }
          break
        default:
          break
      }
      updatePostTable()
    },

    changeUsernameModal: function () {
      let self = this
      this.proxyListUpdate = this.dataObjectList.filter(x => this.selectedObjectList.map(y => y['proxy_username']).includes(x['proxy_username']))
      if (self.validOpenModal()) {
        self.usernameForChange = ''
        self.passwordForChange = ''
        self.modalMessage = ''
        let uniqueItems = [...new Set(this.selectedObjectList.map(x => x['proxy_username']))]
        self.isMultipleUsername = uniqueItems.length > 1
        this.changeUsernameCheckMultipleUsername()
      }
    },
    changePasswordModal: function () {
      let self = this
      this.proxyListUpdate = this.dataObjectList.filter(x =>
          this.selectedObjectList.map(y => y['proxy_username']).includes(x['proxy_username']) &&
          this.selectedObjectList.map(y => y['id']).includes(x['id'])
      )
      if (self.validOpenModal()) {
        self.passwordForChange = ''
        self.modalMessage = ''
        let isDuplicateUsername = false
        this.selectedObjectList.forEach(obj => {
          let duplicatedCount = this.dataObjectList.filter(x =>
              x['proxy_username'] === obj['proxy_username'] &&
              x['id' === obj['id']]
          ).length
          if (duplicatedCount > 1) {
            isDuplicateUsername = true
            let warningText = self.$t('proxy.modal.body.description.usernameDuplicated', [obj['proxy_username'], duplicatedCount])
            if (!self.modalMessage.includes(warningText)) {
              self.modalMessage += warningText
            }
          }
        })

        if (isDuplicateUsername) {
          self.modalMessage += self.$t('proxy.modal.body.description.usernameDuplicatedWarning')
          $('#chang-proxy-password-modal').modal('show')
        } else {
          $('#change-password-modal').modal('show')
        }
      }
    },
    changePortModal: function () {
      let self = this
      if (self.validOpenModal()) {
        self.portForChange = ''

        let isDuplicateVms = false
        let toastedMessage = ''
        this.selectedObjectList.forEach(obj => {
          let duplicatedCount = this.selectedObjectList.filter(x => x['vm_id'] === obj['vm_id']).length
          if (duplicatedCount > 1) {
            isDuplicateVms = true
            let warningText = self.$t('proxy.toast.duplicatedVms', [obj['ip'], duplicatedCount])
            if (!toastedMessage.includes(warningText)) {
              toastedMessage += warningText
              self.$toasted.error(warningText)
            }
          }
        })

        if (!isDuplicateVms) {
          $('#change-port-modal').modal('show')
        }
      }
    },
    exportTextFileModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#export-text-file').modal('show')
      }
    },
    exportExcelFileModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#export-excel-file').modal('show')
      }
    },
    checkExistUsernameModalCancel: function () {
      $('#change-username-modal').modal('show')
    },
    deleteProxyModal: function () {
      let self = this
      if (self.validOpenModal()) {
        $('#delete-proxy-modal').modal('show')
      }
    },

    validOpenModal: function () {
      let self = this
      if(self.selectedObjectList === null || self.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('proxy.toast.errNoSelect'))
        return false
      }
      return true
    },

    changeUsername: function () {
      let self = this
      this.showLoadingModal()
      $('#check-username-existed').modal('hide')
      this.submitObjectList(self.$config.apiPath.updateProxyUsername,
          self.selectedObjectList.map(x => {
            return {
              id: x['id'],
              vm_id: x['vm_id'],
              proxy_username: self.usernameForChange,
              proxy_password: self.passwordForChange
            }
          }),
          'proxyDetail',
          undefined,
          function (result) {
            self.$toasted.success(result.msg)
            if (self.isMultipleUsername) {
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                let obj = self.selectedObjectList[i]
                self.setItemProperty(obj.id, 'proxy_username', self.usernameForChange)
                self.setItemProperty(obj.id, 'proxy_password', self.passwordForChange)
              }
            } else {
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                let obj = self.selectedObjectList[i]
                let objUsernameExisted = self.dataObjectList.find(x => x['proxy_username'] === self.usernameForChange)
                if (objUsernameExisted !== undefined) {
                  self.setItemProperty(obj.id, 'proxy_username', self.usernameForChange)
                  self.setItemProperty(obj.id, 'proxy_password', objUsernameExisted['proxy_password'])
                } else {
                  self.setItemProperty(obj.id, 'proxy_username', self.usernameForChange)
                }
              }
            }
          }, undefined, true, function () {
            self.hiddenLoadingModal()
          })
    },
    changeUsernameCheckExisted: function () {
      $('#change-username-modal').modal('hide')
      $('#change-username-password-modal').modal('hide')
      let self = this
      this.submitObjectList(self.$config.apiPath.checkProxyUsernameExisted,
          self.selectedObjectList.map(function (obj) {
            return {
              'vm_id': obj['vm_id'],
              'proxy_username': self.usernameForChange
            }
          }), 'proxyDetails',
          null,
          function (result) {
            if (![null, undefined].includes(result.data)) {
              self.modalMessage = self.$t('proxy.modal.body.warning.usernameExistedWarning', [self.usernameForChange])
              result.data.forEach(value => {
                self.modalMessage += self.$t('proxy.modal.body.warning.usernameExisted', value)
              })
              $('#check-username-existed').modal('show')
            } else {
              self.changeUsername()
            }
          }, undefined, true)
    },
    changeUsernameCheckMultipleUsername: function () {
      $('#change-username-duplicated').modal('hide')
      if (this.isMultipleUsername) {
        $('#change-username-password-modal').modal('show')
      } else {
        $('#change-username-modal').modal('show')
      }
    },

    changePasswordConfirm: function () {
      $('#chang-proxy-password-modal').modal('hide')
      $('#change-password-modal').modal('show')
    },
    changePassword: function () {
      $('#change-password-modal').modal('hide')
      let self = this
      this.showLoadingModal()
      this.submitObjectList(self.$config.apiPath.updateProxyPassword,
          self.selectedObjectList.map(x => {
            return {
              id: x['id'],
              vm_id: x['vm_id'],
              proxy_password: self.passwordForChange
            }
          }),
          'proxyDetail',
          undefined,
          function (result) {
            for (let i = 0; i < self.selectedObjectList.length; i++) {
              let proxy_username = self.selectedObjectList[i]['proxy_username']
              let proxyChangeList = self.dataObjectList.filter(x =>
                  x['proxy_username'] === proxy_username &&
                  x['id'] === self.selectedObjectList[i]['id'])
              proxyChangeList.forEach(obj => {
                self.setItemProperty(obj.id, 'proxy_password', self.passwordForChange)
              })
            }
            self.$toasted.success(result.msg)
          }, null, true, function () {
            self.hiddenLoadingModal()
          })
    },

    deleteProxy: function () {
      $('#delete-proxy-modal').modal('hide')
      this.showLoadingModal()

      let self = this
      this.submitObjectList(self.$config.apiPath.deleteProxy,
          self.selectedObjectList.map(x => {
            return {
              id: x['id'],
              vm_id: x['vm_id'],
              port: x['port']
            }
          }),
          'proxyDetail',
          undefined,
          function (result) {
            self.$toasted.success(result.msg)
            self.$emit('delete-proxy', self.selectedObjectList)
            self.dataObjectList = self.dataObjectList.filter(x => !self.selectedObjectList.map(y => y['id']).includes(x['id']))
          }, undefined, true, function () {
            self.hiddenLoadingModal()
          })
    },
    changePortConfirm: function () {
      $('#change-port-modal').modal('hide')
      let self = this
      if (this.portForChange == 2258 || this.portForChange >= 65535 || this.portForChange <= 1000) {
        this.$toasted.error(this.$t('proxy.toast.invalidPort', [this.portForChange]))
      } else {
        this.showLoadingModal()
        this.submitObjectList(self.$config.apiPath.updateProxyPort,
            self.selectedObjectList.map(x => {
              return {
                id: x['id'],
                vm_id: x['vm_id'],
                port: x['port'],
                new_port: self.portForChange
              }
            }),
            'proxyDetail',
            undefined,
            function (result) {
              for (let i = 0; i < self.selectedObjectList.length; i++) {
                self.setItemProperty(self.selectedObjectList[i].id, 'port', self.portForChange)
              }
              self.$toasted.success(result.msg)
            }, null, true, function () {
              self.hiddenLoadingModal()
            })
      }
    },
    confirmExportTextFile: function () {
      let self = this
      let dataObject = self.selectedObjectList.map(function(element) {
        let result = `${element.ip}:${element.port}:${element.proxy_username}:${element.proxy_password}`
        return result
      })

      self.exportTextFile(dataObject, 'LowEndViet_Proxy_Excel_File_Export')
    },
    confirmExportExcelFile: function () {
      let self = this
      let dataObject = self.selectedObjectList.map(function(element) {
        let result = {
          id: element.id,
          host: element.ip,
          port: element.port,
          type: element.proxy_type,
          user: element.proxy_username,
          password: element.proxy_password
        }
        return result
      })

      self.exportExcelFile(dataObject, 'LowEndViet_Proxy_Excel_File_Export')
    },
    editNoteModal: function () {
      let self = this
      if (self.validOpenModal()) {
        self.noteForEdit = self.selectedObjectList[0].user_remark
        if (self.selectedObjectList.length > 1) {
          self.noteForEdit = ''
        }
        $('#proxy-detail-edit-remark-modal').modal('show')
      }
    },
    confirmEditNote: function () {
      $('#proxy-detail-edit-remark-modal').modal('hide')
      let self = this
      this.submitObjectList(this.$config.apiPath.updateRemark,
          this.selectedObjectList.map(function (a) { return {id: a.id, vm_id: a.vm_id} }),
          'proxyDetail',
          {remark: self.noteForEdit},
          function (result) {
            self.$toasted.success(result.msg)
            for (let i = 0; i < self.selectedObjectList.length; i++) {
              self.setItemProperty(self.selectedObjectList[i].id, 'user_remark', self.noteForEdit)
            }
          })
    },

    copyContent: function (s) {
      let self = this
      this.$toasted.info(self.$t('detail.toast.okCopy', [s.toString().trim()]))
      this.copyValueToClipboard(s)
    }
  }
}
