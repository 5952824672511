//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "VmPlans",
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    let self = this
    return {
      columns: [
        {
          key: 'vm_plan_name',
          label: self.$t('orderNew.table.h_name')
        },
        {
          key: 'vcores',
          label: self.$t('orderNew.table.h_cpu')
        },
        {
          key: 'ram',
          label: self.$t('orderNew.table.h_ram')
        },
        {
          key: 'disk',
          label: self.$t('orderNew.table.h_storage')
        },
        {
          key: 'transfer_limit',
          label: self.$t('orderNew.table.h_traffic')
        },
        {
          key: 'price',
          label: self.$t('orderNew.table.h_price')
        },
        {
          key: 'description',
          label: self.$t('orderNew.table.h_note')
        },
      ]
    }
  }
}
