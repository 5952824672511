export default {
  en: {
    breadcrumb: 'Cloud VPS List',
    cardHeader: {
      title: 'Your cloud VPS list'
    },
    table: {
      thUsername: 'Username',
      thPassword: 'Password',
      thPrice: 'Price',
      thLocation: 'Location',
      thPlan: 'Plan',
      thBackup: 'Backup',
      thStart: 'Start',
      thEnd: 'End',
      thAutoRenew: 'Auto renew',
      thPay: 'Pay',
      thNote: 'Note',
      thStatus: 'Status',
      thManage: '',

      tdShowPw: 'Show',
      tdHidePw: 'Hide',
      tdOK: 'OK',
      tdOverdue: 'Over due',
      tdSuspend: 'Suspended',
      tdCancel: 'Cancelled',
      tdUnknown: 'Unknown',
      tdPressToCheck: 'Press to check',
      tdChecking: 'Checking...',
      tdRunning: 'Running',
      tdStopped: 'Stopped',
      tdSuccess: 'Success',
      tdStarting: 'Starting...',
      tdStopping: 'Stopping...',
      tdRestarting: 'Restarting...',
      tdRebuilding: 'Rebuilding...',
      tdSnapshoting: 'Taking snapshot...',
      tdRestoring: 'Restoring...',
      tdBackingUp: 'Backing up...',
      tdRecovering: 'Recovering...',
      tdError: 'Error',
      tdManage: 'Manage',
      tdRGCCreating: 'RGC Creating...',
      tdRGCDeleting: 'RGC Deleting...'
    },
    tooltip: {
      editRemark: 'Edit your note',
      stop: 'Stop VPS',
      start: 'Turn on VPS',
      restart: 'Restart VPS',
      console: 'Remote on Web by VNC',
      copy_note: 'Copy note',
      edit_note: 'Edit note',
      save_note: 'Save note',
      cancel_note: 'Cancel note',
      rgc_create: 'Create RGC',
      rgc_show: 'Show RGC',
      rgc_delete: 'Delete RGC',
      rgc_hidden: 'Hidden RGC',
      ip_copy: 'Copy IP:Port',
      user_copy: 'Copy login user',
      pw_copy: 'Copy password',
      pw_show: 'Show password',
      pw_hide: 'Hide password',
      owner_vps: '{owner_full_name}\'s VPS',
      co_owner_vps: 'Shared for {co_owner_full_name}'
    },
    button: {
      status: 'Recheck status',
      editNote: 'Edit note',
      start: 'Start VPS',
      stop: 'Stop VPS',
      restart: 'Restart VPS',
      console: 'Web remote',
      rdp: 'Generate RDP profile',
      rebuild: 'Reinstall OS',
      snapshot: 'Take snapshot',
      restore: 'Restore VPS from snap',
      transfer: 'Transfer out',
      share: 'Team member share',
      change: 'Change VPS',
      renew: 'Create renew invoice',
      autorenew: 'Auto renew',
      all: 'All ({0})',
      overdue: 'Nearly expired ({0})',
      suspended: 'Suspended ({0})',
      cancel: 'Cancel ({0})',
      unknown: 'Unknown ({0})',
      team: 'Team ({0})',
      exportText: 'Export Text',
      exportExcel: 'Export Excel',
      actionMenu: 'Multiple VPS Action',
      exportFile: 'Export File',
      buttonDelete: 'Delete',
    },
    toast: {
      errNoSelect: 'You have to select at least 1 VPS!',
      errSingleSnap: 'System supports snapshot one by one only!',
      errPendingTask: 'Another action is in progress! Please wait!',
      errSingleConsole: 'Currently, the system supports open console one by one only!',
      errActionFailed: 'Failed to {0} VPS. Please contact support!',

      okRebuildRequestSent: 'Sent rebuild request to {0} VPS. Please wait about 10 minutes!',
      okRestoreRequestSent: 'Sent restore request to {0} VPS. Please wait about 10 minutes!',
      errRebuild: '{0}: Rebuild error. Please try again or contact support!',

      okExportRDG: 'Successfully exported {0} VPS as RDG profile!',
      okExportTextFile: 'Successfully exported {0} selected row as text file!',
      okExportExcelFile: 'Successfully exported {0} selected row as excel file!',
      rgcCreating: 'Creating RGC forwarded port for {0}',
      rgcDelete: 'Deleting RGC URL of {0}'
    },
    modal: {
      titleConfirm: 'Confirm {0} {1} VPS',
      titleConfirmRebuild: 'Confirm reinstall {0} VPS',
      titleConfirmSnap: 'Confirm take snapshot VPS',
      titleConfirmRestore: 'Confirm restore {0} VPS',
      titleConfirmRenew: 'Generate renew invoice for {0} VPS',
      titleConfirmChange: 'Generate change invoice for {0} VPS',
      titleConfirmAutoRenew: 'Set auto renew for {0} VPS',
      titleConfirmTransfer: 'Confirm transfer out {0} VPS',
      titleConfirmShare: 'Confirm share {0} VPS',
      titleRDP: 'Generate RDP profile for {0} VPS',
      titleExportTextFile: 'Generate text file for {0} selected row',
      titleExportExcelFile: 'Generate excel file for {0} selected row',
      titleEditRemark: 'Edit note for {0} VPS',
      titleConfirmCreateRGC: 'Confirm create RGC this VPS',
      titleConfirmDeleteRGC: 'Confirm delete RGC URL this this VPS',

      bodyConfirm: 'Do you really want to {0} these VPSes below?',
      bodySelectOS: 'Select OS image to rebuild',
      bodySelectSnapshot: 'Select snapshot to restore',
      bodyConfirmSnapshot: 'Do you really want to take snapshot for the following VPS?',
      bodyConfirmRenew: 'The system will generate an invoice to renew the following VPS.',
      bodyConfirmChange: 'The system will generate an invoice to change the following VPS.',
      bodySelectRGC: 'Select RGC server to create',
      bodyConfirmDeleteRGC: 'Do you really want to delete RGC URL this VPS?',
      bodyConfirmAutoRenew: 'If you set these following VPSes as automatically renew, the system will automatically generate an' +
        ' invoice to renew 3 days before the due date. If you have sufficient balance, your balance will be automatically deducted.',
      bodyConfirmTransfer: 'Please enter the email of the user that you want to transfer these following VPS.<br/>' +
        'For security reason, if you enter wrong email address more than 5 times, this feature will be blocked.',
      bodyConfirmRDP: 'The system will generate an RDP profile for the following VPS.',
      bodyConfirmGenerateTextFile: 'The system will generate a text file for the following VPS.',
      bodyConfirmGenerateExcelFile: 'The system will generate an excel file for the following VPS.',
      bodyEditRemark: 'You can take a note for same purpose VPSes so it will be easier to identify in case you have a lot of VPS.',

      bodyRGCCreateWarning: 'If you use custom remote port, fill this to here',
      bodyDatalossWarning: '<strong>Warning:</strong>This action is the same as press/hold the power button on your PC. It will cut the power on the VPS' +
        ' and make the VPS {0} immediately. Therefore, some recent data that is currently being saved on disk may be lost. ' +
        ' Use this function only when the VPS is not responding. ' + '<br/>We strongly recommend you to shut down/restart the VPS INSIDE it.',

      bodyRebuildWarning: '<strong>Warning:</strong> This action will erase your disk and cannot be recovered!',
      bodyRestoreWarning: '<strong>Warning:</strong> Restore snapshot will erase your disk and all current data will be lost!',
      bodySnapshotWarning: '<strong>Warning - Please read entirely</strong><ul>' +
        '<li>You should take the following steps to make sure the snapshot working properly:<ol>' +
        '<li>Delete all of the unnecessary files from the VPS to keep the snapshot light.</li>' +
        '<li>Shutdown VPS using its shutdown function to ensure data consistency.</li></ol></li>' +
        '<li>Snapshot is provided free, so please don\'t abuse it. <strong>It is not a backup function</strong>,' +
        ' it is intended for cloning VPS. Therefore, you have only 1 free snapshot quota. If you need more snapshot quota, please contact support.' +
        '</li>',
      bodyTransferWarning: '<h5 class="text-danger">Warning:</h5></br><ol>' +
        '<li>We WILL NOT touch your data, so if you don\'t want to transfer data, simply rebuild the VPS before transferring. ' +
        'If you don\'t rebuild the VPS, the receiver will have access to your data.</li>' +
        '<li>Any due invoices are your responsibility, the receiver will pay the next billing cycle.</li>' +
        '</ol>>',
      bodySelectTeamMember: 'Select the team member to share',
      bodyRenewWarning: 'You will be taken to the invoice page to review and pay the invoice.</br>',
      bodyChangeWarning: 'You will be taken to the invoice page to review and pay the invoice.</br>',
      bodyAutoRenewWarning: 'You can cancel the automatically renewal anytime.',
      bodyRDPWarning: 'You will need Remote Desktop Connection Manager to open the profile.' +
        '  <a href="https://docs.microsoft.com/en-us/sysinternals/downloads/rdcman">Download from Microsoft</a>.<br/>' +
        'If you are running Linux, please install <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html">Putty</a> to connect.',
      bodyTeamMember: '<strong>Attention:</strong> You are giving control permissions of this services to team member. However, YOU ARE STILL RESPONSIBLE FOR THE PAYMENT.',

      buttonConfirm: 'Confirm',
      buttonCancel: 'Close',
      buttonRestore: 'Restore',
      buttonSnapshot: 'Snapshot',
      buttonRebuild: 'Reinstall',
      buttonTransfer: 'Transfer',
      buttonRenew: 'Generate invoice',
      buttonChange: 'Generate invoice',
      buttonSetAutoRenew: 'Set auto renew',
      buttonCancelAutoRenew: 'Cancel auto renew',
      buttonRDP: 'Generate RDP Profile',
      buttonGenerateTextFile: 'Generate text file',
      buttonGenerateExcelFile: 'Generate excel file',
      buttonEdit: 'Edit ',
      buttonCreate: 'Create',
      buttonDelete: 'Delete',
      buttonShare: 'Share',
      buttonDeleteShare: 'Stop sharing',
      period: 'Renewal period',
      month: 'Months',

      labelTypeNote: 'Type your note here',
    },
    action: {
      start: 'start',
      shutdown: 'Shutdown',
      stop: 'stop',
      restart: 'restart',
      renew: 'renew'
    },
    label: {
      yourNote: 'Your note',
      note: 'Note'
    },
  },
  vi: {
    breadcrumb: 'Danh Sách Cloud VPS',
    cardHeader: {
      title: 'Danh sách Cloud VPS'
    },
    table: {
      thUsername: 'Tên đăng nhập',
      thPassword: 'Mật khẩu',
      thPrice: 'Giá',
      thLocation: 'Khu vực',
      thPlan: 'Plan',
      thBackup: 'Sao lưu',
      thStart: 'Bắt đầu',
      thEnd: 'Hết hạn',
      thAutoRenew: 'Tự gia hạn',
      thPay: 'Thanh toán',
      thNote: 'Ghi chú',
      thStatus: 'Trạng thái',
      thManage: '',

      tdShowPw: 'Hiện',
      tdHidePw: 'Ẩn',
      tdOK: 'OK',
      tdOverdue: 'Quá hạn',
      tdSuspend: 'Tạm khóa',
      tdCancel: 'Chờ hủy',
      tdPressToCheck: 'Ấn để kiểm tra',
      tdUnknown: 'Không rõ',
      tdChecking: 'Đang kiểm tra...',
      tdRunning: 'Đang chạy',
      tdStopped: 'Đã tắt',
      tdSuccess: 'Thành công',
      tdStarting: 'Đang khởi động...',
      tdStopping: 'Đang tắt...',
      tdRestarting: 'Đang khởi động...',
      tdRebuilding: 'Đang cài đặt OS...',
      tdSnapshoting: 'Đang tạo snapshot...',
      tdRestoring: 'Đang khôi phục snapshot...',
      tdBackingUp: 'Đang backup...',
      tdRecovering: 'Đang khôi phục backup...',
      tdError: 'Lỗi',
      tdManage: 'Chi tiết'
    },
    tooltip: {
      editRemark: 'Ghi chú',
      stop: 'Dừng VPS',
      start: 'Bật VPS',
      restart: 'Khởi động lại',
      console: 'Remote nền web',
      copy_note: 'Sao chép ghi chú',
      edit_note: 'Sửa ghi chú',
      save_note: 'Lưu lại thay đổi ghi chú',
      cancel_note: 'Huỷ bỏ thay đổi ghi chú',
      rgc_create: 'Tạo địa chỉ RGC',
      rgc_show: 'Hiện địa chỉ RGC',
      rgc_delete: 'Xoá địa chỉ RGC',
      rgc_hidden: 'Ẩn địa chỉ RGC',
      ip_copy: 'Sao chép địa chỉ IP:Port',
      user_copy: 'Sao chép tên đăng nhập',
      pw_copy: 'Sao chép mật khẩu',
      pw_show: 'Hiển thị mật khẩu',
      pw_hide: 'Ẩn mật khẩu',
      owner_vps: '{owner_full_name} sở hữu VPS này',
      co_owner_vps: 'VPS này được chia sẻ cho {co_owner_full_name}'
    },
    button: {
      status: 'Kiểm tra trạng thái',
      editNote: 'Sửa ghi chú',
      start: 'Bật VPS',
      stop: 'Dừng VPS',
      restart: 'Khởi động lại',
      console: 'Remote nền web',
      rdp: 'Xuất tệp RDP',
      rebuild: 'Cài lại Hệ điều hành',
      snapshot: 'Tạo snapshot',
      restore: 'Khôi phục từ snapshot',
      transfer: 'Chuyển giao',
      share: 'Chia sẻ nhóm',
      change: 'Đổi VPS mới',
      renew: 'Gia hạn',
      autorenew: 'Tự động gia hạn',
      all: 'Tất cả ({0})',
      overdue: 'Sắp hết hạn ({0})',
      suspended: 'Tạm khóa ({0})',
      cancel: 'Chờ huỷ ({0})',
      unknown: 'Không xác định ({0})',
      team: 'Team ({0})',
      exportText: 'Xuất tệp văn bản',
      exportExcel: 'Xuất tệp Excel',
      actionMenu: 'Thao tác nhiều VPS',
      exportFile: 'Xuất tệp',
      buttonDelete: 'Xoá',
    },
    toast: {
      errNoSelect: 'Vui lòng lựa chọn ít nhất 1 VPS!',
      errSingleSnap: 'Hệ thống chỉ hỗ trợ tạo 1 snapshot!',
      errPendingTask: 'Có tác vụ đang thực thi! Vui lòng chờ',
      errSingleConsole: 'Hệ thống chỉ hỗ trợ mở 1 cửa sổ console!',
      errActionFailed: '{0} VPS thất bại. Vui lòng liên hệ support!',

      okRebuildRequestSent: 'Đang tiến hành cài lại hệ điều hành cho {0} VPS. Vui lòng đợi khoảng 10 phút!',
      okRestoreRequestSent: 'Đang tiến hành khôi phục cho {0} VPS. vui lòng đợi khoảng 10 phút!',
      errRebuild: '{0}: Cài lại hệ điều hành gặp lỗi. Vui lòng thử lại hoặc liên hệ hỗ trợ!',

      okExportRDG: 'Thành công xuất RDP cho {0} VPS!',
      rgcCreating: 'Đang tạo mới địa chỉ RGC tới VPS {0}',
      rgcDelete: 'Đang xoá địa chỉ RGC của VPS {0}'
    },
    modal: {
      titleConfirm: 'Xác nhận {0} {1} VPS',
      titleConfirmRebuild: 'Xác nhận cài lại OS cho {0} VPS',
      titleConfirmSnap: 'Xác nhận tạo snapshot VPS',
      titleConfirmRestore: 'Xác nhận khôi phục cho {0} VPS',
      titleConfirmShare: 'Xác nhận chia sẻ quyền sử dụng cho {0} VPS',
      titleConfirmRenew: 'Gia hạn cho {0} VPS',
      titleConfirmChange: 'Đổi mới {0} VPS',
      titleConfirmAutoRenew: 'Đặt tự động gia hạn cho {0} VPS',
      titleConfirmTransfer: 'Xác nhận chuyển giao {0} VPS',
      titleConfirmCreateRGC: 'Xác nhận quý khách muốn tạo địa chỉ RGC cho VPS này',
      titleConfirmDeleteRGC: 'Xác nhận quý khách muốn xoá địa chỉ RGC cho VPS này',
      titleRDP: 'Xuất RDP cho {0} VPS',
      titleEditRemark: 'Sửa ghi chú cho {0} VPS',

      bodyConfirm: 'Bạn có thực sự muốn {0} những VPS dưới đây?',
      bodySelectOS: 'Lựa chọn hệ điều hành để cài đặt lại',
      bodySelectSnapshot: 'Lựa chọn bản snapshot để tiến hành khôi phục',
      bodySelectTeamMember: 'Lựa chọn thành viên nhóm mà bạn muốn chia sẻ',
      bodyConfirmSnapshot: 'Bạn có thực sự muốn tạo snapshot cho VPS sau đây?',
      bodyConfirmRenew: 'Hệ thống sẽ tạo Hóa đơn gia hạn cho VPS dưới đây.',
      bodyConfirmChange: 'Hệ thống sẽ tạo Hóa đơn đổi mới cho VPS dưới đây.',
      bodySelectRGC: 'Chọn server RGC trung gian',
      bodyConfirmDeleteRGC: 'Quý khách có chắc chắn muốn xoá địa chỉ RGC cho của này?',
      bodyConfirmAutoRenew: 'Khi cài đặt tự động gia hạn, hệ thống sẽ tự động tạo ra hoá đơn gia hạn 3 ngày trước khi VPS đến hạn. ' +
        'Nếu số dư tài khoản của bạn đủ để gia hạn, hệ thống sẽ tự đông trừ tài khoản của bạn.',
      bodyConfirmTransfer: 'Bạn cần điền email của user mà bạn muốn chuyển giao những VPS sau.<br/>' +
        'Vì lý do bảo mật, nếu bạn nhập sai email quá 5 lần, chức năng này sẽ bị tạm khóa.',
      bodyConfirmRDP: 'Hệ thống sẽ xuất RDP file cho VPS dưới đây.',
      bodyEditRemark: 'Bạn có thể ghi chú cho vài VPS chung mục đích để dễ dàng phân biệt.',

      bodyRGCCreateWarning: 'Nếu quý khách dùng custom remote port khác port remote mặc định thì điền vào đây',
      bodyDatalossWarning: '<strong>Chú ý:</strong>Chỉ dùng chức năng này khi VPS bị treo hoặc không thể đăng nhập.' +
        ' Thao tác này giống như việc bấm nút nguồn trên máy tính, sẽ khiến ngắt điện trên VPS' +
        ' và khiến VPS {0} ngắt ngay lập tức. Do đó, một số dữ liệu chưa được lưu hoặc đang trong tiến trình lưu sẽ bị mất.' + '<br/>Quý khách nên dùng chức năng shutdown/restart BÊN TRONG VPS để đảm bảo an toàn dữ liệu.',

      bodyRebuildWarning: '<strong>Chú ý:</strong> Chức năng này sẽ xóa toàn bộ dữ liệu hiện có và không thể phục hồi. Vui lòng backup dữ liệu!',
      bodyRestoreWarning: '<strong>Chú ý:</strong> Chức năng này sẽ xóa toàn bộ dữ liệu hiện có và không thể phục hồi. Vui lòng backup dữ liệu!',
      bodyTeamMember: '<strong>Chú ý:</strong> Khi chia sẻ VPS, thành viên trong nhóm sẽ có quyền điều khiển và cài đặt. Tuy nhiên, BẠN VẪN CHỊU TRÁCH NHIỆM CHO VIỆC THANH TOÁN.',
      bodySnapshotWarning: '<strong>Chú ý:</strong><ul>' +
        '<li>Quý khách nên thực hiện 2 bước sau để chức năng snapshot hoạt động chính xác:<ol>' +
        '<li>Xóa tất cả những dữ liệu không cần thiết để bản snapshot nhẹ nhàng và ít nguy cơ xảy ra lỗi.</li>' +
        '<li>Trước khi tạo snapshot, vui lòng tắt - Shutdown VPS ở trong hệ điều hành để tránh việc mất/lỗi dữ liệu.</li></ol></li>' +
        '<li>Chức năng Snapshot được chúng tôi cung cấp miễn phí, vui lòng không lạm dụng. <strong>Chức năng này được thiết kế để nhân bản VPS, không phải để backup dữ liệu.</strong>,' +
        ' Do đó, quý khách chỉ có 1 snapshot miễn phí, nếu cần nhiều hơn, vui lòng liên hệ hỗ trợ.' +
        '</li>',
      bodyTransferWarning: '<h5 class="text-danger">Lưu ý:</h5></br><ol>' +
        '<li>Chúng tôi sẽ không bao giờ can thiệp vào dữ liệu của bạn. Nếu bạn muốn chuyển giao VPS mà không muốn chuyển giao dữ liệu, hay cài lại hệ điều hành trước khi chuyển giao. ' +
        'Nếu bạn không cài lại hệ điều hành, người nhận có thể toàn quyền truy cập vào dữ liệu của bạn.</li>' +
        '<li>Các hóa đơn quá hạn sẽ thuộc trách nhiệm thanh toán của bạn. Người nhận sẽ thanh toán các hóa đơn kì sau.</li>' +
        '</ol>',
      bodyRenewWarning: 'Bạn sẽ được chuyển đến trang hóa đơn để kiểm tra và thanh toán hóa đơn.</br>',
      bodyChangeWarning: 'Bạn sẽ được chuyển đến trang hóa đơn để kiểm tra và thanh toán hóa đơn.</br>',
      bodyAutoRenewWarning: 'Bạn có thể tắt cài đặt tự động gia hạn bất kì lúc nào bằng nút dưới đây.',
      bodyRDPWarning: 'Bạn sẽ cần phần mềm Remote Desktop Connection Manager để mở file RDP.' +
        '  <a href="https://docs.microsoft.com/en-us/sysinternals/downloads/rdcman">Tải từ Microsoft nếu bạn chưa có</a>.<br/>' +
        'Nếu bạn đang sử dụng Linux, vui lòng cài đặt <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html">Putty</a> để kết nối.',

      buttonConfirm: 'Xác nhận',
      buttonCancel: 'Đóng',
      buttonRestore: 'Khôi phục',
      buttonShare: 'Chia sẻ',
      buttonDeleteShare: 'Ngừng chia sẻ',
      buttonSnapshot: 'Tạo snapshot',
      buttonRebuild: 'Cài đặt lại Hệ điều hành',
      buttonTransfer: 'Chuyển giao',
      buttonRenew: 'Tạo hóa đơn',
      buttonChange: 'Tạo hóa đơn đổi mới',
      buttonSetAutoRenew: 'Đặt tự động gia hạn',
      buttonCancelAutoRenew: 'Tắt tự động gia hạn',
      buttonRDP: 'Xuất DRP',
      buttonEdit: 'Chỉnh sửa',
      buttonCreate: 'Tạo mới',
      buttonDelete: 'Xoá',
      period: 'Chu kì gia hạn',
      month: 'tháng',

      labelTypeNote: 'Ghi chú của bạn',
    },
    action: {
      start: 'Bật',
      shutdown: 'Tắt VPS',
      stop: 'Dừng',
      restart: 'Khởi động lại',
      renew: 'Gia hạn'
    },
    label: {
      yourNote: 'Ghi chú của bạn',
      note: 'Ghi chú'
    }
  },
  zh: {
    cardHeader: {
      title: '云VPS名单'
    },
    table: {
      thLocation: '位置',
      thPlan: '价钱',
      thBackup: '备份',
      thStart: '开始',
      thEnd: '结束',
      thAutoRenew: '自动续费',
      thPay: '交易',
      thNote: '备注',
      thStatus: '状态',
      thManage: '',

      tdShowPw: '显示',
      tdOK: 'OK',
      tdOverdue: '已过期',
      tdSuspend: '已冻结',
      tdCancel: '等待取消',
      tdPressToCheck: '点击查询',
      tdUnknown: '未知原因',
      tdChecking: '正在检查...',
      tdRunning: '正在运行',
      tdStopped: '已停',
      tdSuccess: '成功',
      tdRebuilding: '正在重装系统...',
      tdSnapshoting: '正在制作样版...',
      tdRestoring: '正在恢复样版...',
      tdBackingUp: '正在备份...',
      tdRecovering: '正在恢复备份...',
      tdError: '故障',
      tdManage: '操作'
    },
    tooltip: {
      editRemark: '备注',
      stop: '暂停 VPS',
      start: '开启 VPS',
      restart: '重新启动',
      console: '网页远程连接'
    },
    button: {
      status: '检查状态',
      editNote: '修改备注',
      start: '启动 VPS',
      stop: '暂停 VPS',
      restart: '重新启动',
      console: '网页远程链接',
      rdp: '导出RDP文件',
      rebuild: '重装系统',
      snapshot: '制作样版',
      restore: '恢复样版',
      transfer: '转交',
      renew: '续费',
      autorenew: '自动续费'
    },
    toast: {
      errNoSelect: '请您最少选择1个VPS!',
      errSingleSnap: '系统只允许您制造1个样版!',
      errPendingTask: '系统正在处理请稍后',
      errSingleConsole: '系统只允许您使用一个控制面板!',
      errActionFailed: '{0} VPS 失败. 请联系客服!',

      okRebuildRequestSent: '正在给 {0} VPS重装系统. 请稍等片刻!',
      okRestoreRequestSent: '正在给 {0} VPS恢复. 请稍等片刻!',
      errRebuild: '{0}: 重装系统故障. 请联系客服!',

      okExportRDG: ' 成功导出RDP给 {0} VPS!'
    },
    modal: {
      titleConfirm: '确认 {0} {1} VPS',
      titleConfirmRebuild: '确认给 {0} VPS重装系统',
      titleConfirmSnap: '确认给VPS制作样版',
      titleConfirmRestore: '确认给{0} VPS恢复样版',
      titleConfirmRenew: '给 {0} VPS续费',
      titleConfirmAutoRenew: '给 {0} VPS开启自动续费',
      titleConfirmTransfer: '确认转交 {0} VPS',
      titleRDP: '给 {0} VPS导出RDP',
      titleEditRemark: '给 {0} VPS修改备注',

      bodyConfirm: '您是否确认 {0} 此 VPS ?',
      bodySelectOS: '请您选择您要装的系统',
      bodySelectSnapshot: '请选择您需要恢复的样版',
      bodyConfirmSnapshot: '您是否确认给此VPS制作成样版?',
      bodyConfirmRenew: '系统会给此VPS造出续费订单.',
      bodyConfirmAutoRenew: '开此自动续费功能系统将会在此VPS到期的前3天进行自动续费. ' +
            '到期之前如果你的余额够给此VPS续费系统会自动扣除您的余额.',
      bodyConfirmTransfer: '您需要填入接收VPS的人的邮箱或名字.<br/>' +
          '为了您的账号安全如果你填错对方的邮箱或名称到达5次系统会冻结此功能需要解冻请联系客服.',
      bodyConfirmRDP: '系统会将下面的VPS导出RDP文件.',
      bodyEditRemark: '温馨提示：如果您有较多VPS你可以给VPS换上备注这样会更容易辨别.',

      bodyDatalossWarning: '<strong>注意:</strong>建议此功能在VPS死机或不能的登录的情况下使用.' +
          ' 此操作就像您在您的计算机直接拔掉电源' +
          ' 电源这样会导致你的电脑一些你未来得及保存的文件以及正在进行的文件文档会丢失.' + '<br/>因此为了保证你的文件不被丢失您要关机或重启请你在VPS里面进行谢谢..',

      bodyRebuildWarning: '<strong>注意:</strong> 此功能会删除原有的所有资料会将无法恢复请您将重要资料进行备份!',
      bodyRestoreWarning: '<strong>注意:</strong> 此功能会删除原有的所有资料会将无法恢复请您将重要资料进行备份!',
      bodySnapshotWarning: '<strong>注意 - 请详细读完此注意事项</strong><ul>' +
          '<li>在制作样版之前您需要操作这3步骤来保证制作样版能顺利制作完成:<ol>' +
          '<li>删除掉所有多余的资料文件等等来确保样版在最佳状态这样能减少制作过程中会出现故障.</li>' +
          '<li>不要删除系统和Windows自带所有工具和文件不然会导致您在复制回来的版本导致故障没网络等等.</li>' +
          '<li>在制作样版之前请在VPS内正常关机这样确保你的重要文件不被丢失.</li></ol></li>' +
          '<li>C制作样版此功能我们是免费提供但请您不要滥用资源此功能不允许拿来当作备份功能使用谢谢合作</strong>,' +
          '此制作样版功能只用于复制一个做成样版然后子复印出更多个相同VPS这样比较方便您同时使用多个VPS:' +
          '<ol><li>为了节省资源您只能制作一个样版. 您可以删除旧的再造一个新的.</li>' +
          '<li>每一个星期您只能制作一次.</li></ol></li>',
      bodyTransferWarning: '<h5 class="text-danger">留意:</h5></br><ol>' +
          '<li>我们是不会触碰到您们的资料文件假如您只想转交VPS不想转交资料你可以重装系统后再进行转交. ' +
          '如果您不重装系统就转交的话对方可以进行操作你的资料文件请谨慎操作.</li>' +
          '<li>C此VPS以前的续费订单贵你所有收件人收到VPS 以后他的续费订单等您将看不见任何信息.</li>' +
          '</ol>',
      bodyRenewWarning: '系统会转跳到付款页面请您确认金额.</br>',
      bodyAutoRenewWarning: '您可以随时点此处来关掉自动续费功能.',
      bodyRDPWarning: '您需要有远程桌面连接管理器来打开RDP文件.' +
          '  <a href="https://docs.microsoft.com/en-us/sysinternals/downloads/rdcman">如果您还没有远程桌面连接管理器请点击此链接下载</a>.<br/>' +
          '如果您使用 Linux系统, 请点击下面链接下载 <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html">Putty</a> 来连接.',

      buttonCancel: '关',
      buttonRestore: '恢复',
      buttonSnapshot: '制作样版',
      buttonRebuild: '重装系统',
      buttonTransfer: '转交',
      buttonRenew: '创建订单',
      buttonSetAutoRenew: '自动续费',
      buttonCancelAutoRenew: '关掉自动续费',
      buttonRDP: '导出RDP文件',
      buttonEdit: '修改'
    },
    action: {
      start: '开启',
      shutdown: '关 VPS',
      stop: '停',
      restart: '重新启动',
      renew: '续费'
    },
    label: {
      yourNote: '您的备注'
    }
  }
}
