export default {
  en: {
    breadcrumb: 'Topup',
    card: {
      headerTopup: 'Topup your account'
    },
    label: {
      topupAmount: 'Amount you want to topup ($1-$10000). Maximum allowed balance is $100000.',
      promotionCodeValid: '<label class="font-italic smaller m-0 text-success">Promotion code is valid </label>',
      promotionCodeInvalid: '<label class="font-italic smaller m-0 text-danger">Promotion code is invalid </label>'
    },
    placeholder: {
      topupAmount: '$0'
    },
    button: {
      reset: 'Reset amount',
      finishOrder: 'Finish order'
    }
  },
  vi: {
    breadcrumb: 'Nạp Tiền',
    card: {
      headerTopup: 'Nạp tiền vào tài khoản'
    },
    label: {
      topupAmount: 'Số tiền bạn muốn nạp (trong khoảng từ $1->$10000). Số dư tài khoản tối đa là $100000.',
      promotionCodeValid: '<label class="text-success">Mã khuyến mãi hợp lệ </label>',
      promotionCodeInvalid: '<label class="text-danger">Mã khuyến mãi không hợp lệ </label>'
    },
    placeholder: {
      topupAmount: '$0'
    },
    button: {
      reset: 'Đặt lại số tiền',
      finishOrder: 'Xác nhận'
    }
  },
  zh: {
    card: {
      headerTopup: '充值余额'
    },
    label: {
      topupAmount: '请输入您需要充值的余额 (请选择 $1->$10000). 余额最多限额为 $100000.'
    },
    placeholder: {
      topupAmount: '$0'
    },
    button: {
      reset: '请重新输入金额',
      finishOrder: '确认'
    }
  }
}
