export default {
  en: {
    breadcrumb: 'Activity Logs',
    cardHeader: {
      title: 'Activity logs',
      description: 'Select a time span:',
      from: 'From',
      to: 'To',
      buttonFilter: 'Filter'
    },
    table: {
      thAction: 'Action',
      thStatus: 'Status',
      thDetail: 'Detail',
      thAccessIP: 'IP',
      thUpdated: 'Time'
    },
    modal: {
      deleteTitle: 'Confirm delete backup?',
      bodyWarningDisable: 'This will also disable backup on the VPS. If you are being charged for the backup disk, it will ' +
        'be automatically adjusted next month.',
      bodyWarningDelete: 'Do you really want to delete this backup? The backup will be permanently destroyed.',
      buttonDelete: 'Delete',

      recoverTitle: 'Confirm recover VPS from backup?',
      bodyWarningRecover: 'The following VPS will be overwrite with the backup',
      bodyWarningRecoverDataLoss: 'All the current data will be deleted permanently. Are you sure?',
      buttonRecover: 'Recover'
    },
    toast: {
      errBackupNotActive: 'The backup is not ready yet! Please wait or contact support!'
    }
  },
  vi: {
    breadcrumb: 'Lịch Sử Hoạt Động',
    cardHeader: {
      title: 'Lịch sự hoạt động',
      description: 'Chọn mốc thời gian:',
      from: 'Từ',
      to: 'Đến',
      thAccessIP: 'IP',
      buttonFilter: 'Lọc'
    },
    table: {
      thAction: 'Hành động',
      thStatus: 'Trạng thái',
      thDetail: 'Chi tiết',
      thAccessIP: 'IP',
      thUpdated: 'Thời gian'
    },
    modal: {
      deleteTitle: 'Xác nhận xóa bản sao lưu?',
      bodyWarningDisable: 'Việc này cũng sẽ tắt chức năng tự động sao lưu của VPS này. Nếu bạn đang thanh toán cho chức năng tự động sao lưu, ' +
        'hệ thống sẽ tự động điều chỉnh giá VPS vào tháng tiếp theo.',
      bodyWarningDelete: 'Bạn thực sự muốn xóa bản sao lưu này?',
      buttonDelete: 'Xóa',

      recoverTitle: 'Xác nhận phục hồi VPS từ bản sao lưu?',
      bodyWarningRecover: 'VPS dưới dây sẽ bị ghi đè bởi bàn sao lưu',
      bodyWarningRecoverDataLoss: 'Tất cả dữ liệu hiện tại sẽ bị xóa và thay thế bởi dữ liệu trong bản backup. Bạn có chắc chắn muốn thực hiện?',
      buttonRecover: 'Phục hồi'
    },
    toast: {
      errBackupNotActive: 'Bản sao lưu chưa sẵn sàng! Vui lòng chờ trong ít phút hoặc liên hệ hỗ trợ!'
    }
  },
  zh: {
    cardHeader: {
      title: '历史活动',
      from: '从',
      to: '到',
      thAccessIP: 'IP',
      buttonFilter: '筛选'
    },
    table: {
      thAction: '行动',
      thStatus: '状态',
      thDetail: '详情',
      thUpdated: '时间'
    },
    modal: {
      deleteTitle: '确认删除此备份?',
      bodyWarningDisable: '此操作会关掉这个VPS的自动备份功能, ' +
        '如果您已经缴费自动备份功能系统将会在下个月调整价钱.',
      bodyWarningDelete: '您是否确认要删除这份备份?',
      buttonDelete: '删除',

      recoverTitle: '是否确认从此VPS恢复备份?',
      bodyWarningRecover: '此VPS会被此备份覆盖',
      bodyWarningRecoverDataLoss: '此操作会删除原有的所有资料文件然后会被您所选的备份覆盖。你是否确认恢复??',
      buttonRecover: '恢复'
    },
    toast: {
      errBackupNotActive: '备份未完成！请稍等片刻 或者联系客服!'
    }
  }
}
