export function FixBugTheme() {
    //Fix bug theme
    let inputs = document.querySelectorAll('input')
    for (let i = 0; i < inputs.length; i++) {

        if (inputs[i].value !== undefined && inputs[i].value !== null && inputs[i].value !== '') {
            inputs[i].parentElement.classList.add('is-filled');
        } else {
            inputs[i].classList.remove('is-filled');
        }

        if (inputs[i].hasAttribute('value')) {
            inputs[i].parentElement.classList.add('is-filled');
        }
        inputs[i].addEventListener('focus', function() {
            this.parentElement.classList.add('is-focused');
        }, false);

        inputs[i].onkeyup = function() {
            if (this.value !== '') {
                this.parentElement.classList.add('is-filled');
            } else {
                this.parentElement.classList.remove('is-filled');
            }
        };

        inputs[i].addEventListener('focusout', function() {
            if (this.value !== '') {
                this.parentElement.classList.add('is-filled');
            }
            this.parentElement.classList.remove('is-focused');
        }, false);
    }

    let ripples = document.querySelectorAll('.btn');

    for (let i = 0; i < ripples.length; i++) {
        ripples[i].addEventListener('click', function(e) {
            let targetEl = e.target;
            let rippleDiv = targetEl.querySelector('.ripple');

            rippleDiv = document.createElement('span');
            rippleDiv.classList.add('ripple');
            rippleDiv.style.width = rippleDiv.style.height = Math.max(targetEl.offsetWidth, targetEl.offsetHeight) + 'px';
            targetEl.appendChild(rippleDiv);

            rippleDiv.style.left = (e.offsetX - rippleDiv.offsetWidth / 2) + 'px';
            rippleDiv.style.top = (e.offsetY - rippleDiv.offsetHeight / 2) + 'px';
            rippleDiv.classList.add('ripple');
            setTimeout(function() {
                rippleDiv.parentElement.removeChild(rippleDiv);
            }, 600);
        }, false);
    }
}