export default {
    en: {
        breadcrumb: 'Dedicated Server list',
        cardHeader: {
            title: 'Your dedicated server list'
        },
        table: {
            thUsername: 'Username',
            thPassword: 'Password',
            thPrice: 'Price',
            thLocation: 'Location',
            thPlan: 'Plan',
            thStart: 'Start',
            thEnd: 'End',
            thAutoRenew: 'Auto renew',
            thPay: 'Pay',
            thNote: 'Note',
            thStatus: 'Status',

            tdShowPw: 'Show',
            tdHidePw: 'Ẩn',
            tdOK: 'OK',
            tdOverdue: 'Over due',
            tdSuspend: 'Suspended',
            tdCancel: 'Cancelled',
            tdUnknown: 'Unknown',
            tdPressToCheck: 'Press to check',
            tdChecking: 'Checking...',
            tdRunning: 'Running',
            tdStopped: 'Stopped',
            tdSuccess: 'Success',
            tdStarting: 'Starting...',
            tdStopping: 'Stopping...',
            tdRestarting: 'Restarting...',
            tdRestoring: 'Restoring...',
            tdError: 'Error',
            tdRGCCreating: 'RGC Creating...'
        },
        tooltip: {
            editRemark: 'Edit your note',
            stop: 'Stop dedicated server',
            start: 'Turn on dedicated server',
            restart: 'Restart dedicated server',
            console: 'Remote on Web by VNC',
            copy_note: 'Copy note',
            edit_note: 'Edit note',
            save_note: 'Save note',
            cancel_note: 'Cancel note',
            rgc_create: 'Create RGC',
            rgc_show: 'Show RGC',
            rgc_delete: 'Delete RGC',
            rgc_hidden: 'Hidden RGC',
            ip_copy: 'Copy IP:Port',
            user_copy: 'Copy login user',
            pw_copy: 'Copy password',
            pw_show: 'Show password',
            pw_hide: 'Hide password',
            owner_dedicated: '{owner_full_name}\'s Dedicated server',
            co_owner_dedicated: 'Shared with {co_owner_full_name}'
        },
        button: {
            editNote: 'Edit note',
            start: 'Start dedicated server',
            stop: 'Stop dedicated server',
            restart: 'Restart dedicated server',
            console: 'Web remote',
            rdp: 'Generate RDP profile',
            transfer: 'Transfer out',
            renew: 'Create renew invoice',
            autorenew: 'Auto renew',
            all: 'All ({0})',
            overdue: 'Over Due ({0})',
            suspended: 'Suspended ({0})',
            cancel: 'Cancel ({0})',
            unknown: 'Unknown ({0})',
            team: 'Team ({0})',
            exportText: 'Export Text',
            exportExcel: 'Export Excel',
            actionMenu: 'Multiple Server Action',
            exportFile: 'Export File',
            share: 'Team member share',
        },
        toast: {
            errNoSelect: 'You have to select at least 1 dedicated server!',
            errPendingTask: 'Another action is in progress! Please wait!',
            errSingleConsole: 'Currently, the system supports open console one by one only!',
            errActionFailed: 'Failed to {0} dedicated server. Please contact support!',

            okExportRDG: 'Successfully exported {0} dedicated server as RDG profile!',
            okExportTextFile: 'Successfully exported {0} selected row as text file!',
            rgcCreating: 'Creating RGC forwarded port for {0}',
            rgcDelete: 'Deleting RGC URL of {0}'
        },
        modal: {
            titleConfirmShare: 'Confirm share {0} dedicated server',
            titleConfirm: 'Confirm {0} {1} dedicated server',
            titleConfirmRenew: 'Generate renew invoice for {0} dedicated server',
            titleConfirmAutoRenew: 'Set auto renew for {0} dedicated server',
            titleConfirmTransfer: 'Confirm transfer out {0} dedicated server',
            titleRDP: 'Generate RDP profile for {0} dedicated server',
            titleExportTextFile: 'Generate text file for {0} selected row',
            titleExportExcelFile: 'Generate excel file for {0} selected row',
            titleEditRemark: 'Edit note for {0} dedicated server',
            titleConfirmCreateRGC: 'Confirm create RGC this dedicated server',
            titleConfirmDeleteRGC: 'Confirm delete RGC URL this dedicated server',

            bodySelectTeamMember: 'Select team member to share',
            bodyTeamMember: '<strong>Attention:</strong> You are giving control permissions of this services to team member. However, YOU ARE STILL RESPONSIBLE FOR THE PAYMENT.',
            bodyConfirm: 'Do you really want to {0} these dedicated server below?',
            bodyConfirmRenew: 'The system will generate an invoice to renew the following dedicated server.',
            bodyConfirmAutoRenew: 'If you set these following dedicated server as automatically renew, the system will automatically generate an' +
                ' invoice to renew 3 days before the due date. If you have sufficient balance, your balance will be automatically deducted.',
            bodyConfirmTransfer: 'Please enter the email of the user that you want to transfer these following dedicated server.<br/>' +
                'For security reason, if you enter wrong email address more than 5 times, this feature will be blocked.',
            bodyConfirmRDP: 'The system will generate an RDP profile for the following dedicated server.',
            bodyConfirmGenerateTextFile: 'The system will generate a text file for the following dedicated server.',
            bodyConfirmGenerateExcelFile: 'The system will generate an excel file for the following dedicated server.',
            bodyEditRemark: 'You can take a note for same purpose dedicated server so it will be easier to identify in case you have a lot of dedicated server.',
            bodySelectRGC: 'Select RGC server to create',
            bodyConfirmDeleteRGC: 'Do you really want to delete RGC URL this dedicated server?',

            bodyDatalossWarning: '<strong>Warning:</strong>This action is the same as press/hold the power button on your PC. It will cut the power on the dedicated server' +
                ' and make the dedicated server {0} immediately. Therefore, some recent data that is currently being saved on disk may be lost. ' +
                ' Use this function only when the dedicated server is not responding. ' + '<br/>We strongly recommend you to shutdown/restart the INSIDE it.',
            bodyTransferWarning: '<h5 class="text-danger">Warning:</h5></br><ol>' +
                '<li>We WILL NOT touch your data, so if you don\'t want to transfer data, simply rebuild the dedicated server before transferring. ' +
                'If you don\'t rebuild the dedicated server, the received person will have access to your data.</li>' +
                '<li>Any due invoices belong to you, the received person will pay the next cycle invoice.</li>' +
                '</ol>>',
            bodyRenewWarning: 'You will be taken to the invoice page to review and pay the invoice.</br>',
            bodyAutoRenewWarning: 'You can cancel the automatically renewal anytime.',
            bodyRDPWarning: 'You will need Remote Desktop Connection Manager to open the profile.' +
                '  <a href="https://docs.microsoft.com/en-us/sysinternals/downloads/rdcman">Download from Microsoft</a>.<br/>' +
                'If you are running Linux, please install <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html">Putty</a> to connect.',

            buttonCancel: 'Close',
            buttonTransfer: 'Transfer',
            buttonRenew: 'Generate invoice',
            buttonSetAutoRenew: 'Set auto renew',
            buttonCancelAutoRenew: 'Cancel auto renew',
            buttonRDP: 'Generate RDP Profile',
            buttonGenerateTextFile: 'Generate text file',
            buttonGenerateExcelFile: 'Generate excel file',
            buttonEdit: 'Edit ',
            buttonCreate: 'Create',
            buttonDelete: 'Delete',
            buttonShare: 'Share',
            buttonDeleteShare: 'Stop sharing',
            period: 'Period renew',
            month: 'Months'
        },
        action: {
            start: 'start',
            shutdown: 'Shutdown',
            stop: 'stop',
            restart: 'restart',
            renew: 'renew'
        },
        label: {
            yourNote: 'Your note'
        }
    },
    vi: {
        breadcrumb: 'Danh Sách Máy Chủ Vật Lý',
        cardHeader: {
            title: 'Danh sách máy chủ vật lý'
        },
        table: {
            thUsername: 'Tên đăng nhập',
            thPassword: 'Mật khẩu',
            thPrice: 'Giá',
            thLocation: 'Khu vực',
            thPlan: 'Plan',
            thStart: 'Bắt đầu',
            thEnd: 'Hết hạn',
            thAutoRenew: 'Tự gia hạn',
            thPay: 'Thanh toán',
            thNote: 'Ghi chú',
            thStatus: 'Trạng thái',

            tdShowPw: 'Hiện',
            tdHidePw: 'Ẩn',
            tdOK: 'OK',
            tdOverdue: 'Quá hạn',
            tdSuspend: 'Tạm khóa',
            tdCancel: 'Chờ hủy',
            tdPressToCheck: 'Ấn để kiểm tra',
            tdUnknown: 'Không rõ',
            tdChecking: 'Đang kiểm tra...',
            tdRunning: 'Đang chạy',
            tdStopped: 'Đã tắt',
            tdSuccess: 'Thành công',
            tdStarting: 'Đang khởi động...',
            tdStopping: 'Đang tắt...',
            tdRestarting: 'Đang khởi động...',
            tdError: 'Lỗi'
        },
        tooltip: {
            editRemark: 'Ghi chú',
            stop: 'Dừng máy chủ vật lý',
            start: 'Bật máy chủ vật lý',
            restart: 'Khởi động lại',
            console: 'Remote nền web',
            copy_note: 'Sao chép ghi chú',
            edit_note: 'Sửa ghi chú',
            save_note: 'Lưu lại thay đổi ghi chú',
            cancel_note: 'Huỷ bỏ thay đổi ghi chú',
            rgc_create: 'Tạo địa chỉ RGC',
            rgc_show: 'Hiện địa chỉ RGC',
            rgc_delete: 'Xoá địa chỉ RGC',
            rgc_hidden: 'Ẩn địa chỉ RGC',
            ip_copy: 'Sao chép địa chỉ IP:Port',
            user_copy: 'Sao chép tên đăng nhập',
            pw_copy: 'Sao chép mật khẩu',
            pw_show: 'Hiển thị mật khẩu',
            pw_hide: 'Ẩn mật khẩu',
            owner_dedicated: 'Máy chủ vật lý này là sở hữu của {owner_full_name}',
            co_owner_dedicated: 'Server này được chia sẻ với {co_owner_full_name}'
        },
        button: {
            status: 'Kiểm tra trạng thái',
            editNote: 'Sửa ghi chú',
            start: 'Bật máy chủ vật lý',
            stop: 'Dừng máy chủ vật lý',
            restart: 'Khởi động lại',
            console: 'Remote nền web',
            rdp: 'Xuất tệp RDP',
            transfer: 'Chuyển giao',
            renew: 'Gia hạn',
            autorenew: 'Tự động gia hạn',
            all: 'Tất cả ({0})',
            overdue: 'Sắp hết hạn ({0})',
            suspended: 'Tạm khóa ({0})',
            cancel: 'Chờ huỷ ({0})',
            unknown: 'Không xác định ({0})',
            team: 'Nhóm ({0})',
            exportText: 'Xuất tệp văn bản',
            exportExcel: 'Xuất tệp Excel',
            actionMenu: 'Thao tác nhiều máy chủ',
            exportFile: 'Xuất tệp',
            share: 'Chia sẻ nhóm'
        },
        toast: {
            errNoSelect: 'Vui lòng lựa chọn ít nhất 1 máy chủ vật lý!',
            errPendingTask: 'Có tác vụ đang thực thi! Vui lòng chờ',
            errSingleConsole: 'Hệ thống chỉ hỗ trợ mở 1 cửa sổ console!',
            errActionFailed: '{0} máy chủ vật lý thất bại. Vui lòng liên hệ support!',

            okExportRDG: 'Thành công xuất RDP cho {0} máy chủ vật lý!'
        },
        modal: {
            titleConfirmShare: 'Xác nhận chia sẻ quyền sử dụng cho {0} máy chủ vật lý',
            titleConfirm: 'Xác nhận {0} {1} máy chủ vật lý',
            titleConfirmRenew: 'Gia hạn cho {0} máy chủ vật lý',
            titleConfirmAutoRenew: 'Đặt tự động gia hạn cho {0} máy chủ vật lý',
            titleConfirmTransfer: 'Xác nhận chuyển giao {0} máy chủ vật lý',
            titleRDP: 'Xuất RDP cho {0} máy chủ vật lý',
            titleEditRemark: 'Sửa ghi chú cho {0} máy chủ vật lý',

            bodySelectTeamMember: 'Lựa chọn thành viên nhóm mà bạn muốn chia sẻ',
            bodyTeamMember: '<strong>Chú ý:</strong> Khi chia sẻ máy chủ vật lý, thành viên trong nhóm sẽ có quyền điều khiển và cài đặt. Tuy nhiên, BẠN VẪN CHỊU TRÁCH NHIỆM CHO VIỆC THANH TOÁN.',
            bodyConfirm: 'Bạn có thực sự muốn {0} những máy chủ vật lý dưới đây?',
            bodyConfirmRenew: 'Hệ thống sẽ tạo Hóa đơn gia hạn cho máy chủ vật lý dưới đây.',
            bodyConfirmAutoRenew: 'Khi cài đặt tự động gia hạn, hệ thống sẽ tự động tạo ra hoá đơn gia hạn 3 ngày trước khi máy chủ vật lý đến hạn. ' +
                'Nếu số dư tài khoản của bạn đủ để gia hạn, hệ thống sẽ tự đông trừ tài khoản của bạn.',
            bodyConfirmTransfer: 'Bạn cần điền email của user mà bạn muốn chuyển giao những máy chủ vật lý sau.<br/>' +
                'Vì lý do bảo mật, nếu bạn nhập sai email quá 5 lần, chức năng này sẽ bị tạm khóa.',
            bodyConfirmRDP: 'Hệ thống sẽ xuất RDP file cho máy chủ vật lý dưới đây.',
            bodyEditRemark: 'Bạn có thể ghi chú cho vài máy chủ vật lý chung mục đích để dễ dàng phân biệt.',

            bodyDatalossWarning: '<strong>Chú ý:</strong>Chỉ dùng chức năng này khi máy chủ vật lý bị treo hoặc không thể đăng nhập.' +
                ' Thao tác này giống như việc bấm nút nguồn trên máy tính, sẽ khiến ngắt điện trên máy chủ vật lý' +
                ' và khiến máy chủ vật lý {0} ngắt ngay lập tức. Do đó, một số dữ liệu chưa được lưu hoặc đang trong tiến trình lưu sẽ bị mất.' + '<br/>Quý khách nên dùng chức năng shutdown/restart BÊN TRONG MÁY CHỦ VẬT LÝ để đảm bảo an toàn dữ liệu.',
            bodyTransferWarning: '<h5 class="text-danger">Lưu ý:</h5></br><ol>' +
                '<li>Chúng tôi sẽ không bao giờ can thiệp vào dữ liệu của bạn. Nếu bạn muốn chuyển giao máy chủ vật lý mà không muốn chuyển giao dữ liệu, hay cài lại hệ điều hành trước khi chuyển giao. ' +
                'Nếu bạn không cài lại hệ điều hành, người nhận có thể toàn quyền truy cập vào dữ liệu của bạn.</li>' +
                '<li>Các hóa đơn quá hạn sẽ thuộc trách nhiệm thanh toán của bạn. Người nhận sẽ thanh toán các hóa đơn kì sau.</li>' +
                '</ol>',
            bodyRenewWarning: 'Bạn sẽ được chuyển đến trang hóa đơn để kiểm tra và thanh toán hóa đơn.</br>',
            bodyAutoRenewWarning: 'Bạn có thể tắt cài đặt tự động gia hạn bất kì lúc nào bằng nút dưới đây.',
            bodyRDPWarning: 'Bạn sẽ cần phần mềm Remote Desktop Connection Manager để mở file RDP.' +
                '  <a href="https://docs.microsoft.com/en-us/sysinternals/downloads/rdcman">Tải từ Microsoft nếu bạn chưa có</a>.<br/>' +
                'Nếu bạn đang sử dụng Linux, vui lòng cài đặt <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html">Putty</a> để kết nối.',

            buttonCancel: 'Đóng',
            buttonTransfer: 'Chuyển giao',
            buttonRenew: 'Tạo hóa đơn',
            buttonSetAutoRenew: 'Đặt tự động gia hạn',
            buttonCancelAutoRenew: 'Tắt tự động gia hạn',
            buttonRDP: 'Xuất DRP',
            buttonEdit: 'Chỉnh sửa',
            buttonShare: 'Chia sẻ',
            buttonDeleteShare: 'Ngừng chia sẻ',
            period: 'Chu kì gia hạn',
            month: 'tháng'
        },
        action: {
            start: 'Bật',
            shutdown: 'Tắt máy chủ vật lý',
            stop: 'Dừng',
            restart: 'Khởi động lại',
            renew: 'Gia hạn'
        },
        label: {
            yourNote: 'Ghi chú của bạn'
        }
    }
}
