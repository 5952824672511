//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import dataRequester from '../../mixins/utilities/DataRequester'
import inputHelper from '../../mixins/utilities/InputHelper'
import { FixBugTheme } from '@/mixins/MaterialTheme';

export default {
  name: "ResetPassword",
  mixins: [ inputHelper, dataRequester ],
  props: {
    currentUser: {}
  },
  data: function () {
    return {
      newPw: '',
      newPw2: '',
      resetPWResult: {
        status: '',
        cssTextStatus: '',
        msg: ''
      },
      metaData: {
        logoPath: '',
        siteName: '',
        footNote: '',
        shortFootNote: '',
        availableLang: ''
      }
    }
  },
  mounted: function () {
    this.FixBugTheme()
  },
  created: function () {
    let self = this

    // Update locale
    if (this.$cookies.get('locale') !== null) {
      this.$i18n.locale = this.$cookies.get('locale')
    } else {
      if (navigator.language.match(/en-/)) {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = navigator.language
      }
    }

    self.getDataFromAPI(self.$config.apiPath.domainGetMeta, function (domainObjectList) {
      let metaData = domainObjectList.data
      self.metaData.logoPath = '../../assets/img/logo/' + metaData.domainObjectList['logo']
      self.metaData.siteName = metaData.domainObjectList['site_name']
      self.metaData.footNote = metaData.domainObjectList['footnote']
      self.metaData.shortFootNote = metaData.domainObjectList['shortfootnote']
      self.metaData.availableLang = JSON.parse(metaData.domainObjectList['available_lang'])
    }, function () {
      this.$router.push('https://levcs.com')
    })
  },
  updated() {
    this.FixBugTheme()
  },
  methods: {
    FixBugTheme,
    login: function () {
      this.$router.push('/login')
    },
    resetPW: function () {
      let self = this
      let strongPassword = new RegExp('(?=.*[0-9])(?=.*[a-z])')
      if (this.newPw !== this.newPw2) {
        self.resetPWResult.status = 'error'
        self.resetPWResult.cssTextStatus = 'text-danger'
        self.resetPWResult.msg = this.$t('recoverPassword.status.errPWNotSame')
      } else if (this.newPw.length < 8) {
        self.resetPWResult.status = 'error'
        self.resetPWResult.cssTextStatus = 'text-danger'
        self.resetPWResult.msg = this.$t('recoverPassword.status.errPWTooShort')
      } else if (!strongPassword.test(this.newPw)) {
        self.resetPWResult.status = 'error'
        self.resetPWResult.cssTextStatus = 'text-danger'
        self.resetPWResult.msg = this.$t('recoverPassword.status.errPWNumber')
      } else {
        this.submitObjectList(this.$config.apiPath.resetPassword, this.user, 'user', {
          email: self.$route.query.email,
          token: self.$route.query.token,
          password: self.newPw
        }, function (result) {
          self.resetPWResult.cssTextStatus = 'text-success'
          self.resetPWResult.status = result.status
          self.resetPWResult.msg = result.msg
        }, function (result) {
          self.resetPWResult.cssTextStatus = 'text-danger'
          self.resetPWResult.status = result.status
          self.resetPWResult.msg = result.msg
        })
      }
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      this.$cookies.set('locale', locale, '5y')
    }
  }
}
