export default {
  en: {
    breadcrumb: 'New Order',
    label: {
      trial_fee: 'Trial fee',
      setup_fee: 'Setup fee',
      quantity: 'Quantity',
      trialAvailable: 'Trial',
      soon: 'Soonest delivery: 24-48 hours after payment',
      setupFee: 'Setup fee',
      setupFree: 'Free on:',
      setupFreeToday: 'Free setup today.',
      setupFree2Months: 'Free setup for all orders longer than 2 months!!!',
      serviceType: 'Service Type',
      selectPlanType: '1/ Choose your service type',
      selectPlan: '2/ Choose your plan',
      orderNote: '3/ Note for your order',
      dedicatedIP: 'IP',
      location: 'Location',
      transfer: 'Transfer',
      qty: 'Quantity',
      price: '{0}/month only',
      descPremium: 'Premium VPS comes with dedicated IP and exceptional processing power.' +
        ' It will be ideal for Web server and any tool that requires dedicated IP to work.',
      descNAT: 'NAT VPS shares the same IP with other VPS on a private LAN. You will save the IP cost without losing processing power.' +
        ' It is designed for tools that does not need dedicated IP or to test your code.',
      descStor: 'Storage VPS has massive storage capacity and unlimited transfer.' +
        ' It is designed for disk-greedy applications or to backup your files.',
      descDedicated: 'No share resource. Maximum performance. Virtualization ready. Most affordable dedicated servers in the world!',
      descOrderNote: 'Note for your order',

      cart: 'Your cart',
      delete: 'Delete',
      total: 'Total',
      stateSelect: 'Select State',
      randomState: 'Allow random state when the selected state is not enough stock?',
      plan: 'Plan',
      serverOS: 'Server OS',
      orderDetail: 'Order Detail',
      serviceQuantity: 'Service Quantity',
      serviceMonth: 'Service Months',
      month: 'Month',
      dayTrial: 'Day trial',
      services: 'Services',
      agreePolicyAndTerms: 'I agree to <strong><a href="{0}">Term of services</a></strong>, <strong><a href="{1}">Service level agreement</a></strong> and <strong><a href="{2}">Privacy policy</a></strong> of <strong>{3}</strong>.',
      dedicatedCreated24h: 'Dedicated server will be delivered within 24-48 hours after payment.',
      testingFor24h: 'I want to test this plan for 24 hour.'
    },
    button: {
      empty: 'Empty cart',
      checkOut: 'Check out',
      trial: 'Trial',
      addToCart: 'Add to cart'
    },
    table: {
      h_name: 'Plan',
      h_cpu: 'Vcore CPU',
      h_ram: 'Ram',
      h_storage: 'Storage',
      h_traffic: 'Traffic',
      h_network: 'Network',
      h_price: 'Price',
      h_netword_port: 'Network Port',
      h_note: 'Note',
      h_stock: 'Stock',
      h_deliverDate: 'Deliver Date',
      h_setupFee: 'setup fee',
      h_setupFree: 'Free setup on',
      mo: 'MO',
      unlimited: 'Unlimited'
    },
    toast: {
      zeroQty: 'You have to order at least 1 VPS {0}!',
      added: '{0} x {1} has been added to your cart!'
    },
    tooltip: {
      randomState: 'Allow random when the selected location is out of stock.',
      setupFee: 'This is a one-time setup fee for the service. It is charged only the first time when you order the service.',
      noRefund: 'No refund for mid-term cancellation when you get free setup fee',
    }
  },
  vi: {
    breadcrumb: 'Đặt Hàng Mới',
    table: {
      h_name: 'Plan',
      h_cpu: 'Vcore CPU',
      h_ram: 'Ram',
      h_storage: 'Ổ Cứng',
      h_traffic: 'Lưu Lượng',
      h_network: 'Mạng',
      h_price: 'Giá Tiền',
      h_netword_port: 'Port mạng',
      h_note: 'Ghi Chú',
      h_stock: 'Tồn Kho',
      h_deliverDate: 'Ngày giao hàng',
      h_setupFee: 'Phí cài đặt',
      h_setupFree: 'Miễn phí cài đặt',
      mo: 'Tháng',
      unlimited: 'Không giới hạn'
    },
    label: {
      trial_fee: 'Phí dùng thử',
      setup_fee: 'Phí cài đặt',
      soon: 'Giao hàng sớm nhất: 24-48 giờ sau khi thanh toán',
      quantity: 'Số lượng',
      trialAvailable: 'Dùng thử',
      setupFee: 'Phí cài đặt',
      setupFree: `Miễn phí ngày:`,
      setupFreeToday: `Miễn phí cài đặt ngay trong hôm nay.`,
      setupFree2Months: `Miễn phí cài đặt cho tất cả đơn hàng mới từ 2 tháng trở lên`,
      serviceType: 'Loại dịch vụ',
      selectPlanType: '1/ Lựa chọn loại VPS',
      selectPlan: '2/ Lựa chọn gói VPS phù hợp',
      orderNote: '3/ Ghi chú đơn hàng',
      dedicatedIP: 'IP',
      location: 'Quốc gia',
      transfer: 'Lưu lượng',
      qty: 'Số lượng',
      price: '{0}/tháng',
      descPremium: 'Premium VPS là VPS IP riêng với hiệu năng cao,' +
        ' được tối ưu cấu hình cho các công việc đòi hỏi IP riêng như Web Server, offer, nuôi Account...',
      descNAT: 'NAT VPS là VPS độc lập, nhưng IP được dùng chung với các vps khác trong một hệ thống mạng LAN ảo. Các gói này' +
        ' giúp bạn tiết kiệm được chi phí IP mà không cắt giảm hiệu năng.' +
        ' Các gói này phù hợp với mục đích sử dụng không cần IP riêng hoặc để test code.',
      descStor: 'Storage VPS có dung lượng ổ cứng cao và không giới hạn lưu lượng sử dụng.' +
        ' Các gói này phù hợp với các ứng dụng lưu trữ hoặc backup.',
      descDedicated: 'Các server riêng, rất mạnh, không chia sẻ tài nguyên, bạn có toàn quyền sử dụng tối đa hiệu' +
        ' suất của cả một server. Các server đã được cài đặt đầy đủ, sẵn sàng sử dụng, là dòng server đã được tối ưu cả về chi phí và hiệu năng.',
      descOrderNote: 'Ghi chú đơn hàng',

      cart: 'Giỏ hàng',
      delete: 'Xóa',
      total: 'Tổng cộng',
      stateSelect: 'Chọn khu vực',
      randomState: 'Cho phép lựa chọn khu vực ngẫu nhiên khi khu vực được lựa chọn không còn hàng?',
      plan: 'Gói dịch vụ',
      serverOS: 'Hệ điều hành',
      orderDetail: 'Chi tiết đơn hàng',
      serviceQuantity: 'Số lượng dịch vụ',
      serviceMonth: 'Số tháng dịch vụ',
      month: 'Tháng sử dụng',
      dayTrial: 'Ngày dùng thử',
      services: 'Dịch vụ',
      agreePolicyAndTerms: 'Tôi đồng ý với <strong><a href="{0}" target="_blank">điều khoản sử dụng</a></strong>, <strong><a href="{1}" target="_blank">chính sách dịch vụ</a>, và <a href="{2}" target="_blank">chính sách bảo mật</a></strong> của <strong>{3}</strong>.',
      dedicatedCreated24h: 'Dedicated sẽ được khởi tạo trong vòng 24h-48h kể từ khi thanh toán.',
      testingFor24h: 'Tôi muốn sử dụng thử gói dịch vụ này trong 24 giờ.'
    },
    button: {
      empty: 'Xóa giỏ hàng',
      checkOut: 'Đặt hàng',
      trial: 'Dùng thử',
      addToCart: 'Thêm vào giỏ',
    },
    toast: {
      zeroQty: 'Bạn cần có tối thiểu 1 VPS {0} trong giỏ hàng!',
      added: '{0} x {1} đã được thêm vào giỏ hàng!'
    },
    tooltip: {
      randomState: 'Cho phép chọn bang/vùng khác nếu bang/vùng đã chọn không còn hàng.',
      setupFee: 'Đây là phí cài đặt lần đầu cho dịch vụ. Phí này chỉ được tính 1 lần duy nhất cho suốt vòng đời của dịch vụ.',
      noRefund: 'Khi miễn phí cài đặt, quý khách sẽ không được hủy dịch vụ giữa chu kì.',
    }
  },
  zh: {
    label: {
      selectPlanType: '1/ 选择VPS类型',
      selectPlan: '2/ 选择您需要的套餐',
      orderNote: '3/ 订单备注',
      dedicatedIP: 'IP',
      location: '国家地区',
      transfer: '流量',
      qty: '数量',
      price: '{0}/月',
      descPremium: '高级VPS具有专用IP和卓越的处理能力,' +
        ' 它将是Web服务器和任何需要专用IP才能工作的工具的理想选择比如养号等...',
      descNAT: 'NATVPS与专用LAN上的其他VP共享同一IP。您将在不损失处理能力的情况下节省IP成本。。.' +
        ' 它是为不需要专用IP或测试代码的工具而设计的.',
      descStor: '存储VPS具有巨大的存储容量和无限的传输.' +
        ' 它是为需求大量磁盘的应用程序或备份您的文件而设计的。.',
      descDedicated: '独立主机，不共享资源。最高性能。各项设置就绪。世界上最便宜的专用服务器！.',
      descOrderNote: '订单备注',

      cart: '购物车',
      delete: '删除',
      total: '总数'
    },
    button: {
      empty: '移出购物车',
      checkOut: '订货',
      addToCart: '放入购物车'
    },
    toast: {
      zeroQty: '您最少要有1个 VPS {0} 在购物车!',
      added: '{0} x {1}已放入购物车!'
    }
  }
}
