//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {FixBugTheme} from '@/mixins/MaterialTheme'
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from "@/mixins/utilities/InputHelper";

import LevDropdownMenu from "@/components/children/lev_components/LevDropdownMenu.vue";
import HostingPlans from "@/components/children/order/table-plans/HostingPlans.vue";
import DedicatedPlans from "@/components/children/order/table-plans/DedicatedPlans.vue";
import ProxyPlans from "@/components/children/order/table-plans/ProxyPlans.vue";
import VmPlans from "@/components/children/order/table-plans/VmPlans.vue";
import OsList from "@/components/children/order/OsList.vue";
import HostingInfo from "@/components/children/order/HostingInfo.vue"
import TitleNumber from "@/components/children/order/TitleNumber.vue";
import LevButton from "@/components/children/lev_components/LevButton.vue";
import LevInput from "@/components/children/lev_components/LevInput.vue";

export default {
  name: "NewOrder",
  components: {
    LevDropdownMenu,
    HostingPlans,
    DedicatedPlans,
    ProxyPlans,
    VmPlans,
    OsList,
    HostingInfo,
    TitleNumber,
    LevButton,
    LevInput
  },
  mixins: [dataRequester, inputHelper],
  props: {
    metaData: Object,
    languageSelected: String,
    currentUser: Object
  },
  data() {
    return {
      serviceTypes: [],
      planList: [],
      hostingPlanList: [],
      newOrderObject: {
        random_state: false,
        service_type: null,
        service_type_id: null,
        qty: null,
        service_plan_id: null,
        service_region_id: null,
        service_other_info: null,
        period: null,
        image_detail: null,
        remark: '',
        addon: {
          vcores: 0,
          ram: 0,
          disk: 0,
          period: 1,
          qty: 1,
        },
        acceptPolicy: true
      },
      selectedPlan: null,
      countryCodeSelected: null,
      stateSelected: undefined,
      countries: [],
      regions: [],
      states: [],
      planFilter: [],
      imageList: [],
      is_testing_order: false,
      filter: undefined,
      hostingInfo: {},
      hostingPlanId: null,
      isCheckOutButtonLoading: false,
    }
  },
  computed: {
    priceTotal: function () {
      if (this.is_testing_order) {
        return this.selectedPlan === null ? 0 : (this.selectedPlan['testing_price']) || 0
      } else {
        return this.selectedPlan === null ? 0 : ((this.selectedPlan['price'] + this.newOrderObject.addon.vcores * 1.5 + this.newOrderObject.addon.ram * 1 + this.newOrderObject.addon.disk / 10 * 1) * this.newOrderObject.addon.period * this.newOrderObject.addon.qty + (parseInt(this.newOrderObject.addon.period) === 1 ? (this.selectedPlan['real_setup_fee'] || 0) : 0) * this.newOrderObject.addon.qty)
      }
    },
    imageDescription: function () {
      if (this.newOrderObject.service_plan_id !== null
          && this.newOrderObject.image_detail['description'] !== null
          && this.newOrderObject['service_type_id'] !== this.$constants.PROXY_SERVICE_TYPE_ID) {
        try {
          return JSON.parse(this.newOrderObject.image_detail['description'])['description'][this.languageSelected.substring(0,2)]
        } catch {
          return null
        }
      } else {
        return null
      }
    },
    isPromotionFilter: function () {
      return this.filter === 'promotion'
    },
    isPromotionSoldOut: function () {
      return function (serviceTypeId) {
        return this.planList.filter(plan => plan['service_type']['service_type_id'] === serviceTypeId).every(plan => plan['stock'] === 0)
      }
    },
    planServiceTypeSelected: function () {
      if (this.newOrderObject['service_type_id'] === this.$constants.PROXY_SERVICE_TYPE_ID) {
        return 'proxy'
      } else if (this.newOrderObject['service_type_id'] === this.$constants.HOSTING_SERVICE_TYPE_ID) {
        return 'hosting'
      } else {
        if (this.newOrderObject['service_plan_id'] !== null) {
          return 'vms'
        } else {
          return 'none'
        }
      }
    },
    hostingPlanListFilter: function () {
      if (this.stateSelected === undefined) {
        return []
      } else {
        return this.hostingPlanList.filter(plan => plan['region']['country_code'] === this.countryCodeSelected && plan['region']['state'] === this.stateSelected)
      }
    },
    servicePlanName: function () {
      if (![null, undefined].includes(this.selectedPlan['service_type']) && this.selectedPlan['service_type']['service_type_id'] === this.$constants.DEDICATED_SERVICE_TYPE_ID) {
        return this.selectedPlan['dedicated_plan_name']
      } else if (this.selectedPlan['service_type_id'] === this.$constants.HOSTING_SERVICE_TYPE_ID) {
        return this.selectedPlan['name']
      } else {
        return this.selectedPlan['vm_plan_name']
      }
    }
  },
  watch: {
    states: {
      handler: function () {
        let self = this
        self.newOrderObject.service_region_id = self.planList.filter(plan => plan['region']['country_code'] === self.countryCodeSelected && plan['region']['state'] === self.states[0]).map(plan => plan['region']['region_id'])[0]
        self.newOrderObject.service_plan_id = null
        // self.newOrderObject.image_detail = null
        self.clearCheckedRadioButtonByName('btnradioPlan')
        this.newOrderFilterLoad()
      },
      deep: true
    },
    is_testing_order: {
      handler: function () {
        let self = this
        if(self.is_testing_order){
          self.newOrderObject.addon.qty = 1
          self.newOrderObject.addon.period = 1
        }
      },
      deep: true
    },
    selectedPlan: {
      handler: function () {
        if (![null, undefined].includes(this.selectedPlan)) {
          if ([null, undefined, 0].includes(this.selectedPlan['testing_price'])) {
            this.is_testing_order = false
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  updated() {
    this.FixBugTheme()
  },
  created() {
    let self = this
    this.filter = this.$route.query['filter']

    this.submitObjectList(this.$config.apiPath.vmPlanList, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.planList = result.data['VMPlanObjectList']
        self.submitObjectList(self.$config.apiPath.dedicatedPlanList, undefined, undefined, undefined, function(response) {
          if(response.status === 'success'){
            self.planList = self.planList.concat(response.data['dedicatedPlanObjectList'])
            if (self.isPromotionFilter) {
              self.planList = self.planList.filter(plan => plan['is_promoted'] === 1)
            } else {
              self.planList = self.planList.filter(plan => plan['is_promoted'] === 0)
            }
          } else {
            self.$toasted.error(response.msg)
          }
        })
      } else {
        self.$toasted.error(result.msg)
      }
    })
    this.submitObjectList(this.$config.apiPath.hostingPlanList, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.hostingPlanList = result.data['WebHostingPlansList']
      } else {
        self.$toasted.error(result.msg)
      }
    })
    this.getDataFromAPI(this.$config.apiPath.imageList, function (result) {
      // self.imageList = result.data.sort((a, b) => a['os_type'] < b['os_type'] ? 1 : -1)
      self.imageList = result.data
      self.newOrderObject.image_detail = self.imageList.filter(x => x['name'].includes('2012R2'))[0]
    })
    this.getDataFromAPI(this.$config.apiPath.getServiceTypes, function (result) {
      self.serviceTypes = result.data['serviceTypeObjectList']
    })
  },
  methods: {
    FixBugTheme,
    newOrderFilterLoad: function () {
      let self = this

      if (self.newOrderObject.service_type_id !== null) {
        self.planFilter = self.planList.filter(plan => plan['service_type']['service_type_id'] === self.newOrderObject.service_type_id &&
            plan['region']['region_id'] === self.newOrderObject.service_region_id)

        if(self.newOrderObject.service_type_id === self.$constants.DEDICATED_SERVICE_TYPE_ID) {
          self.planFilter = self.planFilter.sort((a, b) => a['dedicated_plan_name'] > b['dedicated_plan_name'] ? 1 : -1)
        } else if(self.newOrderObject.service_type_id === self.$constants.HOSTING_SERVICE_TYPE_ID) {
          self.planFilter = self.hostingPlanList
        } else {
          self.planFilter = self.planFilter.sort((a, b) => a['vm_plan_name'] > b['vm_plan_name'] ? 1 : -1)
        }
      }
    },
    countryOnChange: function (event) {
      let self = this
      self.countryCodeSelected = event.target.id
      self.is_testing_order = false
      self.states = [...new Map(self.regions.filter(r => r['country_code'] === self.countryCodeSelected).map(region => [region['state'], region])).values()].map(x => x['state'])
      this.stateSelected = self.states[0]
      this.newOrderFilterLoad()
      window.scrollTo({
        top: document.getElementById("step3").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    stateOnChange: function (event) {
      let self = this
      this.stateSelected = event.target.value
      self.newOrderObject.service_region_id = self.planList.filter(plan => plan['region']['country_code'] === self.countryCodeSelected && plan['region']['state'] === this.stateSelected).map(plan => plan['region']['region_id'])[0]
      self.newOrderObject.service_plan_id = null
      self.is_testing_order = false
      // self.newOrderObject.image_detail = null
      self.clearCheckedRadioButtonByName('btnradioPlan')
      this.newOrderFilterLoad()
    },
    serviceTypeOnChange: function (event) {
      let self = this
      self.newOrderObject.service_type = self.serviceTypes.find(x => x.id === parseInt(event.target.value))
      self.newOrderObject.service_type_id = parseInt(event.target.value)
      self.newOrderObject.service_plan_id = null
      // self.newOrderObject.image_detail = null
      self.newOrderObject.service_region_id = null
      self.hostingPlanId = null
      self.selectedPlan = null
      self.countryCodeSelected = null
      self.is_testing_order = false
      self.stateSelected = undefined
      if (this.planServiceTypeSelected === 'hosting') {
        self.newOrderObject.addon.qty = 1
        self.hostingInfo['email'] = self.currentUser['email']
        self.regions = [...new Map(self.hostingPlanList.filter(plan => plan['service_type_id'] == self.$constants.HOSTING_SERVICE_TYPE_ID).map(item => [item['region']['region_id'], item])).values()].map(vm => vm['region']).sort((a, b) => a['region_id'] > b['region_id'] ? 1 : -1)
        self.countries = [...new Map(self.regions.map(region => [region['country_code'], region])).values()]
      } else {
        self.regions = [...new Map(self.planList.filter(plan => plan['service_type']['service_type_id'] == self.newOrderObject.service_type_id).map(item => [item['region']['region_id'], item])).values()].map(vm => vm['region']).sort((a, b) => a['region_id'] > b['region_id'] ? 1 : -1)
        self.countries = [...new Map(self.regions.map(region => [region['country_code'], region])).values()]
      }
      self.clearCheckedRadioButtonByName('btnradioCountry')
      this.newOrderFilterLoad()
      window.scrollTo({
        top: document.getElementById("step2").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    planIdOnChange: function (planId) {
      let self = this
      if (this.planServiceTypeSelected === 'hosting') {
        self.hostingPlanId = planId
        self.selectedPlan = self.hostingPlanList.find(plan => plan['id'] === planId)
      } else {
        self.newOrderObject.service_plan_id = planId
        self.selectedPlan = self.planFilter.filter(plan => plan['service_plan_id'] === self.newOrderObject.service_plan_id)[0]
      }

      window.scrollTo({
        top: document.getElementById("step4").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    imageOnChange: function (imageId) {
      let self = this
      self.newOrderObject.image_detail = self.imageList.find(image => image['id'] === imageId)
    },
    clearCheckedRadioButtonByName: function (radioButtonName) {
      $('input:radio[name=\"' + radioButtonName +'\"]').removeAttr('checked');
      $('input:radio[name=\"' + radioButtonName +'\"]').prop('checked', false);
    },
    createOrder() {
      let self = this
      let itemRequest = []

      if (self.planServiceTypeSelected === 'hosting') {
        itemRequest = [
          {
            service_type_id: self.newOrderObject.service_type_id,
            qty: self.newOrderObject.addon.qty,
            period: self.newOrderObject.addon.period,
            hosting_info: {
              plan_id: self.hostingPlanId,
              region_id: self.newOrderObject.service_region_id,
              service_country: self.countryCodeSelected,
              domain: self.hostingInfo.domain,
              email: self.hostingInfo.email,
              username: self.hostingInfo.username,
              password: self.hostingInfo.password,
            }
          }
        ]
      } else {
        itemRequest = [
          {
            service_type_id: self.newOrderObject.service_type_id,
            qty: self.newOrderObject.addon.qty,
            service_plan_id: self.newOrderObject.service_plan_id,
            service_region_id: self.newOrderObject.service_region_id,
            period: self.newOrderObject.addon.period,
            service_other_info: JSON.stringify({image_id: self.newOrderObject.image_detail['id']})
          }
        ]
      }

      self.isCheckOutButtonLoading = true
      this.submitObjectList(this.$config.apiPath.orderPlace, {
        type: self.is_testing_order ? 'testing' : 'new',
        random_state: self.newOrderObject.random_state,
        remark: self.newOrderObject.remark,
        items: itemRequest
      }, 'order', undefined, function(result) {
        if(result.status === 'success'){
          window.location.href = "#/home/order/detail/" + result.data.id
        } else {
          self.$toasted.error(result.msg)
        }
      }, undefined, true, function () {
        self.isCheckOutButtonLoading = false
      })
    }
  }
}
