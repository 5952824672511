//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LevTable from "@/components/children/LevTable2.vue";
import LevModal from "@/components/children/lev_components/LevModal.vue";
import LevInput from "@/components/children/lev_components/LevInput.vue";
import LevItemList from "@/components/children/lev_components/LevItemList.vue";
import IconButton from "@/components/children/icon_function/IconButton.vue";

import dataRequester from "@/mixins/utilities/DataRequester";
import dataObjectListMixin from "@/mixins/DataObjectList";
import inputHelper from "@/mixins/utilities/InputHelper";

export default {
  name: 'HostingList',
  components: {
    LevModal,
    LevTable,
    LevInput,
    LevItemList,
    IconButton
  },
  mixins: [dataObjectListMixin, dataRequester, inputHelper],
  data () {
    let self = this
    return {
      fields: [
        {
          name: 'domain',
          title: self.$t('hosting.table.header.domain'),
          sortField: 'domain'
        },
        {
          name: 'username',
          title: self.$t('hosting.table.header.username'),
          sortField: 'username'
        },
        {
          name: 'password',
          title: self.$t('hosting.table.header.password'),
          sortField: 'password'
        },
        {
          name: 'email',
          title: self.$t('hosting.table.header.email'),
          sortField: 'email'
        },
        {
          name: 'price',
          title: self.$t('hosting.table.header.price'),
          sortField: 'plan_name'
        },
        {
          name: 'plan_name',
          title: self.$t('hosting.table.header.planName'),
          sortField: 'plan_name'
        },
        {
          name: 'region',
          title: self.$t('hosting.table.header.location'),
          sortField: 'plan_name'
        },
        {
          name: 'start_date',
          title: '<i class="far fa-calendar-plus"></i><span class="text-bolder text-uppercase"> ' + self.$t('hosting.table.header.startDate') + '</span>',
          width: '8%',
          sortField: 'start_date'
        },
        {
          name: 'end_date',
          title: '<i class="far fa-calendar-times"></i><span class="text-bolder text-uppercase"> ' + self.$t('hosting.table.header.endDate') + '</span>',
          width: '8%',
          sortField: 'end_date'
        },
        {
          name: 'auto_renew',
          title: `<span class="text-uppercase"><i class="fas fa-retweet"></i>${self.$t('hosting.table.header.autoRenew')}</span>`,
          sortField: 'auto_renew',
        },
        {
          name: 'user_remark',
          title: `<span class="text-uppercase"><i class="fas fa-edit"></i>${self.$t('hosting.table.header.note')}</span>`,
          order: 9,
          extended_data: function (rowData) {
            // To allow search on the table
            return rowData['user_remark']
          },
          sortField: 'user_remark',
        },
        {
          name: 'payment_status',
          title: '<span class="text-uppercase"><i class="fas fa-file-invoice-dollar"></i> ' + self.$t('hosting.table.header.paymentStatus') + '</span>',
          sortField: 'payment_status',
        },
        {
          name: 'cpanel',
          sortField: 'is_editing_note'
        },
        {
          name: 'is_editing_note',
          extended_data: false,
          visible: false
        },
        {
          name: 'is_password_show',
          extended_data: false,
          visible: false
        }
      ],
      extendtedFields: [

      ],
      searchableFields: ['domain', 'username', 'password', 'email', 'plan_name', 'start_date', 'end_date', 'user_remark', 'payment_status'],
      visibleFields: [],
      modalName: '',
      noteContentForUpdate: '',
      periodRenew: 1,
      menuActionItems: [
        {
          icon: 'fas fa-edit',
          text: this.$t('hosting.button.editNote'),
          color: 'secondary',
          openModal: () => {
            this.showModal('edit-note')
          }
        },
        {
          icon: 'fas fa-calendar',
          text: this.$t('hosting.button.invoiceRenew'),
          color: 'info',
          openModal: () => {
            this.showModal('invoice-renew')
          }
        },
        {
          icon: 'fas fa-retweet',
          text: this.$t('hosting.button.enableAutoRenew'),
          color: 'success',
          openModal: () => {
            this.showModal('enable-auto-renew')
          }
        },
        {
          icon: 'fas fa-ban',
          text: this.$t('hosting.button.disableAutoRenew'),
          color: 'danger',
          openModal: () => {
            this.showModal('disable-auto-renew')
          }
        }
      ]
    }
  },
  computed: {
    modalContenObj: function () {
      let self = this
      switch (self.modalName) {
          case 'edit-note':
            return {
              title: self.$t('hosting.modal.title.editNote', [self.selectedObjectList.length]),
              buttonConfirm: self.$t('hosting.modal.button.confirmEditNote'),
              confirmAction: () => {
                self.updateRemark(self.selectedObjectList.map(x => x['id']), self.noteContentForUpdate)
              }
            }
          case 'invoice-renew':
            return {
              title: self.$t('hosting.modal.title.invoiceRenew', [self.selectedObjectList.length]),
              buttonConfirm: self.$t('hosting.modal.button.confirmInvoiceRenew'),
              confirmAction: () => {
                self.renew()
              }
            }
          case 'enable-auto-renew':
            return {
              title: self.$t('hosting.modal.title.enableAutoRenew', [self.selectedObjectList.length]),
              buttonConfirm: self.$t('hosting.modal.button.confirmEnableAutoRenew'),
              confirmAction: () => {
                self.enableAutoRenew()
              }
            }
          case 'disable-auto-renew':
            return {
              title: self.$t('hosting.modal.title.disableAutoRenew', [self.selectedObjectList.length]),
              buttonConfirm: self.$t('hosting.modal.button.confirmDisableAutoRenew'),
              confirmAction: () => {
                self.disableAutoRenew()
              }
            }
          default:
            return {
              title: 'Modal title',
              confirmAction: () => {}
            }
      }
    },
  },
  created () {
    this.getHostingList()
  },
  methods: {
    onSelectedChanged(selectedRowID) {
      this.selectedObjectList = this.dataObjectList.filter(d => selectedRowID.includes(d['id']))
    },
    onCellClickProcessing(event, rowData, cellField, updatePostTable) {
      updatePostTable()
    },
    windowOpen(url, target= '_blank') {
      window.open(url, target)
    },

    getHostingList () {
      let self = this
      this.submitObjectList(this.$config.apiPath.hostingList, undefined, undefined, undefined,
          function (response) {
            self.dataObjectList = response.data['WebHostingList']
          })
    },
    updateRemark (idList, newNote) {
      let self = this
      idList = [idList].flat()
      this.submitObjectList(this.$config.apiPath.hostingUpdateNote,
          idList.map(function (id) { return {id: id} }),
          'webhostingList',
          {user_remark: newNote}, function (result) {
            self.$toasted.success(result.msg)
            idList.forEach(id => {
              self.setItemProperty(id, 'user_remark', newNote)
            })
          }, function () {
          },true , function () {
            self.hideModal()
          })
    },
    updateUserRemark (rowData) {
      let newRemark = document.getElementById('user_remark_value').value
      rowData['is_editing_note'] = false
      this.setItemProperty(rowData['id'], 'user_remark', newRemark)
      this.updateRemark(rowData['id'], newRemark)
    },

    renew() {
      let self = this
      this.submitObjectList(this.$config.apiPath.hostingRenew,
          this.selectedObjectList.map(function (item) { return {id: item['id']} }),
          'webhostingList',
          {period: self.periodRenew}, function (result) {
            let orderId = result.data['id']
            window.location.href = `#/home/order/detail/${orderId}`
          }, function () {
          }, true, function () {
            self.hideModal()
          })
    },

    enableAutoRenew() {
      let self = this
      this.submitObjectList(this.$config.apiPath.hostingEnableAutoRenew,
          this.selectedObjectList.map(function (item) { return {id: item['id']} }),
          'webhostingList',
          {}, function (result) {
            self.$toasted.success(result.msg)
            self.selectedObjectList.forEach(item => {
              self.setItemProperty(item['id'], 'auto_renew', 1)
            })
          }, function () {
          }, true, function () {
            self.hideModal()
          })
    },
    disableAutoRenew() {
      let self = this
      this.submitObjectList(this.$config.apiPath.hostingDisableAutoRenew,
          this.selectedObjectList.map(function (item) { return {id: item['id']} }),
          'webhostingList',
          {}, function (result) {
            self.$toasted.success(result.msg)
            self.selectedObjectList.forEach(item => {
              self.setItemProperty(item['id'], 'auto_renew', 0)
            })
          }, function () {
          }, true, function () {
            self.hideModal()
          })
    },

    showModal(modalName) {
      if (this.selectedObjectList.length === 0) {
        this.$toasted.error(this.$t('general.toast.noItemSelected'))
        return
      }
      this.modalName = modalName

      switch (this.modalName) {
        case 'edit-note':
          if (this.selectedObjectList.length === 1) {
            this.noteContentForUpdate = this.selectedObjectList[0]['user_remark']
          } else {
            this.noteContentForUpdate = ''
          }
          break
        default:
          break
      }

      $(`#main-modal`).modal('show')
    },
    hideModal() {
      this.$refs['main-modal'].hide()
    }
  }
}

