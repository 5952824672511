//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import inputHelper from '../../mixins/utilities/InputHelper'
import dataRequester from '../../mixins/utilities/DataRequester'
import { FixBugTheme } from '@/mixins/MaterialTheme';
import confirm2Fa from "@/components/children/Confirm2Fa";

import axios from 'axios'
export default {
  name: "Password",
  components: {confirm2Fa},
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {}
  },
  data: function () {
    return {
      password: '',
      password1: '',
      password2: ''
    }
  },
  mounted() {
    this.FixBugTheme()
  },
  created: function () {
  },
  methods: {
    FixBugTheme,
    changePassword: function (otp_code) {
      if (this.password1 !== this.password2) {
        this.$toasted.error(this.$t('profile.toast.pwNotSame'))
      } else {
        let self = this
        let submitData = {
          username: this.$cookies.get('username'),
          sessionId: this.$cookies.get('sessionId'),
          oldPassword: this.password,
          newPassword: this.password1,
          otp_code: otp_code
        }
        axios.post(this.$config.apiPath.updatePassword, submitData)
            .then(function (response) {
              if (response.data.status === 'success') {
                if (response.data.data.is_enable_2fa) {
                  $('#otp_modal').modal('show')
                } else {
                  self.$toasted.success(response.data.msg)
                  $('#otp_modal').modal('hide')
                }
              } else {
                self.$toasted.error(response.data.msg)
                $('#otp_modal').modal('hide')
              }
            })
            .catch(function (err) {
              self.$toasted.error('Error when requesting API!')
              $('#otp_modal').modal('hide')
              // console.log(err)
            })
      }
    }
  }
}
